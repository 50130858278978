import React, { Component } from "react";
import { logout, chkapiinput, generateId } from "./Utility";
import customFetch from "./apicall/api";
import { ThemeContext } from "../context/IsLoader";
import { PEButton, PEDiv, PEImg, PEInput, PEA, PESpan, PETextArea, PELI } from "./permissionComponents";
const generate_Id1 = generateId(10);
class TransactionList extends Component {
  static contextType = ThemeContext;
  constructor(props) {
    super(props);

    this.state = {
      api_token: localStorage.getItem("api_token"),
      transactionList: [],
      isSpin: false,
      flag: 0,
    };
  }

  fetchDetails = async () => {
    try {
      const uid = localStorage.getItem("in_userid");
      const api_token = this.state.api_token;
      /*Check if data is null then logout*/
      var arr = [uid];
      var chkdata = chkapiinput(arr);
      if (chkdata === false) {
        this.setState({ isSpin: false });
        setTimeout(function() {
          logout();
        }, 2000);
      }
      /*Check if data is null then logout End*/
      const params = {
        userid: uid,
      };
      const queryParams = new URLSearchParams(params).toString();
      let generate_Id = generateId(10);
      const requestOptions = {
        method: "GET",
        headers: {
          "api-token": api_token,
          component_id: generate_Id1,
          apiId: generate_Id,
          api_group_id: "",
          component: this.constructor.name,
          user_id: localStorage.getItem("in_userid"),
        },
      };

      const response = await customFetch(
        process.env.REACT_APP_URL + "/reversal/get/transactions?" + queryParams,
        requestOptions
      );

      const data = await response.json();

      return data;
    } catch (e) {
      console.error(e);
      return [];
    }
  };

  componentDidMount() {
    if (
      window.Android &&
      typeof window.Android.getDataFromLocalStorage === "function"
    ) {
      const data = window.Android.getDataFromLocalStorage("your_key");
      if (data) {
        this.setState({ isSpin: false, flag: 1 }); // Set flag to 1 if accessed from Android
        const tokenParts = data.split(",");
        const tokenData = {};

        tokenParts.forEach((part) => {
          const [key, value] = part.split(":");
          tokenData[key] = value;
        });

        console.log("AndroidInterface->", data);
      }
    } else {
      console.log(
        "Error in AndroidInterface: Android method not found or not available."
      );
    }

    setTimeout(() => {
      if (document.readyState === "complete") {
        this.context.onPageLoad({
          component: this.constructor.name,
          component_id: generate_Id1,
        });
      } else {
        window.addEventListener("load", this.context.onPageLoad());
        this.cleanup = () =>
          window.removeEventListener("load", this.context.onPageLoad());
      }
    }, 1000);
    const uid = localStorage.getItem("in_userid");
    if (!uid) {
      localStorage.clear();
      this.props.history.push("/");
    }
    this.setState({ isSpin: true });
    this.fetchDetails().then((_transactionList) => {
      this.setState({ transactionList: _transactionList, isSpin: false });
      console.log(_transactionList);
    });
  }

  // logout = () => {
  //   localStorage.clear();
  //   this.props.history.push("/");
  // };

  render() {
    return (
      <>
        {this.state.isSpin ? (
          <div class="preloader">
            <div class="loader" ng-hide="data.length > 0"></div>
          </div>
        ) : (
          ""
        )}
        <div className="container">
          <div className="reversal_navbar">
            <button
              className="button back_button"
              onClick={() => this.props.history.goBack()}
            >
              <img src={require("../assets/images/back.png")} />
            </button>
            <span>Transactions</span>
            {this.state.flag === 0 && (
            <button className="button" onClick={() => logout()}>
              <img src={require("../assets/images/power-off.png")} alt="" />
            </button>
            )}
          </div>
          {this.state.transactionList.map((transaction, index) => {
            const submitted_at = transaction.submitted_at.split(" ");
            let str = transaction.household_name;
            let acronym = str
              .split(/\s/)
              .reduce((response, word) => (response += word.slice(0, 1)), "");

            return (
              <PEA element_id="SubmitreversalBtn"
                key={index}
                className="d-flex justify-content-center mt-4"
                onClick={() => {
                  this.props.history.push({
                    pathname: "/submitreversal",
                    state: { txn_ref_no: transaction.txn_ref_no },
                  });
                }}
              >
                <div className="reversal_trans_card">
                  <div className="reversal_trans_card_header">
                    <div>{acronym.toUpperCase()}</div>
                    <ul className="reversal_hh_info">
                      <PELI element_id="TransactionRefNo">Ref no: {transaction.txn_ref_no}</PELI>
                      <PELI element_id="TransactionHHID">HH ID : {transaction.household_number}</PELI>
                      <PELI element_id="TransactionHHName">{transaction.household_name}</PELI>
                    </ul>
                  </div>
                  <PEDiv element_id="TransactionAmount" className="reversal_trans_card_amount">
                    <span>Amount :</span>
                    <span>Rs. {transaction.amount.toLocaleString()}</span>
                  </PEDiv>
                  <div className="reversal_trans_card_footer">
                    <ul>
                      <PELI element_id="TransactionDate">
                        Date :{" "}
                        {submitted_at[1] +
                          " " +
                          submitted_at[2] +
                          " " +
                          submitted_at[3]}
                      </PELI>
                      <PELI element_id="TransactionTime">Time : {submitted_at[4]}</PELI>
                    </ul>
                    <div></div>
                  </div>
                </div>
              </PEA>
            );
          })}
        </div>
      </>
    );
  }
}

export default TransactionList;
