import React, { Component } from "react";
import customFetch from "./apicall/api";
import { useHistory } from "react-router-dom";
import { withRouter } from "react-router-dom";
import ChatSupportBtn from "./ChatSupportBtn";
import { logout, chkapiinput, generateId } from "./Utility";
import { ThemeContext } from "../context/IsLoader";
import { PEButton, PEDiv, PEA, PESpan } from "./permissionComponents";
const generate_Id1 = generateId(10);
export default class CashAcceptanceTO_main extends Component {
  static contextType = ThemeContext;
  constructor(props) {
    super(props);

    this.state = {
      csolist: [],
      sort_header: "",
      sort_htl: "",
      og_data: [],
      sort_data: [],
      reversed: [],
      unique_names: [],
      counter: 1,
      isNameFlag: true,
      isBalanceFlag: false,
      isHandoverFlag: false,
      temp_array: [],
      isMonthFlag: false,
      isErrOpen: false,
      api_error_message: "",
      isSpin: false,
    };

    this.handlesortdbc = this.handlesortdbc.bind(this);
    this.handlesorthtl = this.handlesorthtl.bind(this);
    this.handlesort = this.handlesort.bind(this);
    this.handleFilterMenu = this.handleFilterMenu.bind(this);
    this.addName = this.addName.bind(this);
    this.filter = this.filter.bind(this);
    this.checkCashBalance = this.checkCashBalance.bind(this);
  }

  filter(event) {
    this.state.sort_data = [];

    this.state.csolist = this.state.og_data;

    if (this.state.temp_array.length > 0) {
      for (let i = 0; i < this.state.csolist.length; i++) {
        for (let j = 0; j < this.state.temp_array.length; j++) {
          if (
            this.state.csolist[i].employee_name
              .toLowerCase()
              .indexOf(this.state.temp_array[j].toLowerCase()) == 0
          ) {
            this.state.sort_data.push(this.state.csolist[i]);
          }

          if (this.state.temp_array[j] == "dFilter1") {
            for (let i = 0; i < this.state.csolist.length; i++) {
              if (
                parseInt(this.state.csolist[i].current_balance) >= 0 &&
                parseInt(this.state.csolist[i].current_balance) <= 2000
              ) {
                this.state.sort_data.push(this.state.csolist[i]);
              }
            }
          } else if (this.state.temp_array[j] == "dFilter2") {
            for (let i = 0; i < this.state.csolist.length; i++) {
              if (
                parseInt(this.state.csolist[i].current_balance) >= 2001 &&
                parseInt(this.state.csolist[i].current_balance) <= 3999
              ) {
                this.state.sort_data.push(this.state.csolist[i]);
              }
            }
          } else if (this.state.temp_array[j] == "dFilter3") {
            for (let i = 0; i < this.state.csolist.length; i++) {
              if (
                parseInt(this.state.csolist[i].current_balance) >= 4000 &&
                parseInt(this.state.csolist[i].current_balance) <= 6999
              ) {
                this.state.sort_data.push(this.state.csolist[i]);
              }
            }
          } else if (this.state.temp_array[j] == "dFilter4") {
            for (let i = 0; i < this.state.csolist.length; i++) {
              if (
                parseInt(this.state.csolist[i].current_balance) >= 7000 &&
                parseInt(this.state.csolist[i].current_balance) <= 9000
              ) {
                this.state.sort_data.push(this.state.csolist[i]);
              }
            }
          } else if (this.state.temp_array[j] == "dFilter5") {
            for (let i = 0; i < this.state.csolist.length; i++) {
              if (parseInt(this.state.csolist[i].current_balance) >= 9001) {
                this.state.sort_data.push(this.state.csolist[i]);
              }
            }
          }

          if (this.state.temp_array[j] == "hfilter1") {
            for (let i = 0; i < this.state.csolist.length; i++) {
              if (
                parseInt(this.state.csolist[i].amt_submitted) >= 0 &&
                parseInt(this.state.csolist[i].amt_submitted) <= 2000
              ) {
                this.state.sort_data.push(this.state.csolist[i]);
              }
            }
          } else if (this.state.temp_array[j] == "hfilter2") {
            for (let i = 0; i < this.state.csolist.length; i++) {
              if (
                parseInt(this.state.csolist[i].amt_submitted) >= 2001 &&
                parseInt(this.state.csolist[i].amt_submitted) <= 3999
              ) {
                this.state.sort_data.push(this.state.csolist[i]);
              }
            }
          } else if (this.state.temp_array[j] == "hfilter3") {
            for (let i = 0; i < this.state.csolist.length; i++) {
              if (
                parseInt(this.state.csolist[i].amt_submitted) >= 4000 &&
                parseInt(this.state.csolist[i].amt_submitted) <= 6999
              ) {
                this.state.sort_data.push(this.state.csolist[i]);
              }
            }
          } else if (this.state.temp_array[j] == "hfilter4") {
            for (let i = 0; i < this.state.csolist.length; i++) {
              if (
                parseInt(this.state.csolist[i].amt_submitted) >= 7000 &&
                parseInt(this.state.csolist[i].amt_submitted) <= 9000
              ) {
                this.state.sort_data.push(this.state.csolist[i]);
              }
            }
          } else if (this.state.temp_array[j] == "hfilter5") {
            for (let i = 0; i < this.state.csolist.length; i++) {
              if (parseInt(this.state.csolist[i].amt_submitted) >= 9001) {
                this.state.sort_data.push(this.state.csolist[i]);
              }
            }
          }

          if (
            this.state.csolist[i].submitted_at
              .split(" ")[2]
              .toLowerCase()
              .indexOf(this.state.temp_array[j].toLowerCase()) == 0
          ) {
            this.state.sort_data.push(this.state.csolist[i]);
          }
        }
      }
      this.state.reversed = this.state.sort_data;
    } else {
      this.state.reversed = this.state.og_data;
    }

    this.forceUpdate();

    // if (this.state.counter == 1) {

    // } else if (this.state.counter == 2) {
    //   console.log('Balance');
    //   if (this.state.temp_array.length > 0) {
    //     // for (let i = 0; i < this.state.cnames.length; i++) {

    //     for (let j = 0; j < this.state.temp_array.length; j++) {

    //       if (this.state.temp_array[j] == "dFilter1") {
    //         for (let i = 0; i < this.state.csolist.length; i++) {
    //           if (parseInt(this.state.csolist[i].current_balance) >= 0 && parseInt(this.state.csolist[i].current_balance) <= 2000) {
    //             this.state.sort_data.push(this.state.csolist[i]);
    //           }
    //         }
    //       } else if (this.state.temp_array[j] == "dFilter2") {

    //         for (let i = 0; i < this.state.csolist.length; i++) {
    //           if (parseInt(this.state.csolist[i].current_balance) >= 2001 && parseInt(this.state.csolist[i].current_balance) <= 3999) {

    //             this.state.sort_data.push(this.state.csolist[i]);
    //           }
    //         }
    //       } else if (this.state.temp_array[j] == "dFilter3") {
    //         for (let i = 0; i < this.state.csolist.length; i++) {
    //           if (parseInt(this.state.csolist[i].current_balance) >= 4000 && parseInt(this.state.csolist[i].current_balance) <= 6999) {
    //             this.state.sort_data.push(this.state.csolist[i]);
    //           }
    //         }
    //       } else if (this.state.temp_array[j] == "dFilter4") {
    //         for (let i = 0; i < this.state.csolist.length; i++) {
    //           if (parseInt(this.state.csolist[i].current_balance) >= 7000 && parseInt(this.state.csolist[i].current_balance) <= 9000) {
    //             this.state.sort_data.push(this.state.csolist[i]);
    //           }
    //         }
    //       } else if (this.state.temp_array[j] == "dFilter5") {
    //         for (let i = 0; i < this.state.csolist.length; i++) {
    //           if (parseInt(this.state.csolist[i].current_balance) >= 9001) {
    //             this.state.sort_data.push(this.state.csolist[i]);
    //           }
    //         }
    //       }

    //     }
    //     this.state.reversed = this.state.sort_data;
    //   } else {
    //     this.state.reversed = this.state.og_data;
    //   }
    //   this.forceUpdate();

    // } else if (this.state.counter == 3) {
    //   if (this.state.temp_array.length > 0) {
    //     // for (let i = 0; i < this.state.cnames.length; i++) {

    //     for (let j = 0; j < this.state.temp_array.length; j++) {

    //       if (this.state.temp_array[j] == "hfilter1") {
    //         for (let i = 0; i < this.state.csolist.length; i++) {
    //           if (parseInt(this.state.csolist[i].amt_submitted) >= 0 && parseInt(this.state.csolist[i].amt_submitted) <= 2000) {
    //             this.state.sort_data.push(this.state.csolist[i]);
    //           }
    //         }
    //       } else if (this.state.temp_array[j] == "hfilter2") {

    //         for (let i = 0; i < this.state.csolist.length; i++) {
    //           if (parseInt(this.state.csolist[i].amt_submitted) >= 2001 && parseInt(this.state.csolist[i].amt_submitted) <= 3999) {

    //             this.state.sort_data.push(this.state.csolist[i]);
    //           }
    //         }
    //       } else if (this.state.temp_array[j] == "hfilter3") {
    //         for (let i = 0; i < this.state.csolist.length; i++) {
    //           if (parseInt(this.state.csolist[i].amt_submitted) >= 4000 && parseInt(this.state.csolist[i].amt_submitted) <= 6999) {
    //             this.state.sort_data.push(this.state.csolist[i]);
    //           }
    //         }
    //       } else if (this.state.temp_array[j] == "hfilter4") {
    //         for (let i = 0; i < this.state.csolist.length; i++) {
    //           if (parseInt(this.state.csolist[i].amt_submitted) >= 7000 && parseInt(this.state.csolist[i].amt_submitted) <= 9000) {
    //             this.state.sort_data.push(this.state.csolist[i]);
    //           }
    //         }
    //       } else if (this.state.temp_array[j] == "hfilter5") {
    //         for (let i = 0; i < this.state.csolist.length; i++) {
    //           if (parseInt(this.state.csolist[i].amt_submitted) >= 9001) {
    //             this.state.sort_data.push(this.state.csolist[i]);
    //           }
    //         }
    //       }

    //     }
    //     // }
    //     this.state.reversed = this.state.sort_data;
    //   } else {
    //     this.state.reversed = this.state.og_data;
    //   }
    //   this.forceUpdate();
    // } else if (this.state.counter == 4) {

    //   if (this.state.temp_array.length > 0) {

    //     for (let j = 0; j < this.state.temp_array.length; j++) {
    //       for (let i = 0; i < this.state.csolist.length; i++) {

    //         console.log(this.state.csolist[i].submitted_at.split(" ")[2].toLowerCase().indexOf(this.state.temp_array[j].toLowerCase()));
    //         if ((this.state.csolist[i].submitted_at.split(" ")[2].toLowerCase().indexOf(this.state.temp_array[j].toLowerCase())) == 0) {
    //           this.state.sort_data.push(this.state.csolist[i]);
    //         }
    //       }
    //     }
    //     this.state.reversed = this.state.sort_data;

    //     this.forceUpdate();
    //   }
    // }
  }

  addName(event) {
    if (event.target.checked) {
      this.state.temp_array.push(event.target.id);
    } else {
      for (let i = 0; i < this.state.temp_array.length; i++) {
        if (event.target.id == this.state.temp_array[i]) {
          this.state.temp_array.splice(i, 1);
        }
      }
    }

    console.log(this.state.temp_array);
  }

  handleFilterMenu(event) {
    this.state.sort_data = [];
    if (event.currentTarget.id == "nameFilter") {
      this.state.counter = 1;
      this.state.isNameFlag = true;
      this.state.isBalanceFlag = false;
      this.state.isHandoverFlag = false;
      this.state.isMonthFlag = false;
    } else if (event.currentTarget.id == "balFilter") {
      this.state.counter = 2;
      this.state.isNameFlag = false;
      this.state.isBalanceFlag = true;
      this.state.isHandoverFlag = false;
      this.state.isMonthFlag = false;
    } else if (event.currentTarget.id == "handFilter") {
      this.state.counter = 3;
      this.state.isNameFlag = false;
      this.state.isBalanceFlag = false;
      this.state.isHandoverFlag = true;
      this.state.isMonthFlag = false;
      // this.state.isShowBal = false;
      // this.state.isShowCol = false;
    } else if (event.currentTarget.id == "monthFilter") {
      this.state.counter = 4;
      this.state.isNameFlag = false;
      this.state.isBalanceFlag = false;
      this.state.isHandoverFlag = false;
      this.state.isMonthFlag = true;
    }

    this.forceUpdate();
  }

  handlesortdbc(event) {
    this.state.sort_header = event.target.value;

    if (event.target.checked) {
      this.state.sort_header = event.target.value;
    }
  }

  handlesorthtl(event) {
    this.state.sort_htl = event.target.value;

    if (event.target.checked) {
      this.state.sort_htl = event.target.value;
    }
  }

  handlesort(event) {
    if (this.state.sort_header == "balance") {
      if (this.state.sort_htl == "high") {
        this.state.sort_data = []
          .concat(this.state.csolist)
          .sort((a, b) => b.current_balance - a.current_balance);

        this.state.reversed = this.state.sort_data;
        this.forceUpdate();
      } else {
        this.state.sort_data = []
          .concat(this.state.csolist)
          .sort((a, b) => a.current_balance - b.current_balance);

        this.state.reversed = this.state.sort_data;
      }
    } else if (this.state.sort_header == "handover") {
      if (this.state.sort_htl == "high") {
        this.state.sort_data = []
          .concat(this.state.csolist)
          .sort((a, b) => b.amt_submitted - a.amt_submitted);

        this.state.reversed = this.state.sort_data;
      } else {
        this.state.sort_data = []
          .concat(this.state.csolist)
          .sort((a, b) => a.amt_submitted - b.amt_submitted);

        this.state.reversed = this.state.sort_data;
      }
    }
    this.forceUpdate();
  }

  fetchDetails = () => {
    this.setState({ isSpin: true });
    const { api_token } =
      (this.props.location && this.props.location.state) || {};
    const uid = localStorage.getItem("in_userid");
    /*Check if data is null then logout*/
    var arr = [uid];
    var chkdata = chkapiinput(arr);
    if (chkdata === false) {
      this.setState({ isSpin: false });
      setTimeout(function() {
        logout();
      }, 2000);
    }

    const params = {
      in_userid: uid,
      status: 2,
    };

    if (this.state.cash_balance_id != null) {
      params["cash_balance_id"] = this.state.cash_balance_id;
    }

    const queryParams = new URLSearchParams(params).toString();

    let generate_Id = generateId(10);
    const requestOptions = {
      method: "GET",
      headers: {
        "api-token": api_token,
        component_id: generate_Id1,
        apiId: generate_Id,
        api_group_id: "",
        component: this.constructor.name,
        user_id: localStorage.getItem("in_userid"),
      },
    };

    customFetch(
      process.env.REACT_APP_URL + "/get/list/denominations/cso?" + queryParams,
      requestOptions
    )
      .then((response) => {
        if (response.headers.get("api_code") > 0) {
          this.setState({
            isErrOpen: true,
            api_error_message: response.headers.get("api_error_message"),
            isSpin: false,
          });
        }
        return response.json();
      })
      .then((data) => {
        if (data.api_code === 4 || data.api_code === 3 || data.api_code === 5) {
          localStorage.clear();
          this.props.history.push("/");
        }
        this.setState({ csolist: data });
        this.setState({ og_data: data });
        this.setState({ reversed: data.reverse(), isSpin: false });
        //this.state.reversed = this.state.csolist.reverse();

        console.log(data);
      })
      .catch((error) => console.log("error", error));
  };
  componentDidMount = () => {
    setTimeout(() => {
      if (document.readyState === "complete") {
        this.context.onPageLoad({
          component: this.constructor.name,
          component_id: generate_Id1,
        });
      } else {
        window.addEventListener("load", this.context.onPageLoad());
        this.cleanup = () =>
          window.removeEventListener("load", this.context.onPageLoad());
      }
    }, 1000);
    const uid = localStorage.getItem("in_userid");
    if (!uid) {
      localStorage.clear();
      this.props.history.push("/");
    }
    this.fetchDetails();
  };

  // logout() {
  //   localStorage.clear();
  //   this.props.history.push("/");
  // }

  checkCashBalance = (data) => {
    // alert(cur_bal + " " + amt_sub);

    console.log(data);

    const { api_token } =
      (this.props.location && this.props.location.state) || {};

    if (Number(data["current_balance"]) < Number(data["amt_submitted"])) {
      this.setState({
        isErrOpen: true,
        api_error_message:
          "CSO's cash balance is going -ve if this transaction is approved. Please check with CSO.",
      });
    } else {
      this.props.history.push({
        pathname: "/cashacceptancedenom",
        state: {
          user_id: data.userid,
          cash_balance_id: data.cash_balance_id,
          api_token: api_token,
        },
      });
    }
    //
  };

  handleClick = (user_id, cash_balance_id) => {
    //console.log(user_id,cash_balance_id)
    this.props.history.push({
      pathname: "/cashacceptancedenom",
      state: {
        user_id: user_id,
        cash_balance_id: cash_balance_id,
      },
    });
  };
  render() {
    const { api_token } =
      (this.props.location && this.props.location.state) || {};
    const { isErrOpen, api_error_message } = this.state;
    //const { api_token } = 'test-token-for-collection-app';

    if (this.state.csolist.length > 0) {
      this.state.csolist.map((item) => {
        var findItem = this.state.unique_names.find(
          (x) => x.indexOf(item.employee_name.split(" ")[0]) == 0
        );
        if (!findItem)
          this.state.unique_names.push(item.employee_name.split(" ")[0]);
      });
    }
    // this.state.sort_data = [];
    console.log("this.state.reversed", this.state.reversed);
    return (
      <>
        {this.state.isSpin ? (
          <div class="preloader">
            <div class="loader" ng-hide="data.length > 0"></div>
          </div>
        ) : (
          ""
        )}
        <div
          className={
            isErrOpen
              ? "modal fade bd-example-modal-sm show mymodal"
              : "modal fade bd-example-modal-sm"
          }
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="confirm-box"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content cdb-confirm-box col-sm-12">
              <button
                type="button"
                className="close text-right"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => this.setState({ isErrOpen: false })}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <p>{api_error_message}</p>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="cso-filter"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              {(this.state.temp_array = [])}

              <div className="filter_window_1">
                <div className="filter_header">
                  <table className="table filter-table">
                    <thead>
                      <tr>
                        <th>Filter</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        onClick={this.handleFilterMenu}
                        id="nameFilter"
                        className="unique-name-click"
                      >
                        <td>Name</td>
                      </tr>

                      <tr
                        onClick={this.handleFilterMenu}
                        id="balFilter"
                        className="unique-name-click"
                      >
                        <td>Balance</td>
                      </tr>
                      <tr
                        onClick={this.handleFilterMenu}
                        id="handFilter"
                        className="unique-name-click"
                      >
                        <td>Handover</td>
                      </tr>
                      <tr
                        onClick={this.handleFilterMenu}
                        id="monthFilter"
                        className="unique-name-click"
                      >
                        <td>Month</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="filter_amt" id="filter-sub-menu">
                  {this.state.isNameFlag && (
                    <div className="unique-names" id="uniqueNameDiv">
                      <table className="table table-borderless">
                        {this.state.unique_names.map((item, key) => (
                          <tbody>
                            <tr>
                              <td>
                                {" "}
                                <input
                                  type="checkbox"
                                  id={item}
                                  name={item}
                                  onChange={this.addName}
                                />
                                <label htmlFor="vehicle1"> &nbsp; {item}</label>
                              </td>
                            </tr>
                          </tbody>
                        ))}
                      </table>
                    </div>
                  )}
                  {this.state.isBalanceFlag && (
                    <table className="table table-borderless">
                      <tbody>
                        <tr>
                          <td>
                            {" "}
                            <input
                              type="checkbox"
                              id="dFilter1"
                              name="dFilter1"
                              onChange={this.addName}
                            />
                            <label htmlFor="vehicle1"> &nbsp; Rs. 2000</label>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {" "}
                            <input
                              type="checkbox"
                              id="dFilter2"
                              name="dFilter2"
                              onChange={this.addName}
                            />
                            <label htmlFor="vehicle1">
                              {" "}
                              &nbsp;Rs. 2001 - Rs. 3999
                            </label>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {" "}
                            <input
                              type="checkbox"
                              id="dFilter3"
                              name="dfilter3"
                              onChange={this.addName}
                            />
                            <label htmlFor="vehicle1">
                              &nbsp; Rs. 4000 - Rs. 6999
                            </label>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {" "}
                            <input
                              type="checkbox"
                              id="dFilter4"
                              name="dFilter4"
                              onChange={this.addName}
                            />
                            <label htmlFor="vehicle1">
                              &nbsp; Rs. 7000 - Rs. 9000
                            </label>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {" "}
                            <input
                              type="checkbox"
                              id="dFilter5"
                              name="dFilter5"
                              onChange={this.addName}
                            />
                            <label htmlFor="vehicle1">
                              &nbsp; Rs. 9001 - Above
                            </label>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  )}
                  {this.state.isHandoverFlag && (
                    <table className="table table-borderless">
                      <tbody>
                        <tr>
                          <td>
                            {" "}
                            <input
                              type="checkbox"
                              id="hfilter1"
                              name="hfilter1"
                              onChange={this.addName}
                            />
                            <label htmlFor="vehicle1"> &nbsp; Rs. 2000</label>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {" "}
                            <input
                              type="checkbox"
                              id="hfilter2"
                              name="hfilter2"
                              onChange={this.addName}
                            />
                            <label htmlFor="vehicle1">
                              {" "}
                              &nbsp;Rs. 2001 - Rs. 3999
                            </label>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {" "}
                            <input
                              type="checkbox"
                              id="hfilter3"
                              name="hfilter3"
                              onChange={this.addName}
                            />
                            <label htmlFor="vehicle1">
                              &nbsp; Rs. 4000 - Rs. 6999
                            </label>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {" "}
                            <input
                              type="checkbox"
                              id="hfilter4"
                              name="hfilter4"
                              onChange={this.addName}
                            />
                            <label htmlFor="vehicle1">
                              &nbsp; Rs. 7000 - Rs. 9000
                            </label>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {" "}
                            <input
                              type="checkbox"
                              id="hfilter5"
                              name="hfilter5"
                              onChange={this.addName}
                            />
                            <label htmlFor="vehicle1">
                              &nbsp; Rs. 9001 - Above
                            </label>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  )}
                  {this.state.isMonthFlag && (
                    <div className="unique-names">
                      <table className="table table-borderless">
                        <tbody>
                          <tr>
                            <td>
                              {" "}
                              <input
                                type="checkbox"
                                id="jan"
                                name="jan"
                                onChange={this.addName}
                              />
                              <label htmlFor="vehicle1"> &nbsp; Jan</label>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {" "}
                              <input
                                type="checkbox"
                                id="feb"
                                name="feb"
                                onChange={this.addName}
                              />
                              <label htmlFor="vehicle1"> &nbsp; Feb</label>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {" "}
                              <input
                                type="checkbox"
                                id="mar"
                                name="mar"
                                onChange={this.addName}
                              />
                              <label htmlFor="vehicle1"> &nbsp; March</label>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {" "}
                              <input
                                type="checkbox"
                                id="apr"
                                name="apr"
                                onChange={this.addName}
                              />
                              <label htmlFor="vehicle1"> &nbsp; April</label>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {" "}
                              <input
                                type="checkbox"
                                id="may"
                                name="may"
                                onChange={this.addName}
                              />
                              <label htmlFor="vehicle1"> &nbsp; May</label>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {" "}
                              <input
                                type="checkbox"
                                id="jun"
                                name="jun"
                                onChange={this.addName}
                              />
                              <label htmlFor="vehicle1"> &nbsp; June</label>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {" "}
                              <input
                                type="checkbox"
                                id="jul"
                                name="jul"
                                onChange={this.addName}
                              />
                              <label htmlFor="vehicle1"> &nbsp; July</label>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {" "}
                              <input
                                type="checkbox"
                                id="aug"
                                name="aug"
                                onChange={this.addName}
                              />
                              <label htmlFor="vehicle1"> &nbsp; August</label>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {" "}
                              <input
                                type="checkbox"
                                id="sep"
                                name="sep"
                                onChange={this.addName}
                              />
                              <label htmlFor="vehicle1">
                                {" "}
                                &nbsp; September
                              </label>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {" "}
                              <input
                                type="checkbox"
                                id="oct"
                                name="oct"
                                onChange={this.addName}
                              />
                              <label htmlFor="vehicle1"> &nbsp; October</label>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {" "}
                              <input
                                type="checkbox"
                                id="nov"
                                name="nov"
                                onChange={this.addName}
                              />
                              <label htmlFor="vehicle1"> &nbsp; November</label>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {" "}
                              <input
                                type="checkbox"
                                id="dec"
                                name="dec"
                                onChange={this.addName}
                              />
                              <label htmlFor="vehicle1"> &nbsp; December</label>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )}
                  <div className="filter-btn-div">
                    <button
                      data-dismiss="modal"
                      className="btn btn-primary btn-block btn-lg rbtn"
                      onClick={this.filter}
                    >
                      APPLY
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade bd-example-modal-sm"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="sort-filter"
          ref={this.sortModal}
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content sort_block_2 col-sm-12">
              {(this.state.temp_array = [])}
              <span className="bluetext-header-sort">SORT BY</span>
              <table className="table">
                {/* <thead>
                                    <tr>
                                        <th colSpan="2"></th>
                                    </tr>
                                </thead> */}
                <tbody>
                  {/* <tr>
                    <td>Demand</td>
                    <td><input type="radio" className="form-check-input" value="sdemand" name="sortmenu" onChange={this.handlesortdbc} /></td>

                  </tr> */}
                  <tr>
                    <td>Balance</td>
                    <td>
                      <input
                        type="radio"
                        className="form-check-input"
                        value="balance"
                        name="sortmenu"
                        onChange={this.handlesortdbc}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Handover</td>
                    <td>
                      <input
                        type="radio"
                        className="form-check-input"
                        value="handover"
                        name="sortmenu"
                        onChange={this.handlesortdbc}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>

              <div className="sortBlueBox">
                <div>
                  <input
                    type="radio"
                    id="htl"
                    name="htl"
                    value="high"
                    onChange={this.handlesorthtl}
                  />
                  <label htmlFor="high">&nbsp;High To Low</label>
                </div>

                <div>
                  <input
                    type="radio"
                    id="htl"
                    name="htl"
                    onChange={this.handlesorthtl}
                    value="low"
                  />
                  <label htmlFor="low">&nbsp;Low To High</label>
                </div>
              </div>
              <button
                data-dismiss="modal"
                type="button"
                className="btn btn-primary btn-lg btn-block rbtn search_elements"
                onClick={this.handlesort}
              >
                Apply
              </button>
            </div>
          </div>
        </div>

        <div className="cash-acceptance">
          <div className="container fix-width d-flex flex-column">
            <div className="ca-header-box d-flex flex-column align-items-center justify-content-around">
              <div className="collection_block_header">
                <div className="title-holder_bg">
                  <div
                    className="back_icon_bg"
                    onClick={() => this.props.history.goBack()}
                  >
                    <img
                      src={require("../assets/images/back.png")}
                      className="back_icon_img"
                    />
                  </div>
                  <h2>Cash Acceptance Screen</h2>
                  <a>
                    <div className="log-out-deposit">
                      <img
                        src={require("../assets/images/power-off.png")}
                        onClick={() => logout()}
                        alt=""
                      />
                    </div>
                  </a>
                </div>
              </div>
              <div className="filter-options-container-main">
                <PEButton
                  element_id="SortFilter"
                  data-toggle="modal"
                  data-target="#sort-filter"
                  className="btn btn-primary rbtn d-flex justify-content-around align-items-center"
                >
                  <span>Sort</span>
                  {/* <img src={require("../assets/images/drop-menu-icon.png")} alt="#" /> */}
                </PEButton>
                <PEButton
                  element_id="CSOFilter"
                  data-toggle="modal"
                  data-target="#cso-filter"
                  className="btn rbtn btn-primary d-flex justify-content-around align-items-center"
                >
                  CSO
                  {/* <img src={require("../assets/images/drop-menu-icon.png")} alt="#" /> */}
                </PEButton>
              </div>
            </div>

            <div className="cso-list-container d-flex flex-column align-items-center">
              {this.state.reversed.map((data) => {
                return (
                  <PEDiv
                    element_id="CSOCard"
                    key={data["userid"]}
                    onClick={() => this.checkCashBalance(data)}
                    // onClick={() => {
                    //   this.props.history.push({
                    //     pathname: "/cashacceptancedenom",
                    //     state: {
                    //       user_id: data.userid,
                    //       cash_balance_id: data.cash_balance_id,
                    //       api_token: api_token,
                    //     },
                    //   });
                    // }}
                    className="cso-card-container btn d-flex flex-column align-items-center"
                  >
                    <div className="card-header-container d-flex justify-content-between align-items-center">
                      <span>{data["employee_name"]}</span>
                      <PEA
                        element_id="CSOCallBtn"
                        className="call_icon_bg"
                        href={"tel:+91" + Number(data["mobile_no"])}
                      >
                        <img
                          src={require("../assets/images/telephone (2).png")}
                          className="call_icon_img"
                        />
                      </PEA>
                    </div>
                    <div className="d-flex flex-row justify-content-between amount-holder">
                      <PEDiv
                        element_id="CashBalance"
                        className="amt-container d-flex flex-column align-items-center justify-content-around"
                      >
                        <p>Cash Balance</p>
                        <div className="bal-amount d-flex align-items-center justify-content-around">
                          Rs. {Number(data["current_balance"]).toLocaleString()}
                        </div>
                      </PEDiv>
                      <PEDiv
                        element_id="CashHandover"
                        className="amt-container d-flex flex-column align-items-center justify-content-around"
                      >
                        <p>Cash Handover</p>
                        <div className="hnd-amount d-flex align-items-center justify-content-around">
                          Rs. {Number(data["amt_submitted"]).toLocaleString()}
                        </div>
                      </PEDiv>
                    </div>
                    <div className="date-time d-flex flex-column align-items-start">
                      <PESpan element_id="CashDate">
                        {data["submitted_at"].split(" ")[0] +
                          " " +
                          data["submitted_at"].split(" ")[1] +
                          " " +
                          data["submitted_at"].split(" ")[2] +
                          " " +
                          data["submitted_at"].split(" ")[3]}
                      </PESpan>
                      <PESpan element_id="CashTime">
                        {data["submitted_at"].split(" ")[4]}
                      </PESpan>
                    </div>
                  </PEDiv>
                );
              })}
            </div>
          </div>
        </div>
        <ChatSupportBtn history={this.props.history} />
      </>
    );
  }
}
