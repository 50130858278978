import React, { Component, useEffect, useState } from "react";
import customFetch from "./apicall/api";
import DetailComponent from "./PayloadviewDetail";
import { filterData, logout, generateId } from "./Utility";
import {
  get_payload_views,
  update_payload,
  get_mongo_payload,
  update_mongopayload,
  delete_mongofield,
} from "./Services";
import { Select, Modal, Button, Input, DatePicker } from "antd";
import {
  EditOutlined,
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  SettingOutlined,
  ReloadOutlined,
  FileAddOutlined,
  FileExcelOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { ThemeContext } from "../context/IsLoader";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import "../assets/css/payload_view.css";
import {
  QueryBuilder,
  RuleGroupType,
  ValueEditor,
  ValueEditorProps,
} from "react-querybuilder";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import ReactJson from "react-json-view";
import { WidthProvider, Responsive } from "react-grid-layout";
import _ from "lodash";
import "../assets/css/styles.css";
import "../assets/css/example-styles.css";

const ResponsiveReactGridLayout = WidthProvider(Responsive);
const originalLayouts = {};

const { TextArea } = Input;
const dateFormatList = "DD/MM/YYYY";
const generate_Id1 = generateId(10);
export default class PayloadView extends Component {
  static contextType = ThemeContext;
  constructor(props) {
    super(props);

    this.state = {
      payload_config: props.location.state.payload_config,
      defaultpayload_config: props.location.state.payload_config,
      api_token: props.location.state.api_token,
      effective_designation_id: props.location.state.effective_designation_id,
      payload_data: [],
      disabled: false,
      isspin: false,
      isLoginuser: props.location.state.isLoginuser,
      sortOrder: "asc",
      otherScreen: props.location.state.otherScreen,
      no_of_page: "",
      filterDate: "",
      selectDate: "",
      isDateSel: "",
      DateSelMessage: false,
      view_name: props.location.state.view_name,
      search_fields: "",
      search_click: 0,
      isExporting: false,
      subpayload_config: "",
      unique_groups: "",
      filters: {},
      supervisor_branch_code: "",
      isSettings: false,
      HeaderData: [],
      checkedCheckboxes: [],
      Reasonlist: [],
      massUpdateData: [],
      selectedMassUpdateData: [],
      massUpdateInput: [],
      isMassupdate: false,
      group_id: "",
      DataForMassUpdate: [],
      CheckAll: false,
      load_more: 0,
      viewfilter: "",
      searchconfig:
        props.location.state.payload_config.payload_type == "M"
          ? props.location.state.payload_config.field_mapping[0]
              .search_query || {
              combinator: "and",
              not: "False",
              rules: [],
            }
          : [],
      defaultsearchconfig:
        props.location.state.payload_config.payload_type == "M"
          ? props.location.state.payload_config.field_mapping[0]
              .search_query || {
              combinator: "and",
              not: "False",
              rules: [],
            }
          : [],
      filtername: "",
      savedFilter: "",
      filtercrtupd: "",
      isMobile: false,
      query: { combinator: "and", rules: [] },
      Operators: [
        { name: "=", label: "=" },
        { name: "!=", label: "!=" },
        { name: ">", label: ">" },
        { name: "<", label: "<" },
        { name: ">=", label: ">=" },
        { name: "<=", label: "<=" },
        { name: "exists", label: "Exists" },
      ],
      disable_loadmore: false,
      disable_loadmore: false,
      disable_loadmore: false,
      layouts: JSON.parse(JSON.stringify(originalLayouts)),
      bodyLayouts: JSON.parse(JSON.stringify(originalLayouts)),
    };
  }

  componentDidMount() {
    const userAgent = navigator.userAgent.toLowerCase();
    const isMobiledata = /iphone|ipad|ipod|android|blackberry|windows phone/g.test(
      userAgent
    );
    this.setState({ isMobile: isMobiledata });
    console.log("isMobile", isMobiledata);
    const filtersearch = this.props.location.state.payload_config.field_mapping.filter(
      (item) => item.isSearch === "1"
    );
    const extractedDataArray = [];
    if (this.props.location.state.payload_config.payload_type == "M") {
      filtersearch.forEach((item) => {
        const name = item.BucketField;
        const label = item.fieldname;
        const datatype = item.datatype;

        // Push the extracted data as an object into the array
        extractedDataArray.push({ name, label, datatype });
      });
      //setsearch_fields(extractedDataArray);
      this.setState({ search_fields: extractedDataArray });
    } else {
      this.setState({ search_fields: filtersearch });
    }

    setTimeout(() => {
      if (document.readyState === "complete") {
        this.context.onPageLoad({
          component: this.constructor.name,
          component_id: generate_Id1,
        });
      } else {
        window.addEventListener("load", this.context.onPageLoad());
        this.cleanup = () =>
          window.removeEventListener("load", this.context.onPageLoad());
      }
    }, 1000);

    if (this.state.supervisor_branch_code == "") {
      let generate_Id = generateId(10);
      const params = {
        userid: localStorage.getItem("in_userid"),
      };
      const queryParams = new URLSearchParams(params).toString();
      const requestOptions = {
        method: "GET",
        headers: {
          "api-token": this.state.api_token,
          component_id: generate_Id1,
          apiId: generate_Id,
          api_group_id: "",
          component: "",
          user_id: localStorage.getItem("in_userid"),
        },
      };
      // AT-2109 | AT-2156 | Ankur Makavana | 10-07-2023 | replace blob_url with public filepath
      const Reasonlist = customFetch(
        process.env.REACT_APP_URL + "/getrole?" + queryParams,
        requestOptions
      )
        .then((res) => {
          return res.json();
        })
        .then((json) => {
          console.log("json.branch_name", json.branch_name);
          this.setState(
            {
              supervisor_branch_code: json.branch_code,
              contextuser_branchid: json.branch_name,
            },
            () => {
              console.log("data stored");
              if (!this.state.filterDate) {
                let inputDate = moment()
                  .subtract(6, "months")
                  .format("YYYY-MM-DD");
                this.setState(
                  {
                    filterDate: moment(inputDate).unix(),
                    defaultdate: inputDate,
                  },
                  () => {
                    this.getPayloads();
                  }
                );
              }
            }
          );
        });
    }

    this.state.payload_config.field_mapping.map((fields) => {
      if (fields.datatype == "dropdownlist") {
        let generate_Id = generateId(10);
        const requestOptions = {
          method: "GET",
          headers: {
            "api-token": this.state.api_token,
            component_id: generate_Id1,
            apiId: generate_Id,
            api_group_id: "",
            component: "",
            user_id: localStorage.getItem("in_userid"),
          },
        };
        // AT-2109 | AT-2156 | Ankur Makavana | 10-07-2023 | replace blob_url with public filepath
        const Reasonlist = customFetch(
          process.env.REACT_APP_URL +
            "/get/dropdown/item/list?dropdown_id=" +
            fields.dropdownlist,
          requestOptions
        )
          .then((res) => {
            return res.json();
          })
          .then((json) => {
            let bucket_field = fields.BucketField.replace(/\./g, "_");
            // this.setState({
            //   Reasonlist: json,
            // });
            console.log("json11", bucket_field);
            this.setState(
              (prevState) => ({
                ...prevState,
                Reasonlist: {
                  ...prevState.Reasonlist,
                  [bucket_field]: json,
                },
              }),
              () => {
                // Log the entire state to check Reasonlist
                console.log("Updated State:", this.state);
              }
            );
          });
      }
      if (fields.datatype == "group_id") {
        let generate_Id = generateId(10);
        let payload = [
          ["processed", "exists", "false"],
          ["issearch", "==", [{}]],
        ];
        const requestOptions = {
          method: "POST",
          headers: {
            "api-token": this.state.api_token,
            component_id: generate_Id1,
            apiId: generate_Id,
            api_group_id: "",
            component: "",
            user_id: localStorage.getItem("in_userid"),
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        };
        // AT-2109 | AT-2156 | Ankur Makavana | 10-07-2023 | replace blob_url with public filepath
        const response = customFetch(
          process.env.REACT_APP_URL + "/mongo/get/callbacks/unique_groups",
          requestOptions
        )
          .then((res) => {
            return res.json();
          })
          .then((json) => {
            const convertedArray = json.map((item) => ({
              group_id: item.group_id,
            }));
            this.setState({
              unique_groups: convertedArray,
            });
          });
      }
      if (fields.exprow) {
        this.getsubviewconfig(fields.exprow);
      }
      if (fields.massupdate == "1") {
        // this.setState((prevState) =>
        // massUpdateData:[...prevState,fields]
        // );
        this.setState((prevState) => ({
          massUpdateData: [...prevState.massUpdateData, fields],
        }));
      }
    });

    if (this.state.otherScreen != null) {
      this.setState({
        no_of_page: 2,
      });
      this.context.setotherscreen(this.state.otherScreen);
      this.context.setno_of_page(2);
    }

    // if (this.state.isDateSel == "") {
    //   this.setState({ DateSelMessage: true });
    // }

    const filteredData = this.state.payload_config.field_mapping.map((item) => {
      // Extract only the required fields
      const { fieldname, BucketField } = item;
      return { fieldname, BucketField };
    });

    // console.log("this.state.payload_config.field_mapping", filteredData);
    // const fieldnames = this.state.payload_config.field_mapping.map(
    //   (item) => item.fieldname
    // );
    this.setState({
      HeaderData: filteredData,
    });
    this.getviewcolconfig();
  }
  getviewcolconfig = () => {
    let generate_Id = generateId(10);

    let payload = [
      ["view_id", "==", this.state.payload_config.view_id],
      ["user_id", "==", localStorage.getItem("in_userid")],
    ];
    const requestOptions = {
      method: "POST",
      headers: {
        "api-token": this.state.api_token,
        component_id: generate_Id1,
        apiId: generate_Id,
        api_group_id: "",
        component: "",
        user_id: localStorage.getItem("in_userid"),
      },
      body: JSON.stringify(payload),
    };
    // AT-2109 | AT-2156 | Ankur Makavana | 10-07-2023 | replace blob_url with public filepath
    const Reasonlist = customFetch(
      process.env.REACT_APP_URL + "/mongo/get/callbacks/store_user_view",
      requestOptions
    )
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        console.log("json->", json.status);
        if (json.length > 0) {
          // const updatedData = this.state.payload_config.field_mapping.map(
          //   (item) => {
          //     // Check if the BucketField is present in data1
          //     const isBucketFieldInData1 = json[0].view_config.includes(
          //       item.BucketField
          //     );

          //     // Update isVisible property based on the presence in data1
          //     return {
          //       ...item,
          //       isVisible: isBucketFieldInData1 ? "1" : "0",
          //     };
          //   }
          // );

          // this.setState((prevState) => ({
          //   payload_config: {
          //     ...prevState.payload_config,
          //     field_mapping: updatedData,
          //   },
          // }));
          console.log("jsondata->", json);
          this.setState({
            savedFilter: json,
          });

          // this.setState({
          //   checkedCheckboxes: json[0].view_config,
          // });

          let chkdata = [];
          this.state.HeaderData.map((field, index) => {
            chkdata.push(field.BucketField);
          });
          console.log("BucketField", chkdata);
          this.setState({
            checkedCheckboxes: chkdata,
          });
        } else {
          console.log("else part");
          let chkdata = [];
          this.state.HeaderData.map((field, index) => {
            chkdata.push(field.BucketField);
          });
          console.log("BucketField", chkdata);
          this.setState({
            checkedCheckboxes: chkdata,
          });
        }
      });
  };
  handleCheckboxChangeupdate = (fieldName) => {
    const { updatecheckbox } = this.state;
    const updatedCheckboxes = [...updatecheckbox];

    if (updatedCheckboxes.includes(fieldName)) {
      // If the checkbox is already checked, uncheck it
      const index = updatedCheckboxes.indexOf(fieldName);
      updatedCheckboxes.splice(index, 1);
    } else {
      // If the checkbox is unchecked, check it
      updatedCheckboxes.push(fieldName);
    }

    this.setState({ updatecheckbox: updatedCheckboxes });
  };
  handleCheckboxChange = (fieldName) => {
    const { checkedCheckboxes } = this.state;
    const updatedCheckboxes = [...checkedCheckboxes];

    if (updatedCheckboxes.includes(fieldName)) {
      // If the checkbox is already checked, uncheck it
      const index = updatedCheckboxes.indexOf(fieldName);
      updatedCheckboxes.splice(index, 1);
    } else {
      // If the checkbox is unchecked, check it
      updatedCheckboxes.push(fieldName);
    }

    this.setState({ checkedCheckboxes: updatedCheckboxes });
  };
  getsubviewconfig = async (exprow) => {
    const filtered = this.props.location.state.all_view.filter(
      (item) => item.view_id === exprow
    );
    console.log("filtered", filtered[0]);
    this.setState({
      subpayload_config: filtered[0],
    });
  };
  handleSort = () => {
    // Implement sorting logic based on the current sort order
    const sortedData = [...this.state.payload_data].sort((a, b) => {
      if (this.state.sortOrder === "asc") {
        return moment
          .unix(a.created_at)
          .format("MM/DD/YYYY HH:mm:ss")
          .localeCompare(
            moment.unix(b.created_at).format("MM/DD/YYYY HH:mm:ss")
          );
      } else {
        return moment
          .unix(b.created_at)
          .format("MM/DD/YYYY HH:mm:ss")
          .localeCompare(
            moment.unix(a.created_at).format("MM/DD/YYYY HH:mm:ss")
          );
      }
    });

    // Update the table data and sort order

    this.setState({
      sortOrder: this.state.sortOrder === "asc" ? "desc" : "asc",
      payload_data: sortedData,
    });
    // setTableData(sortedData);
    // setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };
  DeletePayload = async (doc_id) => {
    try {
      const url =
        process.env.REACT_APP_URL +
        "/payload/move/" +
        this.state.payload_config.bucket_name +
        "/" +
        doc_id;
      let payload = null;
      let method = "POST";
      let generate_Id = generateId(10);
      const requestOptions = {
        method: method,
        headers: {
          "api-token": this.state.api_token,
          component_id: generate_Id1,
          apiId: generate_Id,
          api_group_id: "",
          component: this.constructor.name,
          user_id: localStorage.getItem("in_userid"),
        },
        body: payload,
      };

      const response = await customFetch(url, requestOptions);

      const data = await response.json();
      if (response.status != 200) throw data;

      Modal.success({
        content: <pre id="json">{JSON.stringify(data)}</pre>,
        title: "Success",
      });
    } catch (e) {
      Modal.error({
        content: <pre id="json">{JSON.stringify(e)}</pre>,
        title: "Error",
      });
    } finally {
      this.getPayloads();
    }
  };
  DeleteMongoPayload = async (doc_id) => {
    try {
      const url =
        process.env.REACT_APP_URL + "/payload/movemongo/callbacks/" + doc_id;
      let payload = null;
      let method = "POST";
      let generate_Id = generateId(10);
      const requestOptions = {
        method: method,
        headers: {
          "api-token": this.state.api_token,
          component_id: generate_Id1,
          apiId: generate_Id,
          api_group_id: "",
          component: this.constructor.name,
          user_id: localStorage.getItem("in_userid"),
        },
        body: payload,
      };

      const response = await customFetch(url, requestOptions);

      const data = await response.json();
      if (response.status != 200) throw data;

      Modal.success({
        content: <pre id="json">{JSON.stringify(data)}</pre>,
        title: "Success",
      });
    } catch (e) {
      Modal.error({
        content: <pre id="json">{JSON.stringify(e)}</pre>,
        title: "Error",
      });
    } finally {
      this.getPayloads();
    }
  };
  getPayloads = async () => {
    console.log("call getPayloads");
    const { contextuser_id } = this.context;

    let data;

    if (
      this.props.location.state.fetch_onload == "true" ||
      this.state.search_click == 1
    ) {
      this.setState({ isspin: true });
      let dbname, collectionname;
      if (
        !this.state.payload_config.db_name ||
        this.state.payload_config.db_name == ""
      ) {
        dbname = "callbacks";
      } else {
        dbname = this.state.payload_config.db_name;
      }
      if (
        !this.state.payload_config.collection_name ||
        this.state.payload_config.collection_name == ""
      ) {
        collectionname = "callback_payloads";
      } else {
        collectionname = this.state.payload_config.collection_name;
      }
      if (this.state.payload_config.payload_type == "M") {
        let defaultfilter = [];
        if (this.state.isLoginuser == "1") {
          this.state.payload_config.field_mapping.map((fieldata) => {
            if (fieldata.defaultfilter != "") {
              let splitfieldata = fieldata.defaultfilter.split(".");
              console.log("splitfieldata", this.state.contextuser_branchid);
              //console.log("splitfieldata", localStorage.getItem(splitfieldata[1]));
              defaultfilter.push({
                field: fieldata.BucketField,
                fieldvalue: localStorage.getItem(splitfieldata[1]),
                //operatordata: "==",
                operatordata: splitfieldata[0],
              });
            }
          });
        }
        console.log("payload_data", this.state.payload_data.length);
        let limit_per_call;
        if (this.state.payload_config.record_per_call) {
          limit_per_call = parseInt(this.state.payload_config.record_per_call);
        } else {
          limit_per_call = 50;
        }
        let offset = 1;
        if (this.state.load_more == 1) {
          offset = this.state.payload_data.length + 1;
        }
        // let filter_bucketfield = this.state.payload_config.field_mapping.map(
        //   (item) => item.BucketField
        // );

        // let filter_bucketfield = this.state.payload_config.field_mapping
        //   .filter(
        //     (item) =>
        //       !["block", "Downloadfile", "jsondata", "image"].includes(
        //         item.datatype
        //       )
        //   )
        //   .map((item) => item.BucketField);

        let filter_bucketfield = this.state.payload_config.field_mapping.map(
          (item) => item.BucketField
        );

        let filter_columns = "";
        if (this.state.payload_config.get_data_by) {
          if (this.state.payload_config.get_data_by == "C") {
            filter_columns = this.filterUniqueData(filter_bucketfield);
          }
        } else {
          filter_columns = this.filterUniqueData(filter_bucketfield);
        }
        console.log("filter_bucketfield", filter_columns);

        data = await get_mongo_payload(
          this.state.api_token,
          this.state.payload_config.search_by_pair,
          this.state.filterDate,
          this.formatQuerybuild(this.state.searchconfig),
          dbname,
          collectionname,
          defaultfilter,
          this.state.isLoginuser,
          limit_per_call,
          offset,
          filter_columns
        );
      } else {
        let defaultfilter = [];
        if (this.state.isLoginuser == "1") {
          this.state.payload_config.field_mapping.map((fieldata) => {
            if (fieldata.defaultfilter != "") {
              let splitfieldata = fieldata.defaultfilter.split(".");
              console.log("splitfieldata", splitfieldata);
              //console.log("splitfieldata", localStorage.getItem(splitfieldata[1]));
              defaultfilter.push({
                field: fieldata.BucketField,
                fieldvalue: localStorage.getItem(splitfieldata[1]),
                operatordata: splitfieldata[0],
              });
            }
          });
        }

        let searchData = [];
        this.state.payload_config.field_mapping.map((cell) => {
          if (cell.isSearch == 1 && this.state[cell.BucketField]) {
            searchData.push([
              cell.BucketField,
              "==",
              this.state[cell.BucketField],
            ]);
          }
        });

        data = await get_payload_views(
          this.state.api_token,
          this.state.payload_config.bucket_name,
          this.state.payload_config.event_name,
          defaultfilter,
          this.state.isLoginuser,
          this.state.filterDate,
          this.state.payload_config.event_location,
          searchData,
          this.state.searchconfig
        );
      }
      const parsed_data = [];
      if (!data.message && data.status != 0) {
        for (let item in data) {
          parsed_data.push({
            ...data[item],
            key: item,
          });
        }
      }

      if (data.length == 0) {
        this.setState({ disable_loadmore: true });
      }
      this.setState({ isspin: false });
      console.log("this.state.payload_data", parsed_data);
      if (Array.isArray(this.state.payload_data) && this.state.load_more == 1) {
        const newArray = [...this.state.payload_data, ...parsed_data];
        const result = [];
        newArray.forEach((item, index) => {
          result[index.toString()] = item;
        });
        this.setState({ payload_data: result });
      } else {
        this.setState({ payload_data: parsed_data });
      }
    }
    this.setState({ load_more: 0 });
  };
  filterUniqueData(data) {
    console.log("filterUniqueData", data);
    const uniqueData = {};

    data.forEach((item) => {
      const segments = item.split(/[.:]/);
      let key = segments[0];

      // Check if the key exists in uniqueData
      if (!uniqueData[key]) {
        uniqueData[key] = true;
      }
    });

    // Convert uniqueData object keys to array
    return Object.keys(uniqueData);
  }
  formatQuerybuild = (query) => {
    if (!query) return {};
    const mongoQuery = {};
    if (query.combinator === "and") {
      if (query.rules && query.rules.length > 0) {
        mongoQuery.$and = query.rules.map(this.formatQuerybuild);
      }
    } else if (query.combinator === "or") {
      if (query.rules && query.rules.length > 0) {
        mongoQuery.$or = query.rules.map(this.formatQuerybuild);
      }
    }

    if (query.field && query.operator) {
      const field = query.field;
      const operator = query.operator;
      const queryvalue = query.value;
      let value;
      const createdAtObject = this.state.payload_config.field_mapping.find(
        (item) => item.BucketField == field
      );

      console.log("createdAtObject", queryvalue);
      if (createdAtObject) {
        if (createdAtObject.datatype == "int") {
          value = parseInt(queryvalue);
        } else if (createdAtObject.datatype == "float") {
          value = parseFloat(queryvalue);
        } else {
          value = queryvalue;
        }
      } else {
        value = queryvalue;
      }

      if (!isNaN(queryvalue) && value !== "") {
        // Check if the value is a number
        if (Number.isInteger(parseFloat(queryvalue))) {
          value = parseInt(queryvalue); // Convert to integer
        } else {
          value = parseFloat(queryvalue); // Convert to float
        }
      } else if (typeof queryvalue === "string") {
        // If the value is a string, leave it as is
        value = queryvalue;
      } else {
        // If the value doesn't match any of the above types, keep it as is
        value = queryvalue;
      }

      if (operator === "=") {
        mongoQuery[field] = value;
      } else if (operator === "!=") {
        mongoQuery[field] = { $ne: value };
      } else if (operator === ">") {
        mongoQuery[field] = { $gt: value };
      } else if (operator === "<") {
        mongoQuery[field] = { $lt: value };
      } else if (operator === ">=") {
        mongoQuery[field] = { $gte: value };
      } else if (operator === "<=") {
        mongoQuery[field] = { $lte: value };
      }

      if (operator === "exists") {
        mongoQuery[field] = {
          $exists: value.toLowerCase() == "true" ? true : false,
        };
      }
    }
    console.log("mongoQuery-->", mongoQuery);
    return mongoQuery;
  };
  setDisabled = (value) => {
    this.setState({ disabled: value });
  };
  changedate = (date, dateString) => {
    var ts = moment(dateString, dateFormatList).unix();
    var m = moment.unix(ts);
    let inputDate = moment(date).format("YYYY-MM-DD");
    this.setState({
      filterDate: moment(inputDate).unix(),
      isDateSel: "1",
    });
  };

  update_search_filter = (field, value) => {
    console.log("field->", field);

    if (this.state.payload_config.payload_type == "M") {
      if (
        this.props.location.state.payload_config.field_mapping[0].search_query
      ) {
        const updatedRule = { ...this.state.searchconfig };

        const findRule = (rules) => {
          for (let rule of rules) {
            if (rule.field === field) {
              rule.value = value;
              return;
            }
            if (rule.rules) {
              findRule(rule.rules);
            }
          }
        };

        findRule(updatedRule.rules);

        this.setState({ searchconfig: updatedRule });
      } else {
        const { searchconfig } = this.state;
        let operator = "=";
        const existingRuleIndex = searchconfig.rules.findIndex(
          (rule) => rule.field == field
        );

        if (existingRuleIndex != -1) {
          // Update the existing rule
          searchconfig.rules[existingRuleIndex] = { field, operator, value };
        } else {
          // Insert a new rule
          searchconfig.rules.push({ field, operator, value });
        }

        // Update the state with the modified search_new object
        this.setState({ searchconfig });
      }
    }
    if (this.state.payload_config.payload_type == "B") {
      const existingIndex = this.state.searchconfig.findIndex(
        (item) => item[0] == field
      );

      if (existingIndex !== -1) {
        // If the field exists, update the corresponding value
        const updatedKeyValues = [...this.state.searchconfig];
        updatedKeyValues[existingIndex][2] = value;

        this.setState({
          searchconfig: updatedKeyValues,
        });
      } else {
        // If the key doesn't exist, create a new entry
        const newKeyValue = [field, "==", value];
        this.setState((prevState) => ({
          searchconfig: [...prevState.searchconfig, newKeyValue],
        }));
      }
    }
  };
  eventNameSearch = (e, columnName) => {
    const updatedFilters = {
      ...this.state.filters,
      [columnName]: e.target.value,
    };
    this.setState({ filters: updatedFilters });
  };
  // filterData = () => {
  //   return this.state.payload_data.filter((row) => {
  //     for (const columnName in this.state.filters) {
  //       const filterText = this.state.filters[columnName].toLowerCase();
  //       const cellText = String(row[columnName]).toLowerCase();
  //       if (!cellText.includes(filterText)) {
  //         return false;
  //       }
  //     }
  //     return true;
  //   });
  // };

  filterData = () => {
    const { payload_data, filters } = this.state;

    // Assuming `filters` and `this.state.payload_config.field_mapping` are defined elsewhere
    console.log("payload_data--->", payload_data);
    console.log("payload_filters--->", filters);
    const filteredData = payload_data.filter((row) => {
      if (!row) {
        return false;
      }

      if (filters) {
        return Object.keys(filters).every((columnName) => {
          const filterText = filters[columnName].toLowerCase();

          const config = this.state.payload_config.field_mapping.find(
            (config) => config.BucketField === columnName
          );

          if (!config) {
            return false; // Exit early if configuration for column not found
          }
          console.log("rowValue-", columnName);
          const cellText = this.deepGet(row, columnName);
          console.log("row_columnName-", cellText);
          if (typeof cellText === "number" && filterText != "") {
            const cellContainsFilter = new RegExp(
              `\\b${cellText}\\b`,
              "i"
            ).test(filterText);
            console.log("filterText if", cellContainsFilter);
            return cellContainsFilter;
          } else {
            console.log(
              "filterText else",
              String(cellText)
                .toLowerCase()
                .includes(filterText)
            );
            return String(cellText)
              .toLowerCase()
              .includes(filterText);
          }
          // if (cellText === undefined) {
          //   return false;
          // }
        });
      }
    });

    const filterdata = {};

    this.state.payload_config.field_mapping.forEach((item) => {
      if (item.ExcludeValueflag === "1" && item.ExcludeValue !== "") {
        filterdata[item.BucketField] = item.ExcludeValue;
      }
    });
    const parsedFilter = {};
    for (const key in filterdata) {
      parsedFilter[key] = filterdata[key]
        .split(",")
        .map((value) => value.trim());
    }

    // Function to check if an item should be excluded
    const shouldExclude = (item) => {
      for (const key in parsedFilter) {
        if (parsedFilter[key].includes(String(item[key]))) {
          return true;
        }
      }
      return false;
    };

    // Filter the data
    return filteredData.filter((item) => !shouldExclude(item));
    //return filteredData;
  };

  deepGet = (obj, path) => {
    const keys = path.split(".");
    return keys.reduce(
      (acc, key) => (acc && acc[key] !== undefined ? acc[key] : undefined),
      obj
    );
  };

  converttoarray = (key, data) => {
    const keys = key.split(/[.:]/);

    // Initialize a variable to store the current data object
    let currentData = data;

    // Loop through each part of the key
    for (const k of keys) {
      console.log("key->", k);
      // Check if the currentData is an object and has the key
      if (currentData && currentData.hasOwnProperty(k)) {
        currentData = currentData[k]; // Update currentData to the nested object
      } else {
        return ""; // Return a default value if any part of the key is not present
      }
    }
    return currentData;
  };
  showpopup = () => {
    this.setState({
      isSettings: true,
    });
  };
  updateFilter = () => {
    let payload = {};
    payload.view_config = this.state.updatecheckbox;
    payload.search_config = this.state.updatesearchconfig;
    payload.filtername = this.state.updatefiltername;
    //const jsonObject = JSON.stringify(payload);
    console.log("Updatedfilter", JSON.stringify(payload));
    this.setState(
      {
        updated_filterdata: payload,
      },
      () => this.editFilter()
    );
  };
  savepreferences = async () => {
    try {
      const url = process.env.REACT_APP_URL + "/add/callbacks/store_user_view";
      let payload = {};
      payload.user_id = localStorage.getItem("in_userid");
      payload.view_id = this.state.payload_config.view_id;
      payload.view_config = this.state.checkedCheckboxes;
      payload.search_config = this.state.searchconfig;
      payload.filtername = this.state.filtername;
      const jsonObject = [payload];

      let method = "POST";
      let generate_Id = generateId(10);
      const requestOptions = {
        method: method,
        headers: {
          "api-token": this.state.api_token,
          component_id: generate_Id1,
          apiId: generate_Id,
          api_group_id: "",
          component: this.constructor.name,
          user_id: localStorage.getItem("in_userid"),
        },
        body: JSON.stringify(jsonObject),
      };

      const response = await customFetch(url, requestOptions);

      const data = await response.json();
      if (response.status != 200) throw data;

      Modal.success({
        content: <pre id="json">{JSON.stringify(data)}</pre>,
        title: "Success",
      });
    } catch (e) {
      Modal.error({
        content: <pre id="json">{JSON.stringify(e)}</pre>,
        title: "Error",
      });
    } finally {
      this.setState({ searchconfig: this.state.defaultsearchconfig });
      //this.getPayloads();
      this.getviewcolconfig();
    }
  };

  updateUniqueData = () => {
    const uniqueBucketFields = new Set();

    // Filter the data array to keep only unique items
    const uniqueData =
      this.state.massUpdateData &&
      this.state.massUpdateData.filter((item) => {
        const isUnique = !uniqueBucketFields.has(item.BucketField);

        // If the BucketField is unique, add it to the Set
        if (isUnique) {
          uniqueBucketFields.add(item.BucketField);
        }

        return isUnique;
      });
    return uniqueData;
    // Update the state with unique data
    // this.setState({
    //   massUpdateData: uniqueData,
    // });
  };

  handlemassInputChange = (index, key, value) => {
    this.setState((prevState) => {
      const newData = [...prevState.massUpdateInput];
      if (!newData[index]) {
        // If the array element doesn't exist yet, create it
        newData[index] = { my_key: key, value: "" };
      }

      newData[index].my_key = key;
      newData[index].value = value; // Set value to e.target.value

      console.log("newData", newData);
      return { massUpdateInput: newData };
    });

    // this.setState((prevState) => {
    //   const newData = [...prevState.massUpdateInput];
    //   if (!newData[index]) {
    //     newData[index] = {}; // Initialize the object if it doesn't exist yet
    //   }
    //   newData[index][key] = value;
    //   return { massUpdateInput: newData };
    // });
  };

  updatemassdata = () => {
    const filteredData = this.filterData();
    if (
      this.state.payload_config.payload_type == "M" &&
      this.state.selectedMassUpdateData
    ) {
      var targetIds = this.state.selectedMassUpdateData;
      var filtered_Data =
        filteredData &&
        filteredData.filter(function(item) {
          return item._id && targetIds.includes(item._id.$oid);
        });
      console.log("filtered_Data", filtered_Data);
    }

    console.log("massUpdateInput-->", this.state.massUpdateInput);
    // const data1Object = this.state.massUpdateInput.find(
    //   (item) => item !== null && typeof item === "object"
    // );
    // if (data1Object) {
    //   const { my_key, value } = data1Object;

    //   // Update data based on matching key
    //   filtered_Data.forEach((item) => {
    //     if (item && item[my_key] !== undefined) {
    //       item[my_key] = value;
    //     }
    //   });
    // }

    // this.state.massUpdateInput.forEach(({ my_key, value }) => {
    //   filtered_Data.forEach((item) => {
    //     if (item && item[my_key] !== undefined) {
    //       item[my_key] = value;
    //     }
    //   });
    // });

    const objectsEqual = (obj1, obj2) =>
      JSON.stringify(obj1) === JSON.stringify(obj2);

    // Store the changed data
    const changedData = [];

    // Update data based on matching key in data1 and track changes
    console.log("this.state.massUpdateInput", this.state.massUpdateInput);
    this.state.massUpdateInput.forEach((item) => {
      if (item && item.my_key !== undefined && item.value !== undefined) {
        let changedItem;
        filtered_Data.forEach((filteredItem) => {
          if (
            filteredItem &&
            filteredItem[item.my_key] !== undefined &&
            filteredItem[item.my_key] !== item.value
          ) {
            changedItem = {
              object_id: filteredItem._id.$oid,
              [item.my_key]: filteredItem[item.my_key],
              [item.my_key]: item.value,
            };
          } else {
            changedItem = {
              object_id: filteredItem._id.$oid,
              [item.my_key]: item.my_key,
              [item.my_key]: item.value,
            };
          }
          if (
            !changedData.some((changed) => objectsEqual(changed, changedItem))
          ) {
            changedData.push(changedItem);
          }
        });
      }
    });

    console.log("changedItem", changedData);
    console.log("payload_config", this.state.payload_config);
    this.setState({ DataForMassUpdate: filtered_Data });
    //this.update_mass_data(filtered_Data);
    let confirmValues = [];

    for (const dataItem of changedData) {
      for (const configItem of this.state.payload_config.field_mapping) {
        const { BucketField, isConfirm } = configItem;

        if (dataItem[BucketField] !== undefined && isConfirm === "1") {
          console.log("confirmValues.length", dataItem[BucketField]);
          confirmValues.push({
            [BucketField]: dataItem[BucketField],
            confirm: "your_confirm_value_here", // Add your confirm value here
          });
          // Exit the loop if isConfirm is 1
          break;
        }
      }
    }
    if (confirmValues.length > 0) {
      const isConfirmed = window.confirm(
        "Are you sure you want to update the value?"
      );
      if (isConfirmed) {
        changedData.map((massdata, index) => {
          let object_id = massdata["object_id"];
          delete massdata["object_id"];
          console.log("del oid", massdata);

          this.update_mass_data(object_id, massdata, changedData.length, index);
        });
      }
    } else {
      changedData.map((massdata, index) => {
        let object_id = massdata["object_id"];
        delete massdata["object_id"];
        console.log("del oid", massdata);

        this.update_mass_data(object_id, massdata, changedData.length, index);
      });
    }
  };

  update_mass_data = async (
    objectid,
    data,
    allchangedData,
    changedDataIndex
  ) => {
    console.log("objectid", objectid);
    console.log("data", data);
    if (this.state.payload_config.payload_type == "M" && objectid && data) {
      let dbname, collectionname;
      if (this.state.payload_config.db_name == "") {
        dbname = "callbacks";
      } else {
        dbname = this.state.payload_config.db_name;
      }

      if (this.state.payload_config.collection_name == "") {
        collectionname = "callback_payloads";
      } else {
        collectionname = this.state.payload_config.collection_name;
      }
      console.log("Data1111->", data);

      const response = await update_mongopayload(
        this.state.api_token,
        dbname,
        collectionname,
        objectid,
        data
      );
      console.log("alldata", allchangedData + "==" + changedDataIndex);
      if (allchangedData - 1 == changedDataIndex) {
        if (response.status == 1) {
          Modal.success({
            content: <pre id="json">Payload updated successfully!</pre>,
            title: "Success",
            onOk: () => window.location.reload(),
          });
          return true;
        } else {
          Modal.error({
            content: <pre id="json">Error updating the payload</pre>,
            title: "Error",
          });
          return false;
        }
      }
    }
    this.setState({
      massUpdateInput: [],
    });
    this.getPayloads();
  };
  handleClick = () => {
    this.setState({ isExporting: true }, () => {
      document.getElementById("table-xls-button").click();
      document
        .getElementById("table-xls-button")
        .addEventListener("click", this.handleStateUpdate());
    });
  };
  handleStateUpdate = () => {
    // Update state
    // This will be executed after the export is completed
    this.setState({ isExporting: false });
  };
  storegroupid = (groupid, index, bucket_field) => {
    console.log("group_id", groupid);
    let generate_Id = generateId(10);
    this.setState({ group_id: groupid });
    const form = new FormData();
    form.append("group_id", groupid);
    const requestOptions = {
      method: "POST",
      headers: {
        "api-token": this.state.api_token,
        component_id: generate_Id1,
        apiId: generate_Id,
        api_group_id: "",
        component: "",
        user_id: localStorage.getItem("in_userid"),
      },
      body: form,
    };
    const response = customFetch(
      process.env.REACT_APP_URL + "/partner/add/groupid",
      requestOptions
    );
    // const newgroupid = [...updategroupid, { group_id: groupid }];
    // setupdategroupid(newgroupid);

    // Update the state with the new array

    console.log(response);

    Modal.success({
      content: <pre id="json">{JSON.stringify(response.message)}</pre>,
      title: "Success",
    });
    this.handlemassInputChange(index, bucket_field, groupid);
    // customFetch(process.env.REACT_APP_URL + "/add/groupid", requestOptions)
    //   .then((res) => {
    //     return res.json();
    //   })
    //   .then((json) => {
    //     setImageView(json.public_url);
    //     setisImageView(true);
    //   });
  };

  adduniqueid = (index, bucket_field) => {
    let maxNumericValue = Number.NEGATIVE_INFINITY;
    let maxGroupID = null;
    this.state.unique_groups &&
      this.state.unique_groups.forEach((item) => {
        if (item.group_id.includes(this.state.supervisor_branch_code)) {
          const numericValue = parseInt(
            item.group_id.replace(this.state.supervisor_branch_code + "G", ""),
            10
          );
          if (numericValue > maxNumericValue) {
            maxNumericValue = numericValue;
            maxGroupID = item.group_id;
          }
        }
      });
    let maxValue;
    if (maxGroupID != null) {
      maxValue = maxGroupID;
    } else {
      maxValue = 0;
    }
    console.log("maxValue", this.state.supervisor_branch_code);
    let newUniqueID;
    if (maxValue == 0) {
      newUniqueID = this.state.supervisor_branch_code + "G000001";
    } else {
      let splitid = maxValue.split("G");
      const numericPart = parseInt(splitid[1], 10) + 1;

      // Construct the new group_id
      newUniqueID = splitid[0] + "G" + numericPart.toString().padStart(6, "0");
    }
    console.log("newUniqueID", newUniqueID);

    // setgroupid(newUniqueID);
    // console.log("groupid", groupid);
    this.storegroupid(newUniqueID, index, bucket_field);
  };
  CustomValueEditor = (props) => {
    if (props.fieldData.datatype === "date") {
      return (
        <div>
          <DatePicker
            dateFormat={dateFormatList}
            onChange={(date) => {
              // Update the query with the selected date
              var ts = moment(date, dateFormatList).unix();
              //var m = moment.unix(ts);
              // const updatedQuery = {
              //   ...props.value,
              //   [props.fieldData.field]: ts,
              // };
              props.handleOnChange(ts);
            }}
          />
        </div>
      );
    }
    return <ValueEditor {...props} />;
  };

  SearchDropdownlist = (val) => {
    console.log("valll", val);
    let bucket_field = val.bucketfield.replace(/\./g, "_");
    let isedits = this.state.Reasonlist && val.isedit == true && (
      <select
        className="form-control"
        style={{
          width: 120,
        }}
        defaultValue={val.value}
        //value={ReasonData}
        // onChange={(event) => {
        //   handlePayloadChange(parseInt(event.target.value), val.bucketfield);
        //   //setReasonData(event.target.value);
        // }}
        onChange={(e) => this.eventNameSearch(e, val.bucketfield)}
      >
        <option value="">Select</option>
        {this.state.Reasonlist[bucket_field] ? (
          this.state.Reasonlist[bucket_field].map((list) => (
            <option value={list.item_value}>{list.item_name}</option>
          ))
        ) : (
          <>
            <option value="1">Yes</option>
            <option value="0">No</option>
          </>
        )}
      </select>
    );
    if (val.isedit == false) {
      // let iseditfalse;
      // iseditfalse = Reasonlist.map(
      //   (list) => list.item_value == val.value && list.item_name
      // );
      console.log("Reasonlist11", this.state.Reasonlist);
      if (this.state.Reasonlist && this.state.Reasonlist[bucket_field]) {
        const matchingItem = this.state.Reasonlist[bucket_field].find(
          (item) => item.item_value == val.value
        );

        // Check if a matching item was found if not then show value
        if (matchingItem) {
          const itemName = matchingItem.item_name;
          isedits = itemName;
        } else {
          isedits = val.value;
        }
      } else {
        isedits = "Select";
      }
    }
    return isedits;
  };
  onLayoutChangeBody(layout, layouts) {
    console.log("onLayoutChangeBody", layout);
    this.state.bodyLayout = layout;
    console.log("bodyEle", this.state.payload_config.component_mapping.body);
    this.state.bodyLayout.map((item, index) => {
      this.state.payload_config.component_mapping.body[index]["x"] = item["x"];
      this.state.payload_config.component_mapping.body[index]["y"] = item["y"];
      this.state.payload_config.component_mapping.body[index]["w"] = item["w"];
      this.state.payload_config.component_mapping.body[index]["h"] = item["h"];
    });
    this.state.bodyLayouts = layouts;
  }
  updateFilterval = (val) => {
    if (val) {
      const json = this.state.savedFilter.filter(
        (item) => item._id.$oid === val
      );
      console.log("savedFilter", json);
      //json[0].view_config
      this.setState({
        updatecheckbox: json[0].view_config,
        updatefiltername: json[0].filtername,
        updatesearchconfig: json[0].search_config,
        updateObjectid: json[0]._id.$oid,
      });

      //      console.log("BucketField", chkdata);
      // this.setState({
      //   checkedCheckboxes: chkdata,
      // });
    }
  };
  filterchange = (val) => {
    if (val) {
      this.setState({
        viewfilter: val,
      });
      const json = this.state.savedFilter.filter(
        (item) => item._id.$oid === val
      );
      const updatedData = this.state.payload_config.field_mapping.map(
        (item) => {
          // Check if the BucketField is present in data1
          const isBucketFieldInData1 = json[0].view_config.includes(
            item.BucketField
          );

          // Update isVisible property based on the presence in data1
          return {
            ...item,
            isVisible: isBucketFieldInData1 ? "1" : "0",
          };
        }
      );

      this.setState(
        (prevState) => ({
          payload_config: {
            ...prevState.payload_config,
            field_mapping: updatedData,
          },
          searchconfig: json[0].search_config,
          search_click: 1,
        }),
        () => this.getPayloads()
      );
    } else {
      this.setState(
        {
          payload_config: this.state.defaultpayload_config,
          searchconfig: this.state.defaultsearchconfig,
          search_click: 1,
        },
        () => this.getPayloads()
      );
    }
  };

  editFilter = async () => {
    let dbname, collectionname;
    dbname = "callbacks";
    collectionname = "store_user_view";

    let objectid = this.state.updateObjectid;
    let upd_data = this.state.updated_filterdata;

    console.log("After state update:", upd_data);
    const response = await update_mongopayload(
      this.state.api_token,
      dbname,
      collectionname,
      objectid,
      upd_data
    );

    if (response.status == 1) {
      this.getviewcolconfig();
      this.setState({
        updatecheckbox: "",
        updatefiltername: "",
        updatesearchconfig: "",
        updateObjectid: "",
        filtercrtupd: "",
      });
      Modal.success({
        content: <pre id="json">{response.message}</pre>,
        title: "Success",
      });
      return true;
    } else {
      Modal.error({
        content: <pre id="json">{response.message}</pre>,
        title: "Error",
      });
      return false;
    }
  };
  render() {
    const updateUniqueData = this.updateUniqueData();
    const filteredData = this.filterData();
    console.log("payload_config.field_mapping", filteredData);
    return (
      // <div className="container">
      <div className="mr-2 ml-2">
        <div
          className={
            this.state.isSettings
              ? "modal fade bd-example-modal-sm show mymodal"
              : "modal fade bd-example-modal-sm"
          }
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="confirm-box"
        >
          <div className="modal-dialog modal-dialog-centered modal-lg">
            <div className="modal-content cdb-confirm-box col-sm-12">
              <button
                type="button"
                className="close text-right"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() =>
                  this.setState({
                    isSettings: false,
                    searchconfig: this.state.defaultsearchconfig,
                    filtercrtupd: "",
                  })
                }
              >
                <span aria-hidden="true">&times;</span>
              </button>
              {/* <p>Do you want to register the household for NACH?</p> */}
              <div className="row">
                {!this.state.filtercrtupd && (
                  <>
                    <div className="col-md-2">
                      <button
                        className="btn btn-primary form-control"
                        onClick={(e) =>
                          this.setState({
                            filtercrtupd: "C",
                          })
                        }
                      >
                        Create
                      </button>
                    </div>
                    <div className="col-md-2">
                      <button
                        className="btn btn-primary form-control"
                        onClick={(e) =>
                          this.setState({
                            filtercrtupd: "U",
                          })
                        }
                      >
                        Update
                      </button>
                    </div>
                  </>
                )}
              </div>
              {this.state.filtercrtupd == "C" && (
                <div className="row">
                  <div className="col-md-12 mb-2">
                    <input
                      className="form-control"
                      placeholder="Enter Name"
                      onChange={(e) =>
                        this.setState({ filtername: e.target.value })
                      }
                    />
                  </div>
                  <div className="col-md-12 mb-2">
                    <QueryBuilder
                      fields={
                        this.state.search_fields && this.state.search_fields
                      }
                      operators={this.state.Operators}
                      addRuleTonewgroupid
                      controlClassnames={{
                        queryBuilder: "queryBuilder-branches",
                      }}
                      query={this.state.searchconfig}
                      onQueryChange={(q) =>
                        this.setState({
                          searchconfig: q,
                        })
                      }
                      controlElements={{
                        valueEditor: this.CustomValueEditor,
                      }}
                    />
                  </div>
                  {this.state.HeaderData.map((field, index) => (
                    <div key={index} className="col-md-4">
                      <div>
                        <input
                          type="checkbox"
                          className="mr-1"
                          checked={this.state.checkedCheckboxes.includes(
                            field.BucketField
                          )}
                          onChange={() =>
                            this.handleCheckboxChange(field.BucketField)
                          }
                        />
                        <label>{field.fieldname}</label>
                      </div>
                    </div>
                  ))}
                  <div className="col-md-12 row">
                    <div className="col-md-2">
                      <button
                        type="button"
                        className="btn btn-primary form-control"
                        disabled={this.state.checkedCheckboxes.length == 0}
                        onClick={() => {
                          this.savepreferences();
                          this.setState({
                            isSettings: false,
                            filtercrtupd: "",
                          });
                        }}
                      >
                        Create
                      </button>
                    </div>
                    <div className="col-md-2">
                      <button
                        type="button"
                        className="btn btn-secondary form-control"
                        onClick={() =>
                          this.setState({
                            isSettings: false,
                            searchconfig: this.state.defaultsearchconfig,
                            filtercrtupd: "",
                          })
                        }
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              )}
              {this.state.filtercrtupd == "U" && (
                <div className="row">
                  <div className="col-md-12 mb-2">
                    <select
                      className="form-control"
                      onChange={(e) => this.updateFilterval(e.target.value)}
                    >
                      <option value="">Select</option>
                      {this.state.savedFilter &&
                        this.state.savedFilter.map((data) => (
                          <option value={data._id.$oid}>
                            {data.filtername}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="col-md-12 mb-2">
                    <input
                      className="form-control"
                      placeholder="Enter Name"
                      value={this.state.updatefiltername}
                      onChange={(e) =>
                        this.setState({ updatefiltername: e.target.value })
                      }
                    />
                  </div>
                  <div className="col-md-12 mb-2">
                    <QueryBuilder
                      fields={
                        this.state.search_fields && this.state.search_fields
                      }
                      operators={this.state.Operators}
                      addRuleTonewgroupid
                      controlClassnames={{
                        queryBuilder: "queryBuilder-branches",
                      }}
                      query={this.state.updatesearchconfig}
                      onQueryChange={(q) =>
                        this.setState({
                          updatesearchconfig: q,
                        })
                      }
                      controlElements={{
                        valueEditor: this.CustomValueEditor,
                      }}
                    />
                  </div>
                  {this.state.HeaderData.map((field, index) => (
                    <div key={index} className="col-md-4">
                      <div>
                        <input
                          type="checkbox"
                          className="mr-1"
                          checked={
                            this.state.updatecheckbox &&
                            this.state.updatecheckbox.includes(
                              field.BucketField
                            )
                          }
                          onChange={() =>
                            this.handleCheckboxChangeupdate(field.BucketField)
                          }
                        />
                        <label>{field.fieldname}</label>
                      </div>
                    </div>
                  ))}
                  <div className="col-md-12 row">
                    <div className="col-md-2">
                      <button
                        type="button"
                        className="btn btn-primary form-control"
                        disabled={this.state.checkedCheckboxes.length == 0}
                        onClick={() => {
                          this.updateFilter();
                          this.setState({ isSettings: false });
                        }}
                      >
                        Update
                      </button>
                    </div>
                    <div className="col-md-2">
                      <button
                        type="button"
                        className="btn btn-secondary form-control"
                        onClick={() =>
                          this.setState({
                            isSettings: false,
                            searchconfig: this.state.defaultsearchconfig,
                            filtercrtupd: "",
                          })
                        }
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {this.state.isspin && (
          <div className="preloader">
            <div className="loader" ng-hide="data.length > 0"></div>
          </div>
        )}
        <div className="payload_view_navbar text-center">
          <button
            className="button back_button"
            onClick={() => this.props.history.goBack()}
          >
            <img src={require("../assets/images/back.png")} alt="" />
          </button>
          <h4>{this.state.view_name ? this.state.view_name : "View"}</h4>
          <button
            className="button"
            onClick={() =>
              logout(this.state.otherScreen, this.state.no_of_page)
            }
          >
            <img src={require("../assets/images/power-off.png")} alt="" />
          </button>
        </div>
        <div className="payload_view_main_block">
          <div className="payload_view_table_block">
            <div id="accordion">
              <div class="card">
                <div class="card-header p-0" id="headingOne">
                  <h5 class="mb-0">
                    <button
                      class="btn btn-link"
                      data-toggle="collapse"
                      data-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      Filters
                    </button>
                  </h5>
                </div>

                <div
                  id="collapseOne"
                  class="collapse"
                  aria-labelledby="headingOne"
                  data-parent="#accordion"
                >
                  <div class="card-body">
                    <div className="row">
                      {this.state.payload_config.payload_type == "B" ? (
                        <>
                          <div className="col-md-3">
                            <DatePicker
                              //defaultValue={moment().subtract(6, "months")}
                              format={dateFormatList}
                              onChange={this.changedate}
                              placeholder="Start Date"
                            />
                            <button
                              className="btn btn-primary ml-2"
                              disabled={!this.state.filterDate}
                              onClick={() => {
                                this.setState({ search_click: 1 }, () =>
                                  this.getPayloads()
                                );
                              }}
                            >
                              Search
                            </button>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="col-md-12">
                            <div className="d-flex flex-row-reverse col-md-4 float-right">
                              <div>
                                <button
                                  className="btn btn-primary"
                                  onClick={() => this.showpopup()}
                                >
                                  <SettingOutlined />
                                </button>
                              </div>
                              <div className="mr-2">
                                <select
                                  className="form-control"
                                  onChange={(e) =>
                                    this.filterchange(e.target.value)
                                  }
                                >
                                  <option value="">Select</option>
                                  {this.state.savedFilter &&
                                    this.state.savedFilter.map((data) => (
                                      <option value={data._id.$oid}>
                                        {data.filtername}
                                      </option>
                                    ))}
                                </select>
                              </div>
                            </div>
                            {this.state.payload_config.hide_filter == "1" ? (
                              ""
                            ) : (
                              <>
                                <div className="col-md-8">
                                  <QueryBuilder
                                    fields={
                                      this.state.search_fields &&
                                      this.state.search_fields
                                    }
                                    operators={this.state.Operators}
                                    addRuleTonewgroupid
                                    controlClassnames={{
                                      queryBuilder: "queryBuilder-branches",
                                    }}
                                    query={
                                      this.state.isSettings
                                        ? this.state.defaultsearchconfig
                                        : this.state.searchconfig
                                    }
                                    onQueryChange={(q) =>
                                      this.setState({
                                        searchconfig: q,
                                      })
                                    }
                                    controlElements={{
                                      valueEditor: this.CustomValueEditor,
                                    }}
                                  />
                                </div>
                                <div className="col-md-3">
                                  <button
                                    className="btn btn-primary mt-2"
                                    disabled={!this.state.filterDate}
                                    onClick={() => {
                                      this.setState({ search_click: 1 }, () =>
                                        this.getPayloads()
                                      );
                                    }}
                                  >
                                    Search
                                  </button>
                                </div>
                              </>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex flex-row-reverse mt-1">
              <button
                className="download-table-xls-button mb-1 btn btn-primary col-md-1"
                onClick={() => this.handleClick()}
              >
                <FileExcelOutlined />
              </button>
              <div className="col-md-2">
                <strong>
                  Total Record :{" "}
                  {this.state.payload_data && this.state.payload_data.length}
                </strong>
              </div>
              <div style={{ display: "none" }}>
                <ReactHTMLTableToExcel
                  id="table-xls-button"
                  className="download-table-xls-button mb-2 btn btn-primary"
                  table="my-table"
                  filename={
                    this.state.view_name +
                    "_" +
                    moment().format("YYYYMMDD_HH_mm_ss")
                  }
                  sheet="sheet1"
                  buttonText="Export to Excel"
                  isExporting={this.state.isExporting}
                />
              </div>
            </div>
            {this.state.effective_designation_id == "1" && (
              <div className="text-center m-2">
                <h3>Waiting For FCM</h3>
              </div>
            )}
            <div className="row">
              {updateUniqueData.length > 0 &&
                updateUniqueData.map((cell, index) => {
                  return (
                    <div className="col-md-3">
                      {cell["BucketField"]}
                      {cell["datatype"] === "group_id" ? (
                        <div className="d-flex">
                          <input
                            className="form-control"
                            readOnly
                            value={this.state.group_id}
                          />
                          <button
                            className="btn btn-primary ml-1"
                            onClick={() =>
                              this.adduniqueid(index, cell["BucketField"])
                            }
                          >
                            +
                          </button>
                        </div>
                      ) : cell["datatype"] === "dropdownlist" ? (
                        <>
                          {/* <Dropdownlist
                            bucketfield={cell["BucketField"]}
                            isedit={true}
                          /> */}
                        </>
                      ) : cell["datatype"] === "textbox" ? (
                        <input
                          className="form-control"
                          placeholder={cell["fieldname"]}
                          value={this.state.massUpdateData[index].value}
                          onChange={(e) =>
                            this.handlemassInputChange(
                              index,
                              cell["BucketField"],
                              e.target.value
                            )
                          }
                        />
                      ) : (
                        cell["datatype"] === "date" && (
                          <input
                            type="date"
                            // onChange={(event) => {
                            //   handlePayloadChange(
                            //     event.target.value,
                            //     cell["BucketField"]
                            //   );
                            // }}
                          />
                        )
                      )}
                    </div>
                  );
                })}
              {updateUniqueData.length > 0 && (
                <div className="col-md-2">
                  <button
                    className="btn btn-primary mt-4"
                    //disabled={this.state.selectedMassUpdateData.length == 0}
                    onClick={() => {
                      this.setState({ isMassupdate: true });
                      this.updatemassdata();
                    }}
                  >
                    Update
                  </button>
                </div>
              )}
            </div>
            {console.log(
              "this.state.payload_config.component_mapping",
              this.state.payload_config.component_mapping
            )}
            {(this.state.payload_config.component_mapping != null ||
            this.state.payload_config.component_mapping != undefined) &&
              this.state.isMobile ? (
              this.state.payload_data.map((rowdata, rowindex) => {
                return (
                  <div className="mt-2 mb-3">
                    <ResponsiveReactGridLayout
                      cols={{ lg: 10, md: 10, sm: 6, xs: 4, xxs: 2 }}
                      breakpoints={{
                        lg: 1200,
                        md: 996,
                        sm: 768,
                        xs: 480,
                        xxs: 0,
                      }}
                      rowHeight={30}
                      layouts={this.state.layouts}
                      // onLayoutChange={(layout, layouts) =>
                      // this.onLayoutChange(layout, layouts)
                      // }
                      isDraggable={false}
                      isResizable={false}
                    >
                      {this.state.payload_config.component_mapping.header.map(
                        (row, rowindexhead) => {
                          const fielddataArray = this.state.payload_config.component_mapping.header.map(
                            (item) => JSON.parse(item.fielddata)
                          );

                          const filtered = this.state.payload_config.field_mapping.filter(
                            (item) =>
                              fielddataArray.some(
                                (d2) => d2.BucketField === item.BucketField
                              )
                          );

                          const fielddataJsonString = {
                            field_mapping: filtered,
                          };
                          return (
                            <div
                              key={rowindexhead}
                              data-grid={{
                                x: row.x,
                                y: row.y,
                                w: row.w,
                                h: row.h,
                              }}
                              className="headerText"
                            >
                              <EditableRow
                                key={rowindexhead}
                                //payload_config={fielddataJsonString}
                                payload_config={this.state.payload_config}
                                payload={rowdata}
                                disabled={this.state.disabled}
                                setDisabled={this.setDisabled}
                                api_token={this.state.api_token}
                                getPayloads={this.getPayloads}
                                DeletePayload={this.DeletePayload}
                                DeleteMongoPayload={this.DeleteMongoPayload}
                                rowindex={rowindex}
                                effective_designation_id={
                                  this.state.effective_designation_id
                                }
                                Reasonlist={this.state.Reasonlist}
                                subpayload_config={this.state.subpayload_config}
                                unique_groups={this.state.unique_groups}
                                supervisor_branch_code={
                                  this.state.supervisor_branch_code
                                }
                                isMassupdate={this.state.isMassupdate}
                                selectedMassUpdateData={
                                  this.state.selectedMassUpdateData
                                }
                                updateUniqueData={updateUniqueData}
                                CheckAll={this.state.CheckAll}
                                props={this.props}
                                isspin={this.state.isspin}
                                view_type="mobile"
                                view_for="mobheader"
                                grid_position={row}
                              />
                            </div>
                          );
                        }
                      )}
                    </ResponsiveReactGridLayout>

                    <ResponsiveReactGridLayout
                      className="pv-block-body-dd"
                      cols={{ lg: 10, md: 10, sm: 6, xs: 4, xxs: 2 }}
                      breakpoints={{
                        lg: 1200,
                        md: 996,
                        sm: 768,
                        xs: 480,
                        xxs: 0,
                      }}
                      rowHeight={30}
                      layouts={this.state.bodyLayouts}
                      onLayoutChange={(layout, layouts) =>
                        this.onLayoutChangeBody(layout, layouts)
                      }
                      isResizable={false}
                      isDraggable={false}
                    >
                      {this.state.payload_config.component_mapping.body.map(
                        (rowbody, rowindexbody) => {
                          // console.log(
                          //   "rowindexbodyCnt",
                          //   this.state.payload_config.component_mapping.body
                          //     .length
                          // );
                          // const fielddataArray = this.state.payload_config.component_mapping.body.map(
                          //   (item) => JSON.parse(item.fielddata)
                          // );

                          // const filtered = this.state.payload_config.field_mapping.filter(item =>
                          //   fielddataArray.some(d2 => d2.BucketField === item.BucketField)
                          // );

                          //console.log("fielddataArray", filtered);
                          // const fielddataJsonString = {
                          //   field_mapping: filtered,
                          // };
                          // const parsedFielddata = JSON.parse(rowbody.fielddata);
                          // const bucketFieldToMatch =
                          //   parsedFielddata.BucketField;

                          // // Find the corresponding object in data array
                          // const body_data = this.state.payload_config.field_mapping.find(
                          //   (item) => item.BucketField === bucketFieldToMatch
                          // );

                          const fielddataObj = JSON.parse(rowbody.fielddata);

                          // Find the corresponding object in the data array
                          const matchingData = this.state.payload_config.field_mapping.find(
                            (item) =>
                              item.BucketField === fielddataObj.BucketField
                          );

                          // If a matching object is found, update the DisableOnFieldValue
                          if (matchingData) {
                            fielddataObj.showLabel = matchingData.showLabel;
                            // Update initialData2 with the modified fielddata
                            rowbody.fielddata = JSON.stringify(fielddataObj);
                          }

                          // Log the updated initialData2
                          console.log("rowindexbody", rowbody);
                          return (
                            <div
                              key={rowindexbody}
                              data-grid={{
                                x: rowbody.x,
                                y: rowbody.y,
                                w: rowbody.w,
                                h: rowbody.h,
                              }}
                              className="bodytext"
                            >
                              <EditableRow
                                key={rowindexbody}
                                //payload_config={fielddataJsonString}
                                payload_config={this.state.payload_config}
                                payload={rowdata}
                                disabled={this.state.disabled}
                                setDisabled={this.setDisabled}
                                api_token={this.state.api_token}
                                getPayloads={this.getPayloads}
                                DeletePayload={this.DeletePayload}
                                DeleteMongoPayload={this.DeleteMongoPayload}
                                rowindex={rowindex}
                                effective_designation_id={
                                  this.state.effective_designation_id
                                }
                                Reasonlist={this.state.Reasonlist}
                                subpayload_config={this.state.subpayload_config}
                                unique_groups={this.state.unique_groups}
                                supervisor_branch_code={
                                  this.state.supervisor_branch_code
                                }
                                isMassupdate={this.state.isMassupdate}
                                selectedMassUpdateData={
                                  this.state.selectedMassUpdateData
                                }
                                updateUniqueData={updateUniqueData}
                                CheckAll={this.state.CheckAll}
                                props={this.props}
                                isspin={this.state.isspin}
                                view_type="mobile"
                                view_for="mobbody"
                                totcnt={
                                  this.state.payload_config.component_mapping
                                    .body.length
                                }
                                grid_position={rowbody}
                                rowindexbody={rowindexbody}
                              />
                            </div>
                          );
                        }
                      )}
                    </ResponsiveReactGridLayout>
                    {/* <div className="pv-block-body"></div> */}
                  </div>
                );
              })
            ) : (
              <div className="tableFixHead">
                <table
                  className="payload_view_table text-center table table-hover table-responsive"
                  id="my-table"
                >
                  <thead>
                    <tr>
                      {updateUniqueData.length > 0 && (
                        <th>
                          {/* <input
                          type="checkbox"
                          value="checkall"
                          onChange={(e) =>
                            this.setState({
                              CheckAll: !this.state.CheckAll,
                            })
                          }
                        /> */}
                          #
                        </th>
                      )}
                      {this.state.payload_config.field_mapping.map(
                        (cell, index) => {
                          return (
                            <>
                              {cell.isVisible == "1" && (
                                <th key={cell.fieldname}>
                                  {cell.fieldname}
                                  {cell.isRequired == 1 && <span> *</span>}
                                  {!this.state.isExporting && (
                                    <>
                                      <br />

                                      {cell.datatype == "dropdownlist" ? (
                                        <this.SearchDropdownlist
                                          className={
                                            !this.state.isExporting
                                              ? "form-control form-control-sm"
                                              : "hidden"
                                          }
                                          bucketfield={cell.BucketField}
                                          isedit={true}
                                        />
                                      ) : (
                                        <input
                                          placeholder={cell.fieldname}
                                          name={cell.fieldname}
                                          type="text"
                                          className={
                                            !this.state.isExporting
                                              ? "form-control form-control-sm"
                                              : "hidden"
                                          }
                                          value={this.state.searchQuery}
                                          onChange={(e) =>
                                            this.eventNameSearch(
                                              e,
                                              cell.BucketField
                                            )
                                          }
                                        />
                                      )}
                                    </>
                                  )}
                                </th>
                              )}
                            </>
                          );
                        }
                      )}
                      <th onClick={this.handleSort}>
                        Date Time{" "}
                        <span
                          className={
                            this.state.sortOrder == "asc"
                              ? "up-arrow"
                              : "down-arrow"
                          }
                        ></span>
                      </th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredData.length > 0 ? (
                      filteredData.map((row, rowindex) => {
                        return (
                          <EditableRow
                            key={row.key}
                            payload_config={this.state.payload_config}
                            payload={row}
                            disabled={this.state.disabled}
                            setDisabled={this.setDisabled}
                            api_token={this.state.api_token}
                            getPayloads={this.getPayloads}
                            DeletePayload={this.DeletePayload}
                            DeleteMongoPayload={this.DeleteMongoPayload}
                            rowindex={rowindex}
                            effective_designation_id={
                              this.state.effective_designation_id
                            }
                            Reasonlist={this.state.Reasonlist}
                            subpayload_config={this.state.subpayload_config}
                            unique_groups={this.state.unique_groups}
                            supervisor_branch_code={
                              this.state.supervisor_branch_code
                            }
                            isMassupdate={this.state.isMassupdate}
                            selectedMassUpdateData={
                              this.state.selectedMassUpdateData
                            }
                            updateUniqueData={updateUniqueData}
                            CheckAll={this.state.CheckAll}
                            props={this.props}
                            isspin={this.state.isspin}
                            view_type="table"
                            view_for="table"
                            grid_position={row}
                          />
                        );
                      })
                    ) : (
                      <div className="text-center font-bold">No Data Found</div>
                    )}
                  </tbody>
                </table>
              </div>
            )}
            <div className="text-center">
              {this.state.payload_config.payload_type == "M" && (
                <button
                  className="btn btn-primary mt-2"
                  disabled={
                    this.state.disable_loadmore ||
                    this.state.payload_data.length == 0
                  }
                  onClick={() => {
                    this.setState({ load_more: 1 }, () => this.getPayloads());
                  }}
                >
                  Load More
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function EditableRow({
  api_token,
  payload_config,
  payload,
  disabled,
  setDisabled,
  getPayloads,
  DeletePayload,
  DeleteMongoPayload,
  rowindex,
  effective_designation_id,
  Reasonlist,
  subpayload_config,
  unique_groups,
  supervisor_branch_code,
  isMassupdate,
  selectedMassUpdateData,
  updateUniqueData,
  CheckAll,
  props,
  isspindata,
  view_type,
  view_for,
  totcnt,
  grid_position,
  rowindexbody,
}) {
  const [defaultpayload_config, setdefaultpayload_config] = useState(
    payload_config
  );
  const [edit, setEdit] = useState(false);
  const [updatedPayload, setUpdatedPayload] = useState({});
  const [ImageView, setImageView] = useState();
  const [isImageView, setisImageView] = useState(false);
  const [isShowData, setisShowData] = useState(false);
  const [isPayloadView, setisPayloadView] = useState(false);
  const [PayloadViewData, setPayloadViewData] = useState();
  const [ShowData, setShowData] = useState(false);
  const [ReasonData, setReasonData] = useState();
  const [isFamily, setisFamily] = useState(false);
  const [familyData, setfamilyData] = useState();
  const [change_data, setchange_data] = useState({});
  const [change_blockdelete, setchange_blockdelete] = useState();
  const [expandedRow, setExpandedRow] = useState(null);
  const [isgroupid, setisgroupid] = useState(false);
  const [groupid, setgroupid] = useState();
  const [blockData, setblockData] = useState();
  const [isblockDataInput, setisblockDataInput] = useState(false);
  const [isBlock, setisBlock] = useState(false);
  const [DeleteBlock, setDeleteBlock] = useState(0);
  const [DeleteBucketField, setDeleteBucketField] = useState();
  const [updategroupid, setupdategroupid] = useState(unique_groups);
  const [updateReasonlist, setupdateReasonlist] = useState();
  const [mand_fld, setmand_fld] = useState();
  const [newfield_mapping, setnewfield_mapping] = useState();
  const [newfieldEdit_mapping, setnewfieldEdit_mapping] = useState();
  const [fieldtoupdate, setfieldtoupdate] = useState();
  const [tenure, settenure] = useState();
  const [productidtoupdate, setproductidtoupdate] = useState();
  const [productlist, setproductlist] = useState();
  const [errorMsg, seterrorMsg] = useState();
  const [isProductList, setisProductList] = useState(false);
  const [selectedProduct, setselectedProduct] = useState();
  const [updateAmount, setupdateAmount] = useState(0);
  const [checkedItems, setCheckedItems] = useState([]);
  const [checkboxValues, setCheckboxValues] = useState([]);
  const [ActionFormType, setActionFormType] = useState();
  const [ModalForm, setModalForm] = useState(false);
  const [replacedFormrow, setreplacedFormrow] = useState();
  const [replacementValues, setreplacementValues] = useState();
  const [isModalFormUpdated, setIsModalFormUpdated] = useState(false);
  const [isspin, setisspin] = useState(isspindata);
  const [onEdit, setonEdit] = useState(true);
  const [isAdd, setisAdd] = useState(false);
  const [DisableBtn, setDisableBtn] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState("");
  const [PayloadRequired, setPayloadRequired] = useState();
  const [isError, setisError] = useState(false);
  const [isErrorValue, setisErrorValue] = useState();
  const [isOpenModal, setisOpenModal] = useState(false);
  const [actionValue, setactionValue] = useState();
  const [editLoading, setEditLoading] = useState(false);

  console.log("unique_groups", updategroupid);
  useEffect(() => {
    const allCheckboxValues = CheckAll ? [] : getDataIds(); // Replace with your dynamic data IDs
    setCheckboxValues(allCheckboxValues);
  }, []);

  const getDataIds = () => {
    // payload_config.field_mapping.map((cell, tdindex) => {
    //   handlemassCheckboxChange(payload_config.payload_type == "M" ? payload._id.$oid : payload["key"]);
    // });
  };
  useEffect(() => {
    setDisabled(edit);
    let _updatedPayload = {
      ...payload,
    };

    setUpdatedPayload(_updatedPayload);
    checkApicall();
  }, [edit]);

  const checkApicall = () => {
    const filteredList = payload_config.field_mapping.filter(
      (item) => item.addapi === "1"
    );
    console.log("Reasonlist", Reasonlist);

    let dropdown_id = "";
    let item_value = "";
    let fieldname = "";
    if (filteredList.length > 0) {
      dropdown_id = filteredList[0].dropdownlist;
      item_value = converttoarray(filteredList[0].BucketField, updatedPayload);
      fieldname = filteredList[0].BucketField;
    }
    if (dropdown_id && item_value) {
      let generate_Id = generateId(10);
      const requestOptions = {
        method: "GET",
        headers: {
          "api-token": api_token,
          component_id: generate_Id1,
          apiId: generate_Id,
          api_group_id: "",
          component: "payloadview",
          user_id: localStorage.getItem("in_userid"),
        },
      };

      customFetch(
        process.env.REACT_APP_URL +
          "/get/dropdown/item/master/data/" +
          dropdown_id +
          "/" +
          item_value,
        requestOptions
      )
        .then((res) => {
          return res.json();
        })
        .then((json) => {
          console.log("json.next_possible_values", json.next_possible_values);
          let resultObject = "";
          if (json.next_possible_values) {
            const filteredArray = Reasonlist[fieldname].filter((item) =>
              json.next_possible_values.includes(item.item_value)
            );

            resultObject = {
              ...Reasonlist,
              [fieldname]: filteredArray,
            };
            console.log("filteredArray->", resultObject);
          } else {
            resultObject = {
              ...Reasonlist,
              [fieldname]: "",
            };
          }
          setupdateReasonlist(resultObject);
          //setmand_fld(json.mand_fld_for_this_status);
          //setisBlock(false);
        });
    } else {
      setupdateReasonlist(Reasonlist);
    }
  };
  useEffect(() => {
    if (DeleteBlock == 1 && change_blockdelete) {
      handlePayloadChange("", DeleteBucketField);
      let loaddata = 1;
      delete_payloadkey(
        payload_config["bucket_name"],
        payload["key"],
        loaddata
      );
    }
  }, [DeleteBlock]);

  useEffect(() => {
    if (updateAmount == 1 && selectedProduct != "") {
      let splitproductdata = selectedProduct.split("~");

      handlePayloadChange(parseInt(splitproductdata[0]), productidtoupdate);
      handlePayloadChange(parseInt(splitproductdata[1]), fieldtoupdate);
      handlePayloadChange(parseInt(splitproductdata[2]), tenure);
    }
    if (
      updateAmount == 1 &&
      selectedProduct != "" &&
      Object.keys(change_data).length != 0
    ) {
      console.log("change_data", change_data);
      let loaddata = 1;
      editHandler(payload_config["bucket_name"], payload["key"], loaddata);
      setupdateAmount(0);
      setselectedProduct();
    }
  }, [updateAmount, change_data]);

  const convertToInputType = (value, inputType) => {
    switch (inputType) {
      case "text":
        return String(value);
      case "number":
        return Number(value);
      case "int32":
        return parseInt(value, 10) | 0;
      // Add more cases for other input types as needed
      default:
        return value;
    }
  };

  const handlePayloadChange = (inputValue, bucket_field) => {
    //if (payload_config.payload_type == "B") {
    //console.log("bucket_field", payload.field_mapping);
    console.log("bucket_field", updatedPayload);
    let _updatedPayload = {
      ...updatedPayload,
    };

    let value = convertToInputType(
      inputValue,
      typeof converttoarray(bucket_field, updatedPayload)
    );
    let splitdata_bucket_field = bucket_field.split(".");
    splitdata_bucket_field.reduce((obj, key, i) => {
      if (i === splitdata_bucket_field.length - 1) {
        //obj[key] = JSON.parse(value) ? JSON.parse(value) : value;
        obj[key] = value;
      }
      return obj[key];
    }, _updatedPayload);
    setUpdatedPayload({ ..._updatedPayload });
    setchange_data((prevData) => ({ ...prevData, [bucket_field]: value }));
    checkReason(_updatedPayload);
    // call below function if save mongo return error
    //checkmand_fld(_updatedPayload);
  };
  const checkmand_fld = (payload_data, req_fld) => {    
    if (req_fld) {
      // set default config
      console.log("payload_config.field_mapping", payload_config.field_mapping);
      //console.log("defaultpayload_config", defaultpayload_config.field_mapping);
      const updatedDataDefault = payload_config.field_mapping.map((item) => {
        const matchingItem = defaultpayload_config.field_mapping.find(
          (item1) => item1.BucketField === item.BucketField
        );

        if (matchingItem) {
          // If a matching item is found in data1, update the isRequired value
          return {
            ...item,
            isRequired: matchingItem.isRequired,
          };
        }

        // If no matching item is found, keep the original item
        return item;
      });
            
      // const filteredList = payload_config.field_mapping.filter(
      //   (item) => item.addapi === "1"
      // );
      
      // let item_value = filteredList[0].BucketField;
      
      // let keyToFilter = converttoarray(item_value, payload_data);
      
       //const filteredData = mand_fld[keyToFilter];
       const filteredData = req_fld;
      

      if (filteredData) {
        const updatedData = updatedDataDefault.map((item) => {
          if (filteredData.includes(item.BucketField)) {
            item.isRequired = "1";
          }
          return item;
        });

        // const updatedData = updatedDataDefault.filter(
        //   (item) => item.isRequired === "1"
        // );
        console.log("updatedData", updatedData);
        setnewfield_mapping(updatedData);
        validatepayloaddata(updatedData);
      }
    }
  };

  const validatepayloaddata = (updatedData = []) => {
    setPayloadRequired();
    let isValid = true;

    let PayloadRequired = [];
    let mapdata = updatedData ? updatedData : payload_config.field_mapping;
    console.log("mapdata--", mapdata);
    mapdata.map((field) => {
      if (field.isRequired === "1") {
        PayloadRequired.push(field);
      }
    });

    console.log("PayloadRequired", updatedData);
    const list = [...PayloadRequired];
    //console.log("list", list);
    PayloadRequired.map((bucket_field, index) => {
      //let splitdata_bucket_field = bucket_field.field.split(".");
      let splitdata = bucket_field.BucketField.split(".");
      let getvalue = splitdata.reduce((obj, key) => obj[key], updatedPayload);
      if (!getvalue) {
        isValid = false;
        list[index]["isError"] = "1";
      } else {
        list[index]["isError"] = "0";
      }
    });

    const isError1Fields = list
      .filter((item) => item.isError === "1")
      .map((item) => item.fieldname)
      .join(", ");

    const hasErrors = isError1Fields.length > 0;
    if (hasErrors) {
      console.log("list->", isError1Fields);
      setisErrorValue(isError1Fields);
      setisError(true);
    } else {
      setisError(false);
    }
    setPayloadRequired(list);
    console.log("PayloadRequired", PayloadRequired);
    return isValid;
  };

  const checkReason = (payload_data) => {
    if (payload_data.advance > 0) {
      const filteredData = Reasonlist["advance"].filter(
        (item) => item.item_value == payload_data.advance
      );
      if (filteredData[0].target_queue != "") {
        let _updatedPayload = {
          ...payload_data,
        };
        let splitqueue = filteredData[0].target_queue.split("|");
        splitqueue.map((splitdata) => {
          let splitpair = splitdata.split("=");
          let bucket_key = splitpair[0].trim();
          let splitdata_bucket_field = bucket_key.split("."); //payload_config[bucket_key].split(".");
          splitdata_bucket_field.reduce((obj, key, i) => {
            if (i === splitdata_bucket_field.length - 1) {
              //obj[key] = JSON.parse(value) ? JSON.parse(value) : value;
              obj[key] = splitpair[1].trim();
            }
            return obj[key];
          }, _updatedPayload);
          console.log("_updatedPayload-->", _updatedPayload);
          setUpdatedPayload({ ..._updatedPayload });
        });
      }
    }
  };

  useEffect(() => {
    if (isModalFormUpdated) {
      // Call actionHandler here based on the updated ModalForm
      setIsModalFormUpdated(false);
      checkonsaveapi();
    }
  }, [ModalForm, replacedFormrow, isModalFormUpdated]);
  const checkonsaveapi = async () => {
    const action = payload_config.action_mapping.filter((item) => {
      return item.action_event == "onsave";
    })[0];
    console.log("actionn", action);
    console.log("ModalFormm", ModalForm);
    const onsave = true;
    if (action) {
      if (ModalForm || replacedFormrow) {
        if (ActionFormType == "formdata") {
          actionHandler(action.id, ModalForm, ActionFormType);
        }
        if (ActionFormType == "formrow") {
          actionHandler(action.id, replacedFormrow, ActionFormType);
        }
      } else {
        generate_modaldata(action.id, updatedPayload, onsave);
      }
    }
  };
  const delete_payloadkey = async (bucket_name, payload_key, loaddata = 0) => {
    if (payload_config.payload_type == "M") {
      let dbname, collectionname;
      if (payload_config.db_name == "") {
        dbname = "callbacks";
      } else {
        dbname = payload_config.db_name;
      }

      if (payload_config.collection_name == "") {
        collectionname = "callback_payloads";
      } else {
        collectionname = payload_config.collection_name;
      }
      console.log("payload_key->", payload_key);
      console.log("payload->", payload);
      let objectid = payload._id.$oid;
      let upd_data;
      if (DeleteBlock == 1) {
        upd_data = change_blockdelete;
      } else {
        upd_data = change_data;
      }
      console.log("After state update:", upd_data);
      const response = await delete_mongofield(
        api_token,
        dbname,
        collectionname,
        objectid,
        upd_data
      );

      if (response.status == 1) {
        if (DeleteBlock == 1) {
          setDeleteBlock(0);
        }
        setisblockDataInput(false);
        checkonsaveapi();
        if (loaddata == 1) {
          getPayloads();
        }
        Modal.success({
          content: <pre id="json">Payload updated successfully!</pre>,
          title: "Success",
        });
        return true;
      } else {
        Modal.error({
          content: <pre id="json">Error updating the payload</pre>,
          title: "Error",
        });
        return false;
      }
    }
  };
  const editHandler = async (bucket_name, payload_key, loaddata = 0) => {
    if (payload_config.payload_type == "M") {
      let dbname, collectionname;
      if (payload_config.db_name == "") {
        dbname = "callbacks";
      } else {
        dbname = payload_config.db_name;
      }

      if (payload_config.collection_name == "") {
        collectionname = "callback_payloads";
      } else {
        collectionname = payload_config.collection_name;
      }
      console.log("payload_key->", payload_key);
      console.log("payload->", payload);
      let objectid = payload._id.$oid;
      let upd_data;
      if (DeleteBlock == 1) {
        upd_data = change_blockdelete;
      } else {
        upd_data = change_data;
      }
      console.log("After state update:", upd_data);
      const response = await update_mongopayload(
        api_token,
        dbname,
        collectionname,
        objectid,
        upd_data
      );

      if (response.status == 0) {
        console.log("updatedPayload", updatedPayload);
        checkmand_fld(updatedPayload, response.fields);
      } else if (response.status == 1) {
        if (DeleteBlock == 1) {
          setDeleteBlock(0);
        }
        setisblockDataInput(false);
        checkonsaveapi();
        if (loaddata == 1) {
          getPayloads();
        }
        Modal.success({
          content: <pre id="json">Payload updated successfully!</pre>,
          title: "Success",
        });
        return true;
      } else {
        Modal.error({
          content: <pre id="json">Error updating the payload</pre>,
          title: "Error",
        });
        return false;
      }
    }
    if (payload_config.payload_type == "B") {
      console.log("finalData", payload_key);
      const response = await update_payload(
        api_token,
        bucket_name,
        payload_key,
        updatedPayload
      );

      if (response.status == 1) {
        Modal.success({
          content: <pre id="json">Payload updated successfully!</pre>,
          title: "Success",
        });
        return true;
      } else {
        Modal.error({
          content: <pre id="json">Error updating the payload</pre>,
          title: "Error",
        });
        return false;
      }
    }
  };

  const actionHandler = async (action_id, payload_data, ActionFormType) => {
    let payloadkey = payload.key;
    console.log("payload_config-->", payload_config);
    try {
      setDisabled(true);
      const action = payload_config.action_mapping.filter((item) => {
        return item.id == action_id;
      })[0];
      let URL;
      console.log("action", action);
      // if (payload_config.payload_type == "M") {
      //   URL = process.env.REACT_APP_PYTHON_URL;
      // }
      // if (payload_config.payload_type == "B") {
      //   URL = process.env.REACT_APP_URL;
      // }
      if (action.url_type == "B") {
        URL = action.api_url;
      } else {
        URL = process.env.REACT_APP_URL + "/" + action.api_url;
      }
      const url = URL;
      let payload_input = null;
      let method = null;
      if (payload_data) {
        if (ActionFormType == "formdata") {
          const form = new FormData();
          payload_data.forEach((field) => {
            form.append(field.field, field.value);
          });
          if (payload_config.payload_type == "B") {
            form.append("doc_id", payloadkey);
          }
          payload_input = form;
        }
        if (ActionFormType == "formrow") {
          payload_input = payload_data;
        }
        method = "POST";
      }
      let generate_Id = generateId(10);
      let requestOptions = "";
      if (ActionFormType == "formdata") {
        requestOptions = {
          method: method,
          headers: {
            "api-token": api_token,
            component_id: generate_Id1,
            apiId: generate_Id,
            api_group_id: "",
            component: "",
            user_id: localStorage.getItem("in_userid"),
          },
          body: payload_input,
        };
      }
      if (ActionFormType == "formrow") {
        requestOptions = {
          method: method,
          headers: {
            "api-token": api_token,
            component_id: generate_Id1,
            apiId: generate_Id,
            api_group_id: "",
            component: "",
            user_id: localStorage.getItem("in_userid"),
            "Content-Type": "application/json",
          },
          body: payload_input,
        };
      }

      const response = await customFetch(url, requestOptions);

      const data = await response.json();
      if (response.status != 200) throw data;

      Modal.success({
        content: <pre id="json">{JSON.stringify(data)}</pre>,
        title: "Success",
      });
    } catch (e) {
      Modal.error({
        content: <pre id="json">{JSON.stringify(e)}</pre>,
        title: "Error",
      });
    } finally {
      setDisabled(false);
      getPayloads();
    }
  };
  const generate_modaldata = async (
    action_id,
    payload_data,
    onsave = false
  ) => {
    console.log("action_idd", action_id);
    console.log("payload_dataa", payload_data);
    const action = payload_config.action_mapping.filter((item) => {
      return item.id == action_id;
    })[0];
    if (onsave) {
      setIsModalFormUpdated(true);
    }
    const url = action.api_url;
    // let payload = null;
    // let method = null;
    if (action["formdata"]) {
      setActionFormType("formdata");
      let form = [];
      for (var k = 0; k < payload_config.field_mapping.length; k++) {
        for (var l = 0; l < action.formdata.length; l++) {
          var exists = false;
          if (
            payload_config.field_mapping[k].fieldname ===
            action.formdata[l].formdatavalue
          ) {
            console.log(
              "action->",
              payload_config.field_mapping[k].BucketField
            );
            console.log("payload_data->", payload_data);
            let splitdaTa = payload_config.field_mapping[k].BucketField.split(
              "."
            );
            let searchdata = "";
            // if (splitdaTa.length > 0) {
            //   for (let index = 0; index < splitdaTa.length; index++) {
            //     searchdata += '["' + splitdaTa[index] + '"]';
            //   }
            // }
            let result = payload_data;
            for (let key of splitdaTa) {
              result = result[key];
            }
            //console.log("field", action.formdata[l].formdatakey);
            //console.log("value", action.formdata);

            form.push({
              field: action.formdata[l].formdatakey,
              //value: payload_data[action.formdata[l].formdatakey],
              value: result,
              isRequired: payload_config.field_mapping[k].isRequired,
              datatype: payload_config.field_mapping[k].datatype,
              isEdit: payload_config.field_mapping[k].isEdit,
              fieldlabel: payload_config.field_mapping[k].fieldname,
              isError: "",
            });
            break;
          }
        }
      }
      console.log("form-->", form);
      setModalForm(form);
      // payload = form;
      // method = "POST";
    }
    if (action["formrow"]) {
      setActionFormType("formrow");
      const extractVariablesFromObject = (obj) => {
        if (typeof obj === "object" && obj !== null) {
          const result = {};
          for (const key in obj) {
            result[key] = extractVariablesFromObject(obj[key]);
          }
          return result;
        } else if (typeof obj === "string") {
          return extractVariables(obj);
        } else {
          return obj;
        }
      };
      console.log("payload_config.field_mapping", payload_config.field_mapping);
      // Function to extract variables from a string
      const extractVariables = (str) => {
        const regex = /{{(.*?)}}/g;
        const matches = str.match(regex);
        return matches ? matches.map((match) => match.slice(2, -2)) : [str];
      };

      // Extract variables from the input object
      const result = extractVariablesFromObject(action["formrow"]);
      let replacementValues = {};
      const checkNullData = (key_name) => {
        console.log("key_name", key_name);
        if (key_name == "user_id") {
          return localStorage.getItem("in_userid");
        }
        if (key_name == "payload") {
          return JSON.stringify(updatedPayload);
        }
      };

      result.map((cell) => {
        replacementValues[cell] = converttoarray(cell, payload)
          ? converttoarray(cell, payload)
          : checkNullData(cell);
      });

      const extractVariablesFroReplace = (obj, replacementValues) => {
        if (typeof obj === "object" && obj !== null) {
          const result = {};
          for (const key in obj) {
            result[key] = extractVariablesFroReplace(
              obj[key],
              replacementValues
            );
          }
          return result;
        } else if (typeof obj === "string") {
          return replaceVariables(obj, replacementValues);
        } else {
          return obj;
        }
      };

      const replaceVariables = (str, replacementValues) => {
        const regex = /{{(.*?)}}/g;
        const replacedStr = str.replace(regex, (match, variable) => {
          return replacementValues[variable] || match;
        });
        return replacedStr;
      };

      // Example usage:
      console.log("Before:", action["formrow"]);
      let replacedFormrow;

      replacedFormrow = extractVariablesFroReplace(
        action["formrow"],
        replacementValues
      );
      const keysWithNullValues = Object.keys(replacementValues).filter(
        (key) =>
          replacementValues[key] === null || replacementValues[key] === ""
      );
      console.log("hasNullValue", keysWithNullValues);
      setreplacementValues(keysWithNullValues);

      console.log("After:", replacedFormrow);

      //console.log("replacementValues", replacementValues);
      setreplacedFormrow(replacedFormrow);
    }
  };
  const converttoarray = (key, data) => {
    const keys = key.split(/[.:]/);
    // Initialize a variable to store the current data object
    let currentData = data;

    // Loop through each part of the key
    for (const k of keys) {
      console.log("keys-->", k);
      console.log("keys1-->", currentData);
      // Check if the currentData is an object and has the key
      if (currentData && currentData.hasOwnProperty(k)) {
        currentData = currentData[k]; // Update currentData to the nested object
      } else {
        return ""; // Return a default value if any part of the key is not present
      }
    }
    console.log("key->", key + "--" + currentData);
    return currentData;
  };
  const viewFamily = (hhid) => {
    const cbResponseArray = payload.data.cb_response;

    // Filter ConsumerName and ReferenceNumber based on household_id
    const filteredData = cbResponseArray
      .filter((response) => payload.data.household_id === hhid)
      .map((response) => ({
        ConsumerName: response.ConsumerName,
        ReferenceNumber: response.ReferenceNumber,
      }));

    // Display the result
    setfamilyData(filteredData);
    setisFamily(true);
  };
  const viewImage = (path) => {
    const formdata = new FormData();
    formdata.append("blob_url", path);
    let generate_Id = generateId(10);
    const requestOptions = {
      method: "POST",
      headers: {
        "api-token": api_token,
        component_id: generate_Id1,
        apiId: generate_Id,
        api_group_id: "",
        component: this.constructor.name,
        user_id: localStorage.getItem("in_userid"),
      },
      body: formdata,
    };
    // AT-2109 | AT-2156 | Ankur Makavana | 10-07-2023 | replace blob_url with public filepath
    customFetch(process.env.REACT_APP_URL + "/get/public/url", requestOptions)
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        setImageView(json.public_url);
        setisImageView(true);
      });
    //const data = await response.blob();
  };

  const showdata = async (apiname, tabledata) => {
    let getapiconfig = JSON.parse(payload_config.get_api_mapping);
    let findapi = getapiconfig.find((item) => item.action_name == apiname);

    let form = [];
    for (var k = 0; k < payload_config.field_mapping.length; k++) {
      for (var l = 0; l < findapi.formdata.length; l++) {
        var exists = false;
        if (
          payload_config.field_mapping[k].fieldname ===
          findapi.formdata[l].formdatavalue
        ) {
          form.push({
            field: findapi.formdata[l].formdatakey,
            value: payload[payload_config.field_mapping[k].BucketField],
          });
          break;
        }
      }
    }
    for (var l = 0; l < findapi.formdata.length; l++) {
      if (
        findapi.formdata[l].formdatavalue == "" ||
        findapi.formdata[l].formdatavalue == null ||
        findapi.formdata[l].formdatavalue == undefined
      ) {
        form.push({
          field: findapi.formdata[l].formdatakey,
          value: findapi.formdata[l].defaultvalue,
        });
      }
    }
    const formdata = new FormData();
    form.map((fomdata) => {
      formdata.append(fomdata.field, fomdata.value);
    });
    let generate_Id = generateId(10);
    const requestOptions = {
      method: "POST",
      headers: {
        "api-token": api_token,
        component_id: generate_Id1,
        apiId: generate_Id,
        api_group_id: "",
        component: this.constructor.name,
        user_id: localStorage.getItem("in_userid"),
      },
      body: formdata,
    };

    const response = await customFetch(
      process.env.REACT_APP_URL + "/" + findapi.api_url,
      requestOptions
    );
    if (response.status != 200) throw "Error updating the payload";

    const data = await response.json();
    setShowData(data);
    setisShowData(true);
  };
  const handleCancelimage = () => {
    setisImageView(false);
  };
  const handleCancelfamily = () => {
    setisFamily(false);
  };
  const handleCanceljson = () => {
    setisPayloadView(false);
  };
  const handleCancelblock = () => {
    setisBlock(false);
  };
  const handleCanceldata = () => {
    setisShowData(false);
  };
  const handleCancelgroupid = () => {
    setisgroupid(false);
  };
  const handleRowClick = (rowData) => {
    console.log("rowData", payload);
    setExpandedRow(payload);
  };
  const Dropdownlist = (val) => {
    console.log("valll", val);
    let bucket_field = val.bucketfield.replace(/\./g, "_");
    let isedits = updateReasonlist && val.isedit == true && (
      <select
        className="form-control"
        defaultValue={val.value}
        //value={ReasonData}
        style={{
          width: 120,
        }}
        onChange={(event) => {
          handlePayloadChange(parseInt(event.target.value), val.bucketfield);
          //setReasonData(event.target.value);
        }}
      >
        <option value="">Select</option>
        {updateReasonlist[bucket_field] &&
          updateReasonlist[bucket_field].map((list) => (
            <option value={list.item_value}>{list.item_name}</option>
          ))}
      </select>
    );
    if (val.isedit == false) {
      // let iseditfalse;
      // iseditfalse = Reasonlist.map(
      //   (list) => list.item_value == val.value && list.item_name
      // );
      console.log("Reasonlist11", Reasonlist);
      if (Reasonlist && Reasonlist[bucket_field]) {
        const matchingItem = Reasonlist[bucket_field].find(
          (item) => item.item_value == val.value
        );

        // Check if a matching item was found if not then show value
        if (matchingItem) {
          const itemName = matchingItem.item_name;
          isedits = itemName;
        } else {
          isedits = val.value;
        }
      } else {
        isedits = "Select";
      }
    }
    return isedits;
  };
  const downloadfile = async (path) => {
    setisspin(true);
    // try {
    //   const storage = getStorage(); // Initialize Firebase Storage
    //   const fileRef = ref(storage, path);

    //   // Get the download URL for the file
    //   const downloadUrl = await getDownloadURL(fileRef);
    //   // Create a temporary link for the download
    //   const link = document.createElement("a");
    //   link.href = downloadUrl;
    //   link.setAttribute("download", path);
    //   document.body.appendChild(link);

    //   // Simulate a click on the link to trigger the download
    //   link.click();

    //   // Remove the temporary link from the document
    //   document.body.removeChild(link);
    // } catch (error) {
    //   console.error("Error downloading file:", error);
    // }
    const formdata = new FormData();
    formdata.append("blob_url", path);
    let generate_Id = generateId(10);
    const requestOptions = {
      method: "POST",
      headers: {
        "api-token": api_token,
        component_id: generate_Id1,
        apiId: generate_Id,
        api_group_id: "",
        component: "PaylodView",
        user_id: localStorage.getItem("in_userid"),
      },
      body: formdata,
    };
    customFetch(
      process.env.REACT_APP_URL + "/download/files/bucket",
      requestOptions
    )
      .then((res) => {
        if (res.headers.get("api_code") > 0) {
          this.setState({
            isErrOpen: true,
            api_error_message: res.headers.get("api_error_message"),
          });
        }

        res.blob().then((img) => {
          //URL.createObjectURL(img)
          const url = window.URL.createObjectURL(img);
          //console.log(URL.createObjectURL(img))
          // this.setState({
          //   viewImg: URL.createObjectURL(img),
          //   isOpen: true,
          // });
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", path);
          // Append to html link element page
          document.body.appendChild(link);
          // Start download
          link.click();
          // Clean up and remove the link
          link.parentNode.removeChild(link);
        });
        setisspin(false);
      })
      .catch((error) => console.log("error", error));
  };

  const showproductdata = (amount, fieldto_update, productid, tenure) => {
    setfieldtoupdate(fieldto_update);
    setproductidtoupdate(productid);
    settenure(tenure);
    setselectedProduct(
      (converttoarray(productid, payload) || "") +
        "~" +
        (converttoarray(fieldto_update, payload) || "") +
        "~" +
        (converttoarray(tenure, payload) || "")
    );
    let generate_Id = generateId(10);
    const requestOptions = {
      method: "GET",
      headers: {
        "api-token": api_token,
        component_id: generate_Id1,
        apiId: generate_Id,
        api_group_id: "",
        component: "payloadview",
        user_id: localStorage.getItem("in_userid"),
      },
    };
    const params = {
      max_amount: amount,
      household_id: converttoarray("household_id", payload),
    };
    const queryParams = new URLSearchParams(params).toString();
    customFetch(
      process.env.REACT_APP_URL + "/partner/get/dcb/products?" + queryParams,
      requestOptions
    )
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        if (json.status == 1) {
          setproductlist(json.data);
        } else {
          setproductlist([]);
          seterrorMsg(json.message);
        }
        setisProductList(true);
        console.log("jsondata->", json);
      });
  };
  const showblockdata = (block, fieldname, BucketField) => {
    // const groupedData = {};

    // for (const key in block) {
    //   if (block.hasOwnProperty(key)) {
    //     const field = block[key];
    //     const parts = key.split(".");
    //     const group = parts.slice(0, -1).join(".") || fieldname; // Set to "aaa" if group is not found

    //     if (!groupedData[group]) {
    //       groupedData[group] = [];
    //     }

    //     groupedData[group].push({ BucketField: key, value: field });
    //   }
    // }

    setblockData(block);
    setDeleteBucketField(BucketField);
    setisBlock(true);
  };
  const deleteblockdelete = (deletepath) => {
    console.log("deleteblockpath:", deletepath);

    setchange_blockdelete({
      [deletepath]: "",
    });
    setblockData();
    setisBlock(false);
    setDeleteBlock(1);
    console.log("Before State Update:", blockData);
    // setTimeout(() => {

    // }, 1000);
    //console.log("DeleteBucketField", change_data);
  };

  const handlemassCheckboxChange = (fieldName) => {
    console.log("fieldName", fieldName);
    const updatedCheckboxes = selectedMassUpdateData;
    if (updatedCheckboxes.includes(fieldName)) {
      // If the checkbox is already checked, uncheck it
      const index = updatedCheckboxes.indexOf(fieldName);
      updatedCheckboxes.splice(index, 1);
    } else {
      // If the checkbox is unchecked, check it
      updatedCheckboxes.push(fieldName);
    }
    console.log("updatedCheckboxes", updatedCheckboxes);
    setCheckedItems(updatedCheckboxes);
  };

  const chkpayloadrequired = () => {
    // below condition for check if status U then user can't update field
    if (updateReasonlist["status"]) {
      if (converttoarray("status", payload)) {
        const hasItemWithValueTwoAndStatusU = updateReasonlist["status"].some(
          (item) =>
            item.item_value === converttoarray("status", payload) &&
            item.status === "U"
        );
        // If such an item exists, trigger an alert
        if (hasItemWithValueTwoAndStatusU) {
          setEdit(false);
          alert("This Entry Can Not Edit");
        }
        console.log("status==>", updateReasonlist["status"]);
      }
    }
    console.log("chkpayloadrequired", payload_config.field_mapping);
    ////console.log("payload_config.field_mapping", payload_config.field_mapping);
    let Editfields = [];
    payload_config.field_mapping.map((field) => {
      if (field.isEdit === "1") {
        Editfields.push({
          fieldName: field.fieldname,
          field: field.BucketField,
          isError: "",
        });
      }
    });
    console.log("Editfields", Editfields);
    const updatedDefaultData = payload_config.field_mapping.map((item) => {
      const matchingItem = defaultpayload_config.field_mapping.find(
        (item1) => item1.BucketField === item.BucketField
      );
      if (matchingItem) {
        // If a matching item is found in data1, update the isRequired value
        return {
          ...item,
          isEdit: matchingItem.isEdit,
        };
      }
      // If no matching item is found, keep the original item
      return item;
    });
    let DisableEditfields = [];
    payload_config.field_mapping.map((field) => {
      if (field.isEditDisable === "1") {
        // if (field.datatype === "dropdownlist") {
        //   let oldvalue = updatedPayload[field.BucketField];
        //   let row_columnName = this.state.Reasonlist[field.BucketField].find(
        //     (item) => item.item_value === oldvalue
        //   );
        //   if (row_columnName !== undefined) {
        //     console.log("row_columnName", row_columnName.item_name);
        //     updatedPayload[field.BucketField] = row_columnName.item_name;
        //   }
        // }

        const matches = field.DisableOnFieldValue.match(/(\w+)(=|>|<)(\w+)/);
        if (matches) {
          const [, key, operator, value] = matches;
          let disablefield = [];
          DisableEditfields.push({
            fieldName: field.fieldname,
            field: field.BucketField,
            isError: "",
            DisableOnField: key,
            DisableOnFieldValue: value,
            DisableOperator: operator,
          });
          Editfields.forEach((dataItem) => {
            const matchingData1 = DisableEditfields.find(
              (data1Item) => data1Item.field === dataItem.field
            );
            if (matchingData1) {
              const {
                field,
                DisableOnField,
                DisableOnFieldValue,
                DisableOperator,
              } = matchingData1;
              // Assuming data2 is an object, iterate through its keys
              for (const key in updatedPayload) {
                let expression = `${updatedPayload[key]} ${DisableOperator} ${DisableOnFieldValue}`;
                console.log("expression", expression);
                try {
                  // Evaluate the expression using a safer alternative like Function constructor
                  let evaluateExpression = new Function(`return ${expression}`);
                  let result = evaluateExpression();
                  // 'result' will be true or false based on the evaluation
                  console.log("result", result);
                } catch (error) {
                  console.error("Error evaluating expression:", error);
                }
                if (key == DisableOnField && eval(expression)) {
                  disablefield.push(field);
                  // Do something if the condition is met
                }
              }
            }
          });
          // set isEdit = 0 if any field is mark as disable on specific status
          updatedDefaultData.forEach((item) => {
            if (disablefield.includes(item.BucketField)) {
              item.isEdit = "0";
            }
          });
          setnewfieldEdit_mapping(updatedDefaultData);
        } else {
          console.log("No matches found.");
        }
      }
    });
    console.log("DisableEditfields", DisableEditfields);
    console.log("DisablePayload", updatedPayload);
  };
  const extractstatedataVariablesFromObject = (obj) => {
    if (typeof obj === "object" && obj !== null) {
      const result = {};
      for (const key in obj) {
        result[key] = extractstatedataVariablesFromObject(obj[key]);
      }
      return result;
    } else if (typeof obj === "string") {
      return extractVariables(obj);
    } else {
      return obj;
    }
  };
  const extractVariables = (str) => {
    const regex = /{{(.*?)}}/g;
    const matches = str.match(regex);
    return matches ? matches.map((match) => match.slice(2, -2)) : [str];
  };
  const getstatedata = (data) => {
    const result = extractstatedataVariablesFromObject(data);
    let replacementValues = {};
    result.map((cell) => {
      console.log("celll", cell);
      replacementValues[cell] = converttoarray(cell, payload);
    });
    // Example usage:
    console.log("Before:", replacementValues);
    let replacedFormrow;

    replacedFormrow = extractstatedataVariablesFroReplace(
      data,
      replacementValues
    );

    console.log("After:", replacedFormrow);
    return replacedFormrow;
  };

  const extractstatedataVariablesFroReplace = (obj, replacementValues) => {
    if (typeof obj === "object" && obj !== null) {
      const result = {};
      for (const key in obj) {
        result[key] = extractstatedataVariablesFroReplace(
          obj[key],
          replacementValues
        );
      }
      return result;
    } else if (typeof obj === "string") {
      return replacestatedataVariables(obj, replacementValues);
    } else {
      return obj;
    }
  };

  const replacestatedataVariables = (str, replacementValues) => {
    const regex = /{{(.*?)}}/g;
    const replacedStr = str.replace(regex, (match, variable) => {
      return replacementValues[variable] || match;
    });
    return replacedStr;
  };

  const checkredirect = (RedirectData, RedirectCondition, BucketField) => {
    console.log("RedirectData", RedirectData);
    console.log("RedirectCondition", RedirectCondition);
    console.log("BucketField", BucketField);
    // Define a regular function
    // Extracting key, operator, and value from RedirectCondition
    const conditions = RedirectCondition.split(",");
    let allConditionsMatch = true; // For AND logic
    let anyConditionMatch = false; // For OR logic

    conditions.forEach((condition) => {
      const matches = condition.match(/(\w+)(==|=|>|<)(\w+)/);
      if (matches) {
        const [, key, operator, value] = matches;
        let conditionResult = false;
        const keyValue = parseInt(converttoarray(key, payload));

        console.log(
          "conditions",
          key + "-" + operator + "-" + value + "-" + keyValue
        );
        switch (operator) {
          case "==":
            conditionResult = keyValue == parseInt(value);
            break;
          case ">":
            conditionResult = keyValue > parseInt(value);
            break;
          case "<":
            conditionResult = keyValue < parseInt(value);
            break;
          default:
            console.log("Unsupported operator");
            conditionResult = false;
        }

        // For AND logic, all conditions must be true
        allConditionsMatch = allConditionsMatch && conditionResult;

        // For OR logic, any one condition being true is sufficient
        anyConditionMatch = anyConditionMatch || conditionResult;
      } else {
        console.log("Invalid condition format");
        allConditionsMatch = false;
      }
    });

    // Set RedirectConditionWork based on the required logic
    let RedirectConditionWOrk = anyConditionMatch ? 1 : 0;
    console.log("RedirectConditionWOrk:", RedirectConditionWOrk);

    // Checking condition
    if (RedirectConditionWOrk == 1) {
      console.log("chkcondition", RedirectConditionWOrk);
      // Extracting object from RedirectData
      let obj = RedirectData.toString()
        .split("requiredata:")[1]
        .split(",");
      // Getting state data
      const results = obj.map((obj, key) => {
        //const key = Object.keys(obj)[0];
        const statedata = getstatedata(obj);
        console.log("obj0", statedata);
        return { [key]: JSON.parse(statedata) }; // Return the updated object
      });

      let statedata = results; //getstatedata(obj);
      let urldata;
      let match = RedirectData.match(/path:'(.*?)'/);
      if (match && match[1]) {
        urldata = match[1];
      }
      let urlpath = getstatedata(urldata);
      console.log("urldata", urlpath);
      const convertedData = urlpath.replace(/{|}|"/g, "");
      console.log("convertedData", convertedData);

      if (statedata) {
        let parsedData;

        try {
          // Parsing state data
          console.log("parsedData", statedata);
          parsedData = statedata; //JSON.parse(statedata);
        } catch (error) {
          console.error("Error parsing data:", error);
          return null;
        }

        // Check if parsedData is an array
        let modifiedArray = {};
        if (Array.isArray(parsedData)) {
          parsedData.forEach((obj) => {
            for (let key in obj) {
              const innerArray = obj[key];
              innerArray.forEach((innerObj) => {
                for (let innerKey in innerObj) {
                  modifiedArray[innerKey] = innerObj[innerKey];
                }
              });
            }
          });

          console.log("modifiedArray", parsedData);
        }

        const url = new URL(window.location.href);
        const baseUrl = url.origin;
        console.log("urlExists1", baseUrl + "/payload/view"); // true if URL exists, false otherwise

        // Returning JSX
        return (
          <a
            href="javascript:void(0)"
            onClick={() => {
              checkUrlExists(baseUrl + "/" + convertedData).then(
                (urlExists) => {
                  if (urlExists == false) {
                    const state = {
                      api_token: api_token,
                      modifiedArray,
                      // ...parsedData.reduce((acc, item) => {
                      //   const key = Object.keys(item)[0];
                      //   acc[key] = item[key];
                      //   return acc;
                      // }, {}),
                    };

                    props.history.push({
                      pathname: "../" + convertedData,
                      // RedirectData.toString()
                      //   .split("path:")[1]
                      //   .split(",")[0]
                      //   .replace(/'/g, "")
                      //   .trim(),
                      state: state,
                    });
                  }
                }
              );
            }}
          >
            {converttoarray(BucketField, payload)}
          </a>
        );
      }
    }
    // Returning null if condition is not met
    return converttoarray(BucketField, payload);
  };

  const checkfields = (cell) => {
    if (cell["picklast"] && cell["picklast"] == 1) {
      let updatedString;
      if (cell["pickindex"] && cell["pickindex"] != "") {
        updatedString = cell["BucketField"].replace(
          /:\d+\./,
          ":" + cell["pickindex"] + "."
        );
      } else {
        console.log("BucketField_picklast", cell["BucketField"]);
        const [arrayPath, restPath] = cell["BucketField"].split(":");
        if (payload[arrayPath]) {
          //let number = (getarray - 1);
          updatedString = cell["BucketField"].replace(
            /:\d+\./,
            ":" + parseInt(payload[arrayPath].length - 1) + "."
          );
          console.log("Cel_data-3", updatedString);
        }
      }
      if (updatedString) {
        cell["BucketField"] = updatedString;
      }
    }
    return edit && cell["isEdit"] === "1" ? (
      cell["datatype"] === "productlist" ? (
        <>
          <a
            href="javascript:void(0)"
            disabled={disabled}
            onClick={() => {
              {
                !disabled &&
                  showproductdata(
                    converttoarray(cell["BucketField"], payload),
                    cell["productlist"],
                    cell["productid"],
                    cell["tenure"]
                  );
              }
            }}
          >
            {converttoarray(cell["BucketField"], payload)}
          </a>
        </>
      ) : cell["datatype"] === "block" ? (
        <>
          <a>{cell["fieldname"]}</a>
        </>
      ) : cell["datatype"] === "Downloadfile" ? (
        converttoarray(cell["BucketField"], updatedPayload) ? (
          <button
            className="btn btn-primary"
            onClick={() =>
              downloadfile(converttoarray(cell["BucketField"], updatedPayload))
            }
          >
            Download
          </button>
        ) : (
          "File Not Found"
        )
      ) : cell["datatype"] === "group_id" ? (
        <div className="d-flex">
          <select
            className="form-control"
            style={{
              width: 120,
            }}
            onChange={(event) => {
              handlePayloadChange(event.target.value, cell["BucketField"]);
            }}
            value={converttoarray(cell["BucketField"], updatedPayload)}
          >
            <option value="">Select</option>
            {updategroupid &&
              updategroupid.map((grpid) => (
                <option value={grpid.group_id}>{grpid.group_id}</option>
              ))}
          </select>
          {/* <button
      className="btn btn-primary ml-1"
      onClick={() => adduniqueid()}
    >
      +
    </button> */}
        </div>
      ) : cell["datatype"] === "DCB" ? (
        <TextArea
          autoSize={true}
          readOnly={!edit}
          bordered={edit}
          style={{
            width: 150,
          }}
          maxLength={cell["FieldLength"]}
          value={
            edit
              ? converttoarray(cell["BucketField"], updatedPayload)
              : // :
                // cell["datatype"] === "boolean"
                // ? converttoarray(cell["BucketField"], payload) == 1
                //   ? "Yes"
                //   : "No"
                converttoarray(cell["BucketField"], updatedPayload) &&
                JSON.stringify(
                  converttoarray(cell["BucketField"], updatedPayload)
                ).replace(/\"/g, "")
          }
          onChange={(event) => {
            handlePayloadChange(event.target.value, cell["BucketField"]);
          }}
        />
      ) : cell["datatype"] === "jsondata" ? (
        <>
          <a>{cell["BucketField"]}</a>
        </>
      ) : cell["datatype"] === "dropdownlist" ? (
        <>
          <Dropdownlist
            value={
              converttoarray(cell["BucketField"], updatedPayload) &&
              JSON.stringify(
                converttoarray(cell["BucketField"], updatedPayload)
              ).replace(/\"/g, "")
            }
            style={{
              width: 120,
            }}
            bucketfield={cell["BucketField"]}
            isedit={true}
          />
        </>
      ) : cell["datatype"] === "boolean" ? (
        <select
          className="form-control"
          value={converttoarray(cell["BucketField"], updatedPayload)}
          style={{
            width: 120,
          }}
          onChange={(event) => {
            handlePayloadChange(event.target.value, cell["BucketField"]);
          }}
        >
          <option value="">Select</option>
          <option value="1">Yes</option>
          <option value="0">No</option>
        </select>
      ) : cell["datatype"] === "textbox" ? (
        <TextArea
          autoSize={true}
          readOnly={!edit}
          bordered={edit}
          style={{
            width: 120,
          }}
          maxLength={cell["FieldLength"]}
          value={
            edit
              ? converttoarray(cell["BucketField"], updatedPayload)
              : // :
                // cell["datatype"] === "boolean"
                // ? converttoarray(cell["BucketField"], payload) == 1
                //   ? "Yes"
                //   : "No"
                converttoarray(cell["BucketField"], payload) &&
                JSON.stringify(
                  converttoarray(cell["BucketField"], payload)
                ).replace(/\"/g, "")
          }
          onChange={(event) => {
            handlePayloadChange(event.target.value, cell["BucketField"]);
          }}
        />
      ) : (
        cell["datatype"] === "date" && (
          <input
            type="date"
            style={{
              width: 120,
            }}
            value={
              edit
                ? moment(
                    converttoarray(cell["BucketField"], updatedPayload)
                  ).format("YYYY-MM-DD")
                : // :
                  // cell["datatype"] === "boolean"
                  // ? converttoarray(cell["BucketField"], payload) == 1
                  //   ? "Yes"
                  //   : "No"
                  converttoarray(cell["BucketField"], updatedPayload) &&
                  JSON.stringify(
                    converttoarray(cell["BucketField"], updatedPayload)
                  ).replace(/\"/g, "")
            }
            onChange={(event) => {
              handlePayloadChange(event.target.value, cell["BucketField"]);
            }}
          />
        )
      )
    ) : (
      // <TextArea
      //   autoSize
      //   readOnly={true}
      //   bordered={false}
      //   //bordered={edit}
      //   value={JSON.stringify(
      //     converttoarray(cell["BucketField"], payload)
      //   )}
      // />
      <span>
        {cell["datatype"] === "productlist" ? (
          <>
            <a
              href="javascript:void(0)"
              disabled={disabled}
              onClick={() => {
                {
                  !disabled &&
                    showproductdata(
                      converttoarray(cell["BucketField"], payload),
                      cell["productlist"],
                      cell["productid"],
                      cell["tenure"]
                    );
                }
              }}
            >
              {converttoarray(cell["BucketField"], payload)}
            </a>
          </>
        ) : cell["datatype"] === "date" ? (
          converttoarray(cell["BucketField"], updatedPayload) &&
          isUnixTimestamp(
            converttoarray(cell["BucketField"], updatedPayload)
          ) ? (
            moment(
              moment.unix(converttoarray(cell["BucketField"], updatedPayload))
            ).format("YYYY-MM-DD HH:mm:ss")
          ) : isValidDate(
              converttoarray(cell["BucketField"], updatedPayload)
            ) ? (
            moment(converttoarray(cell["BucketField"], updatedPayload)).format(
              "YYYY-MM-DD"
            )
          ) : (
            JSON.stringify(
              converttoarray(cell["BucketField"], updatedPayload)
            ).replace(/\"/g, "")
          )
        ) : cell["datatype"] === "block" ? (
          <>
            <a
              href="javascript:void(0)"
              onClick={() => {
                console.log("call useEffect");
                let _updatedPayload = {
                  ...payload,
                };

                setUpdatedPayload(_updatedPayload);
                showblockdata(
                  converttoarray(cell["BucketField"], updatedPayload),
                  cell["fieldname"],
                  cell["BucketField"]
                );
              }}
            >
              {cell["fieldname"]}
            </a>
          </>
        ) : cell["datatype"] === "Downloadfile" ? (
          converttoarray(cell["BucketField"], payload) ? (
            <button
              className="btn btn-primary"
              onClick={() =>
                downloadfile(converttoarray(cell["BucketField"], payload))
              }
            >
              Download
            </button>
          ) : (
            "File Not Found"
          )
        ) : cell["datatype"] === "DCB" ? (
          <>
            <div
              class="table-elipse"
              data-toggle="collapse"
              data-target={"#" + rowindex}
              onClick={handleRowClick}
            >
              <span className="exprowbtn">+</span>
              {converttoarray(cell["BucketField"], payload) &&
                JSON.stringify(
                  converttoarray(cell["BucketField"], payload)
                ).replace(/\"/g, "")}
            </div>
          </>
        ) : cell["datatype"] === "jsondata" ? (
          <>
            <a
              className="text-primary"
              onClick={() => {
                setisPayloadView(true);
                setPayloadViewData(
                  converttoarray(cell["BucketField"], payload) &&
                    JSON.stringify(converttoarray(cell["BucketField"], payload))
                );
              }}
            >
              {cell["BucketField"].split(".").pop()}
            </a>
          </>
        ) : cell["datatype"] === "boolean" ? (
          converttoarray(cell["BucketField"], payload) &&
          JSON.stringify(converttoarray(cell["BucketField"], payload)).replace(
            /\"/g,
            ""
          ) == 1 ? (
            "Yes"
          ) : (
            "No"
          )
        ) : cell["datatype"] === "dropdownlist" ? (
          <>
            <Dropdownlist
              value={
                converttoarray(cell["BucketField"], payload) &&
                JSON.stringify(
                  converttoarray(cell["BucketField"], payload)
                ).replace(/\"/g, "")
              }
              bucketfield={cell["BucketField"]}
              isedit={false}
            />
          </>
        ) : cell["datatype"] === "image" ? (
          <>
            <button
              className="btn btn-primary"
              onClick={(e) =>
                viewImage(converttoarray(cell["BucketField"], payload))
              }
            >
              View
            </button>
          </>
        ) : cell["isRedirect"] == 1 ? ( // Check if cell["isRedirect"] equals 1
          checkredirect(
            cell["RedirectData"],
            cell["RedirectCondition"],
            cell["BucketField"]
          )
        ) : (
          // : cell["addapi"] == 1 ? (
          //   <>
          //     <span
          //       className="payload-openmodal"
          //       onClick={() =>
          //         showdata(cell["selectapi"], cell["tabledata"])
          //       }
          //     >
          //       {converttoarray(cell["BucketField"], payload) &&
          //         JSON.stringify(
          //           converttoarray(cell["BucketField"], payload)
          //         ).replace(/\"/g, "")}
          //     </span>
          //   </>
          // )
          converttoarray(cell["BucketField"], payload) &&
          JSON.stringify(converttoarray(cell["BucketField"], payload)).replace(
            /\"/g,
            ""
          )
        )}
        {/* {converttoarray(
    "payload.payment.entity.notes.notes_key_2",
    payload
  )} */}
      </span>
    );
  };
  const isUnixTimestamp = (input) => {
    // Check if input is a number and within Unix timestamp range
    const isNumeric = !isNaN(input);
    const isInt = Number.isInteger(Number(input));
    const isValidRange = Number(input) >= 0 && Number(input) <= 2147483647; // Valid Unix timestamp range in seconds

    return isNumeric && isInt && isValidRange;
  };
  const handleCancel = () => {
    setisOpenModal(false);
  };
  const isValidDate = (input) => {
    // Use Moment.js to check if the input is a valid date
    return moment(input, moment.ISO_8601, true).isValid();
  };
  const validateForm = (ModalForm) => {
    let isValid = true;
    ModalForm.map((field, index) => {
      const list = [...ModalForm];
      if (field.isRequired == "1") {
        if (!field.value) {
          isValid = false;
          list[index]["isError"] = "1";
        }
      } else {
        list[index]["isError"] = "";
      }
      setModalForm(list);
    });
    return isValid;
  };
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...ModalForm];
    list[index]["value"] = value;
    setModalForm(list);
  };
  const handleCancelError = () => {
    setisError(false);
  };

  const checkUrlExists = async (url) => {
    try {
      const response = await fetch(url);
      if (response.ok) {
        console.log("Response status:", response.status);
        return false; // No 404 error, real URL
      } else {
        console.log("Response status:", response.status);
        return true; // 404 error occurred
      }
    } catch (error) {
      console.error("Error checking for 404:", error);
      return true; // Error occurred, assume 404
    }
  };

  const handleBlur = (event, minmaxlength, fieldname, bucketField) => {
    const minLength = minmaxlength;
    const maxLength = minmaxlength;
    const inputLength = event.target.value.length;

    if (inputLength < minLength) {
      //alert(`${fieldname} must be at least ${minLength} characters long.`);
      setErrorMessage({
        ...ErrorMessage,
        [bucketField]: `${fieldname} must be at least ${minLength} characters long.`,
      });
      setDisableBtn(true);
    } else {
      setErrorMessage("");
      setDisableBtn(false);
    }
  };
  console.log("updatedPayload_change_data", change_data);
  return (
    <>
      <Modal
        open={isImageView}
        onCancel={handleCancelimage}
        footer={[]}
        width="auto"
      >
        {ImageView && <img src={ImageView} className="w-100" />}
      </Modal>
      <Modal
        open={isProductList}
        onCancel={() => setisProductList(false)}
        width="auto"
        onOk={() => {
          setupdateAmount(1);
          setisProductList(false);
        }}
        okButtonProps={{
          disabled: !selectedProduct || errorMsg,
        }}
      >
        <div className="row">
          {console.log("fieldtoupdate->", productlist)}
          {productlist && productlist.length > 0 ? (
            <table className="table">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Amount</th>
                  <th>EMI</th>
                  <th>Tenure</th>
                </tr>
              </thead>
              <tbody>
                {productlist.map((product) => (
                  <tr>
                    <td>
                      <input
                        type="radio"
                        value={
                          product.product_id +
                          "~" +
                          product.amount +
                          "~" +
                          product.tenure
                        }
                        checked={
                          product.product_id +
                            "~" +
                            product.amount +
                            "~" +
                            product.tenure ==
                          selectedProduct
                        }
                        onChange={(e) => setselectedProduct(e.target.value)}
                      />
                    </td>
                    <td>Rs. {product.amount.toLocaleString("en-IN")}</td>
                    <td>Rs. {product.emi.toLocaleString("en-IN")} / Month</td>
                    <td>{product.tenure} Month</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            errorMsg
          )}
        </div>
      </Modal>
      <Modal
        open={isFamily}
        onCancel={handleCancelfamily}
        footer={[]}
        width="auto"
      >
        <table className="table">
          <thead>
            <tr>
              <th>Consumername</th>
              <th>Reference Number</th>
            </tr>
          </thead>
          <tbody>
            {familyData &&
              familyData.map((fdata) => (
                <tr>
                  <td>{fdata.ConsumerName}</td>
                  <td>{fdata.ReferenceNumber}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </Modal>
      <Modal
        open={isPayloadView}
        onCancel={handleCanceljson}
        footer={[]}
        width="auto"
      >
        <div>
          <pre className="showjson">{PayloadViewData}</pre>
        </div>
      </Modal>
      <Modal
        open={isAdd}
        onCancel={() => setisAdd(false)}
        onOk={() => {
          handlePayloadChange(blockData, DeleteBucketField);
          setisAdd(false);
        }}
        okButtonProps={{
          disabled: !blockData,
        }}
        width="auto"
      >
        <div>
          <TextArea
            className="form-control"
            onChange={(e) => {
              try {
                const parsedData = JSON.parse(e.target.value);
                setblockData(parsedData);
                setisblockDataInput(true);
              } catch (error) {
                setblockData("");
                setisblockDataInput(false);
              }
            }}
            value={blockData && JSON.stringify(blockData)}
            rows={15}
          />
        </div>
      </Modal>
      <Modal
        open={isBlock}
        onCancel={handleCancelblock}
        footer={[]}
        width="auto"
      >
        <div>
          {console.log("blockData", blockData)}
          {blockData ? (
            <>
              <ReactJson
                src={blockData}
                onEdit={
                  onEdit
                    ? (e) => {
                        console.log(e);
                        //this.setState({ src: e.updated_src });
                        setUpdatedPayload(e.updated_src);
                      }
                    : false
                }
              />
              {edit && isblockDataInput == false && (
                <div className="text-right">
                  <button
                    className="btn btn-danger"
                    onClick={() => deleteblockdelete(DeleteBucketField)}
                  >
                    <DeleteOutlined />
                  </button>
                </div>
              )}
            </>
          ) : (
            edit && (
              <>
                <button
                  className="btn btn-primary mt-4 mr-4"
                  onClick={() => setisAdd(true)}
                >
                  <FileAddOutlined />
                </button>
              </>
            )
          )}
        </div>
        {/* <div>
          {blockData &&
            Object.entries(blockData).map(([group, fields]) => (
              <div key={group}>
                <h2>{group}</h2>
                <div className="row">
                  {fields.map(({ BucketField, value }) => (
                    <div className="col-md-4" key={BucketField}>
                      <strong>{BucketField}:</strong> {JSON.stringify(value)}
                    </div>
                  ))}
                </div>
              </div>
            ))}
        </div>
         */}
      </Modal>
      <Modal
        //title="Title"
        open={isShowData}
        onCancel={handleCanceldata}
        footer={[]}
        width="auto"
      >
        <div className="row">
          {/* {ShowData &&
            ShowData.map((userdata) => (
              <div className="form-group col-md-4">
                {userdata.Branches.Branch}
              </div>
            ))} */}
          {ShowData &&
            ShowData.Branches.map((userdata, index) => {
              let getapiconfig = JSON.parse(payload_config.get_api_mapping);
              return getapiconfig[0].outputdata.map((apiconfig, i) => {
                return (
                  <>
                    <div className="form-group col-md-4">
                      <label className="font-weight-bold">
                        {apiconfig.outputkey}
                      </label>
                      <div>{userdata[apiconfig.outputvalue]}</div>
                    </div>
                  </>
                );
              });
            })}
          {ShowData &&
            ShowData.Branches.map((userdata, index) => {
              let getapiconfig = JSON.parse(payload_config.get_api_mapping);
              let splitrowdata = getapiconfig[0].tabledata[0].tablevalue.split(
                "."
              );
              let splitdata = splitrowdata[0];
              return (
                <>
                  <div className="form-group col-md-12">
                    <table className="table table-hove text-center">
                      <thead>
                        <tr>
                          {getapiconfig[0].tabledata.length > 0 &&
                            getapiconfig[0].tabledata.map((tabledata) => {
                              return <td>{tabledata.tablekey}</td>;
                            })}
                        </tr>
                      </thead>
                      <tbody>
                        {/* {getapiconfig[0].tabledata.length > 0 &&
                          getapiconfig[0].tabledata.map((tabledata, i) => {
                            let splitdata = tabledata.tablevalue.split(".");
                            //console.log("i", splitdata);
                            return (
                              <tr>
                                {userdata[splitdata[0]].map(
                                  (lastransaction) => {
                                    return (
                                      <td>{lastransaction[splitdata[1]]}</td>
                                    );
                                  }
                                )}
                              </tr>
                            );
                          })} */}
                        {getapiconfig[0].tabledata.length > 0 &&
                          userdata[splitdata].map((split, i) => {
                            //let splitdata = tabledata.tablevalue.split(".");
                            //console.log("i", splitdata);
                            return (
                              <tr key={i}>
                                {getapiconfig[0].tabledata.map(
                                  (lastransaction) => {
                                    let tablevalue = lastransaction.tablevalue.split(
                                      "."
                                    );
                                    return <td>{split[tablevalue[1]]}</td>;
                                  }
                                )}
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </>
              );
            })}
        </div>
      </Modal>
      <Modal
        //title="Title"
        open={isOpenModal}
        onOk={() => {
          let validation = validateForm(ModalForm);

          if (validation) {
            if (ActionFormType == "formdata") {
              actionHandler(actionValue, ModalForm, ActionFormType);
            }
            if (ActionFormType == "formrow") {
              actionHandler(actionValue, replacedFormrow, ActionFormType);
            }

            setisOpenModal(false);
          }
        }}
        // confirmLoading={confirmLoading}
        onCancel={handleCancel}
        width={1000}
      >
        <div className="row">
          {ActionFormType == "formdata" &&
            ModalForm &&
            ModalForm.map((field, index) => (
              <>
                <div className="col-3">
                  <label>{field.fieldlabel}</label>
                  {field.datatype === "textbox" ? (
                    <>
                      <input
                        required={field.isRequired == 1}
                        className="form-control"
                        readOnly={field.isEdit == 0}
                        name={field.field}
                        value={field.value}
                        placeholder={field.fieldlabel}
                        onChange={(e) => handleInputChange(e, index)}
                      />
                    </>
                  ) : field.datatype === "boolean" ? (
                    <select
                      className="form-control"
                      value={field.value}
                      disabled={field.isEdit == 0}
                      style={{
                        width: 120,
                      }}
                      onChange={(e) => handleInputChange(e, index)}
                    >
                      <option value="">Select</option>
                      <option value="1">Yes</option>
                      <option value="0">No</option>
                    </select>
                  ) : field.datatype === "date" ? (
                    <input
                      type="date"
                      required={field.isRequired == 1}
                      className="form-control"
                      readOnly={field.isEdit == 0}
                      name={field.field}
                      value={
                        field.value
                          ? moment(field.value).format("YYYY-MM-DD")
                          : ""
                      }
                      placeholder={field.field}
                      onChange={(e) => handleInputChange(e, index)}
                    />
                  ) : (
                    <input
                      required={field.isRequired == 1}
                      className="form-control"
                      readOnly={field.isEdit == 0}
                      name={field.field}
                      value={field.value}
                      placeholder={field.fieldlabel}
                      onChange={(e) => handleInputChange(e, index)}
                    />
                  )}
                  {field.isError === "1" && (
                    <span className="errmsg">
                      {field.fieldlabel} is required
                    </span>
                  )}
                </div>
              </>
            ))}
          {ActionFormType == "formrow" && (
            // replacementValues.length > 0 ? (
            //   <></>
            // ) : (
            //   <div>Click OK to Perform Action</div>
            // )
            <div>Click OK to Perform Action</div>
          )}
        </div>
      </Modal>
      <Modal
        //title="Title"
        open={isError}
        onOk={async () => {
          setEditLoading(true);
          let response = await editHandler(
            payload_config["bucket_name"],
            payload["key"]
          );
          setEditLoading(false);
          if (response === true) {
            setEdit(false);
            getPayloads();
            setisError(false);
          } else {
            setEdit(true);
          }
        }}
        onCancel={handleCancelError}
        width={1000}
      >
        <div className="row">
          {PayloadRequired &&
            PayloadRequired.map((cell) => (
              <div className="col-md-2">
                <label>{cell["fieldname"]}</label>
                {checkfields(cell)}
              </div>
            ))}
        </div>
        <div className="mt-2">
          <span className="errmsg">
            Please Update Values for {isErrorValue}
          </span>
        </div>
      </Modal>
      {view_type == "table" && (
        <tr key={rowindex}>
          {updateUniqueData.length > 0 ? (
            <td>
              <input
                //checked={CheckAll}
                type="checkbox"
                value={
                  payload_config.payload_type == "M"
                    ? payload["_id.$oid"]
                      ? payload["_id.$oid"]
                      : payload._id.$oid
                    : payload["key"]
                }
                onChange={(e) => handlemassCheckboxChange(e.target.value)}
              />
            </td>
          ) : (
            ""
          )}
          {(newfieldEdit_mapping || payload_config.field_mapping).map(
            (cell, tdindex) => {
              return (
                <>
                  {cell["isVisible"] == "1" && (
                    <td key={tdindex}>{checkfields(cell)}</td>
                  )}
                </>
              );
            }
          )}
          <td>{moment(moment.unix(payload["created_at"])).fromNow()}</td>
          <td>
            <div className="payload_view_table_actions">
              <Actions
                disabled={disabled}
                defaultValue=""
                payload_config={payload_config}
                actionHandler={actionHandler}
                editHandler={editHandler}
                payload={payload}
                edit={edit}
                setEdit={setEdit}
                getPayloads={getPayloads}
                DeletePayload={DeletePayload}
                DeleteMongoPayload={DeleteMongoPayload}
                updatedPayload={updatedPayload}
                effective_designation_id={effective_designation_id}
                converttoarray={converttoarray}
                newfield_mapping={newfield_mapping}
                chkpayloadrequired={chkpayloadrequired}
                generate_modaldata={generate_modaldata}
                ActionFormType={ActionFormType}
                ModalForm={ModalForm}
                replacedFormrow={replacedFormrow}
                replacementValues={replacementValues}
                setModalForm={setModalForm}
                DisableBtn={DisableBtn}
                setDisableBtn={setDisableBtn}
                PayloadRequired={PayloadRequired}
                validatepayloaddata={validatepayloaddata}
                isError={isError}
                setisError={setisError}
                isErrorValue={isErrorValue}
                setisErrorValue={setisErrorValue}
                actionValue={actionValue}
                setisOpenModal={setisOpenModal}
                setactionValue={setactionValue}
                editLoading={editLoading}
                setEditLoading={setEditLoading}
              />
            </div>
          </td>
        </tr>
      )}

      {view_type == "mobile" && (
        <>
          {JSON.parse(grid_position.fielddata).showLabel == "1" && (
            <div className="font-weight-bold">
              {JSON.parse(grid_position.fielddata).fieldname} :
            </div>
          )}
          {checkfields(JSON.parse(grid_position.fielddata), edit)}
          {
            (view_for = "mobbody" && rowindexbody + 1 == totcnt && (
              <>
                <div
                  className="payload_view_table_actions_mobile"
                  id={rowindex}
                >
                  <Actions
                    disabled={disabled}
                    defaultValue=""
                    payload_config={payload_config}
                    actionHandler={actionHandler}
                    editHandler={editHandler}
                    payload={payload}
                    edit={edit}
                    setEdit={setEdit}
                    getPayloads={getPayloads}
                    DeletePayload={DeletePayload}
                    DeleteMongoPayload={DeleteMongoPayload}
                    updatedPayload={updatedPayload}
                    effective_designation_id={effective_designation_id}
                    converttoarray={converttoarray}
                    newfield_mapping={newfield_mapping}
                    chkpayloadrequired={chkpayloadrequired}
                    generate_modaldata={generate_modaldata}
                    ActionFormType={ActionFormType}
                    ModalForm={ModalForm}
                    replacedFormrow={replacedFormrow}
                    replacementValues={replacementValues}
                    setModalForm={setModalForm}
                    DisableBtn={DisableBtn}
                    setDisableBtn={setDisableBtn}
                    PayloadRequired={PayloadRequired}
                    validatepayloaddata={validatepayloaddata}
                    isError={isError}
                    setisError={setisError}
                    isErrorValue={isErrorValue}
                    setisErrorValue={setisErrorValue}
                    actionValue={actionValue}
                    setisOpenModal={setisOpenModal}
                    setactionValue={setactionValue}
                    editLoading={editLoading}
                    setEditLoading={setEditLoading}
                  />
                </div>
              </>
            ))
          }
        </>
      )}
      {/* {view_for == "action" && (
        
      )} */}
      {expandedRow && (
        <tr id={rowindex} class="collapse cell-1 row-child">
          <td colSpan={5}>
            <DetailComponent
              detailData={expandedRow}
              payload_config={subpayload_config}
              api_token={api_token}
              effective_designation_id={effective_designation_id}
              fetch_onload={true}
            />
          </td>
        </tr>
      )}
    </>
  );
}
function PaymentData({ payment }) {
  return (
    <tr>
      {Object.entries(payment.entity).map(([key, value]) => (
        <td key={key}>{value}</td>
      ))}
    </tr>
  );
}
function Actions({
  actionHandler,
  payload_config,
  payload,
  disabled,
  edit,
  setEdit,
  editHandler,
  getPayloads,
  DeletePayload,
  DeleteMongoPayload,
  updatedPayload,
  effective_designation_id,
  converttoarray,
  newfield_mapping,
  chkpayloadrequired,
  generate_modaldata,
  ActionFormType,
  ModalForm,
  replacedFormrow,
  replacementValues,
  setModalForm,
  DisableBtn,
  setDisableBtn,
  PayloadRequired,
  validatepayloaddata,
  isError,
  setisError,
  isErrorValue,
  setisErrorValue,
  actionValue,
  setisOpenModal,
  setactionValue,
  editLoading,
  setEditLoading,
}) {
  const [actionLoading, setActionLoading] = useState(false);
  const [allowClear, setAllowClear] = useState(false);

  useEffect(() => {
    if (actionValue) {
      setisOpenModal(true);
    }
  }, [actionValue]);
  return (
    <>
      {payload_config.action_mapping[0].action_name &&
      effective_designation_id != "1" ? (
        <Select
          placeholder="Action"
          style={{
            width: 120,
          }}
          disabled={disabled}
          loading={actionLoading}
          options={[
            {
              label: "Select an action", // You can customize this label
              value: "", // An empty value
            },
            ...payload_config.action_mapping
              .map((action) => {
                if (action.action_event != "onsave") {
                  return { label: action.action_name, value: action.id };
                } else {
                  return null;
                }
              })
              .filter((item) => item !== null),
          ]}
          allowClear={allowClear}
          onChange={(value) => {
            setactionValue(value);
            value != "" && generate_modaldata(value, payload);
          }}
        />
      ) : (
        ""
      )}
      {!edit && (
        <Button
          type="default"
          onClick={() => {
            chkpayloadrequired();
            setEdit(true);
          }}
          icon={<EditOutlined />}
        />
      )}
      {edit && !editLoading ? (
        <Button
          type="default"
          onClick={() => {
            setEdit(false);
            setDisableBtn(false);
          }}
          icon={<CloseOutlined />}
        />
      ) : (
        ""
      )}
      {edit ? (
        <Button
          type="default"
          loading={editLoading}
          disabled={DisableBtn}
          icon={<CheckOutlined />}
          onClick={
            // () => {
            // let validatepayload = validatepayloaddata();
            // if (validatepayload) {
            async () => {
              let validatepayload = validatepayloaddata();

              if (validatepayload) {
                setEditLoading(true);
                let response = await editHandler(
                  payload_config["bucket_name"],
                  payload["key"]
                );
                setEditLoading(false);
                if (response === true) {
                  setEdit(false);
                  getPayloads();
                } else {
                  setEdit(true);
                }
              } else {
                //console.log("PayloadRequired", PayloadRequired);
              }
            }
            //}
            //}
          }
        />
      ) : (
        ""
      )}

      {effective_designation_id == 21 &&
        (payload_config.payload_type == "M" ? (
          <Button
            type="default"
            onClick={() => {
              if (window.confirm("Delete the item?")) {
                DeleteMongoPayload(payload._id.$oid);
              }
            }}
            icon={<DeleteOutlined />}
          />
        ) : (
          <Button
            type="default"
            onClick={() => {
              if (window.confirm("Delete the item?")) {
                DeletePayload(payload.key);
              }
            }}
            icon={<DeleteOutlined />}
          />
        ))}
    </>
  );
}
