import React, { Component } from "react";
import customFetch from "./apicall/api";
import { withRouter } from "react-router";
import ChatSupportBtn from "./ChatSupportBtn";
import { get_payload_config } from "./Services";
import moment from "moment/moment";
import { ThemeContext } from "../context/IsLoader";
import { PermissionsContext } from "./permissionComponents/PermissionContext";
import { PEButton, PEDiv, PEA, PESpan } from "./permissionComponents";
// import Popup from 'reactjs-popup';

import {
  logout,
  navtime,
  chkapiinput,
  checkBanner,
  bannertimer,
  generateId,
} from "./Utility";
const generate_Id1 = generateId(10);
class Dashboard extends Component {
  static contextType = PermissionsContext;
  constructor(props) {
    super(props);
    this.state = {
      branch_unit_level_id: "",
      parent_entity_name: "",
      branch_name: "",
      max_unit_level_id: "",
      entity_name: "",
      effective_designation_id: "",
      cash_holding_status: "",
      isOpen: false,
      isErrOpen: false,
      api_error_message: "",
      notificationdata: [],
      isSpin: false,
      flag: 0,
      speed_net: "",
      isPasswordOld: false,
      isViewpass: true,
      isViewpass1: true,
      SuccessOpen: false,
      payloadConfig: [],
      popup: false,
      report_data: [],
    };
    //navtime();
    //this.logout = this.logout.bind(this);
  }
  componentDidMount() {
    document.title = "Dashboard";
    if (
      window.Android &&
      typeof window.Android.getDataFromLocalStorage === "function"
    ) {
      const data = window.Android.getDataFromLocalStorage("your_key");
      if (data) {
        this.setState({ isSpin: false, flag: 1 }); // Set flag to 1 if accessed from Android
        const tokenParts = data.split(",");
        const tokenData = {};

        tokenParts.forEach((part) => {
          const [key, value] = part.split(":");
          tokenData[key] = value;
        });

        console.log("AndroidInterface->", data);
      }
    } else {
      console.log(
        "Error in AndroidInterface: Android method not found or not available."
      );
    }


    const { password_age } =
      (this.props.location && this.props.location.state) || {};
    if (password_age > 90) {
      this.setState({ isPasswordOld: true });
    }
    if (checkBanner() != false) {
      //setInterval(bannertimer(), 1000);
      const interval = setInterval(() => {
        this.setState({
          banner_timer: bannertimer(),
        });
      }, 1000);
      this.setState({
        maintaenanceBanner: true,
        maintaenanceBannerText: checkBanner(),
      });
    }
    // setTimeout(() => {
    //   if (document.readyState === "complete") {
    //     this.context.onPageLoad({
    //       component: "Dashboard",
    //       component_id: generate_Id1,
    //     });
    //   } else {
    //     window.addEventListener("load", this.context.onPageLoad());
    //     this.cleanup = () =>
    //       window.removeEventListener("load", this.context.onPageLoad());
    //   }
    // }, 1000);
    this.setState({
      isSpin: true,
    });
    const uid = localStorage.getItem("in_userid");
    // if (!uid) {
    //   localStorage.clear();
    //   this.props.history.push("/");
    // }
    const { api_token } =
      (this.props.location && this.props.location.state) || {};
    console.log("api_token->", api_token);
    // Check API input are null or not if null then logout
    /*Check if data is null then logout*/
    var arr = [uid];
    var chkdata = chkapiinput(arr);
    if (chkdata === false) {
      this.setState({ isSpin: false });
      setTimeout(function() {
        logout();

      }, 2000);
    }
    // AT-2143 | AT-2179 | Ankur Makavana | 13-07-2023 | conver post method to get method
    const params = {
      userid: uid,
    };
    const queryParams = new URLSearchParams(params).toString();
    let generate_Id = generateId(10);
    customFetch(process.env.REACT_APP_URL + "/getrole?" + queryParams, {
      method: "GET",
      headers: {
        "api-token": api_token,
        component_id: generate_Id1,
        apiId: generate_Id,
        api_group_id: "",
        component: "Dashboard",
        user_id: localStorage.getItem("in_userid"),
      },
    })
      .then((res) => {
        if (res.headers.get("api_code") > 0) {
          this.setState({
            isErrOpen: true,
            api_error_message: res.headers.get("api_error_message"),
            isSpin: false,
          });
        }
        return res.json();
      })
      .then((data) => {
        console.log("data->", data);
        if (data.api_code === 4 || data.api_code === 3 || data.api_code === 5) {
          localStorage.clear();
          this.props.history.push("/");
        }
        localStorage.setItem("employee_id", data.employee_id);
        localStorage.setItem("branch_id", data.branch_id);
        localStorage.setItem("mapped_branch_id", data.mapped_branch_id);
        localStorage.setItem("designation_name", data.designation_name);
        console.log(data.max_unit_level_id);
        this.setState({
          isSpin: false,
          branch_unit_level_id: data.max_unit_level_id,
          parent_entity_name: data.parent_entity_name,
          max_unit_level_id: data.max_unit_level_id,

          effective_designation_id: data.effective_designation_id,
          branch_name: data.branch_name,
          cash_holding_status: data.cash_holding_status,
          closing_balance: data.closing_balance,
          current_day_fcm_approved_amount: data.current_day_fcm_approved_amount,
          fcm_approved_amount: data.fcm_approved_amount,
        });
        localStorage.setItem("contextuser_branchid", data.mapped_branch_id);
        localStorage.setItem("e_designation_id", data.effective_designation_id);
        localStorage.setItem("designation_name", data.designation_name);
        localStorage.setItem("em_branch_id", data.em_branch_id);
        localStorage.setItem("branch_name", data.branch_name);
        this.setState({
          payloadConfig: get_payload_config(
            api_token,
            data.effective_designation_id,
            generate_Id1,
            generate_Id,
            "Dashboard"
          ),
        });
        // const formdata1 = new FormData();
        // formdata1.append('in_userid',uid);
        // formdata1.append('call_procedure',uid);
        // customFetch('https://pythonservice-htyabn7jja-el.a.run.app/getreport',{
        //   method:'POST',
        //   headers: { 'api-token': api_token },
        //   body: formdata1
        // }).then(res1 => res1.json())
        // .then(data1 => {
        //   console.log(data1);
        // })
      })
      .catch((error) => {
        console.log("error-->", error);
      });
    //this.getnotification();
    // Log the environment variable to check its value
    console.log('REACT_APP_UTR_VIEW:', process.env.REACT_APP_UTR_VIEW);
  }

  getnotification() {
    const { api_token } =
      (this.props.location && this.props.location.state) || {};
    const uid = localStorage.getItem("in_userid");
    /*Check if data is null then logout*/
    var arr = [uid];
    var chkdata = chkapiinput(arr);
    if (chkdata === false) {
      this.setState({ isSpin: false });
      setTimeout(function() {
        logout();
      }, 2000);
    }
    // AT-2143 | AT-2179 | Ankur Makavana | 13-07-2023 | conver post method to get method
    const params = {
      user_id: uid,
      limit: process.env.REACT_APP_notification_limit,
    };
    const queryParams = new URLSearchParams(params).toString();
    let generate_Id = generateId(10);
    customFetch(
      process.env.REACT_APP_URL +
        "/get/collection/notification/user?" +
        queryParams,
      {
        method: "GET",
        headers: {
          "api-token": api_token,
          component_id: generate_Id1,
          apiId: generate_Id,
          api_group_id: "",
          component: "Dashboard",
          user_id: localStorage.getItem("in_userid"),
        },
      }
    )
      .then((res) => {
        // if (res.headers.get("api_code") > 0) {
        //   this.setState({
        //     isErrOpen: true,
        //     api_error_message: res.headers.get("api_error_message"),
        //   });
        // }
        return res.json();
      })
      .then((data) => {
        if (data.api_code === 4 || data.api_code === 3 || data.api_code === 5) {
          localStorage.clear();
          this.props.history.push("/");
        }
        console.log(data.max_unit_level_id);
        this.setState({
          notificationdata: data.reverse(),
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  // logout() {
  //   localStorage.clear();
  //   this.props.history.push("/");
  // }
  comparepass(e) {
    if (this.state.npass !== e.target.value) {
      this.setState({ passerr: "Password Not Match" });
    } else {
      this.setState({ passerr: "" });
    }
  }
  viewpass(val) {
    if (val === 1) {
      this.setState({ isViewpass: !this.state.isViewpass });
    }
    if (val === 2) {
      this.setState({ isViewpass1: !this.state.isViewpass1 });
    }
  }

  handlemailSubmitpass(event) {
    // event.preventDefault();
    this.setState({ PassChange: false });
    const { api_token } =
      (this.props.location && this.props.location.state) || {};
    const uid = localStorage.getItem("in_userid");
    /*Check if data is null then logout*/
    var arr = [uid, this.state.npass];
    var chkdata = chkapiinput(arr);
    if (chkdata === false) {
      this.setState({ isSpin: false });
      setTimeout(function() {
        logout();
      }, 2000);
    }
    const formdata = new FormData();
    formdata.append("userid", uid);
    formdata.append("user_password", this.state.npass);
    let generate_Id = generateId(10);
    customFetch(process.env.REACT_APP_URL + "/changepassword", {
      method: "POST",
      headers: {
        "api-token": api_token,
        component_id: generate_Id1,
        apiId: generate_Id,
        api_group_id: "",
        component: "Dashboard",
        user_id: localStorage.getItem("in_userid"),
      },
      body: formdata,
    })
      .then((res) => {
        if (res.headers.get("api_code") > 0) {
          this.setState({
            isErrOpen: true,
            api_error_message: res.headers.get("api_error_message"),
          });
        }
        return res.json();
      })
      .then((json) => {
        if (json.api_code === 4 || json.api_code === 3 || json.api_code === 5) {
          localStorage.clear();
          this.props.history.push("/");
        }
        if (json.isupdate === true) {
          this.setState({ SuccessOpen: true, isPasswordOld: false });
        }
      })
      .catch((error) => console.log("error", error));
  }
  render() {
    console.log(popup);
    // console.log("Local storage uid",uid)
    const {
      effective_designation_id,
      branch_name,
      parent_entity_name,
      cash_holding_status,
      isOpen,
      isErrOpen,
      api_error_message,
      notificationdata,
      isPasswordOld,
      popup,
    } = this.state;
    const { api_token, password_age } =
      (this.props.location && this.props.location.state) || {};
    //console.log("Token", api_token);
    // console.log(
    //   "Speed",
    //   navigator.connection.downlink > 1
    //     ? navigator.connection.downlink + " Mb/s"
    //     : navigator.connection.downlink + " Kb/s"
    // );

    console.log("report result------", this.state.report_data, {
      UserName: this.state.report_data.username,
      PassWord: this.state.report_data.password,
    });
    return (
      <>
        {this.state.isSpin ? (
          <div className="preloader">
            <div className="loader" ng-hide="data.length > 0"></div>
          </div>
        ) : (
          ""
        )}

        <div
          className={
            this.state.SuccessOpen
              ? "modal fade bd-example-modal-sm show mymodal"
              : "modal fade bd-example-modal-sm"
          }
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="confirm-box"
        >
          <div className="modal-dialog modal-dialog-centered modal-lg">
            <div className="modal-content cdb-confirm-box col-sm-12">
              <button
                type="button"
                className="close text-right"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => logout()}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <p>Password Updated Succesfully! Please Login Again</p>
            </div>
          </div>
        </div>
        <div
          className={
            isErrOpen
              ? "modal fade bd-example-modal-sm show mymodal"
              : "modal fade bd-example-modal-sm"
          }
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="confirm-box"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content cdb-confirm-box col-sm-12">
              <button
                type="button"
                className="close text-right"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => this.setState({ isErrOpen: false })}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <p>{api_error_message}</p>
            </div>
          </div>
        </div>
        <div
          className={
            isPasswordOld
              ? "modal fade bd-example-modal-sm show mymodal"
              : "modal fade bd-example-modal-sm"
          }
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="confirm-box"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLongTitle">
                  Change Password
                </h5>
              </div>
              <div className="modal-body">
                <form onSubmit={(e) => this.handlemailSubmitpass(e)}>
                  <div className="form-group">
                    <input
                      {...(this.state.isViewpass === true
                        ? { type: "password" }
                        : { type: "text" })}
                      name="npass"
                      placeholder="Enter Password"
                      className="form-control"
                      value={this.state.npass}
                      onChange={(e) => this.setState({ npass: e.target.value })}
                    />
                    <img
                      src={require("../assets/images/view.png")}
                      className="view-pass-change"
                      onClick={() => this.viewpass(1)}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      {...(this.state.isViewpass1 === true
                        ? { type: "password" }
                        : { type: "text" })}
                      name="cpass"
                      placeholder="Enter Confirm Password"
                      className="form-control"
                      //value={this.state.cpass}
                      onChange={(e) => this.comparepass(e)}
                    />
                    <img
                      src={require("../assets/images/view.png")}
                      className="view-pass-change"
                      onClick={() => this.viewpass(2)}
                    />
                    <span className="errmsg">{this.state.passerr}</span>
                  </div>
                  <div className="text-center mt-3">
                    <input
                      type="submit"
                      className="btn btn-lg btn-primary"
                      value="Update"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div
          className={
            isOpen
              ? "modal fade bd-example-modal-sm show mymodal"
              : "modal fade bd-example-modal-sm"
          }
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="confirm-box"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content cdb-confirm-box col-sm-12">
              <button
                type="button"
                className="close text-right"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => this.setState({ isOpen: false })}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <p>Your Current Cash Holding Exceeded Specified Limit For You.</p>
            </div>
          </div>
        </div>
        <div
          className={
            this.state.isToBlock
              ? "modal fade bd-example-modal-sm show mymodal"
              : "modal fade bd-example-modal-sm"
          }
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="confirm-box"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content cdb-confirm-box col-sm-12">
              <button
                type="button"
                className="close text-right"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => this.setState({ isToBlock: false })}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <p>
                Please Get Your Previous Day Closing Amount Verified By FCM.
              </p>
            </div>
          </div>
        </div>
        <section className="main-sec">
          <div className="container fix-width">
            <div className="logo-area">

            {this.state.flag === 0 && (
              <PEDiv element_id="ProfileBtn" className="user_icon">
                <img
                  src={require("../assets/images/Profile.svg")}
                  onClick={() => {
                    this.props.history.push({
                      pathname: "/Profile",
                      state: {
                        api_token: api_token,
                      },
                    });
                  }}
                  alt=""
                />
              </PEDiv>
            )}
              <img
                className=""
                src={require("../assets/images/aceN Logo.png")}
                alt=""
              />
              {this.state.flag=== 0 &&(
                <>
                <PEDiv
                element_id="NotificationBtn"
                className="log-out"
                htmlFor="btnControl"
              >
                <img
                  src={require("../assets/images/bell.png")}
                  onClick={() => this.getnotification()}
                  className={
                    notificationdata.length > 0
                      ? "btn11 ring animated"
                      : "btn11"
                  }
                  tabIndex="1"
                  alt=""
                />
                <div className="box">
                  {/* <div className="header">
                      <p>Notifications</p>
                      <a href="#">See all</a>
                    </div> */}

                  <div className="content">
                    {notificationdata.length > 0 ? (
                      notificationdata.map((notif) => (
                        <div className="notification">
                          <div className="notiuicon">
                            {notif.username
                              .split(/\s/)
                              .reduce(
                                (response, word) =>
                                  (response += word.slice(0, 1)),
                                ""
                              )}
                          </div>
                          <div className="text">
                            <div>
                              <span className="name">
                                {notif.notification_title}
                              </span>
                            </div>
                            <div className="time">
                              {moment(notif.sent_ts).fromNow()}
                            </div>
                            <div>
                              <span className="notidesc">
                                {notif.notification}
                              </span>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="text-center mt-2">No Data Found</div>
                    )}
                  </div>
                </div>
              </PEDiv>
              <div className="log-out">
                <img src={require("../assets/images/question.png")} alt="" />
              </div>
              </>
              )}
              
              {this.state.flag === 0 && (
              <div className="log-out">
                <img
                  src={require("../assets/images/power-off.png")}
                  onClick={() => logout()}
                  alt=""
                />
              </div>
              )}
            </div>

            <div className="dashboard-card-holder row mt-4">
              <div className="dashboard-card-holder row mt-4">
                {this.state.maintaenanceBanner ? (
                  <>
                    <marquee className="maintaenance-text marquee_data">
                      {this.state.maintaenanceBannerText}
                    </marquee>
                    <div className="maintaenance-text text-center timer_data">
                      {this.state.banner_timer}
                    </div>
                  </>
                ) : (
                  ""
                )}

                {/* {this.state.effective_designation_id !=
                process.env.REACT_APP_effective_designation_id_to && (
                <>
                  {this.state.branch_unit_level_id ==
                    process.env.REACT_APP_branch_unit_level_id_zone && (
                    <div
                      className="card card-border-small col-sm-6 py-2"
                      onClick={() => {
                        this.props.history.push({
                          pathname: "/Zone",
                          state: {
                            api_token: api_token,
                            entity_name: this.state.parent_entity_name,
                          },
                        });
                      }}
                    >
                      <div className="card-body">
                        <div className="image-holder">
                          <img
                            src={require("../assets/images/stats.png")}
                            alt=""
                          />
                        </div>
                        <div className="card-heading">
                          <h2>Dashboard</h2>
                        </div>
                        <div className="text-center"></div>
                      </div>
                    </div>
                  )}
                  {this.state.branch_unit_level_id ==
                    process.env.REACT_APP_branch_unit_level_id_division && (
                    <div
                      className="card card-border-small col-sm-6 py-2"
                      onClick={() => {
                        this.props.history.push({
                          pathname: "/Division",
                          state: {
                            api_token: api_token,
                            entity_name: this.state.parent_entity_name,
                          },
                        });
                      }}
                    >
                      <div className="card-body">
                        <div className="image-holder">
                          <img
                            src={require("../assets/images/stats.png")}
                            alt=""
                          />
                        </div>
                        <div className="card-heading">
                          <h2>Dashboard</h2>
                        </div>
                        <div className="text-center"></div>
                      </div>
                    </div>
                  )}
                  {this.state.branch_unit_level_id ==
                    process.env.REACT_APP_branch_unit_level_id_cluster && (
                    <div
                      className="card card-border-small col-sm-6 py-2"
                      onClick={() => {
                        this.props.history.push({
                          pathname: "/Cluster",
                          state: {
                            api_token: api_token,
                            entity_name: this.state.parent_entity_name,
                          },
                        });
                      }}
                    >
                      <div className="card-body">
                        <div className="image-holder">
                          <img
                            src={require("../assets/images/stats.png")}
                            alt=""
                          />
                        </div>
                        <div className="card-heading">
                          <h2>Dashboard</h2>
                        </div>
                        <div className="text-center"></div>
                      </div>
                    </div>
                  )}
                  {this.state.effective_designation_id ==
                    process.env.REACT_APP_effective_designation_id_bm &&
                    this.state.branch_unit_level_id ==
                      process.env.REACT_APP_branch_unit_level_id_branch && (
                      <div
                        className="card card-border-small col-sm-6 py-2"
                        onClick={() => {
                          this.props.history.push({
                            pathname: "/Branch",
                            state: {
                              api_token: api_token,
                              entity_name: this.state.parent_entity_name,
                            },
                          });
                        }}
                      >
                        <div className="card-body">
                          <div className="image-holder">
                            <img
                              src={require("../assets/images/stats.png")}
                              alt=""
                            />
                          </div>
                          <div className="card-heading">
                            <h2>Dashboard</h2>
                          </div>
                          <div className="text-center"></div>
                        </div>
                      </div>
                    )}
                  {this.state.effective_designation_id ==
                    process.env.REACT_APP_effective_designation_id_pm &&
                    this.state.branch_unit_level_id ==
                      process.env.REACT_APP_branch_unit_level_id_branch && (
                      <div
                        className="card card-border-small col-sm-6 py-2"
                        onClick={() => {
                          this.props.history.push({
                            pathname: "/Pm",
                            state: {
                              api_token: api_token,
                              entity_name: this.state.parent_entity_name,
                              max_unit_level_id: this.state.max_unit_level_id,
                            },
                          });
                        }}
                      >
                        <div className="card-body">
                          <div className="image-holder">
                            <img
                              src={require("../assets/images/stats.png")}
                              alt=""
                            />
                          </div>
                          <div className="card-heading">
                            <h2>Dashboard</h2>
                          </div>
                          <div className="text-center"></div>
                        </div>
                      </div>
                    )}
                  {this.state.effective_designation_id ==
                    process.env.REACT_APP_effective_designation_id_cso &&
                    this.state.branch_unit_level_id ==
                      process.env.REACT_APP_branch_unit_level_id_branch && (
                      <div
                        className="card card-border-small col-sm-6 py-2"
                        onClick={() => {
                          this.props.history.push({
                            pathname: "/Cso",
                            state: {
                              api_token: api_token,
                              entity_name: this.state.parent_entity_name,
                              max_unit_level_id: this.state.max_unit_level_id,
                            },
                          });
                        }}
                      >
                        <div className="card-body">
                          <div className="image-holder">
                            <img
                              src={require("../assets/images/stats.png")}
                              alt=""
                            />
                          </div>
                          <div className="card-heading">
                            <h2>Dashboard</h2>
                          </div>
                          <div className="text-center"></div>
                        </div>
                      </div>
                    )}{" "}
                </>
              )} */}
                {this.state.effective_designation_id ==
                  process.env.REACT_APP_effective_designation_id_cso ||
                this.context.isAdminMode ? (
                  this.state.cash_holding_status >=
                  process.env.REACT_APP_cash_holding_status_CSO ? (
                    <PEDiv
                      element_id="CollectionBlockCard"
                      className="m-2"
                      onClick={() => this.setState({ isOpen: true })}
                    >
                      <div className="image-holder-2">
                        <img
                          src={require("../assets/images/Collection_Block.svg")}
                          alt=""
                        />

                        <h5>Collection</h5>
                        <div className="card-body">
                          <div>
                            <div className="image-holder-22">
                              <img
                                src={require("../assets/images/Collection_Block.svg")}
                                alt=""
                                className="p-0"
                              />
                            </div>
                            <div className="card-heading">
                              <h2>Collection</h2>
                            </div>
                          </div>
                        </div>
                      </div>
                    </PEDiv>
                  ) : (
                    <PEDiv
                      element_id="CollectionCard"
                      className="m-2"
                      onClick={() => {
                        this.props.history.push({
                          pathname: "/SearchHousehold",
                          state: {
                            api_token: api_token,
                            effective_designation_id: this.state
                              .effective_designation_id,
                          },
                        });
                      }}
                    >
                      <div className="image-holder-2">
                        <img
                          src={require("../assets/images/Collection.svg")}
                          alt=""
                        />
                        <h5>Collection</h5>
                      </div>
                    </PEDiv>
                  )
                ) : (
                  ""
                )}

                {this.state.effective_designation_id ==
                  process.env.REACT_APP_effective_designation_id_to ||
                this.context.isAdminMode ? (
                  this.state.cash_holding_status >=
                  process.env.REACT_APP_cash_holding_status_TO ? (
                    //    ||
                    // (parseFloat(this.state.closing_balance) >
                    //   parseFloat(this.state.current_day_fcm_approved_amount) &&
                    //   parseFloat(this.state.closing_balance) >
                    //     parseFloat(this.state.fcm_approved_amount))
                    <>
                      <PEDiv
                        element_id="CollectionCsoBlockCard"
                        className="m-2"
                        onClick={() =>
                          parseFloat(this.state.closing_balance) >
                            parseFloat(
                              this.state.current_day_fcm_approved_amount
                            ) &&
                          parseFloat(this.state.closing_balance) >
                            parseFloat(this.state.fcm_approved_amount)
                            ? this.setState({ isToBlock: true })
                            : this.setState({ isOpen: true })
                        }
                      >
                        <div className="image-holder-2">
                          <img
                            src={require("../assets/images/Collection_CSO_Block.svg")}
                            alt=""
                          />

                          <h5>CSO</h5>
                        </div>
                      </PEDiv>
                      <PEDiv
                        element_id="CollectionHHBlockCard"
                        className="m-2"
                        onClick={() =>
                          this.state.closing_balance >
                            this.state.current_day_fcm_approved_amount &&
                          this.state.closing_balance >
                            this.state.fcm_approved_amount
                            ? this.setState({ isToBlock: true })
                            : this.setState({ isOpen: true })
                        }
                      >
                        <div className="image-holder-2">
                          <img
                            src={require("../assets/images/Collection_HH_Block.svg")}
                            alt=""
                          />

                          <h5>Household</h5>
                        </div>
                      </PEDiv>
                    </>
                  ) : (
                    <>
                      <PEDiv
                        element_id="CollectionCsoForTOCard"
                        className="m-2"
                        onClick={() => {
                          this.props.history.push({
                            pathname: "/CashAcceptanceTO",
                            state: { api_token: api_token },
                          });
                        }}
                      >
                        <div className="image-holder-2">
                          <img
                            src={require("../assets/images/Colle_CSO.svg")}
                            alt=""
                          />
                          <h5>CSO</h5>
                        </div>
                      </PEDiv>
                      <PEDiv
                        element_id="CollectionHHForTOCard"
                        className="m-2"
                        onClick={() => {
                          this.props.history.push({
                            pathname: "/SearchHousehold",
                            state: {
                              api_token: api_token,
                              effective_designation_id: this.state
                                .effective_designation_id,
                            },
                          });
                        }}
                      >
                        <div className="image-holder-2">
                          <img
                            src={require("../assets/images/Colle_HH.svg")}
                            alt=""
                          />
                          <h5>Household</h5>
                        </div>
                      </PEDiv>
                    </>
                  )
                ) : (
                  ""
                )}
                {/* <div
                className="m-2"
                onClick={() => {
                  this.props.history.push({
                    pathname: "/Other_collection",
                    state: {
                      api_token: api_token,
                      effective_designation_id: this.state
                        .effective_designation_id,
                    },
                  });
                }}
              >
                <div className="image-holder-2">
                      <img
                        src={require("../assets/images/Colle_HH.svg")}
                        alt=""
                      />
                      <h5>Other Collection</h5>
                    </div>
              </div> */}
                {this.state.effective_designation_id ==
                  process.env.REACT_APP_effective_designation_id_bm ||
                this.state.effective_designation_id ==
                  process.env.REACT_APP_effective_designation_id_pm ||
                this.state.effective_designation_id ==
                  process.env.REACT_APP_effective_designation_id_fcic ||
                this.context.isAdminMode ? (
                  <>
                    <PEDiv
                      element_id="CollectionHHCard"
                      className="m-2"
                      onClick={() => {
                        this.props.history.push({
                          pathname: "/SearchHousehold",
                          state: {
                            api_token: api_token,
                            effective_designation_id: this.state
                              .effective_designation_id,
                          },
                        });
                      }}
                    >
                      <div className="image-holder-2">
                        <img
                          src={require("../assets/images/Colle_HH.svg")}
                          alt=""
                        />

                        <h5>Household</h5>
                      </div>
                    </PEDiv>
                    <PEDiv
                      element_id="DepositCard"
                      className="m-2"
                      onClick={() => {
                        this.props.history.push({
                          pathname: "/Deposit_money",
                          state: {
                            api_token: api_token,
                            effective_designation_id: this.state
                              .effective_designation_id,
                          },
                        });
                      }}
                    >
                      <div className="image-holder-2">
                        <img
                          src={require("../assets/images/Deposit.svg")}
                          alt=""
                        />
                        <h5>Deposit</h5>
                      </div>
                    </PEDiv>
                  </>
                ) : (
                  ""
                )}
                {(this.state.effective_designation_id ==
                  process.env.REACT_APP_effective_designation_id_cso ||
                  this.context.isAdminMode) && (
                  <PEDiv
                    element_id="DepositCard"
                    className="m-2"
                    onClick={() => {
                      this.props.history.push({
                        pathname: "/Deposit_money",
                        state: {
                          api_token: api_token,
                          effective_designation_id: this.state
                            .effective_designation_id,
                        },
                      });
                    }}
                  >
                    <div className="image-holder-2">
                      <img
                        src={require("../assets/images/Deposit.svg")}
                        alt=""
                      />
                      <h5>Deposit</h5>
                    </div>
                  </PEDiv>
                )}
                {(this.state.effective_designation_id ==
                  process.env.REACT_APP_effective_designation_id_to ||
                  this.context.isAdminMode) && (
                  <PEDiv
                    element_id="BankDepositCard"
                    className="m-2"
                    onClick={() => {
                      this.props.history.push({
                        pathname: "/CashDepositeBank",
                        state: {
                          api_token: api_token,
                          effective_designation_id: this.state
                            .effective_designation_id,
                        },
                      });
                    }}
                  >
                    <div className="image-holder-2">
                      <img
                        src={require("../assets/images/Deposit.svg")}
                        alt=""
                      />
                      <h5>Deposit</h5>
                    </div>
                  </PEDiv>
                )}

                <PEDiv
                  element_id="TransactionHistoryCard"
                  className="m-2"
                  onClick={() => {
                    this.props.history.push({
                      pathname: "/Cso_transaction_history",
                      state: {
                        api_token: api_token,
                        effective_designation_id: effective_designation_id,
                      },
                    });
                  }}
                >
                  <div className="image-holder-2">
                    <img src={require("../assets/images/History.svg")} alt="" />
                    <h5>History</h5>
                  </div>
                </PEDiv>

              {this.state.flag=== 0 &&(
                <PEDiv
                  element_id="SupportTicketCard"
                  className="m-2"
                  onClick={() => {
                    this.props.history.push({
                      pathname: "/supportticketlist",
                      state: {
                        api_token: api_token,
                        effective_designation_id: effective_designation_id,
                      },
                    });
                  }}
                >
                  <div className="image-holder-2">
                    <img src={require("../assets/images/support.svg")} alt="" />
                    <h5>Support</h5>
                  </div>
                </PEDiv>
              )}

                <PEDiv
                  element_id="ReversalCard"
                  className="m-2"
                  onClick={() => {
                    this.props.history.push({
                      pathname: "/reversals",
                      state: {
                        api_token: api_token,
                        effective_designation_id: effective_designation_id,
                      },
                    });
                  }}
                >
                  <div className="image-holder-2">
                    <img
                      src={require("../assets/images/Cash_reversal.svg")}
                      alt=""
                    />
                    <h5>Reversal</h5>
                  </div>
                </PEDiv>

              {/* -------- */}
              {this.state.flag=== 0 &&(
              <div
                className="m-2"
                onClick={() => {
                  //this.report_customFetch();
                  // this.makeRequest()
                  this.props.history.push({
                    pathname: "/Report",
                    state: {
                      api_token: api_token,
                      effective_designation_id: effective_designation_id,
                    },
                  });
                }}
              >
                <div className="image-holder-2">
                  <img src={require("../assets/images/Report.svg")} alt="" />
                  <h5>Reports</h5>
                </div>
              </div>
              )}
             
              {process.env.REACT_APP_UTR_VIEW.split(",").includes(
                effective_designation_id.toString()
              ) && this.state.flag === 0 && (
                <div
                  className="m-2"
                  onClick={() => {
                    this.props.history.push({
                      pathname: "/UTR",
                      state: {
                        api_token: api_token,
                        effective_designation_id: effective_designation_id,
                      },
                    });
                  }}
                >
                  <div className="image-holder-2">
                    <img src={require("../assets/images/UTR.svg").default} alt="UTR" />
                    <h5>UTR</h5>
                  </div>
                </div>
              )}

              {process.env.REACT_APP_DCB_BRANCH.split(",").includes(
                localStorage.getItem("branch_id")
              ) == true
                ? ""
                : (this.state.effective_designation_id ==
                    process.env.REACT_APP_effective_designation_id_to ||
                    this.state.effective_designation_id ==
                      process.env.REACT_APP_effective_designation_id_dto ||
                    this.state.effective_designation_id ==
                      process.env.REACT_APP_effective_designation_id_bm) && (
                    <div
                      className="m-2"
                      onClick={() => {
                        this.props.history.push({
                          pathname: "/Esign",
                          state: {
                            api_token: api_token,
                            effective_designation_id: effective_designation_id,
                            isDCB:
                              process.env.REACT_APP_DCB_BRANCH.split(
                                ","
                              ).includes(
                                localStorage.getItem("branch_id").toString()
                              ) == true
                                ? 1
                                : 0,
                          },
                        });
                      }}
                    >
                      <div className="image-holder-2">
                  <img src={require("../assets/images/E-sign.svg")} alt="" />                  
                          <h5>E-sign</h5>
                        </div>
                    </div>
                    )}
                {this.state.effective_designation_id ==
                process.env.REACT_APP_effective_designation_id_cso ? (
                  ""
                ) : (
                  <PEDiv
                    element_id="NACHRegisterCard"
                    className="m-2"
                    onClick={() => {
                      this.props.history.push({
                        pathname: "/RegisterNachBank",
                        state: {
                          api_token: api_token,
                          effective_designation_id: effective_designation_id,
                        },
                      });
                    }}
                  >
                    <div className="image-holder-2">
                      <img
                        src={require("../assets/images/Nach_of_bank.svg")}
                        alt=""
                      />
                      <h5>NACH</h5>
                    </div>
                  </PEDiv>
                )}

                {this.state.effective_designation_id ==
                  process.env.REACT_APP_effective_designation_id_bm ||
                this.context.isAdminMode ? (
                  <PEDiv
                    element_id="OvernightCard"
                    className="m-2"
                    onClick={() => {
                      this.props.history.push({
                        pathname: "/Overnightrequest",
                        state: {
                          api_token: api_token,
                          effective_designation_id: effective_designation_id,
                        },
                      });
                    }}
                  >
                    <div className="image-holder-2">
                      <img
                        src={require("../assets/images/Overnight.svg")}
                        alt=""
                      />
                      <h5>Overnight</h5>
                    </div>
                  </PEDiv>
                ) : (
                  ""
                )}

                {this.state.payloadConfig.length != 0 ||
                this.context.isAdminMode ? (
                  <PEDiv
                    element_id="PayloadCard"
                    className="m-2"
                    onClick={() => {
                      this.props.history.push({
                        pathname:
                          this.state.payloadConfig.length == 1
                            ? "/payload/view"
                            : "/payload",
                        state: {
                          api_token: api_token,
                          effective_designation_id: effective_designation_id,
                        },
                      });
                    }}
                  >
                    <div className="image-holder-2">
                      <img
                        src={require("../assets/images/Payload.svg")}
                        alt=""
                      />
                      <h5>Payload</h5>
                    </div>
                  </PEDiv>
                ) : (
                  ""
                )}

              {this.state.flag=== 0 &&(
                <PEDiv
                  element_id="AppDownloadCard"
                  className="m-2"
                  onClick={() => {
                    this.props.history.push({
                      pathname: "/App_download",
                      state: {
                        api_token: api_token,
                        effective_designation_id: effective_designation_id,
                      },
                    });
                  }}
                >
                  <div className="image-holder-2">
                    <img
                      src={require("../assets/images/App_Download.svg")}
                      alt=""
                    />
                    <h5>Download</h5>
                  </div>
                </PEDiv>
                
              )}
            </div>
          </div>
          </div>
        </section>
        {this.state.flag=== 0 &&(
  
        <ChatSupportBtn history={this.props.history} />
        )}
      </>
    );
  }
}

export default withRouter(Dashboard);
