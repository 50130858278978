import jwt_decode from "jwt-decode";
import Cookies from "js-cookie";
import moment from "moment";
import db from "../../firebase";
import { collection, addDoc } from "firebase/firestore";
import UserExperior from "user-experior-web";

async function customFetch(url, options) {
  if (
    Cookies.get("parameter_value") !== "null" &&
    Cookies.get("parameter_value") !== undefined
  ) {
    const ue = new UserExperior();
    ue.startRecording(Cookies.get("parameter_value"), {
      sessionReplay: {
        maskAllInputs: false,
        maskInputOptions: {
          password: false,
          email: false,
          tel: false,
          color: false,
          date: false,
          "datetime-local": false,
          month: false,
          number: false,
          range: false,
          search: false,
          text: false,
          time: false,
          url: false,
          week: false,
          textarea: false,
          select: false,
        },
      },
    });
    ue.setUserIdentifier(
      `${localStorage.getItem("in_username")}_${
        process.env.REACT_APP_ENVIRONMENT
      }`
    );

    let body = JSON.stringify(options.body);

    let urlName = url.split("/");

    let eventLog = {
      body: body,
      headers: JSON.stringify(options.headers),
      method: options.method,
      url: url,
    };
    ue.logEvent(urlName[urlName.length - 1], eventLog);
  }
  if ("api-token" in options.headers) {
    console.log("branch_id_", localStorage.getItem("api_token"));
    options.headers["api-token"] = localStorage.getItem("api_token");
    return await isTokenExpired(url, options);
  } else {
    return await call_api(url, options);
  }
}

const isTokenExpired = async (url, options) => {
  const token = localStorage.getItem("api_token");
  if (token) {
    var decoded = jwt_decode(token);
    let currentDate = new Date();
    // JWT exp is in seconds
    if (decoded.exp * 1000 < currentDate.getTime()) {
      return await generate_token(url, options);
    } else {
      return await call_api(url, options);
    }
  }
};

const generate_token = async (url, options) => {
  const formdata = new FormData();
  if (Cookies.get("refresh_token")) {
    var decode_reftoken = jwt_decode(Cookies.get("refresh_token"));
    let currentDate = new Date();

    if (decode_reftoken.exp * 1000 < currentDate.getTime()) {
      Cookies.remove("refresh_token");
      localStorage.clear();
      window.location.href = "/";
    } else {
      formdata.append("refresh_token", Cookies.get("refresh_token"));
      try {
        const response = await fetch(
          process.env.REACT_APP_URL + "/get/access/token",
          {
            method: "POST",
            body: formdata,
          }
        );

        if (response.ok) {
          const data3 = await response.json();
          console.log("access_token->", data3.access_token);
          localStorage.setItem("api_token", data3.access_token);
          options.headers["api-token"] = data3.access_token;
          return await call_api(url, options);
        } else {
          console.log("Error fetching token:", response);
        }
      } catch (error) {
        console.log("Error fetching token:", error);
      }
    }
  }
};
const isvalidjson = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};
const save_data = (url, options) => {
  let api_data = {};
  var jsonformdata = "";
  if (options.body) {
    if (isvalidjson(options.body)) {
      jsonformdata = options.body;
    } else {
      var object = {};
      options.body.forEach((value, key) => (object[key] = value));
      jsonformdata = JSON.stringify(object);
    }
  }

  const myDate = new Date();

  // Define the options for formatting
  const dateoptions = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  };

  // Format the date
  const formattedDate = myDate.toLocaleString("en-GB", dateoptions);

  api_data.created_at = formattedDate;
  api_data.data_write_for = "API";
  api_data.component_name = options.headers.component || null;
  api_data.url = url || null;
  api_data.method = options.method || null;
  api_data.data = jsonformdata || null;
  api_data.apiId = options.headers.apiId || null;
  api_data.component_id = options.headers.component_id || null;
  api_data.api_group_id = options.headers.api_group_id || null;
  api_data.added_at = moment().unix();
  api_data.log_id = localStorage.getItem("api_token");
  api_data.user_id = localStorage.getItem("in_userid");

  let bucketName = process.env.REACT_APP_ENVIRONMENT + "_frontend_req_payload";
  //setTimeout(() => {
  addDoc(collection(db, bucketName), api_data).then((docRef) => {
    console.log("Document written with ID: ", docRef.id);
  });
  //}, 1000);
};
const call_api = async (url, options) => {
  save_data(url, options);
  console.log("call_api->", url);
  try {
    const response = await fetch(url, options);

    // if (!response.ok) {
    //   console.log("Network response was not ok:", response);
    //   if (response.status !== 200) {
    //     localStorage.clear();
    //     window.location.href = "/";
    //   }
    // }
    const jsonData = await response;
    if (
      Cookies.get("parameter_value") !== "null" &&
      Cookies.get("parameter_value") !== undefined
    ) {
      const ue = new UserExperior();
      ue.startRecording(Cookies.get("parameter_value"), {
        sessionReplay: {
          maskAllInputs: false,
          maskInputOptions: {
            password: false,
            email: false,
            tel: false,
            color: false,
            date: false,
            "datetime-local": false,
            month: false,
            number: false,
            range: false,
            search: false,
            text: false,
            time: false,
            url: false,
            week: false,
            textarea: false,
            select: false,
          },
        },
      });

      ue.logEvent("Response", {
        headers: JSON.stringify(jsonData),
        status: jsonData.status,
        url: jsonData.url,
      });
    }
    return jsonData;
  } catch (error) {
    console.log("Error fetching data:", error);
  }
};

export default customFetch;
