import { upload } from "@testing-library/user-event/dist/upload";
import customFetch from "./apicall/api";
import React, { Component } from "react";
import { useHistory } from "react-router-dom";
import { withRouter } from "react-router-dom";
import style from "../assets/css/CashDepositeBank.css";
import ChatSupportBtn from "./ChatSupportBtn";
import moment from "moment";
import { logout, chkapiinput, generateId } from "./Utility";
import { ThemeContext } from "../context/IsLoader";
import { PEButton, PEDiv, PEImg, PEInput, PEA, PESpan } from "./permissionComponents";
const generate_Id1 = generateId(10);
export default class CashDepositeBank extends Component {
  static contextType = ThemeContext;
  constructor(props) {
    super(props);

    this.state = {
      viewImg: "",
      viewImgType: "",
      reversed: [],
      current_balance: "",
      amt_submitted: 0,
      cash_balance_id_val: "",
      selectedFile: [],
      isOpen: false,
      sort_htl: "",
      sort_data: [],
      items: [],
      isShowBalanceId: false,
      isShowMonthFilter: true,
      isShowFileUpload: false,
      isShowFilterStatus: false,
      counter: 1,
      MenuName: "",
      uniqueNames: [],
      uniqueLocation: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      temp_array: [],
      statSaved: true,
      statSubmit: true,
      statAppr: true,
      statRej: true,
      statVer: false,
      search_text: "",
      isErrOpen: false,
      api_error_message: "",
      isSpin: false,
    };
    this.ImgchangeHandler = this.ImgchangeHandler.bind(this);
    this.handlesorthtl = this.handlesorthtl.bind(this);
    this.handlesort = this.handlesort.bind(this);
    this.handleFilterMenu = this.handleFilterMenu.bind(this);
    this.addName = this.addName.bind(this);
    this.filter = this.filter.bind(this);
    this.statusFilter = this.statusFilter.bind(this);
    this.handleSearchText = this.handleSearchText.bind(this);
    this.searchBalanceID = this.searchBalanceID.bind(this);
  }

  searchBalanceID(event) {
    this.state.sort_data = [];

    for (let i = 0; i < this.state.items.length; i++) {
      if (
        this.state.items[i].cash_balance_id == parseInt(this.state.search_text)
      ) {
        this.state.sort_data.push(this.state.items[i]);
      }
    }
    this.state.reversed = this.state.sort_data;
    this.forceUpdate();
  }

  handleSearchText(event) {
    this.state.search_text = event.target.value;
    if (this.state.search_text == "") {
      this.state.reversed = this.state.items;
      this.forceUpdate();
    }
  }

  filter(event) {
    this.state.sort_data = [];

    if (this.state.counter == 1) {
      if (this.state.temp_array.length > 0) {
        for (let i = 0; i < this.state.items.length; i++) {
          for (let j = 0; j < this.state.temp_array.length; j++) {
            if (
              this.state.items[i].Lead_Name.toLowerCase().indexOf(
                this.state.temp_array[j].toLowerCase()
              ) == 0
            ) {
              this.state.sort_data.push(this.state.cnames[i]);
            }
          }
        }
        this.state.cnames = this.state.sort_data;
      } else {
        this.state.cnames = this.state.og_data;
      }
    } else if (this.state.counter == 2) {
      if (this.state.temp_array.length > 0) {
        for (let i = 0; i < this.state.items.length; i++) {
          for (let j = 0; j < this.state.temp_array.length; j++) {
            if (
              this.state.items[i].submitted_at
                .split(" ")[2]
                .toLowerCase()
                .indexOf(this.state.temp_array[j].toLowerCase()) == 0
            ) {
              this.state.sort_data.push(this.state.items[i]);
            }
          }
        }
        this.state.reversed = this.state.sort_data;
      } else {
        this.state.reversed = this.state.items;
      }
    } else if (this.state.counter == 3) {
      if (this.state.temp_array.length > 0) {
        for (let i = 0; i < this.state.items.length; i++) {
          for (let j = 0; j < this.state.temp_array.length; j++) {
            // console.log(this.state.temp_array[j].indexOf("nUpload"));

            // console.log(this.state.items[i].filename == null);

            if (this.state.temp_array[j].indexOf("upload") == 0) {
              if (this.state.items[i].filename != null) {
                this.state.sort_data.push(this.state.items[i]);
              }
            }

            if (this.state.temp_array[j].indexOf("nUpload") == 0) {
              if (this.state.items[i].filename == null) {
                this.state.sort_data.push(this.state.items[i]);
              }
            }
          }
        }
        this.state.reversed = this.state.sort_data;
      } else {
        this.state.reversed = this.state.items;
      }
    } else if (this.state.counter == 4) {
      if (this.state.statSaved) {
        for (let i = 0; i < this.state.reversed.length; i++) {
          if (this.state.reversed[i].status == 1) {
            this.state.sort_data.push(this.state.reversed[i]);
          }
        }
      }
      if (this.state.statSubmit) {
        for (let i = 0; i < this.state.reversed.length; i++) {
          if (this.state.reversed[i].status == 2) {
            this.state.sort_data.push(this.state.reversed[i]);
          }
        }
      }
      if (this.state.statAppr) {
        for (let i = 0; i < this.state.reversed.length; i++) {
          if (this.state.reversed[i].status == 3) {
            this.state.sort_data.push(this.state.reversed[i]);
          }
        }
      }
      if (this.state.statRej) {
        for (let i = 0; i < this.state.reversed.length; i++) {
          if (this.state.reversed[i].status == 4) {
            this.state.sort_data.push(this.state.reversed[i]);
          }
        }
      }
      if (this.state.statVer) {
        for (let i = 0; i < this.state.reversed.length; i++) {
          if (this.state.reversed[i].status == 5) {
            this.state.sort_data.push(this.state.reversed[i]);
          }
        }
      }

      this.state.reversed = this.state.sort_data;
    }
    console.log(this.state.reversed);
    this.forceUpdate();
  }

  statusFilter(event) {
    // if (event.target.checked) {
    //   this.state.temp_array.push(event.target.id);
    // } else {

    //   for (let i = 0; i < this.state.temp_array.length; i++) {
    //     if (event.target.id == this.state.temp_array[i]) {
    //       this.state.temp_array.splice(i, 1);

    //     }
    //   }
    // }
    if (event.target.id == "statSaved") {
      this.state.statSaved = this.state.statSaved ? false : true;
    }

    if (event.target.id == "statSubmit") {
      this.state.statSubmit = this.state.statSubmit ? false : true;
    }

    if (event.target.id == "statAppr") {
      this.state.statAppr = this.state.statAppr ? false : true;
    }

    if (event.target.id == "statRej") {
      this.state.statRej = this.state.statRej ? false : true;
    }

    if (event.target.id == "statVer") {
      this.state.statVer = this.state.statVer ? false : true;
    }

    this.forceUpdate();

    // if (event.target.checked) {
    //   event.target.checked = false;
    //   for (let i = 0; i < this.state.temp_array.length; i++) {
    //     if (event.target.id == this.state.temp_array[i]) {
    //       this.state.temp_array.splice(i, 1);

    //     }
    //   }
    // } else {
    //   this.state.temp_array.push(event.target.id);
    // }
  }

  addName(event) {
    if (event.target.checked) {
      this.state.temp_array.push(event.target.id);
    } else {
      for (let i = 0; i < this.state.temp_array.length; i++) {
        if (event.target.id == this.state.temp_array[i]) {
          this.state.temp_array.splice(i, 1);
        }
      }
    }

    console.log(this.state.temp_array);
  }

  handleFilterMenu(event) {
    if (event.currentTarget.id == "balanceIdFilter") {
      console.log("Balance ID");
      this.state.counter = 1;
      this.state.MenuName = "Balance ID";
      this.state.isShowBalanceId = true;
      this.state.isShowMonthFilter = false;
      this.state.isShowFileUpload = false;
      this.state.isShowFilterStatus = false;
    } else if (event.currentTarget.id == "monthFilter") {
      console.log("Month Filter");
      this.state.MenuName = "Month";
      this.state.counter = 2;
      this.state.isShowBalanceId = false;
      this.state.isShowMonthFilter = true;
      this.state.isShowFileUpload = false;
      this.state.isShowFilterStatus = false;
    } else if (event.currentTarget.id == "fileuFilter") {
      console.log("File Upload");

      this.state.counter = 3;
      this.state.MenuName = "File Upload";
      this.state.isShowBalanceId = false;
      this.state.isShowMonthFilter = false;
      this.state.isShowFileUpload = true;
      this.state.isShowFilterStatus = false;
    } else if (event.currentTarget.id == "statusFilter") {
      console.log("Status");

      this.state.counter = 4;
      this.state.MenuName = "Status";
      this.state.isShowBalanceId = false;
      this.state.isShowMonthFilter = false;
      this.state.isShowFileUpload = false;
      this.state.isShowFilterStatus = true;
    }
    this.forceUpdate();
  }

  handlesorthtl(event) {
    this.state.sort_htl = event.target.value;

    if (event.target.checked) {
      this.state.sort_htl = event.target.value;
    }
  }

  handlesort(event) {
    console.log(this.state.items);

    if (this.state.sort_htl == "high") {
      this.state.sort_data = []
        .concat(this.state.items)
        .sort((a, b) => parseInt(b.amt_submitted) - parseInt(a.amt_submitted));
      this.state.reversed = this.state.sort_data;
      this.forceUpdate();
    } else {
      this.state.sort_data = []
        .concat(this.state.items)
        .sort((a, b) => parseInt(a.amt_submitted) - parseInt(b.amt_submitted));
      this.state.reversed = this.state.sort_data;
      this.forceUpdate();
    }
  }

  ImgchangeHandler(event, bid, amtsubmit) {
    event.preventDefault();
    this.setState({ isSpin: true });
    const uid = localStorage.getItem("in_userid");
    console.log("Balanceid", bid);
    var file = event.target.files[0];
    console.log(
      "FileNew",
      uid +
        "_" +
        bid +
        "_" +
        moment().valueOf() +
        "." +
        file.name.split(".").pop()
    );
    this.setState({
      selectedFile: file,
    });
    const { api_token } =
      (this.props.location && this.props.location.state) || {};
    /*Check if data is null then logout*/
    var arr = [file, bid];
    var chkdata = chkapiinput(arr);
    if (chkdata === false) {
      this.setState({ isSpin: false });
      setTimeout(function() {
        logout();
      }, 2000);
    }
    const formdata = new FormData();
    let fname =
      uid +
      "_" +
      bid +
      "_" +
      moment().valueOf() +
      "." +
      file.name.split(".").pop();
    formdata.append(
      "file",
      file,
      uid +
        "_" +
        bid +
        "_" +
        moment().valueOf() +
        "." +
        file.name.split(".").pop()
    );
    formdata.append(
      "blob_url",
      "deposit_slips_by_transaction_officer/" + fname
    );
    formdata.append("id", bid);
    let generate_Id = generateId(10);
    const requestOptions = {
      method: "POST",
      headers: {
        "api-token": api_token,
        component_id: generate_Id1,
        apiId: generate_Id,
        api_group_id: "",
        component: this.constructor.name,
        user_id: localStorage.getItem("in_userid"),
      },
      body: formdata,
    };
    customFetch(
      process.env.REACT_APP_URL + "/save/files/bucket",
      requestOptions
    )
      .then((response) => {
        if (response.headers.get("api_code") > 0) {
          this.setState({
            isErrOpen: true,
            api_error_message: response.headers.get("api_error_message"),
            isSpin: false,
          });
        }
        return response.json();
      })
      .then((data) => {
        if (data.api_code === 4 || data.api_code === 3 || data.api_code === 5) {
          localStorage.clear();
          this.props.history.push("/");
        }
        this.setState({ isSpin: false });
        this.fetchDetails();
      })
      .catch((error) => console.log("error", error));
  }
  componentDidMount() {
    document.title = "Deposit Money";
    setTimeout(() => {
      if (document.readyState === "complete") {
        this.context.onPageLoad({
          component: this.constructor.name,
          component_id: generate_Id1,
        });
      } else {
        window.addEventListener("load", this.context.onPageLoad());
        this.cleanup = () =>
          window.removeEventListener("load", this.context.onPageLoad());
      }
    }, 1000);
    const uid = localStorage.getItem("in_userid");
    if (!uid) {
      localStorage.clear();
      this.props.history.push("/");
    }
    this.fetchDetails();
  }
  fetchDetails = () => {
    this.setState({ isSpin: true });
    const uid = localStorage.getItem("in_userid");
    const { api_token } =
      (this.props.location && this.props.location.state) || {};
    /*Check if data is null then logout*/
    var arr = [uid];
    var chkdata = chkapiinput(arr);
    if (chkdata === false) {
      this.setState({ isSpin: false });
      setTimeout(function() {
        logout();
      }, 2000);
    }

    const params = {
      in_userid: uid,
    };
    const queryParams = new URLSearchParams(params).toString();
    let generate_Id = generateId(10);
    const requestOptions = {
      method: "GET",
      headers: {
        "api-token": api_token,
        component_id: generate_Id1,
        apiId: generate_Id,
        api_group_id: "",
        component: this.constructor.name,
        user_id: localStorage.getItem("in_userid"),
      },
    };

    customFetch(
      process.env.REACT_APP_URL + "/get/list/denominations/to?" + queryParams,
      requestOptions
    )
      .then((response) => {
        if (response.headers.get("api_code") > 0) {
          this.setState({
            isErrOpen: true,
            api_error_message: response.headers.get("api_error_message"),
            isSpin: false,
          });
        }
        return response.json();
      })
      .then((data) => {
        if (data.api_code === 4 || data.api_code === 3 || data.api_code === 5) {
          localStorage.clear();
          this.props.history.push("/");
        }
        // AT-1913 | AT-1915 | Ankur Makavana | 04-07-2023 | UI code changes update data based on api response
        this.setState({
          reversed: data.denomination_data.reverse(),
          current_balance: data.current_balance,
          isSpin: false,
        });
        this.setState({ items: data.denomination_data, isSpin: false });

        // for (let index = 0; index < data.denomination_data.length; index++) {
        //   this.setState({
        //     amt_submitted: parseFloat(data[index]["amt_submitted"]),
        //     cash_balance_id_val: data[index]["cash_balance_id"],
        //   });
        // }
      })
      .catch((error) => console.log("error", error));
  };
  delimage(id, amtsubmit) {
    this.setState({ isSpin: true });
    const { api_token } =
      (this.props.location && this.props.location.state) || {};
    /*Check if data is null then logout*/
    var arr = [id];
    var chkdata = chkapiinput(arr);
    if (chkdata === false) {
      this.setState({ isSpin: false });
      setTimeout(function() {
        logout();
      }, 2000);
    }
    const formdata = new FormData();
    formdata.append("id", id);
    let generate_Id = generateId(10);
    const requestOptions = {
      method: "POST",
      headers: {
        "api-token": api_token,
        component_id: generate_Id1,
        apiId: generate_Id,
        api_group_id: "",
        component: this.constructor.name,
        user_id: localStorage.getItem("in_userid"),
      },
      body: formdata,
    };
    customFetch(
      process.env.REACT_APP_URL + "/delete/filename/cashbalid",
      requestOptions
    )
      .then((response) => {
        if (response.headers.get("api_code") > 0) {
          this.setState({
            isErrOpen: true,
            api_error_message: response.headers.get("api_error_message"),
            isSpin: false,
          });
        }
        return response.json();
      })
      .then((data) => {
        if (data.api_code === 4 || data.api_code === 3 || data.api_code === 5) {
          localStorage.clear();
          this.props.history.push("/");
        }
        this.setState({ isSpin: false });
        this.fetchDetails();
      })
      .catch((error) => {
        console.log(error);
      });
  }
  viewimg(path) {
    this.setState({ isSpin: true });
    const { api_token } =
      (this.props.location && this.props.location.state) || {};

    const formdata = new FormData();
    // formdata.append("bucket_name", path.split("/")[0]);
    // formdata.append("blob_name", path.split("/")[2]);
    formdata.append("blob_url", path);
    let generate_Id = generateId(10);
    const requestOptions = {
      method: "POST",
      headers: {
        "api-token": api_token,
        component_id: generate_Id1,
        apiId: generate_Id,
        api_group_id: "",
        component: this.constructor.name,
        user_id: localStorage.getItem("in_userid"),
      },
      body: formdata,
    };
    customFetch(process.env.REACT_APP_URL + "/get/public/url", requestOptions)
      .then((res) => {
        if (res.headers.get("api_code") > 0) {
          this.setState({
            isErrOpen: true,
            api_error_message: res.headers.get("api_error_message"),
            isSpin: false,
          });
        }

        if (res.api_code === 4 || res.api_code === 3 || res.api_code === 5) {
          localStorage.clear();
          this.props.history.push("/");
        }
        return res.json();
      })
      .then((img) => {
        console.log("View Img:", img.type);
        this.setState({
          viewImg: img.public_url,
          isOpen: true,
          isSpin: false,
        });
      })
      .catch((error) => console.log("error", error));
    // .then((response) => {
    //   return response.body;
    // })
    // .then((data) => {
    //   //const href = URL.createObjectURL(data);
    //   console.log("Download:",data);
    //   this.setState({
    //     viewImg:data
    //   })
    // });
  }
  // logout() {
  //   localStorage.clear();
  //   this.props.history.push("/");
  // }
  render() {
    const { api_token } =
      (this.props.location && this.props.location.state) || {};
    const {
      reversed,
      current_balance,
      viewImg,
      viewImgType,
      isOpen,
    } = this.state;
    //console.log("Rev:", reversed);

    // this.state.items = this.state.items.reverse();
    // for (let i = 0; i < this.state.items.length; i++) {
    //   if (this.state.items[i].status != 5) {
    //     this.state.reversed.push(this.state.items[i]);
    //   }
    // }

    if (this.state.reversed.length > 0) {
      this.state.reversed.map((item) => {
        var findItem = this.state.uniqueNames.find(
          (x) => x.cash_balance_id == item.cash_balance_id
        );
        if (!findItem) this.state.uniqueNames.push(item.cash_balance_id);
      });

      // this.state.reversed.map((item) => {
      //   var findItem = this.state.uniqueLocation.find((x) => x.indexOf(item.submitted_at.split(" ")[2]) == 0);
      //   if (!findItem) this.state.uniqueLocation.push(item.submitted_at.split(" ")[2]);
      // });

      // this.state.uniqueLocation = this.state.uniqueLocation.sort((a, b) => a.localeCompare(b));

      // this.state.uniqueNames = this.state.uniqueNames.sort((a, b) => a.localeCompare(b));
    }

    return (
      <>
        {this.state.isSpin ? (
          <div class="preloader">
            <div class="loader" ng-hide="data.length > 0"></div>
          </div>
        ) : (
          ""
        )}
        <div
          className="modal fade bd-example-modal-sm"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="sort-filter"
          ref={this.sortModal}
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content sort_block col-sm-12">
              <span className="bluetext-header-sort">SORT BY</span>

              <div className="sortBlueBox">
                <div>
                  <input
                    type="radio"
                    id="htl"
                    name="htl"
                    value="high"
                    onChange={this.handlesorthtl}
                  />
                  <label htmlFor="high">&nbsp;High To Low</label>
                </div>

                <div>
                  <input
                    type="radio"
                    id="htl"
                    name="htl"
                    onChange={this.handlesorthtl}
                    value="low"
                  />
                  <label htmlFor="low">&nbsp;Low To High</label>
                </div>
              </div>
              <button
                data-dismiss="modal"
                type="button"
                className="btn btn-primary btn-lg btn-block rbtn search_elements"
                onClick={this.handlesort}
              >
                Apply
              </button>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="filter"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="filter_window">
                <div className="filter_header">
                  <table className="table filter-table">
                    <thead>
                      <tr>
                        <th>Filter</th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* 
                      <tr onClick={this.handleFilterMenu} id="balanceIdFilter" className='unique-name-click' >
                        <td>Balance ID</td>
                      </tr> */}

                      <tr
                        onClick={this.handleFilterMenu}
                        id="monthFilter"
                        className="unique-name-click"
                      >
                        <td>Month</td>
                      </tr>
                      <tr
                        onClick={this.handleFilterMenu}
                        id="fileuFilter"
                        className="unique-name-click"
                      >
                        <td>File Uploaded</td>
                      </tr>

                      <tr
                        onClick={this.handleFilterMenu}
                        id="statusFilter"
                        className="unique-name-click"
                      >
                        <td>Status</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div className="filter_amt" id="filter-sub-menu">
                  {/* {

                    this.state.isShowBalanceId && (
                      <div className='unique-names' id="uniqueNameDiv">


                        <center><strong>{this.state.MenuName}</strong></center>
                        <table className="table table-borderless">
                          {
                            this.state.uniqueNames.map((item, key) => (
                              <tbody>
                                <tr>
                                  <td> <input type="checkbox" id={item} name={item} onChange={this.addName} />
                                    <label htmlFor="vehicle1"> &nbsp; {item}</label>
                                  </td>
                                </tr>
                              </tbody>
                            )
                            )
                          }
                        </table>
                      </div>
                    )
                  } */}

                  {this.state.isShowMonthFilter && (
                    <div className="unique-names" id="uniqueNameDiv">
                      <center>
                        <strong>{this.state.MenuName}</strong>
                      </center>
                      <table className="table table-borderless">
                        {this.state.uniqueLocation.map((item, key) => (
                          <tbody>
                            <tr>
                              <td>
                                {" "}
                                <input
                                  type="checkbox"
                                  id={item}
                                  name={item}
                                  onChange={this.addName}
                                />
                                <label htmlFor="vehicle1"> &nbsp; {item}</label>
                              </td>
                            </tr>
                          </tbody>
                        ))}
                      </table>
                    </div>
                  )}

                  {this.state.isShowFileUpload && (
                    <div className="unique-names">
                      <center>
                        <strong>{this.state.MenuName}</strong>
                      </center>

                      <table className="table table-borderless">
                        <tbody>
                          <tr>
                            <td>
                              {" "}
                              <input
                                type="checkbox"
                                id="upload"
                                name="upload"
                                onChange={this.addName}
                              />
                              <label htmlFor="vehicle1"> &nbsp; Yes</label>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {" "}
                              <input
                                type="checkbox"
                                id="nUpload"
                                name="nUpload"
                                onChange={this.addName}
                              />
                              <label htmlFor="vehicle1"> &nbsp; No</label>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )}
                  {this.state.isShowFilterStatus && (
                    <div className="unique-names">
                      <center>
                        <strong>{this.state.MenuName}</strong>
                      </center>

                      <table className="table table-borderless">
                        <tbody>
                          <tr>
                            <td>
                              {" "}
                              <input
                                type="checkbox"
                                id="statSaved"
                                name="statSaved"
                                onClick={this.statusFilter}
                                checked={this.state.statSaved}
                              />
                              <label htmlFor="vehicle1"> &nbsp; Saved</label>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {" "}
                              <input
                                type="checkbox"
                                id="statSubmit"
                                name="statSubmit"
                                onChange={this.statusFilter}
                                checked={this.state.statSubmit}
                              />
                              <label htmlFor="vehicle1">
                                {" "}
                                &nbsp; Submitted
                              </label>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {" "}
                              <input
                                type="checkbox"
                                id="statAppr"
                                name="statAppr"
                                onChange={this.statusFilter}
                                checked={this.state.statAppr}
                              />
                              <label htmlFor="vehicle1"> &nbsp; Approved</label>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {" "}
                              <input
                                type="checkbox"
                                id="statRej"
                                name="statRej"
                                onChange={this.statusFilter}
                                checked={this.state.statRej}
                              />
                              <label htmlFor="vehicle1"> &nbsp; Rejected</label>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {" "}
                              <input
                                type="checkbox"
                                id="statVer"
                                name="statVer"
                                onChange={this.statusFilter}
                                checked={this.state.statVer}
                              />
                              <label htmlFor="vehicle1"> &nbsp; Verified</label>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )}

                  <div className="filter-btn-div">
                    <button
                      data-dismiss="modal"
                      className="btn btn-primary btn-block btn-lg rbtn"
                      onClick={this.filter}
                    >
                      APPLY
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade bd-example-modal-sm"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="search"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content search_block col-sm-12">
              {/* <form onSubmit={this.searchCustomer}> */}

              <input
                type="text"
                className="form-control rinput1 search_elements1"
                id="sfname"
                name="sfname"
                placeholder="Enter Balance ID"
                required
                onChange={this.handleSearchText}
              />
              <button
                data-dismiss="modal"
                onClick={this.searchBalanceID}
                type="submit"
                className="btn btn-primary btn-lg btn-block rbtn search_elements"
              >
                Search
              </button>
              {/* </form> */}
            </div>
          </div>
        </div>

        {/* Image popup Start */}
        <div
          className={
            isOpen
              ? "modal fade bd-example-modal-sm show mymodal"
              : "modal fade bd-example-modal-sm"
          }
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="confirm-box"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content cdb-confirm-box col-sm-12">
              <button
                type="button"
                className="close text-right"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => this.setState({ isOpen: false })}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              {(viewImg && viewImg.split(".").pop() === "pdf") ||
              viewImg.split(".").pop() === "PDF" ? (
                // AT-2109 | AT-2156 | Ankur Makavana | 10-07-2023 | replace blob_url with public filepath
                <iframe
                  className="pdfviewfile"
                  src={
                    viewImg + "#toolbar=1&navpanes=0&scrollbar=0&page=1&zoom=25"
                  }
                ></iframe>
              ) : (
                <img src={viewImg} />
              )}
            </div>
          </div>
        </div>
        {/* Image popup End */}

        <div className="container">
          <div className="ca-header-box">
            <div className="collection_block_header">
              <div className="title-holder_bg">
                <div
                  className="back_icon_bg"
                  onClick={() => this.props.history.goBack()}
                >
                  <img
                    src={require("../assets/images/back.png")}
                    className="back_icon_img"
                  />
                </div>
                <h2>Cash Deposit To Bank</h2>
                <div className="log-out">
                  <img
                    src={require("../assets/images/power-off.png")}
                    onClick={() => logout()}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="filter-options-container">
            <PEA element_id="CashDepositBankSortFilterBtn" data-toggle="modal" data-target="#sort-filter">
              <div className="icon_bg">
                <img
                  src={require("../assets/images/list.png")}
                  className="icon_img"
                />
              </div>
            </PEA>
            <PEA element_id="CashDepositBankFilterBtn" data-toggle="modal" data-target="#filter">
              <div className="icon_bg">
                <img
                  src={require("../assets/images/sort.png")}
                  className="icon_img"
                />
              </div>
            </PEA>

            <PEA element_id="CashDepositBankSearchBtn" data-toggle="modal" data-target="#search">
              <div className="icon_bg">
                <img
                  src={require("../assets/images/search.png")}
                  className="icon_img"
                />
              </div>
            </PEA>

            <PEDiv element_id="CashDepositBankDepositBtn">
              <div className="icon_bg">
                <img
                  src={require("../assets/images/plus.png")}
                  alt="#"
                  className="icon_img"
                  onClick={() => {
                    this.props.history.push({
                      pathname: "/Deposit_money",
                      state: { api_token: api_token },
                    });
                  }}
                />
              </div>
              {/* <a data-toggle="modal" data-target="#sort-filter">
                <img
                  src={require("../assets/images/list.png")}
                  alt="#"
                  className="list_img"

                />
              </a> */}
              {/* <img
                src={require("../assets/images/sort.png")}
                alt="#"
                className="list_img"

              /> */}
            </PEDiv>
          </div>
          <PEDiv element_id="CashDepositBankCashBalance" className="card mt-4 card-border">
            <div className="card-body">
              {/* AT-1913 | AT-1915 | Ankur Makavana | 04-07-2023 | UI code changes update data based on api response */}
              {current_balance != "" ? (
                <>
                  <div className="card-label">Cash Balance</div>
                  <div className="card-amtlabel">
                    Rs.
                    {parseFloat(current_balance).toLocaleString("en-IN")}
                  </div>
                </>
              ) : (
                <div className="text-center">No Data Found</div>
              )}
            </div>
          </PEDiv>
          {this.state.reversed.length > 0
            ? this.state.reversed.map((item) => (
                <PEDiv element_id="CashDepositBankCard"
                  className="card mt-4 card-border"
                  key={item.cash_balance_id}
                >
                  <div className="card-body">
                    <PEDiv element_id="CashDepositBankCashBalanceId" className="csoname-holder d-flex justify-content-center">
                      <span className="cash-id">
                        <b>Cash Balanceid:</b> {item.cash_balance_id}
                      </span>
                    </PEDiv>
                    <PEDiv element_id="CashDepositBankCashDeposit">
                      {/* <input type="button" onClick={()=> window.open("https://upload.wikimedia.org/wikipedia/commons/thumb/3/3e/LetterA.svg/1200px-LetterA.svg.png", "_blank")} /> */}
                      <div className="card-label">Cash Deposit</div>
                      <div className="card-amtdep">
                        Rs.{" "}
                        {parseFloat(item.amt_submitted).toLocaleString("en-IN")}
                      </div>
                    </PEDiv>
                    {/* if Status 2 then TO can upload or delete slip */}
                    {item.status === 2 ? (
                      item.filename ? (
                        <div className="fileupload-holder">
                          <div className="file-upload">
                            <PEDiv element_id="CashDepositBankViewFile"
                              className="view-img"
                              // AT-2109 | AT-2156 | Ankur Makavana | 10-07-2023 | replace blob_url with public filepath
                              onClick={() => this.viewimg(item.filename)}
                            >
                              <img
                                src={require("../assets/images/view_blue.png")}
                                alt=""
                              />
                            </PEDiv>
                            <PEDiv element_id="CashDepositBankDeleteFile"
                              className="remove-img"
                              onClick={() => {
                                if (window.confirm("are you sure to delete ?"))
                                  this.delimage(
                                    item.cash_balance_id,
                                    item.amt_submitted
                                  );
                              }}
                            >
                              <img
                                src={require("../assets/images/delete.png")}
                                alt=""
                              />
                            </PEDiv>

                            {/* {item.status === 2 ? (
                            <div
                              className="view-img"
                              onClick={() =>
                                this.props.history.push({
                                  pathname: "/Editdeposit",
                                  state: {
                                    api_token: api_token,
                                    cash_balance_id: item.cash_balance_id,
                                  },
                                })
                              }
                            >
                              <img
                                src={require("../assets/images/edit-blue.png")}
                                alt=""
                              />
                            </div>
                          ) : (
                            ""
                          )} */}
                          </div>
                        </div>
                      ) : (
                        <div className="fileupload-holder">
                          <div className="file-upload">
                            <div
                              className="upload-img"
                              onClick={() => {
                                // this.['upload'+item.cash_balance_id].click();
                                this["upload" + item.cash_balance_id].click();
                              }}
                            >
                              <img
                                src={require("../assets/images/upload (1).png")}
                                primary={false}
                                alt=""
                              />
                            </div>
                          </div>
                          <input
                            type="file"
                            name="file"
                            accept=".jpg, .JPG, .jpeg, .JPEG, .png, .PNG, .pdf, .PDF"
                            onChange={(event) =>
                              this.ImgchangeHandler(
                                event,
                                item.cash_balance_id,
                                item.amt_submitted
                              )
                            }
                            //ref={(ref) => (this.upload = ref)}
                            ref={(ref) => {
                              this["upload" + item.cash_balance_id] = ref;
                            }}
                            style={{ display: "none" }}
                          />
                        </div>
                      )
                    ) : (
                      ""
                    )}
                    <div className="card-foot-area">
                      <div className="date-time-area">
                        <PEDiv element_id="CashDepositBankDate" className="date-area">
                          <span>Date : </span>
                          <span>
                            {item.submitted_at.split(" ")[0] +
                              " " +
                              item.submitted_at.split(" ")[1] +
                              " " +
                              item.submitted_at.split(" ")[2] +
                              " " +
                              item.submitted_at.split(" ")[3]}
                          </span>
                        </PEDiv>
                        <PEDiv element_id="CashDepositBankTime" className="time-area">
                          <span>Time : {item.submitted_at.split(" ")[4]}</span>
                        </PEDiv>
                      </div>
                      <PEDiv element_id="CashDepositBankStatus" className="status-area">
                        <span>
                          {item.status === 1
                            ? "Saved"
                            : item.status === 2
                            ? "Submitted" //Sent For Rectification
                            : item.status === 3 && item.filename
                            ? "FinOps Verification"
                            : item.status === 3
                            ? "Self Approved"
                            : item.status === 4
                            ? "Rejected"
                            : item.status === 5
                            ? "Verified"
                            : ""}
                        </span>
                        {/* <span className="cbfoot-img-holder-submit">i</span> */}
                        {item.status === 1 && (
                          <span className="foot-img-holder-save">
                            <img
                              src={require("../assets/images/Save.png")}
                              alt=""
                            />
                          </span>
                        )}
                        {item.status === 2 && (
                          <span className="foot-img-holder-submit">
                            <img
                              src={require("../assets/images/Submit.png")}
                              alt=""
                            />
                          </span>
                        )}
                        {item.status === 3 && item.filename ? (
                          <span className="foot-img-holder-finopsverify">
                            <img
                              src={require("../assets/images/FinOps-Verification.png")}
                              alt=""
                            />
                          </span>
                        ) : (
                          item.status === 3 && (
                            <span className="foot-img-holder-approve">
                              <img
                                src={require("../assets/images/Approve.png")}
                                alt=""
                              />
                            </span>
                          )
                        )}
                        {item.status === 4 && (
                          <span className="foot-img-holder-reject">
                            <img
                              src={require("../assets/images/Reject.png")}
                              alt=""
                            />
                          </span>
                        )}
                        {item.status === 5 && (
                          <span className="foot-img-holder-approve">
                            <img
                              src={require("../assets/images/Approve.png")}
                              alt=""
                            />
                          </span>
                        )}
                      </PEDiv>
                    </div>
                  </div>
                </PEDiv>
              ))
            : ""}
          {/* <div className="card mt-4 card-border">
            <div className="card-body">
              <div className="csoname-holder">Vikas Singh (Ref. No.)</div>
              <div>
                <div className="card-label">Cash Deposit</div>
                <div className="card-amtdep">Rs. 10,000</div>
              </div>
              <div className="fileupload-holder">
                <div className="file-upload">
                  <div className="view-img">
                    <img
                      src={require("../assets/images/view_blue.png")}
                      alt=""
                    />
                    <span>View</span>
                  </div>
                  <div className="remove-img">
                    <img src={require("../assets/images/delete.png")} alt="" />
                    <span>Delete</span>
                  </div>
                </div>
              </div>
              <div className="card-foot-area">
                <div className="date-time-area">
                  <div className="date-area">
                    <span>Date : </span>
                    <span>Thu, 28 Jul 2022</span>
                  </div>
                  <div className="time-area">
                    <span>Time : 15:11:22</span>
                  </div>
                </div>
                <div className="status-area">
                  <span>Submitted</span>
                  <span className="cbfoot-img-holder-submit">i</span>
                </div>
              </div>
            </div>
          </div> */}
        </div>
        <ChatSupportBtn history={this.props.history} />
      </>
    );
  }
}
