import React, { Component, useState, useRef, useEffect } from "react";
import customFetch from "./apicall/api";
import { withRouter } from "react-router";
import { useHistory } from "react-router-dom";
import { logout, chkapiinput, generateId } from "./Utility";
import { Button } from "antd";
import {
  AudioFilled,
  DeleteOutlined,
  BorderOutlined,
  DeleteFilled,
} from "@ant-design/icons";
import { ThemeContext } from "../context/IsLoader";
import { PEButton, PEDiv, PEImg, PEInput, PEA, PESpan, PETextArea } from "./permissionComponents";
const generate_Id1 = generateId(10);
class SupportTicketForm extends Component {
  static contextType = ThemeContext;
  constructor(props) {
    super(props);

    this.state = {
      categories: [],
      category: "Feature/functionalitynotworking",
      summary: "",
      priority: "2",
      description: "",
      file: "",
      warning_msg: "",
      user_id: "",
      designation_name: "",
      isSpin: false,
      isDisable: false,
      api_token: localStorage.getItem("api_token"),
      audio: null,
    };
  }

  componentDidMount() {
    setTimeout(() => {
      if (document.readyState === "complete") {
        this.context.onPageLoad({
          component: this.constructor.name,
          component_id: generate_Id1,
        });
      } else {
        window.addEventListener("load", this.context.onPageLoad());
        this.cleanup = () =>
          window.removeEventListener("load", this.context.onPageLoad());
      }
    }, 1000);
    //static values for categories(will be removed in the future)
    const _category = [
      "Feature/functionalitynotworking",
      "Datacorrection",
      "Appdown",
      "Login/role/access/mapping issue",
      "Reports/MISrelated",
      "Query/clarification",
    ];
    this.setState({ categories: _category });

    const uid = localStorage.getItem("in_userid");
    if (!uid) {
      localStorage.clear();
      this.props.history.push("/");
    }
    const designation_name = localStorage.getItem("designation_name");
    this.setState({ user_id: uid, designation_name: designation_name });
  }

  // logout = () => {
  //   localStorage.clear();
  //   this.props.history.push("/");
  // };

  handleChange = (e) => {
    const name = e.target.name;
    let value = e.target.value;
    if (name == "file") value = e.target.files[0];
    this.setState({ [name]: value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ isSpin: true, isDisable: true });
    // console.log(process.env.REACT_APP_collection_hostname);
    let warning_msg = "";
    if (this.state.summary == "") {
      warning_msg = "Please enter the issue summary";
      this.setState({ warning_msg, isSpin: false });
      return;
    }
    if (this.state.category == "") {
      warning_msg = "Please choose the issue category";
      this.setState({ warning_msg, isSpin: false });
      return;
    }
    if (this.state.priority == "") {
      warning_msg = "Please choose the issue priority";
      this.setState({ warning_msg, isSpin: false });
      return;
    }

    if (this.state.description == "") {
      warning_msg = "Please write the issue description";
      this.setState({ warning_msg, isSpin: false });
      return;
    }
    // if (this.state.file == "") {
    //   warning_msg = "Please select the file to be uploaded";
    //   this.setState({ warning_msg, isSpin: false });
    //   return;
    // }
    /*Check if data is null then logout*/
    var arr = [
      this.state.user_id,
      this.state.summary,
      this.state.description,
      this.state.priority,
      this.state.designation_name,
      this.state.category,
    ];
    var chkdata = chkapiinput(arr);
    if (chkdata === false) {
      this.setState({ isSpin: false });
      setTimeout(function() {
        logout();
      }, 2000);
    }
    const data = {
      reporter_id: this.state.user_id,
      summary: this.state.summary,
      description: this.state.description,
      priority: this.state.priority,
      user_type: this.state.designation_name,
      category: this.state.category,
      assignee_id: this.state.user_id,
    };

    var _headers = new Headers();
    _headers.append("Content-Type", "application/json");
    // _headers.append("Accept", "application/json");
    let generate_Id = generateId(10);
    customFetch(process.env.REACT_APP_URL + "/issue/create/ticket", {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "api-token": this.state.api_token,
        "Content-Type": "application/json",
        component_id: generate_Id1,
        apiId: generate_Id,
        api_group_id: "",
        component: this.constructor.name,
        user_id: localStorage.getItem("in_userid"),
      },
    })
      .then((res) => res.json())
      .then(async (res) => {
        if (res.status_code != 200) return;
        const formData = [];
        if (this.state.file != "") {
          const file = new FormData();
          file.append("file", this.state.file);
          file.append("issueIdOrKey", res["data"]["id"]);
          formData.push(file);
        }
        if (this.state.audio) {
          const audiofile = new FormData();
          let blob = await fetch(this.state.audio).then((r) => r.blob());
          audiofile.append(
            "file",
            blob,
            "voice_description_" + Math.floor(Math.random() * 100) + ".mka"
          );
          audiofile.append("issueIdOrKey", res["data"]["id"]);
          formData.push(audiofile);
        }
        let generate_Id = generateId(10);
        const promises = await Promise.all(
          formData.map((input) => {
            return customFetch(
              process.env.REACT_APP_URL + "/issue/attachment",
              {
                method: "POST",
                body: input,
                headers: {
                  "api-token": this.state.api_token,
                  component_id: generate_Id1,
                  apiId: generate_Id,
                  api_group_id: "",
                  component: this.constructor.name,
                  user_id: localStorage.getItem("in_userid"),
                },
              }
            );
          })
        );

        const data = await Promise.all(promises.map((r) => r.json()));
        return data.flat();
      })
      .then((res) => {
        console.log(res);
        this.props.history.push("/supportticketlist");
      })
      .catch((error) => console.log("error", error));

    // let res = {
    //   "data": {
    //       "id": "11066",
    //       "key": "FIEL-19",
    //       "self": "https://navadhan.atlassian.net/rest/api/3/issue/11066"
    //   },
    //   "message": "issue ticket created successfully",
    //   "status_code": 200
    // }
  };

  setAudio = (audiourl) => {
    this.setState({ audio: audiourl });
  };

  render() {
    return (
      <>
        {this.state.isSpin ? (
          <div class="preloader">
            <div class="loader" ng-hide="data.length > 0"></div>
          </div>
        ) : (
          ""
        )}
        <div className="container support_nav_section">
          <div className="support_navbar">
            <button
              className="button back_button"
              onClick={() => this.props.history.goBack()}
            >
              <img src={require("../assets/images/back.png")} />
            </button>
            <span>Help Center</span>
            <button className="button" onClick={() => logout()}>
              <img src={require("../assets/images/power-off.png")} alt="" />
            </button>
          </div>
          <img src={require("../assets/images/help-centre.png")} />
        </div>
        <div className="container support_form_body">
          <div className="d-flex align-items-center justify-content-center mt-2">
            Support Ticket
          </div>
          <form onChange={this.handleChange} className="support_form">
            <PEInput element_id="TicketSummary" type="text" name="summary" placeholder="Summary" />
            {/* <select name="category" defaultValue="Feature/functionalitynotworking" disabled id="">
              <option value="" disabled hidden>
                Categorization
              </option>
              {this.state.categories.map((item) => {
                return (
                  <option key={item} value={item}>
                    {item.toString()}
                  </option>
                );
              })}
            </select> */}
            {/* <select defaultValue="2" name="priority" disabled id="">
              <option value="" disabled hidden>
                Priority
              </option>
              <option value="2">High</option>
              <option value="3">Medium</option>
              <option value="4">Low</option>
            </select> */}
            <AudioRecorder audio={this.state.audio} setAudio={this.setAudio} />
            <label
              htmlFor="fileInput"
              name="file"
              className="support_file_upload"
            >
              <span>
                {this.state.file != "" ? this.state.file.name : "File Upload"}
              </span>
              <input name="file" id="fileInput" type="file" />
              <img src={require("../assets/images/upload (1).png")} />
            </label>
            <PETextArea element_id="TicketDescription"
              type="text"
              name="description"
              placeholder="Description"
              maxLength="1024"
            ></PETextArea>
            {this.state.warning_msg != "" ? (
              <span className="text-danger">*{this.state.warning_msg}</span>
            ) : (
              ""
            )}
            <PEButton element_id="TicketSubmitBtn"
              className="btn btn-primary rbtn btn-lg col-8 col-md-6 col-lg-4"
              type="submit"
              // disabled={this.state.isDisable}
              onClick={this.handleSubmit}
            >
              Submit
            </PEButton>
          </form>
        </div>
      </>
    );
  }
}

export default withRouter(SupportTicketForm);

function AudioRecorder({ audio, setAudio }) {
  const [permission, setPermission] = useState(false);
  const mediaRecorder = useRef(null);
  const [recordingStatus, setRecordingStatus] = useState("inactive");
  // const [stream, setStream] = useState(null);
  const [audioChunks, setAudioChunks] = useState([]);
  // const [audio, setAudio] = useState(null);
  const mimeType = "audio/webm";

  const getMicrophonePermission = async () => {
    if ("MediaRecorder" in window) {
      try {
        const streamData = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: false,
        });
        setPermission(true);
        // setStream(streamData);
        return streamData;
      } catch (err) {
        alert(err.message);
      }
    } else {
      alert("The MediaRecorder API is not supported in your browser.");
    }
  };

  const startRecording = async () => {
    const stream = await getMicrophonePermission();
    if (!stream) return;
    setRecordingStatus("recording");
    const media = new MediaRecorder(stream, { type: mimeType });
    mediaRecorder.current = media;
    mediaRecorder.current.start();
    let localAudioChunks = [];
    mediaRecorder.current.ondataavailable = (event) => {
      if (typeof event.data === "undefined") return;
      if (event.data.size === 0) return;
      localAudioChunks.push(event.data);
    };
    setAudioChunks(localAudioChunks);
  };

  const stopRecording = () => {
    setRecordingStatus("inactive");
    mediaRecorder.current.stop();
    mediaRecorder.current.onstop = () => {
      const audioBlob = new Blob(audioChunks, { type: mimeType });
      const audioUrl = URL.createObjectURL(audioBlob);
      setAudio(audioUrl);
      setAudioChunks([]);
    };
  };

  const deleteRecording = () => {
    setAudio(null);
    setAudioChunks(null);
  };

  return (
    <>
      <div className="st_audio_input">
        <PEDiv element_id="TicketVoice">
          {audio ? (
            <audio src={audio} controls></audio>
          ) : recordingStatus === "inactive" ? (
            "Record voice message"
          ) : (
            "Recording..."
          )}
        </PEDiv>
        <div className="audio-controls">
          {audio && recordingStatus === "inactive" ? (
            <Button onClick={deleteRecording} icon={<DeleteFilled />} />
          ) : null}

          {recordingStatus === "inactive" ? (
            <Button onClick={startRecording} icon={<AudioFilled />} />
          ) : null}
          {recordingStatus === "recording" ? (
            <Button onClick={stopRecording} icon={<BorderOutlined />} />
          ) : null}
        </div>
      </div>
    </>
  );
}
