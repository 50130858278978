import React, { Component, useEffect, useState } from "react";
import customFetch from "./apicall/api";
import { filterData, logout, generateId } from "./Utility";
import {
  get_payload_views,
  update_payload,
  get_mongo_payload,
  update_mongopayload,
} from "./Services";
import { Select, Modal, Button, Input, DatePicker } from "antd";
import {
  EditOutlined,
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { ThemeContext } from "../context/IsLoader";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { QueryBuilder, RuleGroupType } from "react-querybuilder";

const { TextArea } = Input;
const dateFormatList = "DD/MM/YYYY";
const generate_Id1 = generateId(10);
export default class PayloadviewDetail extends Component {
  static contextType = ThemeContext;
  constructor(props) {
    super(props);

    this.state = {
      payload_config: props.payload_config,
      api_token: props.api_token,
      effective_designation_id: props.effective_designation_id,
      payload_data: [props.detailData],
      disabled: false,
      isspin: false,
      isLoginuser: true,
      sortOrder: "asc",
      otherScreen: "",
      no_of_page: "",
      filterDate: "",
      selectDate: "",
      isDateSel: "",
      DateSelMessage: false,
      view_name: "",
      search_fields: "",
      search_click: 0,
      filters: {},
      searchconfig:
        props.payload_config.payload_type == "M"
          ? props.payload_config.field_mapping[0].search_query || {
              combinator: "and",
              not: "False",
              rules: [],
            }
          : [],
      query: { combinator: "and", rules: [] },
      Operators: [
        { name: "=", label: "=" },
        { name: "!=", label: "!=" },
        { name: ">", label: ">" },
        { name: "<", label: "<" },
        { name: ">=", label: ">=" },
        { name: "<=", label: "<=" },
        { name: "exists", label: "Exists" },
      ],
    };
  }

  componentDidMount() {
    console.log("call detail");
    const filtersearch = this.props.payload_config.field_mapping.filter(
      (item) => item.isSearch === "1"
    );
    const extractedDataArray = [];
    if (this.props.payload_config.payload_type == "M") {
      filtersearch.forEach((item) => {
        const name = item.BucketField;
        const label = item.fieldname;

        // Push the extracted data as an object into the array
        extractedDataArray.push({ name, label });
      });
      //setsearch_fields(extractedDataArray);
      this.setState({ search_fields: extractedDataArray });
    } else {
      this.setState({ search_fields: filtersearch });
    }

    setTimeout(() => {
      if (document.readyState === "complete") {
        this.context.onPageLoad({
          component: this.constructor.name,
          component_id: generate_Id1,
        });
      } else {
        window.addEventListener("load", this.context.onPageLoad());
        this.cleanup = () =>
          window.removeEventListener("load", this.context.onPageLoad());
      }
    }, 1000);
    this.state.payload_config.field_mapping.map((fields) => {
      if (fields.datatype == "dropdownlist") {
        let generate_Id = generateId(10);
        const requestOptions = {
          method: "GET",
          headers: {
            "api-token": this.state.api_token,
            component_id: generate_Id1,
            apiId: generate_Id,
            api_group_id: "",
            component: "",
            user_id: localStorage.getItem("in_userid"),
          },
        };
        // AT-2109 | AT-2156 | Ankur Makavana | 10-07-2023 | replace blob_url with public filepath
        const Reasonlist = customFetch(
          process.env.REACT_APP_URL +
            "/get/dropdown/item/list?dropdown_id=" +
            fields.dropdownlist,
          requestOptions
        )
          .then((res) => {
            return res.json();
          })
          .then((json) => {
            this.setState({
              Reasonlist: json,
            });
          });
      }
    });
    if (this.state.otherScreen != null) {
      this.setState({
        no_of_page: 2,
      });
      this.context.setotherscreen(this.state.otherScreen);
      this.context.setno_of_page(2);
    }
    if (!this.state.filterDate) {
      let inputDate = moment()
        .subtract(6, "months")
        .format("YYYY-MM-DD");
      this.setState(
        {
          filterDate: moment(inputDate).unix(),
          defaultdate: inputDate,
        },
        () => {
          this.getPayloads();
        }
      );
    }
    // if (this.state.isDateSel == "") {
    //   this.setState({ DateSelMessage: true });
    // }
  }
  handleSort = () => {
    // Implement sorting logic based on the current sort order
    const sortedData = [...this.state.payload_data].sort((a, b) => {
      if (this.state.sortOrder === "asc") {
        return moment
          .unix(a.created_at)
          .format("MM/DD/YYYY HH:mm:ss")
          .localeCompare(
            moment.unix(b.created_at).format("MM/DD/YYYY HH:mm:ss")
          );
      } else {
        return moment
          .unix(b.created_at)
          .format("MM/DD/YYYY HH:mm:ss")
          .localeCompare(
            moment.unix(a.created_at).format("MM/DD/YYYY HH:mm:ss")
          );
      }
    });

    // Update the table data and sort order

    this.setState({
      sortOrder: this.state.sortOrder === "asc" ? "desc" : "asc",
      payload_data: sortedData,
    });
    // setTableData(sortedData);
    // setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };
  DeletePayload = async (doc_id) => {
    try {
      const url =
        process.env.REACT_APP_URL +
        "/payload/move/" +
        this.state.payload_config.bucket_name +
        "/" +
        doc_id;
      let payload = null;
      let method = "POST";
      let generate_Id = generateId(10);
      const requestOptions = {
        method: method,
        headers: {
          "api-token": this.state.api_token,
          component_id: generate_Id1,
          apiId: generate_Id,
          api_group_id: "",
          component: this.constructor.name,
          user_id: localStorage.getItem("in_userid"),
        },
        body: payload,
      };

      const response = await customFetch(url, requestOptions);

      const data = await response.json();
      if (response.status != 200) throw data;

      Modal.success({
        content: <pre id="json">{JSON.stringify(data)}</pre>,
        title: "Success",
      });
    } catch (e) {
      Modal.error({
        content: <pre id="json">{JSON.stringify(e)}</pre>,
        title: "Error",
      });
    } finally {
      this.getPayloads();
    }
  };
  DeleteMongoPayload = async (doc_id) => {
    try {
      const url =
        process.env.REACT_APP_URL + "/payload/movemongo/callbacks/" + doc_id;
      let payload = null;
      let method = "POST";
      let generate_Id = generateId(10);
      const requestOptions = {
        method: method,
        headers: {
          "api-token": this.state.api_token,
          component_id: generate_Id1,
          apiId: generate_Id,
          api_group_id: "",
          component: this.constructor.name,
          user_id: localStorage.getItem("in_userid"),
        },
        body: payload,
      };

      const response = await customFetch(url, requestOptions);

      const data = await response.json();
      if (response.status != 200) throw data;

      Modal.success({
        content: <pre id="json">{JSON.stringify(data)}</pre>,
        title: "Success",
      });
    } catch (e) {
      Modal.error({
        content: <pre id="json">{JSON.stringify(e)}</pre>,
        title: "Error",
      });
    } finally {
      this.getPayloads();
    }
  };
  getPayloads = async () => {
    const { contextuser_id } = this.context;

    let defaultfilter = [];
    if (this.state.isLoginuser == "1") {
      this.state.payload_config.field_mapping.map((fieldata) => {
        if (fieldata.defaultfilter != "") {
          let splitfieldata = fieldata.defaultfilter.split(".");
          //console.log("splitfieldata", localStorage.getItem(splitfieldata[1]));
          defaultfilter.push({
            field: fieldata.BucketField,
            fieldvalue: localStorage.getItem(splitfieldata[1]),
            operatordata: splitfieldata[0],
          });
        }
      });
    }

    let searchData = [];
    this.state.payload_config.field_mapping.map((cell) => {
      if (cell.isSearch == 1 && this.state[cell.BucketField]) {
        searchData.push([cell.BucketField, "==", this.state[cell.BucketField]]);
      }
    });
    let data;
    console.log("searchData->", searchData);
    if (this.props.fetch_onload == "true" || this.state.search_click == 1) {
      this.setState({ isspin: true });
      let dbname, collectionname;
      if (this.state.payload_config.db_name == "") {
        dbname = "callbacks";
      } else {
        dbname = this.state.payload_config.db_name;
      }
      if (this.state.payload_config.collection_name == "") {
        collectionname = "callback_payloads";
      } else {
        collectionname = this.state.payload_config.collection_name;
      }
      if (this.state.payload_config.payload_type == "M") {
        data = await get_mongo_payload(
          this.state.api_token,
          this.state.payload_config.search_by_pair,
          this.state.filterDate,
          this.formatQuerybuild(this.state.searchconfig),
          dbname,
          collectionname
        );
      } else {
        data = await get_payload_views(
          this.state.api_token,
          this.state.payload_config.bucket_name,
          this.state.payload_config.event_name,
          defaultfilter,
          this.state.isLoginuser,
          this.state.filterDate,
          this.state.payload_config.event_location,
          searchData,
          this.state.searchconfig
        );
      }
      const parsed_data = [];
      if (data.status != 0) {
        for (let item in data) {
          parsed_data.push({
            ...data[item],
            key: item,
          });
        }
      }

      this.setState({ payload_data: parsed_data, isspin: false });
    }
  };
  formatQuerybuild = (query) => {
    if (!query) return {};
    const mongoQuery = {};
    if (query.combinator === "and") {
      if (query.rules && query.rules.length > 0) {
        mongoQuery.$and = query.rules.map(this.formatQuerybuild);
      }
    } else if (query.combinator === "or") {
      if (query.rules && query.rules.length > 0) {
        mongoQuery.$or = query.rules.map(this.formatQuerybuild);
      }
    }

    if (query.field && query.operator) {
      const field = query.field;
      const operator = query.operator;
      const queryvalue = query.value;
      let value;
      const createdAtObject = this.state.payload_config.field_mapping.find(
        (item) => item.BucketField == field
      );

      if (createdAtObject.datatype == "int") {
        value = parseInt(queryvalue);
      } else if (createdAtObject.datatype == "float") {
        value = parseFloat(queryvalue);
      } else {
        value = queryvalue;
      }

      if (operator === "=") {
        mongoQuery[field] = value;
      } else if (operator === "!=") {
        mongoQuery[field] = { $ne: value };
      } else if (operator === ">") {
        mongoQuery[field] = { $gt: value };
      } else if (operator === "<") {
        mongoQuery[field] = { $lt: value };
      } else if (operator === ">=") {
        mongoQuery[field] = { $gte: value };
      } else if (operator === "<=") {
        mongoQuery[field] = { $lte: value };
      }

      if (operator === "exists") {
        mongoQuery[field] = {
          $exists: value.toLowerCase() == "true" ? true : false,
        };
      }
    }
    console.log("mongoQuery-->", mongoQuery);
    return mongoQuery;
  };
  setDisabled = (value) => {
    this.setState({ disabled: value });
  };
  changedate = (date, dateString) => {
    var ts = moment(dateString, dateFormatList).unix();
    var m = moment.unix(ts);
    let inputDate = moment(date).format("YYYY-MM-DD");
    this.setState({
      filterDate: moment(inputDate).unix(),
      isDateSel: "1",
    });
  };

  update_search_filter = (field, value) => {
    console.log("field->", field);

    if (this.state.payload_config.payload_type == "M") {
      if (this.props.payload_config.field_mapping[0].search_query) {
        const updatedRule = { ...this.state.searchconfig };

        const findRule = (rules) => {
          for (let rule of rules) {
            if (rule.field === field) {
              rule.value = value;
              return;
            }
            if (rule.rules) {
              findRule(rule.rules);
            }
          }
        };

        findRule(updatedRule.rules);

        this.setState({ searchconfig: updatedRule });
      } else {
        const { searchconfig } = this.state;
        let operator = "=";
        const existingRuleIndex = searchconfig.rules.findIndex(
          (rule) => rule.field == field
        );

        if (existingRuleIndex != -1) {
          // Update the existing rule
          searchconfig.rules[existingRuleIndex] = { field, operator, value };
        } else {
          // Insert a new rule
          searchconfig.rules.push({ field, operator, value });
        }

        // Update the state with the modified search_new object
        this.setState({ searchconfig });
      }
    }
    if (this.state.payload_config.payload_type == "B") {
      const existingIndex = this.state.searchconfig.findIndex(
        (item) => item[0] == field
      );

      if (existingIndex !== -1) {
        // If the field exists, update the corresponding value
        const updatedKeyValues = [...this.state.searchconfig];
        updatedKeyValues[existingIndex][2] = value;

        this.setState({
          searchconfig: updatedKeyValues,
        });
      } else {
        // If the key doesn't exist, create a new entry
        const newKeyValue = [field, "==", value];
        this.setState((prevState) => ({
          searchconfig: [...prevState.searchconfig, newKeyValue],
        }));
      }
    }
  };
  eventNameSearch = (e, columnName) => {
    const updatedFilters = {
      ...this.state.filters,
      [columnName]: e.target.value,
    };
    this.setState({ filters: updatedFilters });
  };
  // filterData = () => {
  //   return this.state.payload_data.filter((row) => {
  //     for (const columnName in this.state.filters) {
  //       const filterText = this.state.filters[columnName].toLowerCase();
  //       const cellText = String(row[columnName]).toLowerCase();
  //       if (!cellText.includes(filterText)) {
  //         return false;
  //       }
  //     }
  //     return true;
  //   });
  // };

  filterData = () => {
    const { payload_data, filters } = this.state;
    console.log("payload_data->", payload_data);
    return payload_data.filter((row) => {
      if (!row) {
        return false;
      }

      return Object.keys(filters).every((columnName) => {
        const filterText = filters[columnName].toLowerCase();
        const cellText = this.deepGet(row, columnName);

        if (cellText === undefined || cellText === null) {
          return false;
        }

        return String(cellText)
          .toLowerCase()
          .includes(filterText);
      });
    });
  };

  deepGet = (obj, path) => {
    const keys = path.split(".");
    return keys.reduce(
      (acc, key) => (acc && acc[key] !== undefined ? acc[key] : undefined),
      obj
    );
  };
  render() {
    console.log("detailData->", this.state.payload_data);
    const filteredData = this.filterData();
    console.log("searchconfig-->", this.state.payload_config);
    return (
      // <div className="container">
      <div className="mr-2 ml-2">
        {this.state.isspin && (
          <div className="preloader">
            <div className="loader" ng-hide="data.length > 0"></div>
          </div>
        )}

        <div className="payload_view_sub_block">
          <div className="payload_sub_view_table_block">
            <div className="tableFixHead">
              <table
                className="payload_view_table text-center table table-hover table-responsive"
                id="my-table"
              >
                <thead>
                  <tr>
                    {this.state.payload_config.field_mapping.map(
                      (cell, index) => {
                        return (
                          <>
                            {cell.isVisible == "1" && (
                              <th key={cell.fieldname}>{cell.fieldname}</th>
                            )}
                          </>
                        );
                      }
                    )}
                    <th onClick={this.handleSort}>
                      Date Time{" "}
                      <span
                        className={
                          this.state.sortOrder == "asc"
                            ? "up-arrow"
                            : "down-arrow"
                        }
                      ></span>
                    </th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredData.length > 0 ? (
                    filteredData.map((row, rowindex) => {
                      return (
                        <EditableRow
                          key={row.key}
                          payload_config={this.state.payload_config}
                          payload={row}
                          disabled={this.state.disabled}
                          setDisabled={this.setDisabled}
                          api_token={this.state.api_token}
                          getPayloads={this.getPayloads}
                          DeletePayload={this.DeletePayload}
                          DeleteMongoPayload={this.DeleteMongoPayload}
                          rowindex={rowindex}
                          effective_designation_id={
                            this.state.effective_designation_id
                          }
                          Reasonlist={this.state.Reasonlist}
                        />
                      );
                    })
                  ) : (
                    <div className="text-center font-bold">No Data Found</div>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function EditableRow({
  api_token,
  payload_config,
  payload,
  disabled,
  setDisabled,
  getPayloads,
  DeletePayload,
  DeleteMongoPayload,
  rowindex,
  effective_designation_id,
  Reasonlist,
}) {
  const [edit, setEdit] = useState(false);
  const [updatedPayload, setUpdatedPayload] = useState({});
  const [ImageView, setImageView] = useState();
  const [isImageView, setisImageView] = useState(false);
  const [isShowData, setisShowData] = useState(false);
  const [isPayloadView, setisPayloadView] = useState(false);
  const [PayloadViewData, setPayloadViewData] = useState();
  const [ShowData, setShowData] = useState(false);
  const [ReasonData, setReasonData] = useState();
  const [isFamily, setisFamily] = useState(false);
  const [familyData, setfamilyData] = useState();
  const [change_data, setchange_data] = useState({});
  const [currentData, setcurrentData] = useState();

  useEffect(() => {
    setDisabled(edit);
    let _updatedPayload = {
      ...payload,
    };

    setUpdatedPayload(_updatedPayload);
  }, [edit]);

  const isFieldArray = (field) => {
    const keyParts = field.split(".");

    let lastArrayName = null;
    for (let i = keyParts.length - 1; i >= 0; i--) {
      if (!isNaN(keyParts[i])) {
        lastArrayName = keyParts[i - 1];
        break;
      }
    }
    console.log("lastArrayBucketField->", lastArrayName);
    if (lastArrayName != null) {
      return true;
    }
  };

  useEffect(() => {
    let lastArrayBucketField = null;
    payload_config.field_mapping.forEach((item) => {
      const bucketField = item.BucketField;
      if (isFieldArray(bucketField)) {
        lastArrayBucketField = bucketField;
      }
    });
    if (lastArrayBucketField != null) {
      const keyParts = lastArrayBucketField.split(".");
      let fullPathToLastArray = null;

      // Iterate through the key parts to find the last array name
      for (let i = keyParts.length - 1; i >= 0; i--) {
        if (!isNaN(keyParts[i])) {
          fullPathToLastArray = keyParts.slice(0, i).join(".");
          break;
        }
      }
      //const lastArray = payload[fullPathToLastArray];
      const lastArray = null;
      console.log("pathParts", fullPathToLastArray);
      let currentData = payload;
      if (fullPathToLastArray != null) {
        const pathParts = fullPathToLastArray.split(".");

        // Traverse the path to ensure each part exists
        for (const part of pathParts) {
          if (currentData && currentData.hasOwnProperty(part)) {
            console.log("hasOwnProperty Yes");
            currentData = currentData[part];
          } else {
            // Property along the path does not exist, handle the case accordingly
            console.log("hasOwnProperty No");
            currentData = null; // Set to null or handle as needed
            break;
          }
        }
      }
      console.log("currentData-->", currentData);
      setcurrentData(currentData);
    }
  }, []);
  const handlePayloadChange = (value, bucket_field) => {
    //if (payload_config.payload_type == "B") {
    let _updatedPayload = {
      ...updatedPayload,
    };

    let splitdata_bucket_field = bucket_field.split(".");
    splitdata_bucket_field.reduce((obj, key, i) => {
      if (i === splitdata_bucket_field.length - 1) {
        //obj[key] = JSON.parse(value) ? JSON.parse(value) : value;
        obj[key] = value;
      }
      return obj[key];
    }, _updatedPayload);
    setUpdatedPayload({ ..._updatedPayload });
    checkReason(_updatedPayload);
    // }
    // if (payload_config.payload_type == "M") {
    setchange_data((prevData) => ({
      ...prevData,
      [bucket_field]: value,
    }));
    //}
  };

  const checkReason = (payload_data) => {
    if (payload_data.advance > 0) {
      const filteredData = Reasonlist.filter(
        (item) => item.item_id == payload_data.advance
      );
      if (filteredData[0].target_queue != "") {
        let _updatedPayload = {
          ...payload_data,
        };
        let splitqueue = filteredData[0].target_queue.split("|");
        splitqueue.map((splitdata) => {
          let splitpair = splitdata.split("=");
          let bucket_key = splitpair[0].trim();
          let splitdata_bucket_field = bucket_key.split("."); //payload_config[bucket_key].split(".");
          splitdata_bucket_field.reduce((obj, key, i) => {
            if (i === splitdata_bucket_field.length - 1) {
              //obj[key] = JSON.parse(value) ? JSON.parse(value) : value;
              obj[key] = splitpair[1].trim();
            }
            return obj[key];
          }, _updatedPayload);
          //console.log("_updatedPayload-->", _updatedPayload);
          setUpdatedPayload({ ..._updatedPayload });
        });
      }
    }
  };

  const editHandler = async (bucket_name, payload_key) => {
    if (payload_config.payload_type == "M") {
      let dbname, collectionname;
      if (payload_config.db_name == "") {
        dbname = "callbacks";
      } else {
        dbname = payload_config.db_name;
      }

      if (payload_config.collection_name == "") {
        collectionname = "callback_payloads";
      } else {
        collectionname = payload_config.collection_name;
      }
      console.log("payload_key->", payload_key);
      console.log("payload->", payload._id.$oid);
      let objectid = payload._id.$oid;
      const response = await update_mongopayload(
        api_token,
        dbname,
        collectionname,
        objectid,
        change_data
      );

      if (response.updated == 1) {
        Modal.success({
          content: <pre id="json">Payload updated successfully!</pre>,
          title: "Success",
        });
        return true;
      } else {
        Modal.error({
          content: <pre id="json">Error updating the payload</pre>,
          title: "Error",
        });
        return false;
      }
    }
    if (payload_config.payload_type == "B") {
      console.log("finalData", payload._id.$oid);
      const response = await update_payload(
        api_token,
        bucket_name,
        payload_key,
        updatedPayload
      );

      if (response.status == 1) {
        Modal.success({
          content: <pre id="json">Payload updated successfully!</pre>,
          title: "Success",
        });
        return true;
      } else {
        Modal.error({
          content: <pre id="json">Error updating the payload</pre>,
          title: "Error",
        });
        return false;
      }
    }
  };

  const actionHandler = async (action_id, payload_data) => {
    let payloadkey = payload.key;
    console.log("payload_config-->", payload_config);
    try {
      setDisabled(true);
      const action = payload_config.action_mapping.filter((item) => {
        return item.id == action_id;
      })[0];
      let URL;
      if (payload_config.payload_type == "M") {
        URL = process.env.REACT_APP_PYTHON_URL;
      }
      if (payload_config.payload_type == "B") {
        URL = process.env.REACT_APP_URL;
      }
      const url = URL + "/" + action.api_url;
      let payload = null;
      let method = null;
      if (payload_data) {
        const form = new FormData();
        payload_data.forEach((field) => {
          form.append(field.field, field.value);
        });
        if (payload_config.payload_type == "B") {
          form.append("doc_id", payloadkey);
        }
        payload = form;
        method = "POST";
      }
      let generate_Id = generateId(10);
      const requestOptions = {
        method: method,
        headers: {
          "api-token": api_token,
          component_id: generate_Id1,
          apiId: generate_Id,
          api_group_id: "",
          component: "",
          user_id: localStorage.getItem("in_userid"),
        },
        body: payload,
      };

      const response = await customFetch(url, requestOptions);

      const data = await response.json();
      if (response.status != 200) throw data;

      Modal.success({
        content: <pre id="json">{JSON.stringify(data)}</pre>,
        title: "Success",
      });
    } catch (e) {
      Modal.error({
        content: <pre id="json">{JSON.stringify(e)}</pre>,
        title: "Error",
      });
    } finally {
      setDisabled(false);
      getPayloads();
    }
  };
  const converttoarray = (key, data, indexid) => {
    const regex = /\d+/;
    const newKey = key.replace(regex, indexid);

    const keys = newKey.split(".");

    // Initialize a variable to store the current data object
    let currentData = data;

    // Loop through each part of the key
    for (const k of keys) {
      // Check if the currentData is an object and has the key
      if (currentData && currentData.hasOwnProperty(k)) {
        currentData = currentData[k]; // Update currentData to the nested object
      } else {
        return ""; // Return a default value if any part of the key is not present
      }
    }
    console.log("currentData", currentData);
    return currentData;
  };
  const viewFamily = (hhid) => {
    const cbResponseArray = payload.data.cb_response;

    // Filter ConsumerName and ReferenceNumber based on household_id
    const filteredData = cbResponseArray
      .filter((response) => payload.data.household_id === hhid)
      .map((response) => ({
        ConsumerName: response.ConsumerName,
        ReferenceNumber: response.ReferenceNumber,
      }));

    // Display the result
    setfamilyData(filteredData);
    setisFamily(true);
  };
  const viewImage = (path) => {
    const formdata = new FormData();
    formdata.append("blob_url", path);
    let generate_Id = generateId(10);
    const requestOptions = {
      method: "POST",
      headers: {
        "api-token": api_token,
        component_id: generate_Id1,
        apiId: generate_Id,
        api_group_id: "",
        component: this.constructor.name,
        user_id: localStorage.getItem("in_userid"),
      },
      body: formdata,
    };
    // AT-2109 | AT-2156 | Ankur Makavana | 10-07-2023 | replace blob_url with public filepath
    customFetch(process.env.REACT_APP_URL + "/get/public/url", requestOptions)
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        setImageView(json.public_url);
        setisImageView(true);
      });
    //const data = await response.blob();
  };

  const showdata = async (apiname, tabledata) => {
    let getapiconfig = JSON.parse(payload_config.get_api_mapping);
    let findapi = getapiconfig.find((item) => item.action_name == apiname);

    let form = [];
    for (var k = 0; k < payload_config.field_mapping.length; k++) {
      for (var l = 0; l < findapi.formdata.length; l++) {
        var exists = false;
        if (
          payload_config.field_mapping[k].fieldname ===
          findapi.formdata[l].formdatavalue
        ) {
          form.push({
            field: findapi.formdata[l].formdatakey,
            value: payload[payload_config.field_mapping[k].BucketField],
          });
          break;
        }
      }
    }
    for (var l = 0; l < findapi.formdata.length; l++) {
      if (
        findapi.formdata[l].formdatavalue == "" ||
        findapi.formdata[l].formdatavalue == null ||
        findapi.formdata[l].formdatavalue == undefined
      ) {
        form.push({
          field: findapi.formdata[l].formdatakey,
          value: findapi.formdata[l].defaultvalue,
        });
      }
    }
    const formdata = new FormData();
    form.map((fomdata) => {
      formdata.append(fomdata.field, fomdata.value);
    });
    let generate_Id = generateId(10);
    const requestOptions = {
      method: "POST",
      headers: {
        "api-token": api_token,
        component_id: generate_Id1,
        apiId: generate_Id,
        api_group_id: "",
        component: this.constructor.name,
        user_id: localStorage.getItem("in_userid"),
      },
      body: formdata,
    };

    const response = await customFetch(
      process.env.REACT_APP_URL + "/" + findapi.api_url,
      requestOptions
    );
    if (response.status != 200) throw "Error updating the payload";

    const data = await response.json();
    setShowData(data);
    setisShowData(true);
  };
  const handleCancelimage = () => {
    setisImageView(false);
  };
  const handleCancelfamily = () => {
    setisFamily(false);
  };
  const handleCanceljson = () => {
    setisPayloadView(false);
  };
  const handleCanceldata = () => {
    setisShowData(false);
  };

  const Dropdownlist = (val) => {
    let isedits = Reasonlist && val.isedit == true && (
      <select
        className="form-control"
        defaultValue={val.value}
        value={ReasonData}
        onChange={(event) => {
          handlePayloadChange(event.target.value, val.bucketfield);
          setReasonData(event.target.value);
        }}
      >
        <option value="">Select Reason</option>
        {Reasonlist &&
          Reasonlist.map((list) => (
            <option value={list.item_id}>{list.item_name}</option>
          ))}
      </select>
    );
    if (val.isedit == false) {
      // let iseditfalse;
      // iseditfalse = Reasonlist.map(
      //   (list) => list.item_id == val.value && list.item_name
      // );
      if (Reasonlist) {
        const matchingItem = Reasonlist.find(
          (item) => item.item_id == val.value
        );

        // Check if a matching item was found
        if (matchingItem) {
          const itemName = matchingItem.item_name;
          isedits = itemName;
        } else {
          isedits = "Select Reason";
        }
      } else {
        isedits = "Select Reason";
      }
    }
    return isedits;
  };
  console.log("change_data", change_data);
  return (
    <>
      <Modal
        open={isImageView}
        onCancel={handleCancelimage}
        footer={[]}
        width="auto"
      >
        {ImageView && <img src={ImageView} className="w-100" />}
      </Modal>
      <Modal
        open={isFamily}
        onCancel={handleCancelfamily}
        footer={[]}
        width="auto"
      >
        <table className="table">
          <thead>
            <tr>
              <th>Consumername</th>
              <th>Reference Number</th>
            </tr>
          </thead>
          <tbody>
            {familyData &&
              familyData.map((fdata) => (
                <tr>
                  <td>{fdata.ConsumerName}</td>
                  <td>{fdata.ReferenceNumber}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </Modal>
      <Modal
        open={isPayloadView}
        onCancel={handleCanceljson}
        footer={[]}
        width="auto"
      >
        <div>
          <pre className="showjson">{PayloadViewData}</pre>
        </div>
      </Modal>
      <Modal
        //title="Title"
        open={isShowData}
        onCancel={handleCanceldata}
        footer={[]}
        width="auto"
      >
        <div className="row">
          {/* {ShowData &&
            ShowData.map((userdata) => (
              <div className="form-group col-md-4">
                {userdata.Branches.Branch}
              </div>
            ))} */}
          {ShowData &&
            ShowData.Branches.map((userdata, index) => {
              let getapiconfig = JSON.parse(payload_config.get_api_mapping);
              return getapiconfig[0].outputdata.map((apiconfig, i) => {
                return (
                  <>
                    <div className="form-group col-md-4">
                      <label className="font-weight-bold">
                        {apiconfig.outputkey}
                      </label>
                      <div>{userdata[apiconfig.outputvalue]}</div>
                    </div>
                  </>
                );
              });
            })}
          {ShowData &&
            ShowData.Branches.map((userdata, index) => {
              let getapiconfig = JSON.parse(payload_config.get_api_mapping);
              let splitrowdata = getapiconfig[0].tabledata[0].tablevalue.split(
                "."
              );
              let splitdata = splitrowdata[0];
              return (
                <>
                  <div className="form-group col-md-12">
                    <table className="table table-hove text-center">
                      <thead>
                        <tr>
                          {getapiconfig[0].tabledata.length > 0 &&
                            getapiconfig[0].tabledata.map((tabledata) => {
                              return <td>{tabledata.tablekey}</td>;
                            })}
                        </tr>
                      </thead>
                      <tbody>
                        {/* {getapiconfig[0].tabledata.length > 0 &&
                          getapiconfig[0].tabledata.map((tabledata, i) => {
                            let splitdata = tabledata.tablevalue.split(".");
                            //console.log("i", splitdata);
                            return (
                              <tr>
                                {userdata[splitdata[0]].map(
                                  (lastransaction) => {
                                    return (
                                      <td>{lastransaction[splitdata[1]]}</td>
                                    );
                                  }
                                )}
                              </tr>
                            );
                          })} */}
                        {getapiconfig[0].tabledata.length > 0 &&
                          userdata[splitdata].map((split, i) => {
                            //let splitdata = tabledata.tablevalue.split(".");
                            //console.log("i", splitdata);
                            return (
                              <tr key={i}>
                                {getapiconfig[0].tabledata.map(
                                  (lastransaction) => {
                                    let tablevalue = lastransaction.tablevalue.split(
                                      "."
                                    );
                                    return <td>{split[tablevalue[1]]}</td>;
                                  }
                                )}
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </>
              );
            })}
        </div>
      </Modal>
      {currentData &&
        Array.isArray(currentData) &&
        currentData.map((item, indexid) => {
          console.log("lastArrayName", currentData);

          return (
            <tr key={rowindex}>
              {payload_config.field_mapping.map((cell, tdindex) => {
                return (
                  <>
                    {console.log("item", cell["BucketField"])}
                    {cell["isVisible"] == "1" && (
                      <td key={tdindex}>
                        {edit && cell["isEdit"] === "1" ? (
                          cell["datatype"] === "DCB" ? (
                            <TextArea
                              autoSize={true}
                              readOnly={!edit}
                              bordered={edit}
                              value={
                                edit
                                  ? converttoarray(
                                      cell["BucketField"],
                                      updatedPayload,
                                      indexid
                                    )
                                  : // :
                                    // cell["datatype"] === "boolean"
                                    // ? converttoarray(cell["BucketField"], payload) == 1
                                    //   ? "Yes"
                                    //   : "No"
                                    converttoarray(
                                      cell["BucketField"],
                                      payload,
                                      indexid
                                    ) &&
                                    JSON.stringify(
                                      converttoarray(
                                        cell["BucketField"],
                                        payload,
                                        indexid
                                      )
                                    ).replace(/\"/g, "")
                              }
                              onChange={(event) => {
                                handlePayloadChange(
                                  event.target.value,
                                  cell["BucketField"]
                                );
                              }}
                            />
                          ) : cell["datatype"] === "jsondata" ? (
                            <>
                              <a>{cell["BucketField"]}</a>
                            </>
                          ) : cell["datatype"] === "dropdownlist" ? (
                            <>
                              <Dropdownlist
                                value={
                                  converttoarray(
                                    cell["BucketField"],
                                    payload,
                                    indexid
                                  ) &&
                                  JSON.stringify(
                                    converttoarray(
                                      cell["BucketField"],
                                      payload,
                                      indexid
                                    )
                                  ).replace(/\"/g, "")
                                }
                                bucketfield={cell["BucketField"]}
                                isedit={true}
                              />
                            </>
                          ) : cell["datatype"] === "boolean" ? (
                            <select
                              className="form-control"
                              value={converttoarray(
                                cell["BucketField"],
                                updatedPayload,
                                indexid
                              )}
                              onChange={(event) => {
                                handlePayloadChange(
                                  event.target.value,
                                  cell["BucketField"]
                                );
                              }}
                            >
                              <option value="">Select</option>
                              <option value="1">Yes</option>
                              <option value="0">No</option>
                            </select>
                          ) : cell["datatype"] === "textbox" ? (
                            <TextArea
                              autoSize={true}
                              readOnly={!edit}
                              bordered={edit}
                              value={
                                edit
                                  ? converttoarray(
                                      cell["BucketField"],
                                      updatedPayload,
                                      indexid
                                    )
                                  : // :
                                    // cell["datatype"] === "boolean"
                                    // ? converttoarray(cell["BucketField"], payload) == 1
                                    //   ? "Yes"
                                    //   : "No"
                                    converttoarray(
                                      cell["BucketField"],
                                      payload,
                                      indexid
                                    ) &&
                                    JSON.stringify(
                                      converttoarray(
                                        cell["BucketField"],
                                        payload,
                                        indexid
                                      )
                                    ).replace(/\"/g, "")
                              }
                              onChange={(event) => {
                                handlePayloadChange(
                                  event.target.value,
                                  cell["BucketField"]
                                );
                              }}
                            />
                          ) : (
                            cell["datatype"] === "date" && (
                              <input
                                type="date"
                                value={
                                  edit
                                    ? moment(
                                        converttoarray(
                                          cell["BucketField"],
                                          updatedPayload,
                                          indexid
                                        )
                                      ).format("YYYY-MM-DD")
                                    : // :
                                      // cell["datatype"] === "boolean"
                                      // ? converttoarray(cell["BucketField"], payload) == 1
                                      //   ? "Yes"
                                      //   : "No"
                                      converttoarray(
                                        cell["BucketField"],
                                        payload,
                                        indexid
                                      ) &&
                                      JSON.stringify(
                                        converttoarray(
                                          cell["BucketField"],
                                          payload,
                                          indexid
                                        )
                                      ).replace(/\"/g, "")
                                }
                                onChange={(event) => {
                                  handlePayloadChange(
                                    event.target.value,
                                    cell["BucketField"]
                                  );
                                }}
                              />
                            )
                          )
                        ) : (
                          // <TextArea
                          //   autoSize
                          //   readOnly={true}
                          //   bordered={false}
                          //   //bordered={edit}
                          //   value={JSON.stringify(
                          //     converttoarray(cell["BucketField"], payload)
                          //   )}
                          // />
                          <span>
                            {cell["datatype"] === "DCB" ? (
                              <a
                                href="javascript:void(0)"
                                onClick={() =>
                                  viewFamily(
                                    converttoarray(
                                      "data.household_id",
                                      payload,
                                      indexid
                                    )
                                  )
                                }
                              >
                                {converttoarray(
                                  cell["BucketField"],
                                  payload,
                                  indexid
                                ) &&
                                  JSON.stringify(
                                    converttoarray(
                                      cell["BucketField"],
                                      payload,
                                      indexid
                                    )
                                  ).replace(/\"/g, "")}
                              </a>
                            ) : cell["datatype"] === "jsondata" ? (
                              <>
                                <a
                                  className="text-primary"
                                  onClick={() => {
                                    setisPayloadView(true);
                                    setPayloadViewData(
                                      converttoarray(
                                        cell["BucketField"],
                                        payload,
                                        indexid
                                      ) &&
                                        JSON.stringify(
                                          converttoarray(
                                            cell["BucketField"],
                                            payload,
                                            indexid
                                          )
                                        )
                                    );
                                  }}
                                >
                                  {cell["BucketField"].split(".").pop()}
                                </a>
                              </>
                            ) : cell["datatype"] === "boolean" ? (
                              converttoarray(
                                cell["BucketField"],
                                payload,
                                indexid
                              ) &&
                              JSON.stringify(
                                converttoarray(
                                  cell["BucketField"],
                                  payload,
                                  indexid
                                )
                              ).replace(/\"/g, "") == 1 ? (
                                "Yes"
                              ) : (
                                "No"
                              )
                            ) : cell["datatype"] === "dropdownlist" ? (
                              <>
                                <Dropdownlist
                                  value={
                                    converttoarray(
                                      cell["BucketField"],
                                      payload,
                                      indexid
                                    ) &&
                                    JSON.stringify(
                                      converttoarray(
                                        cell["BucketField"],
                                        payload,
                                        indexid
                                      )
                                    ).replace(/\"/g, "")
                                  }
                                  bucketfield={cell["BucketField"]}
                                  isedit={false}
                                />
                              </>
                            ) : cell["datatype"] === "image" ? (
                              <>
                                <button
                                  className="btn btn-primary"
                                  onClick={(e) =>
                                    viewImage(
                                      converttoarray(
                                        cell["BucketField"],
                                        payload,
                                        indexid
                                      )
                                    )
                                  }
                                >
                                  View
                                </button>
                              </>
                            ) : cell["addapi"] == 1 ? (
                              <>
                                <span
                                  className="payload-openmodal"
                                  onClick={() =>
                                    showdata(
                                      cell["selectapi"],
                                      cell["tabledata"]
                                    )
                                  }
                                >
                                  {converttoarray(
                                    cell["BucketField"],
                                    payload,
                                    indexid
                                  ) &&
                                    JSON.stringify(
                                      converttoarray(
                                        cell["BucketField"],
                                        payload,
                                        indexid
                                      )
                                    ).replace(/\"/g, "")}
                                </span>
                              </>
                            ) : (
                              converttoarray(
                                cell["BucketField"],
                                payload,
                                indexid
                              ) &&
                              JSON.stringify(
                                converttoarray(
                                  cell["BucketField"],
                                  payload,
                                  indexid
                                )
                              ).replace(/\"/g, "")
                            )}
                            {/* {converttoarray(
                        "payload.payment.entity.notes.notes_key_2",
                        payload
                      )} */}
                          </span>
                        )}
                      </td>
                    )}
                  </>
                );
              })}
              <td>{moment(moment.unix(payload["created_at"])).fromNow()}</td>
              <td>
                <div className="payload_view_table_actions">
                  <Actions
                    disabled={disabled}
                    defaultValue=""
                    payload_config={payload_config}
                    actionHandler={actionHandler}
                    editHandler={editHandler}
                    payload={payload}
                    edit={edit}
                    setEdit={setEdit}
                    getPayloads={getPayloads}
                    DeletePayload={DeletePayload}
                    DeleteMongoPayload={DeleteMongoPayload}
                    updatedPayload={updatedPayload}
                    effective_designation_id={effective_designation_id}
                  />
                </div>
              </td>
            </tr>
          );
        })}
    </>
  );
}
function PaymentData({ payment }) {
  return (
    <tr>
      {Object.entries(payment.entity).map(([key, value]) => (
        <td key={key}>{value}</td>
      ))}
    </tr>
  );
}
function Actions({
  actionHandler,
  payload_config,
  payload,
  disabled,
  edit,
  setEdit,
  editHandler,
  getPayloads,
  DeletePayload,
  DeleteMongoPayload,
  updatedPayload,
  effective_designation_id,
}) {
  const [actionLoading, setActionLoading] = useState(false);
  const [allowClear, setAllowClear] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [actionValue, setactionValue] = useState();
  const [isOpenModal, setisOpenModal] = useState(false);
  const [ModalForm, setModalForm] = useState(false);
  const [PayloadRequired, setPayloadRequired] = useState();

  const handleCancel = () => {
    setisOpenModal(false);
  };
  const validatepayloaddata = () => {
    let isValid = true;
    const list = [...PayloadRequired];
    //console.log("list", list);
    PayloadRequired.map((bucket_field, index) => {
      //let splitdata_bucket_field = bucket_field.field.split(".");
      let splitdata = bucket_field.field.split(".");
      let getvalue = splitdata.reduce((obj, key) => obj[key], updatedPayload);
      if (!getvalue) {
        isValid = false;
        list[index]["isError"] = "1";
      }
    });
    setPayloadRequired(list);

    return isValid;
  };
  const chkpayloadrequired = () => {
    ////console.log("payload_config.field_mapping", payload_config.field_mapping);
    let requiredfields = [];
    payload_config.field_mapping.map((field) => {
      if (field.isRequired === "1") {
        requiredfields.push({
          fieldName: field.fieldname,
          field: field.BucketField,
          isError: "",
        });
      }
    });

    setPayloadRequired(requiredfields);
  };
  const generate_modaldata = (action_id, payload_data) => {
    const action = payload_config.action_mapping.filter((item) => {
      return item.id == action_id;
    })[0];

    const url = action.api_url;
    // let payload = null;
    // let method = null;
    if (action["formdata"]) {
      let form = [];
      for (var k = 0; k < payload_config.field_mapping.length; k++) {
        for (var l = 0; l < action.formdata.length; l++) {
          var exists = false;
          if (
            payload_config.field_mapping[k].fieldname ===
            action.formdata[l].formdatavalue
          ) {
            console.log(
              "action->",
              payload_config.field_mapping[k].BucketField
            );
            console.log("payload_data->", payload_data);
            let splitdaTa = payload_config.field_mapping[k].BucketField.split(
              "."
            );
            let searchdata = "";
            // if (splitdaTa.length > 0) {
            //   for (let index = 0; index < splitdaTa.length; index++) {
            //     searchdata += '["' + splitdaTa[index] + '"]';
            //   }
            // }
            let result = payload_data;
            for (let key of splitdaTa) {
              result = result[key];
            }
            //console.log("field", action.formdata[l].formdatakey);
            //console.log("value", action.formdata);

            form.push({
              field: action.formdata[l].formdatakey,
              //value: payload_data[action.formdata[l].formdatakey],
              value: result,
              isRequired: payload_config.field_mapping[k].isRequired,
              datatype: payload_config.field_mapping[k].datatype,
              isEdit: payload_config.field_mapping[k].isEdit,
              fieldlabel: payload_config.field_mapping[k].fieldname,
              isError: "",
            });
            break;
          }
        }
      }
      console.log("form-->", form);
      setModalForm(form);
      // payload = form;
      // method = "POST";
    }
  };
  const validateForm = (ModalForm) => {
    let isValid = true;
    ModalForm.map((field, index) => {
      const list = [...ModalForm];
      if (field.isRequired == "1") {
        if (!field.value) {
          isValid = false;
          list[index]["isError"] = "1";
        }
      } else {
        list[index]["isError"] = "";
      }
      setModalForm(list);
    });
    return isValid;
  };
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...ModalForm];
    list[index]["value"] = value;
    setModalForm(list);
  };
  useEffect(() => {
    if (actionValue) {
      setisOpenModal(true);
    }
  }, [actionValue]);
  return (
    <>
      <Modal
        //title="Title"
        open={isOpenModal}
        onOk={() => {
          let validation = validateForm(ModalForm);

          if (validation) {
            actionHandler(actionValue, ModalForm);
            setisOpenModal(false);
          }
        }}
        // confirmLoading={confirmLoading}
        onCancel={handleCancel}
        width={1000}
      >
        <div className="row">
          {ModalForm &&
            ModalForm.map((field, index) => (
              <>
                <div className="col-3">
                  <label>{field.fieldlabel}</label>
                  {field.datatype === "textbox" ? (
                    <>
                      <input
                        required={field.isRequired == 1}
                        className="form-control"
                        readOnly={field.isEdit == 0}
                        name={field.field}
                        value={field.value}
                        placeholder={field.fieldlabel}
                        onChange={(e) => handleInputChange(e, index)}
                      />
                    </>
                  ) : field.datatype === "boolean" ? (
                    <select
                      className="form-control"
                      value={field.value}
                      disabled={field.isEdit == 0}
                      onChange={(e) => handleInputChange(e, index)}
                    >
                      <option value="">Select</option>
                      <option value="1">Yes</option>
                      <option value="0">No</option>
                    </select>
                  ) : field.datatype === "date" ? (
                    <input
                      type="date"
                      required={field.isRequired == 1}
                      className="form-control"
                      readOnly={field.isEdit == 0}
                      name={field.field}
                      value={
                        field.value
                          ? moment(field.value).format("YYYY-MM-DD")
                          : ""
                      }
                      placeholder={field.field}
                      onChange={(e) => handleInputChange(e, index)}
                    />
                  ) : (
                    <input
                      required={field.isRequired == 1}
                      className="form-control"
                      readOnly={field.isEdit == 0}
                      name={field.field}
                      value={field.value}
                      placeholder={field.fieldlabel}
                      onChange={(e) => handleInputChange(e, index)}
                    />
                  )}
                  {field.isError === "1" && (
                    <span className="errmsg">
                      {field.fieldlabel} is required
                    </span>
                  )}
                </div>
              </>
            ))}
        </div>
      </Modal>

      {payload_config.action_mapping[0].action_name &&
      effective_designation_id != "1" ? (
        <Select
          placeholder="Action"
          style={{
            width: 120,
          }}
          disabled={disabled}
          loading={actionLoading}
          options={payload_config.action_mapping.map((action) => {
            return {
              label: action.action_name,
              value: action.id,
            };
          })}
          allowClear={allowClear}
          onChange={(value) => {
            setactionValue(value);
            generate_modaldata(value, payload);
          }}
        />
      ) : (
        ""
      )}
      {!edit && (
        <Button
          type="default"
          onClick={() => {
            chkpayloadrequired();
            setEdit(true);
          }}
          icon={<EditOutlined />}
        />
      )}
      {edit && !editLoading ? (
        <Button
          type="default"
          onClick={() => {
            setEdit(false);
          }}
          icon={<CloseOutlined />}
        />
      ) : (
        ""
      )}
      {edit ? (
        <Button
          type="default"
          loading={editLoading}
          icon={<CheckOutlined />}
          onClick={
            // () => {
            // let validatepayload = validatepayloaddata();
            // if (validatepayload) {
            async () => {
              let validatepayload = validatepayloaddata();

              if (validatepayload) {
                setEditLoading(true);
                let response = await editHandler(
                  payload_config["bucket_name"],
                  payload["key"]
                );
                setEditLoading(false);
                if (response === true) {
                  setEdit(false);
                  getPayloads();
                } else {
                  setEdit(true);
                }
              } else {
                //console.log("PayloadRequired", PayloadRequired);
              }
            }
            //}
            //}
          }
        />
      ) : (
        ""
      )}

      {effective_designation_id == 21 &&
        (payload_config.payload_type == "M" ? (
          <Button
            type="default"
            onClick={() => {
              if (window.confirm("Delete the item?")) {
                DeleteMongoPayload(payload._id.$oid);
              }
            }}
            icon={<DeleteOutlined />}
          />
        ) : (
          <Button
            type="default"
            onClick={() => {
              if (window.confirm("Delete the item?")) {
                DeletePayload(payload.key);
              }
            }}
            icon={<DeleteOutlined />}
          />
        ))}
    </>
  );
}
