import React, { Component, useState } from "react";
import customFetch from "./apicall/api";
import { withRouter } from "react-router";
import { useHistory } from "react-router-dom";
import moment from "moment";
import "antd/dist/antd.css";
import { Switch } from "antd";
import { logout, chkapiinput, generateId } from "./Utility";
import "../assets/css/BankRegisterNACH.css";
import { ThemeContext } from "../context/IsLoader";
import { PEButton, PEDiv, PEImg, PEInput, PEA, PESpan, PETextArea, PELI } from "./permissionComponents";
const generate_Id1 = generateId(10);
export default class UTR extends Component {
  static contextType = ThemeContext;
  constructor(props) {
    super(props);

    this.state = {
      isVisible: "",
      // incorrect_mobile_number: 0,
      // unable_to_reach: 0,
      //preferred_date: new Date(),
      call_duration: 0,
      preferred_mode: "",
      status: 1,
      nach_flag: props.location.state.nach_flag,
      isDisable: true,
      isErrOpen: false,
      api_error_message: "",
      isOpen: false,
      nach_mapid: "",
      utrdata: [],
      data: [],
      isSpin: false,
      flag: 0,
      NACH_form_upload: "",
      ISNACH_form_upload: false,
      pbutton: false,
      nbutton: false,
      dbutton: false,
      isOpenNach: false,
      vendor_id: 2,
      IPv4: null,
      latitude: null,
      longitude: null,
      search_text: "",
      og_data: [],
    };
    // this.onSubmitHandler = this.onSubmitHandler.bind(this);
  }

  componentDidMount() {
    if (
      window.Android &&
      typeof window.Android.getDataFromLocalStorage === "function"
    ) {
      const data = window.Android.getDataFromLocalStorage("your_key");
      if (data) {
        this.setState({ isSpin: false, flag: 1 }); // Set flag to 1 if accessed from Android
        const tokenParts = data.split(",");
        const tokenData = {};

        tokenParts.forEach((part) => {
          const [key, value] = part.split(":");
          tokenData[key] = value;
        });

        console.log("AndroidInterface->", data);
      }
    } else {
      console.log(
        "Error in AndroidInterface: Android method not found or not available."
      );
    }
    // const { Preferred_pay_date } =
    // (this.props.location && this.props.location.state) || {};
    setTimeout(() => {
  
      if (document.readyState === "complete") {
        this.context.onPageLoad({
          component: this.constructor.name,
          component_id: generate_Id1,
        });
      } else {
        window.addEventListener("load", this.context.onPageLoad());
        this.cleanup = () =>
          window.removeEventListener("load", this.context.onPageLoad());
      }
    }, 1000);
    const uid = localStorage.getItem("in_userid");

    fetch("https://geolocation-db.com/json/")
      .then((response) => {
        return response.json();
      }, "jsonp")
      .then((res) => {
        console.log(res);
        this.setState({
          IPv4: res.IPv4,
          latitude: res.latitude,
          longitude: res.longitude,
        });
      })
      .catch((err) => console.log(err));

    // console.log("Superman ID: " + uid);
    // if (!uid) {
    //   localStorage.clear();
    //   this.props.history.push("/");
    // }
    // if (Preferred_pay_date !== "" || Preferred_pay_date !== null) {
    //   this.setState({
    //     preferred_date: moment(Preferred_pay_date).format("YYYY-MM-DD"),
    //   });
    // }
    // this.setState({
    //   'currentDate': this.getDate(new Date(Date.now()))
    // });
    this.getUTRData();
  }

  getUTRData() {
    this.state.utrdata = [];
    const uid = 1;

    this.setState({ isSpin: true });
    const { api_token } =
      (this.props.location && this.props.location.state) || {};

    const formdata = new FormData();
    formdata.append("userid", uid);
    let generate_Id = generateId(10);
    customFetch(process.env.REACT_APP_URL + "/utr/?userid=" + uid, {
      method: "GET",
      headers: {
        "api-token": api_token,
        component_id: generate_Id1,
        apiId: generate_Id,
        api_group_id: "",
        component: this.constructor.name,
        user_id: localStorage.getItem("in_userid"),
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        console.log("utr_data - ", json);
        if (json.api_code === 4 || json.api_code === 3 || json.api_code === 5) {
          localStorage.clear();
          this.props.history.push("/");
        }
        this.setState({
          isSpin: false,
          utrdata: json,
          og_data: json,
        });
      })
      .catch((error) => console.log("error", error));
  }

  handleSearchInput = (e) => {
    this.setState({ search_text: e.target.value.toUpperCase() });
    console.log(this.state.search_text);
    if (this.state.search_text == "") {
      this.state.utrdata = this.state.og_data;
    }
    this.forceUpdate();
  };

  onSearch = () => {
    console.log(this.state.search_text);

    this.state.utrdata = [];
    const uid = 1;

    this.setState({ isSpin: true });
    const { api_token } =
      (this.props.location && this.props.location.state) || {};

    const formdata = new FormData();
    formdata.append("userid", uid);
    let generate_Id = generateId(10);
    customFetch(
      process.env.REACT_APP_URL +
        "/utr/?userid=" +
        uid +
        "&search_query=" +
        this.state.search_text,
      {
        method: "GET",
        headers: {
          "api-token": api_token,
          component_id: generate_Id1,
          apiId: generate_Id,
          api_group_id: "",
          component: this.constructor.name,
          user_id: localStorage.getItem("in_userid"),
        },
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        console.log("utr_data - ", json);
        if (json.api_code === 4 || json.api_code === 3 || json.api_code === 5) {
          localStorage.clear();
          this.props.history.push("/");
        }
        this.setState({
          isSpin: false,
          utrdata: json,
          og_data: json,
        });
      })
      .catch((error) => console.log("error", error));

    // let temp = this.state.og_data;

    // if (this.state.search_text != "") {
    //   let sort_data = this.state.utrdata.filter((i) => {
    //     return ((i.household_number.includes(this.state.search_text) || i.household_name.includes(this.state.search_text)) ? i : "");
    //   });
    //   console.log(sort_data);
    //   this.state.utrdata = sort_data;
    // } else {
    //   this.state.utrdata = this.state.og_data;
    // }

    // this.forceUpdate();
  };

  render() {
    const { utrdata, data, isErrOpen, message, isOpenNach } = this.state;
    // console.log(this.state.data);
    return (
      <>
        {this.state.isSpin ? (
          <div class="preloader">
            <div class="loader" ng-hide="data.length > 0"></div>
          </div>
        ) : (
          ""
        )}

        <div
          className={
            isErrOpen
              ? "modal fade bd-example-modal-sm show mymodal"
              : "modal fade bd-example-modal-sm"
          }
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="confirm-box"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content cdb-confirm-box col-sm-12">
              <button
                type="button"
                className="close text-right"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => this.setState({ isErrOpen: false })}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <p>{message}</p>
            </div>
          </div>
        </div>

        <div className="call_detail container d-flex flex-column align-items-center">
        {this.state.flag === 0 && (
          <div className="ca-header-box">
            <div className="collection_block_header">
              <div className="title-holder_bg">
                <div
                  className="back_icon_bg"
                  onClick={() => this.props.history.goBack()}
                >
                  <img
                    src={require("../assets/images/back.png")}
                    className="back_icon_img"
                  />
                </div>
                <h2>UTR</h2>
                <div className="log-out">
                  <img
                    src={require("../assets/images/power-off.png")}
                    onClick={() => logout()}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
          )}

          <br />
          <PEDiv element_id="UTRSearch" class="input-group">
            <input
              type="text"
              class="form-control"
              value={this.state.search_text}
              onChange={this.handleSearchInput}
              placeholder="Search Household"
            />
            <button
              class="btn btn-primary"
              type="button"
              onClick={this.onSearch}
            >
              Search
            </button>
          </PEDiv>
          <br />

          <table className="table table-striped utrTable">
            <thead>
              <tr>
                <th scope="col">Loan ID</th>
                <th scope="col">Beneficiary AC No.</th>
                <th scope="col">IFSC Code.</th>
                <th scope="col">UTR No.</th>
                <th scope="col">Disburse Date</th>
                <th scope="col">Amount</th>
              </tr>
            </thead>
            <tbody>
              {utrdata.map((item, key) => {
                return (
                  <tr>
                    <td>{item.loan_id}</td>
                    <td>{item.account_number}</td>
                    <td>{item.ifsc}</td>
                    <td>{item.utr}</td>
                    <td>
                      {moment(item.remittance_date).format("DD MMM YYYY")}
                    </td>
                    <td>Rs. {parseInt(item.amount).toLocaleString("en-IN")}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </>
    );
  }
}
