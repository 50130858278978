import React, { Component } from "react";
import customFetch from "./apicall/api";
import { withRouter } from "react-router";
import ChatSupportBtn from "./ChatSupportBtn";
import { logout, chkapiinput, generateId } from "./Utility";
import { ThemeContext } from "../context/IsLoader";
import {
  PEButton,
  PEDiv,
  PEImg,
  PEInput,
  PEA,
  PESpan,
} from "./permissionComponents";
const generate_Id1 = generateId(10);
class Cso_transaction_history extends Component {
  static contextType = ThemeContext;
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      items_bkp: [],
      Category: "",
      Filter: "",
      Month: "",
      sort_data: [],
      FilterData: [],
      MonthData: [],
      CategoryData: [],
      Merge: [],
      temp_array: [],
      reversed: [],
      farray: [],
      showDetailHistory: false,
      DetailHistory: [],
      isSpin: false,
      flag: 0,
    };
    this.showDetail = this.showDetail.bind(this);
  }
  showDetail(cbid) {
    this.setState({ isSpin: true });
    const { api_token } =
      (this.props.location && this.props.location.state) || {};
    /*Check if data is null then logout*/
    var arr = [cbid];
    var chkdata = chkapiinput(arr);
    if (chkdata === false) {
      this.setState({ isSpin: false });
      setTimeout(function() {
        logout();
      }, 2000);
    }
    // AT-2143 | AT-2179 | Ankur Makavana | 13-07-2023 | conver post method to get method
    const params = {
      cash_balance_id: cbid,
    };
    const queryParams = new URLSearchParams(params).toString();
    let generate_Id = generateId(10);
    customFetch(
      process.env.REACT_APP_URL + "/review/denominations?" + queryParams,
      {
        method: "GET",
        headers: {
          "api-token": api_token,
          component_id: generate_Id1,
          apiId: generate_Id,
          api_group_id: "",
          component: this.constructor.name,
          user_id: localStorage.getItem("in_userid"),
        },
      }
    )
      .then((res) => {
        // if (res.headers.get("api_code") > 0) {
        //   this.setState({
        //     isErrOpen: true,
        //     api_error_message: res.headers.get("api_error_message"),
        //   });
        // }
        return res.json();
      })
      .then((data) => {
        if (data.api_code === 4 || data.api_code === 3 || data.api_code === 5) {
          localStorage.clear();
          this.props.history.push("/");
        }
        this.setState({
          isSpin: false,
          DetailHistory: data,
          showDetailHistory: true,
        });
      })
      .catch((error) => console.log("error", error));
  }
  componentDidMount() {
    if (
      window.Android &&
      typeof window.Android.getDataFromLocalStorage === "function"
    ) {
      const data = window.Android.getDataFromLocalStorage("your_key");
      if (data) {
        this.setState({ isSpin: false, flag: 1 }); // Set flag to 1 if accessed from Android
        const tokenParts = data.split(",");
        const tokenData = {};

        tokenParts.forEach((part) => {
          const [key, value] = part.split(":");
          tokenData[key] = value;
        });

        console.log("AndroidInterface->", data);
      }
    } else {
      console.log(
        "Error in AndroidInterface: Android method not found or not available."
      );
    }

    document.title = "CSO Transaction History";
    setTimeout(() => {
      if (document.readyState === "complete") {
        this.context.onPageLoad({
          component: this.constructor.name,
          component_id: generate_Id1,
        });
      } else {
        window.addEventListener("load", this.context.onPageLoad());
        this.cleanup = () =>
          window.removeEventListener("load", this.context.onPageLoad());
      }
    }, 1000);
    this.setState({ isSpin: true });
    const { api_token } =
      (this.props.location && this.props.location.state) || {};
    console.log("Token", api_token);
    const uid = localStorage.getItem("in_userid");
    if (!uid) {
      localStorage.clear();
      this.props.history.push("/");
    }
    /*Check if data is null then logout*/
    var arr = [uid];
    var chkdata = chkapiinput(arr);
    if (chkdata === false) {
      this.setState({ isSpin: false });
      setTimeout(function() {
        logout();
      }, 2000);
    }
    const params = {
      in_userid: uid,
    };
    const queryParams = new URLSearchParams(params).toString();
    let generate_Id = generateId(10);
    customFetch(
      process.env.REACT_APP_URL + "/get/transaction/history?" + queryParams,
      {
        method: "GET",
        headers: {
          "api-token": api_token,
          component_id: generate_Id1,
          apiId: generate_Id,
          api_group_id: "",
          component: this.constructor.name,
          user_id: localStorage.getItem("in_userid"),
        },
      }
    )
      .then((res) => {
        if (res.headers.get("api_code") > 0) {
          this.setState({
            isErrOpen: true,
            api_error_message: res.headers.get("api_error_message"),
            isSpin: false,
          });
        }
        return res.json();
      })
      .then((data) => {
        if (data.api_code === 4 || data.api_code === 3 || data.api_code === 5) {
          localStorage.clear();
          this.props.history.push("/");
        }
        console.log(data);
        this.setState({
          isSpin: false,
          items: data,
          items_bkp: data,
        });
      })
      .catch((error) => console.log("error", error));
  }

  updateCategory(e) {
    this.setState({
      Category: e.target.value,
    });
    // this.setState({
    //   Category: e.target.value,
    // });
    this.setState({ Category: e.target.value }, () => {
      console.log(this.state.Category, "dealersOverallTotal1");
      this.filterArray();
    });
    // if (e.target.value === "") {
    //   this.state.items = this.state.items_bkp;
    // } else {
    //   if (e.target.value === "cutocso" || e.target.value === "tocsototo") {
    //     //console.log("Cu to CSO select");
    //     this.state.items = this.state.items_bkp;
    //     for (let index = 0; index < this.state.items.length; index++) {
    //       if (this.state.items[index].txn_type === "C") {
    //         this.state.sort_data.push(this.state.items[index]);
    //       }
    //     }
    //     this.state.CategoryData = this.state.sort_data;
    //   }
    //   if (e.target.value === "csototo" || e.target.value === "totobank") {
    //     //console.log("Cu to CSO select");
    //     this.state.items = this.state.items_bkp;
    //     for (let index = 0; index < this.state.items.length; index++) {
    //       if (this.state.items[index].txn_type === "D") {
    //         this.state.sort_data.push(this.state.items[index]);
    //       }
    //     }
    //     this.state.CategoryData = this.state.sort_data;
    //   }
    // }
    //this.filterArray();
  }
  updateFilter(e) {
    //this.state.sort_data = [];
    this.setState({ Filter: e.target.value }, () => {
      console.log(this.state.Filter, "dealersOverallTotal1");
      this.filterArray();
    });
    // if (e.target.value === "") {
    //   this.state.items = this.state.items_bkp;
    // } else {
    //   if (e.target.value === "Saved") {
    //     //console.log("Cu to CSO select");
    //     this.state.items = this.state.items_bkp;
    //     for (let index = 0; index < this.state.items.length; index++) {
    //       if (this.state.items[index].status === 1) {
    //         this.state.sort_data.push(this.state.items[index]);
    //       }
    //     }
    //     this.state.FilterData = this.state.sort_data;
    //   }
    //   if (e.target.value === "Submitted") {
    //     //console.log("Cu to CSO select");
    //     this.state.items = this.state.items_bkp;
    //     for (let index = 0; index < this.state.items.length; index++) {
    //       if (this.state.items[index].status === 2) {
    //         this.state.sort_data.push(this.state.items[index]);
    //       }
    //     }
    //     this.state.FilterData = this.state.sort_data;
    //   }
    //   if (e.target.value === "Approved") {

    //     //console.log("Cu to CSO select");
    //     this.state.items = this.state.items_bkp;
    //     for (let index = 0; index < this.state.items.length; index++) {
    //       if (this.state.items[index].status === 3) {
    //         this.state.sort_data.push(this.state.items[index]);
    //       }
    //     }
    //     this.state.FilterData = this.state.sort_data;
    //   }
    //   if (e.target.value === "Rejected") {
    //     //console.log("Cu to CSO select");
    //     this.state.items = this.state.items_bkp;
    //     for (let index = 0; index < this.state.items.length; index++) {
    //       if (this.state.items[index].status === 4) {
    //         this.state.sort_data.push(this.state.items[index]);
    //       }
    //     }
    //     this.state.FilterData = this.state.sort_data;
    //   }
    // }
    //this.filterArray('Filter',e.target.value);
  }

  updateMonth(e) {
    this.setState({
      Month: e.target.value,
    });

    // console.log(e.target.value);
    // this.setState({
    //   Month: e.target.value,
    // });
    this.setState({ Month: e.target.value }, () => {
      // console.log(this.state.Month, "dealersOverallTotal1");
      this.filterArray();
    });
    // if (e.target.value === "") {
    //   this.state.items = this.state.items_bkp;
    // } else {
    //   //if(e.target.value==='Jan'){
    //   //console.log("Cu to CSO select");
    //   this.state.items = this.state.items_bkp;
    //   for (let index = 0; index < this.state.items.length; index++) {
    //     if (
    //       this.state.items[index].submitted_at.split(" ")[2] === e.target.value
    //     ) {
    //       this.state.sort_data.push(this.state.items[index]);
    //     }
    //     //console.log(this.state.items[index].submitted_at.split(" ")[2]);
    //   }
    //   this.state.MonthData = this.state.sort_data;
    //   //}
    // }
    //this.filterArray();
  }
  filterArray() {
    if (this.state.Month != "") {
      this.state.sort_data = [];
      for (let i = 0; i < this.state.items.length; i++) {
        if (
          this.state.items[i].submitted_at &&
          this.state.items[i].submitted_at
            .split(" ")[2]
            .toLowerCase()
            .indexOf(this.state.Month.toLowerCase()) == 0
        ) {
          this.state.sort_data.push(this.state.items[i]);
        }
      }

      if (this.state.Category != "") {
        if (
          this.state.Category.toLowerCase().indexOf("cutocso") == 0 ||
          this.state.Category.toLowerCase().indexOf("tocsototo") == 0
        ) {
          for (let i = 0; i < this.state.sort_data.length; i++) {
            if (this.state.sort_data[i].txn_type === "C") {
              this.state.farray.push(this.state.sort_data[i]);
            }
          }
        }

        if (
          this.state.Category.toLowerCase().indexOf("csototo") == 0 ||
          this.state.Category.toLowerCase().indexOf("totobank") == 0
        ) {
          for (let i = 0; i < this.state.sort_data.length; i++) {
            if (this.state.sort_data[i].txn_type === "D") {
              this.state.farray.push(this.state.sort_data[i]);
            }
          }
        }

        if (this.state.Category.toLowerCase().indexOf("totofcm") == 0) {
          for (let i = 0; i < this.state.sort_data.length; i++) {
            if (this.state.sort_data[i].txn_type === "H") {
              this.state.farray.push(this.state.sort_data[i]);
            }
          }
        }

        this.state.sort_data = this.state.farray;
        this.state.farray = [];
      }

      if (this.state.Filter != "") {
        for (let i = 0; i < this.state.sort_data.length; i++) {
          if (this.state.sort_data[i].status == this.state.Filter) {
            this.state.farray.push(this.state.sort_data[i]);
          }
        }
        this.state.sort_data = this.state.farray;
        this.state.farray = [];
      }
    } else {
      if (this.state.Category != "") {
        this.state.sort_data = [];
        if (
          this.state.Category.toLowerCase().indexOf("cutocso") == 0 ||
          this.state.Category.toLowerCase().indexOf("tocsototo") == 0
        ) {
          for (let i = 0; i < this.state.items.length; i++) {
            if (this.state.items[i].txn_type === "C") {
              this.state.sort_data.push(this.state.items[i]);
            }
          }
        }

        if (
          this.state.Category.toLowerCase().indexOf("csototo") == 0 ||
          this.state.Category.toLowerCase().indexOf("totobank") == 0
        ) {
          for (let i = 0; i < this.state.items.length; i++) {
            if (this.state.items[i].txn_type === "D") {
              this.state.sort_data.push(this.state.items[i]);
            }
          }
        }

        if (this.state.Category.toLowerCase().indexOf("totofcm") == 0) {
          for (let i = 0; i < this.state.items.length; i++) {
            if (this.state.items[i].txn_type === "H") {
              this.state.sort_data.push(this.state.items[i]);
            }
          }
        }
      }

      if (this.state.Filter != "") {
        for (let i = 0; i < this.state.sort_data.length; i++) {
          if (this.state.sort_data[i].status == this.state.Filter) {
            this.state.farray.push(this.state.sort_data[i]);
          }
        }
        this.state.sort_data = this.state.farray;
        this.state.farray = [];
      }
    }

    if (this.state.Filter != "") {
      if (this.state.Month == "") {
        if (this.state.Category == "") {
          this.state.sort_data = [];
          for (let i = 0; i < this.state.items.length; i++) {
            if (this.state.items[i].status == this.state.Filter) {
              this.state.sort_data.push(this.state.items[i]);
            }
          }
        }
      }

      if (this.state.Month != "") {
        this.state.sort_data = [];

        for (let i = 0; i < this.state.items.length; i++) {
          if (
            this.state.items[i].submitted_at
              .split(" ")[2]
              .toLowerCase()
              .indexOf(this.state.Month.toLowerCase()) == 0
          ) {
            if (this.state.items[i].status == this.state.Filter) {
              this.state.sort_data.push(this.state.items[i]);
            }
          }
        }
      }

      if (this.state.Category != "") {
        if (
          this.state.Category.toLowerCase().indexOf("cutocso") == 0 ||
          this.state.Category.toLowerCase().indexOf("tocsototo") == 0
        ) {
          for (let i = 0; i < this.state.sort_data.length; i++) {
            if (this.state.sort_data[i].txn_type === "C") {
              this.state.farray.push(this.state.sort_data[i]);
            }
          }
        }

        if (
          this.state.Category.toLowerCase().indexOf("csototo") == 0 ||
          this.state.Category.toLowerCase().indexOf("totobank") == 0
        ) {
          for (let i = 0; i < this.state.sort_data.length; i++) {
            if (this.state.sort_data[i].txn_type === "D") {
              this.state.farray.push(this.state.sort_data[i]);
            }
          }
        }

        if (this.state.Category.toLowerCase().indexOf("totofcm") == 0) {
          for (let i = 0; i < this.state.sort_data.length; i++) {
            if (this.state.sort_data[i].txn_type === "H") {
              this.state.farray.push(this.state.sort_data[i]);
            }
          }
        }
        this.state.sort_data = this.state.farray;
        this.state.farray = [];
      }
    }

    this.state.items_bkp = this.state.sort_data.filter(
      (v, i, a) => a.indexOf(v) === i
    );
    // console.log(this.state.items_bkp);
    this.forceUpdate();

    // if (this.state.Month != "" || this.state.Category != "" || this.state.Filter != "") {

    //   if (this.state.Month != "") {
    //     this.state.sort_data = [];
    //     if (this.state.Category != "") {
    //       if (this.state.Category.toLowerCase().indexOf("cutocso") == 0) {
    //         for (let i = 0; i < this.state.items.length; i++) {
    //           if (this.state.items[i].txn_type === "C") {
    //             if (this.state.items[i].submitted_at.split(" ")[2].toLowerCase().indexOf(this.state.Month.toLowerCase()) == 0) {
    //               this.state.sort_data.push(this.state.items[i]);
    //             }
    //           }
    //         }
    //       }
    //       if (this.state.Category.toLowerCase().indexOf("csototo") == 0) {
    //         for (let i = 0; i < this.state.items.length; i++) {
    //           if (this.state.items[i].txn_type === "D") {
    //             if (this.state.items[i].submitted_at.split(" ")[2].toLowerCase().indexOf(this.state.Month.toLowerCase()) == 0) {
    //               this.state.sort_data.push(this.state.items[i]);
    //             }
    //           }
    //         }
    //       }
    //     } else {
    //       for (let i = 0; i < this.state.items.length; i++) {
    //         if (this.state.items[i].submitted_at.split(" ")[2].toLowerCase().indexOf(this.state.Month.toLowerCase()) == 0) {
    //           this.state.sort_data.push(this.state.items[i]);
    //         }
    //       }
    //     }

    //     if (this.state.Filter != "") {
    //       for (let i = 0; i < this.state.items.length; i++) {
    //         if (this.state.items[i].status == this.state.Filter) {
    //           if (this.state.items[i].submitted_at.split(" ")[2].toLowerCase().indexOf(this.state.Month.toLowerCase()) == 0) {
    //             this.state.sort_data.push(this.state.items[i]);
    //           }
    //         }
    //       }
    //     } else {
    //       for (let i = 0; i < this.state.items.length; i++) {
    //         if (this.state.items[i].submitted_at.split(" ")[2].toLowerCase().indexOf(this.state.Month.toLowerCase()) == 0) {
    //           this.state.sort_data.push(this.state.items[i]);
    //         }
    //       }
    //     }
    //   }

    //   if (this.state.Category != "") {
    //     this.state.sort_data = [];

    //     if (this.state.Month != "") {
    //       for (let i = 0; i < this.state.items.length; i++) {
    //         if (this.state.items[i].submitted_at.split(" ")[2].toLowerCase().indexOf(this.state.Month.toLowerCase()) == 0) {
    //           if (this.state.Category.toLowerCase().indexOf("cutocso") == 0) {

    //             if (this.state.items[i].txn_type === "C") {
    //               this.state.sort_data.push(this.state.items[i]);
    //             }
    //           }
    //           if (this.state.Category.toLowerCase().indexOf("csototo") == 0) {
    //             if (this.state.items[i].txn_type === "D") {
    //               this.state.sort_data.push(this.state.items[i]);
    //             }
    //           }

    //         }
    //       }
    //     } else {
    //       for (let i = 0; i < this.state.items.length; i++) {
    //         if (this.state.Category.toLowerCase().indexOf("cutocso") == 0) {

    //           if (this.state.items[i].txn_type === "C") {
    //             this.state.sort_data.push(this.state.items[i]);
    //           }
    //         }
    //         if (this.state.Category.toLowerCase().indexOf("csototo") == 0) {
    //           if (this.state.items[i].txn_type === "D") {
    //             this.state.sort_data.push(this.state.items[i]);
    //           }
    //         }

    //       }

    //     }

    //     if (this.state.Filter != "") {
    //       for (let i = 0; i < this.state.items.length; i++) {
    //         if (this.state.items[i].status == this.state.Filter) {
    //           if (this.state.Category.toLowerCase().indexOf("cutocso") == 0) {
    //             if (this.state.items[i].txn_type === "C") {
    //               this.state.sort_data.push(this.state.items[i]);
    //             }
    //           }
    //           if (this.state.Category.toLowerCase().indexOf("csototo") == 0) {
    //             if (this.state.items[i].txn_type === "D") {
    //               this.state.sort_data.push(this.state.items[i]);
    //             }
    //           }
    //         }
    //       }
    //     }else {
    //       for (let i = 0; i < this.state.items.length; i++) {
    //         if (this.state.Category.toLowerCase().indexOf("cutocso") == 0) {

    //           if (this.state.items[i].txn_type === "C") {
    //             this.state.sort_data.push(this.state.items[i]);
    //           }
    //         }
    //         if (this.state.Category.toLowerCase().indexOf("csototo") == 0) {
    //           if (this.state.items[i].txn_type === "D") {
    //             this.state.sort_data.push(this.state.items[i]);
    //           }
    //         }

    //       }

    //     }
    //   }
    //   if (this.state.Filter != "") {
    //     this.state.sort_data = [];
    //     if (this.state.Month != "") {
    //       if (this.state.Category != "") {
    //         for (let i = 0; i < this.state.items.length; i++) {
    //           if (this.state.items[i].submitted_at.split(" ")[2].toLowerCase().indexOf(this.state.Month.toLowerCase()) == 0) {
    //             if (this.state.Category.toLowerCase().indexOf("cutocso") == 0) {
    //               if (this.state.items[i].txn_type === "C") {
    //                 if (this.state.items[i].status == this.state.Filter) {
    //                   this.state.sort_data.push(this.state.items[i]);
    //                 }
    //               }
    //             }
    //             if (this.state.Category.toLowerCase().indexOf("csototo") == 0) {
    //               if (this.state.items[i].txn_type === "D") {
    //                 if (this.state.items[i].status == this.state.Filter) {
    //                   this.state.sort_data.push(this.state.items[i]);
    //                 }
    //               }
    //             }
    //           }
    //         }
    //       } else {
    //         for (let i = 0; i < this.state.items.length; i++) {
    //           if (this.state.items[i].submitted_at.split(" ")[2].toLowerCase().indexOf(this.state.Month.toLowerCase()) == 0) {
    //             if (this.state.items[i].status == this.state.Filter) {
    //               this.state.sort_data.push(this.state.items[i]);
    //             }
    //           }
    //         }
    //       }
    //     } else {
    //       for (let i = 0; i < this.state.items.length; i++) {
    //         if (this.state.items[i].status == this.state.Filter) {
    //           this.state.sort_data.push(this.state.items[i]);
    //         }
    //       }
    //     }
    //   }
    // } else {
    //   this.state.sort_data = this.state.items;
    // }

    // this.state.items = this.state.sort_data.filter((v, i, a) => a.indexOf(v) === i);
  }
  // logout() {
  //   localStorage.clear();
  //   this.props.history.push("/");
  // }
  render() {
    const {
      items,
      isErrOpen,
      api_error_message,
      showDetailHistory,
      DetailHistory,
    } = this.state;
    const { effective_designation_id } =
      (this.props.location && this.props.location.state) || {};
    let reversed = this.state.items_bkp.reverse();
    const e_designation_id = localStorage.getItem("e_designation_id");
    //const reversed = items.sort((a, b) => new Date(...a.submitted_at.split('/').reverse()) - new Date(...b.submitted_at.split('/').reverse()));
    return (
      <>
        {this.state.isSpin ? (
          <div class="preloader">
            <div class="loader" ng-hide="data.length > 0"></div>
          </div>
        ) : (
          ""
        )}
        <div
          className={
            showDetailHistory
              ? "modal fade bd-example-modal-sm show mymodal"
              : "modal fade bd-example-modal-sm"
          }
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="confirm-box"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content cdb-confirm-box col-sm-12">
              <button
                type="button"
                className="close text-right"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => this.setState({ showDetailHistory: false })}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <p>
                <>
                  {DetailHistory.length > 0 ? (
                    <div className="dn-container mt-3 pr-3">
                      <div className="dn-row d-flex flex-row justify-content-between align-items-center">
                        <div className="dn-row-box dn-header-text">Denom.</div>
                        <div className="dn-row-box dn-header-text">
                          Approved
                        </div>
                        <div className="dn-row-box dn-header-text">
                          Submitted
                        </div>
                      </div>
                      {DetailHistory.reverse().map((items) => (
                        <div className="dn-row d-flex flex-row justify-content-between align-items-center">
                          <div className="dn-row-box dn-gre-amt">
                            {items.currency_value}
                          </div>
                          <div className="dn-row-box dn-red-amt">
                            {items.qty_approved}
                          </div>
                          <div className="dn-row-box dn-pri-amt">
                            {items.qty_submitted}
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    "Data Not Found"
                  )}
                </>
              </p>
            </div>
          </div>
        </div>
        <div
          className={
            isErrOpen
              ? "modal fade bd-example-modal-sm show mymodal"
              : "modal fade bd-example-modal-sm"
          }
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="confirm-box"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content cdb-confirm-box col-sm-12">
              <button
                type="button"
                className="close text-right"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => this.setState({ isErrOpen: false })}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <p>{api_error_message}</p>
            </div>
          </div>
        </div>
        <section className="main-sec">
          <div className="container fix-width">
            <div className="">
              <div className="card-body">
                <div className="transaction-head-area">
                  <div
                    className="trans_back_icon_bg"
                    onClick={() => this.props.history.goBack()}
                  >
                    <img
                      src={require("../assets/images/back.png")}
                      className="back_icon_img"
                      alt=""
                    />
                  </div>
                  Transaction History
                  {this.state.flag === 0 && (
                  <div className="log-out-history">
                    <img
                      src={require("../assets/images/power-off.png")}
                      onClick={() => logout()}
                      alt=""
                    />
                  </div>
                  )}
                </div>
                <div className="transaction-header-area">
                  <PEDiv
                    element_id="HistoryMonth"
                    className="month-drop head-align"
                  >
                    <select
                      id="monthFilter"
                      className="select-data"
                      name="Month"
                      onChange={(e) => this.updateMonth(e)}
                      value={this.state.Month}
                    >
                      <option value="">Month</option>
                      <option value="Jan">January</option>
                      <option value="Feb">February</option>
                      <option value="Mar">March</option>
                      <option value="Apr">April</option>
                      <option value="May">May</option>
                      <option value="Jun">June</option>
                      <option value="Jul">July</option>
                      <option value="Aug">August</option>
                      <option value="Sep">September</option>
                      <option value="Oct">October</option>
                      <option value="Nov">November</option>
                      <option value="Dec">December</option>
                    </select>
                  </PEDiv>
                  <PEDiv
                    element_id="HistoryCategory"
                    className="category-drop head-align"
                  >
                    <select
                      id="categoryFilter"
                      className="select-data"
                      name="Category"
                      onChange={(e) => this.updateCategory(e)}
                      value={this.state.Category}
                    >
                      <option value="">Category</option>
                      {(effective_designation_id ===
                        process.env.REACT_APP_effective_designation_id_cso ||
                        e_designation_id ===
                          process.env
                            .REACT_APP_effective_designation_id_cso) && (
                        <>
                          <option value="cutocso">Customer to CSO</option>
                          <option value="csototo">CSO to TO</option>
                        </>
                      )}
                      {(effective_designation_id ===
                        process.env.REACT_APP_effective_designation_id_to ||
                        e_designation_id ===
                          process.env
                            .REACT_APP_effective_designation_id_to) && (
                        <>
                          <option value="tocsototo">CSO to TO</option>
                          <option value="totobank">TO to BANK</option>
                          <option value="totofcm">TO to FCM</option>
                        </>
                      )}
                      {(effective_designation_id ===
                        process.env.REACT_APP_effective_designation_id_bm ||
                        e_designation_id ===
                          process.env
                            .REACT_APP_effective_designation_id_bm) && (
                        <>
                          {/* <option value="tocsototo">CSO to TO</option>
                          <option value="totobank">TO to BANK</option> */}
                          <option value="totofcm">TO to FCM</option>
                        </>
                      )}
                    </select>
                  </PEDiv>
                  <PEDiv
                    element_id="HistoryFilter"
                    className="filter-drop head-align"
                    name="Filter"
                    onChange={(e) => this.updateFilter(e)}
                    value={this.state.Filter}
                  >
                    <select className="select-data">
                      <option value="">Filter</option>
                      <option value="1">Saved</option>
                      <option value="2">Submitted</option>
                      <option value="3">Approved</option>
                      <option value="4">Rejected</option>
                    </select>
                  </PEDiv>
                </div>
              </div>
            </div>
            <div className="transaction-card-holder">
              {reversed.length > 0
                ? reversed.map((item) => (
                    <>
                      {(item.txn_type === "D" || item.txn_type === "H") && (
                        <PEDiv
                          element_id="HistoryDepositCard"
                          className="card card-border mt-4 cursor-pointer"
                          onClick={() => this.showDetail(item.cash_balance_id)}
                        >
                          <div className="card-body">
                            <div className="card-head-area">
                              <PEDiv
                                element_id="HistoryCardIcon"
                                className="card-image-holder"
                              >
                                <img
                                  src={require("../assets/images/payto.png")}
                                  alt=""
                                />
                              </PEDiv>
                              <PEDiv
                                element_id="HistoryPayTo"
                                className="card-text"
                              >
                                <h2>Payment to</h2>
                                <h3>
                                  {item.from_to !== null
                                    ? item.from_to.toLowerCase()
                                    : ""}
                                </h3>
                              </PEDiv>
                              <PEDiv
                                element_id="HistoryStatus"
                                className="status-area"
                              >
                                <span>
                                  {item.status === 1
                                    ? "Saved"
                                    : item.status === 2
                                    ? "Submitted"
                                    : item.status === 3
                                    ? "Approved"
                                    : item.status === 4
                                    ? "Rejected"
                                    : item.status === 5
                                    ? "Verified"
                                    : ""}
                                </span>
                                {item.status === 1 && (
                                  <span className="foot-img-holder-save">
                                    <img
                                      src={require("../assets/images/Save.png")}
                                      alt=""
                                    />
                                  </span>
                                )}
                                {item.status === 2 && (
                                  <span className="foot-img-holder-submit">
                                    <img
                                      src={require("../assets/images/Submit.png")}
                                      alt=""
                                    />
                                  </span>
                                )}
                                {item.status === 3 && (
                                  <span className="foot-img-holder-approve">
                                    <img
                                      src={require("../assets/images/Approve.png")}
                                      alt=""
                                    />
                                  </span>
                                )}
                                {item.status === 4 && (
                                  <span className="foot-img-holder-reject">
                                    <img
                                      src={require("../assets/images/Reject.png")}
                                      alt=""
                                    />
                                  </span>
                                )}
                                {item.status === 5 && (
                                  <span className="foot-img-holder-approve">
                                    <img
                                      src={require("../assets/images/Approve.png")}
                                      alt=""
                                    />
                                  </span>
                                )}
                              </PEDiv>
                            </div>
                            {/* <div className="card-amount-area">
                              <span>Amount : </span>
                              <span className="card-amt">
                                Rs {item.cso_cash_handover}
                              </span>
                            </div> */}
                            <div className="amt_details_block card-amount-area">
                              <PEDiv element_id="HistorySubmitted">
                                <span className="bluetext">Submitted</span>
                                <div className="dem_block">
                                  Rs.{" "}
                                  {parseFloat(
                                    item.cso_cash_handover
                                  ).toLocaleString("en-IN")}
                                </div>
                              </PEDiv>
                              <PEDiv element_id="HistoryApproved">
                                <span className="greentext">Approved</span>
                                <div className="col_block">
                                  {item.status === 1 || item.status === 2
                                    ? "NA"
                                    : "Rs." +
                                      parseFloat(
                                        item.amt_approved
                                      ).toLocaleString("en-IN")}
                                </div>
                              </PEDiv>
                            </div>
                            <div className="amt_bal_block card-amount-area">
                              <PEDiv element_id="HistoryRejected">
                                <span className="redtext">Rejected</span>
                                <div className="bal_block">
                                  {item.status === 1 || item.status === 2
                                    ? "NA"
                                    : "Rs." +
                                      (
                                        parseFloat(item.cso_cash_handover) -
                                        parseFloat(item.amt_approved)
                                      ).toLocaleString("en-IN")}
                                </div>
                              </PEDiv>
                            </div>

                            <div className="card-foot-area">
                              <div className="date-time-area">
                                <PEDiv
                                  element_id="HistoryDate"
                                  className="date-area"
                                >
                                  <span>Date : </span>
                                  <span>
                                    {item.submitted_at &&
                                      item.submitted_at.split(" ")[0] +
                                        " " +
                                        item.submitted_at.split(" ")[1] +
                                        " " +
                                        item.submitted_at.split(" ")[2] +
                                        " " +
                                        item.submitted_at.split(" ")[3]}
                                  </span>
                                </PEDiv>
                                <PEDiv
                                  element_id="HistoryTime"
                                  className="time-area"
                                >
                                  <span>
                                    Time :{" "}
                                    {item.submitted_at &&
                                      item.submitted_at.split(" ")[4]}
                                  </span>
                                </PEDiv>
                              </div>

                              <PEDiv
                                element_id="HistoryRefNo"
                                className="status-area"
                              >
                                <br />
                                {item.txn_ref_no}
                              </PEDiv>
                            </div>
                          </div>
                        </PEDiv>
                      )}
                      {/* {item.txn_type === "H" && (
                        <div
                          className="card card-border mt-4 cursor-pointer"
                          onClick={() => this.showDetail(item.cash_balance_id)}
                        >
                          <div className="card-body">
                            <div className="card-head-area">
                              <div className="card-image-holder">
                                <img
                                  src={require("../assets/images/payto.png")}
                                  alt=""
                                />
                              </div>
                              <div className="card-text">
                                <h2>Payment to</h2>
                                <h3>
                                  {item.from_to !== null
                                    ? item.from_to.toLowerCase()
                                    : ""}
                                </h3>
                              </div>
                              <div className="status-area">
                                <span>
                                  {item.status === 1
                                    ? "Saved"
                                    : item.status === 2
                                    ? "Submitted"
                                    : item.status === 3
                                    ? "Approved"
                                    : item.status === 4
                                    ? "Rejected"
                                    : item.status === 5
                                    ? "Verified"
                                    : ""}
                                </span>
                                {item.status === 1 && (
                                  <span className="foot-img-holder-save">
                                    <img
                                      src={require("../assets/images/Save.png")}
                                      alt=""
                                    />
                                  </span>
                                )}
                                {item.status === 2 && (
                                  <span className="foot-img-holder-submit">
                                    <img
                                      src={require("../assets/images/Submit.png")}
                                      alt=""
                                    />
                                  </span>
                                )}
                                {item.status === 3 && (
                                  <span className="foot-img-holder-approve">
                                    <img
                                      src={require("../assets/images/Approve.png")}
                                      alt=""
                                    />
                                  </span>
                                )}
                                {item.status === 4 && (
                                  <span className="foot-img-holder-reject">
                                    <img
                                      src={require("../assets/images/Reject.png")}
                                      alt=""
                                    />
                                  </span>
                                )}
                                {item.status === 5 && (
                                  <span className="foot-img-holder-approve">
                                    <img
                                      src={require("../assets/images/Approve.png")}
                                      alt=""
                                    />
                                  </span>
                                )}
                              </div>
                            </div>                            
                            <div className="amt_details_block card-amount-area">
                              <div>
                                <span className="bluetext">Submitted</span>
                                <div className="dem_block">
                                  Rs.{" "}
                                  {parseFloat(
                                    item.cso_cash_handover
                                  ).toLocaleString("en-IN")}
                                </div>
                              </div>
                              <div>
                                <span className="greentext">Approved</span>
                                <div className="col_block">
                                  {item.status === 1 || item.status === 2
                                    ? "NA"
                                    : "Rs." +
                                      parseFloat(
                                        item.amt_approved
                                      ).toLocaleString("en-IN")}
                                </div>
                              </div>
                            </div>
                            <div className="amt_bal_block card-amount-area">
                              <div>
                                <span className="redtext">Rejected</span>
                                <div className="bal_block">
                                  {item.status === 1 || item.status === 2
                                    ? "NA"
                                    : "Rs." +
                                      (
                                        parseFloat(item.cso_cash_handover) -
                                        parseFloat(item.amt_approved)
                                      ).toLocaleString("en-IN")}
                                </div>
                              </div>
                            </div>

                            <div className="card-foot-area">
                              <div className="date-time-area">
                                <div className="date-area">
                                  <span>Date : </span>
                                  <span>
                                    {item.submitted_at &&
                                      item.submitted_at.split(" ")[0] +
                                        " " +
                                        item.submitted_at.split(" ")[1] +
                                        " " +
                                        item.submitted_at.split(" ")[2] +
                                        " " +
                                        item.submitted_at.split(" ")[3]}
                                  </span>
                                </div>
                                <div className="time-area">
                                  <span>
                                    Time :{" "}
                                    {item.submitted_at &&
                                      item.submitted_at.split(" ")[4]}
                                  </span>
                                </div>
                              </div>

                              <div className="status-area">
                                <br/>{item.txn_ref_no}
                              </div>
                            </div>
                          </div>
                        </div>
                      )} */}
                      {item.txn_type === "C" && (
                        <PEDiv element_id="HistoryReceivedCard"
                          className="card card-border mt-4 cursor-pointer"
                          onClick={() => this.showDetail(item.cash_balance_id)}
                        >
                          <div className="card-body">
                            <div className="card-head-area">
                              <PEDiv element_id="HistoryReceivedImg" className="card-image-holder">
                                <img
                                  src={require("../assets/images/receive-from.png")}
                                  alt=""
                                />
                              </PEDiv>
                              <PEDiv element_id="HistoryReceivedFrom" className="card-text">
                                <h2>Received from</h2>

                                <h3>
                                  {item.from_to !== null
                                    ? item.from_to.toLowerCase()
                                    : ""}{" "}
                                </h3>
                              </PEDiv>
                              <PEDiv element_id="HistoryReceiveStatus" className="status-area">
                                <span>
                                  {item.status === 1
                                    ? "Saved"
                                    : item.status === 2
                                    ? "Submitted"
                                    : item.status === 3
                                    ? "Approved"
                                    : item.status === 4
                                    ? "Rejected"
                                    : item.status === 5
                                    ? "Verified"
                                    : ""}
                                </span>
                                {item.status === 1 && (
                                  <span className="foot-img-holder-save">
                                    <img
                                      src={require("../assets/images/Save.png")}
                                      alt=""
                                    />
                                  </span>
                                )}
                                {item.status === 2 && (
                                  <span className="foot-img-holder-submit">
                                    <img
                                      src={require("../assets/images/Submit.png")}
                                      alt=""
                                    />
                                  </span>
                                )}
                                {item.status === 3 && (
                                  <span className="foot-img-holder-approve">
                                    <img
                                      src={require("../assets/images/Approve.png")}
                                      alt=""
                                    />
                                  </span>
                                )}
                                {item.status === 4 && (
                                  <span className="foot-img-holder-reject">
                                    <img
                                      src={require("../assets/images/Reject.png")}
                                      alt=""
                                    />
                                  </span>
                                )}
                                {item.status === 5 && (
                                  <span className="foot-img-holder-approve">
                                    <img
                                      src={require("../assets/images/Approve.png")}
                                      alt=""
                                    />
                                  </span>
                                )}
                              </PEDiv>
                            </div>
                            {/* <div className="card-amount-area">
                              <span>Amount : </span>
                              <span className="card-amt">
                                Rs {item.cso_cash_handover}
                              </span>
                            </div> */}
                            <div className="amt_details_block card-amount-area">
                              <PEDiv element_id="HistoryReceivedSubmit">
                                <span className="bluetext">Submitted</span>
                                <div className="dem_block">
                                  Rs.{" "}
                                  {parseFloat(
                                    item.cso_cash_handover
                                  ).toLocaleString("en-IN")}
                                </div>
                              </PEDiv>
                              <PEDiv element_id="HistoryReceivedApproved">
                                <span className="greentext">Approved</span>
                                <div className="col_block">
                                  {item.status === 1 || item.status === 2
                                    ? "NA"
                                    : "Rs. " +
                                      parseFloat(
                                        item.amt_approved
                                      ).toLocaleString("en-IN")}
                                </div>
                              </PEDiv>
                            </div>
                            <div className="amt_bal_block card-amount-area">
                              <PEDiv element_id="HistoryReceivedReject">
                                <span className="redtext">Rejected</span>
                                <div className="bal_block">
                                  {item.status === 1 || item.status === 2
                                    ? "NA"
                                    : "Rs. " +
                                      (
                                        parseFloat(item.cso_cash_handover) -
                                        parseFloat(item.amt_approved)
                                      ).toLocaleString("en-IN")}
                                </div>
                              </PEDiv>
                            </div>
                            <div className="card-foot-area">
                              <div className="date-time-area">
                                <PEDiv element_id="HistoryReceivedDate" className="date-area">
                                  <span>
                                    Date :{" "}
                                    {item.submitted_at &&
                                      item.submitted_at.split(" ")[0] +
                                        " " +
                                        item.submitted_at.split(" ")[1] +
                                        " " +
                                        item.submitted_at.split(" ")[2] +
                                        " " +
                                        item.submitted_at.split(" ")[3]}
                                  </span>
                                </PEDiv>
                                <PEDiv element_id="HistoryReceivedTime" className="time-area">
                                  <span>
                                    Time :{" "}
                                    {item.submitted_at &&
                                      item.submitted_at.split(" ")[4]}
                                  </span>
                                </PEDiv>
                              </div>

                              <PEDiv element_id="HistoryReceivedRefNo" className="status-area">
                                <br />
                                {item.txn_ref_no}
                              </PEDiv>
                            </div>
                          </div>
                          {item.reversals.length != 0 && (
                            <ReversalCard reversals={item.reversals} />
                          )}
                        </PEDiv>
                      )}
                    </>
                  ))
                : ""}
            </div>
          </div>
        </section>
        {this.state.flag === 0 && (
        <ChatSupportBtn history={this.props.history} />
        )}
      </>
    );
  }
}

export default withRouter(Cso_transaction_history);

class ReversalCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showReversal: false,
      status: ["Saved", "Submitted", "Approved", "Rejected", "Verified"],
      status_img: ["Save", "Submit", "Approve", "Reject", "Approve"],
      status_background: ["success", "warning", "success", "danger", "success"],
    };
  }

  ordinal(n) {
    var s = ["th", "st", "nd", "rd"];
    var v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
  }

  render() {
    return (
      <>
        <button
          className="reversal_history_button mb-2"
          onClick={(e) => {
            e.stopPropagation();
            this.setState({ showReversal: !this.state.showReversal });
          }}
        >
          Reversal History
        </button>
        {this.state.showReversal ? (
          <ul className="reversal_history">
            {this.props.reversals.map((reversal, index) => {
              const d = new Date(reversal.last_updated);

              let hours = d.getHours();
              let minutes = d.getMinutes();

              minutes = minutes < 10 ? `0${minutes}` : minutes;

              const strTime = `${hours}:${minutes}`;
              const strDate = `${d.getDate()}-${
                d.getMonth() < 10 ? `0${d.getMonth()}` : d.getMonth()
              }-${d.getFullYear()}`;
              return (
                <li
                  className="d-flex align-items-center justify-content-between"
                  key={reversal.id}
                >
                  <img
                    className="reversal_history_header_img"
                    src={require("../assets/images/transfer.png")}
                  />
                  <ul className="reversal_history_amount">
                    <li>
                      <span>{this.ordinal(index + 1)} Reversal Entry</span>
                      <div className="reversal_history_status">
                        <span>{this.state.status[reversal.status - 1]}</span>
                        <img
                          className={`bg-${
                            this.state.status_background[reversal.status - 1]
                          }`}
                          src={require(`../assets/images/${
                            this.state.status_img[reversal.status - 1]
                          }.png`)}
                        />
                      </div>
                    </li>
                    {/* <li>
                  <span>Rs. {parseFloat(reversal.amount).toLocaleString("en-IN")}</span>
                </li> */}
                    <li className="reversal_history_last_updated">
                      Last updated : {strDate + ", " + strTime}
                    </li>
                  </ul>
                </li>
              );
            })}
          </ul>
        ) : (
          ""
        )}
      </>
    );
  }
}
