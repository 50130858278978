import React, { Component, useState } from "react";
import customFetch from "./apicall/api";
import { withRouter } from "react-router";
import { useHistory } from "react-router-dom";
import { logout, chkapiinput, generateId } from "./Utility";
import { Modal, Rate, Input, Button } from "antd";
import { ThemeContext } from "../context/IsLoader";
import { PEButton, PEDiv, PEImg, PEInput, PEA, PESpan } from "./permissionComponents";

const { TextArea } = Input;
const generate_Id1 = generateId(10);
export default class SupportTicket extends Component {
  static contextType = ThemeContext;
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      issue_key: props.location.state.issue_key,
      ticket: null,
      attachment: [],
      created_at: null,
      status: ["Resolved", "In Queue", "In Work"],
      imageSrc: "",
      isSpin: false,
      flag: 0,
      api_token: localStorage.getItem("api_token"),
      employee_name: "",
      comment: "",
    };
  }

  fetchDetails = () => {
    this.setState({ isSpin: true });
    /*Check if data is null then logout*/
    var arr = [this.state.issue_key];
    var chkdata = chkapiinput(arr);
    if (chkdata === false) {
      this.setState({ isSpin: false });
      setTimeout(function() {
        logout();
      }, 2000);
    }

    const params = {
      ticket_no: this.state.issue_key,
    };
    const queryParams = new URLSearchParams(params).toString();

    const _headers = new Headers();
    _headers.append("Content-Type", "multipart/form-data");
    let generate_Id = generateId(10);
    customFetch(
      process.env.REACT_APP_URL + "/get/jira/ticket/details?" + queryParams,
      {
        method: "GET",
        headers: {
          "api-token": this.state.api_token,
          component_id: generate_Id1,
          apiId: generate_Id,
          api_group_id: generate_Id1,
          component: this.constructor.name,
          user_id: localStorage.getItem("in_userid"),
        },
      }
    )
      .then((res) => {
        if (res.status != 200) throw "Internal Server Error";
        return res.json();
      })
      .then((res) => {
        this.setState({ ticket: res, isSpin: false });

        // code for setting the created at text in ui
        let created_at = this.created_at(new Date(res.created_at));
        this.setState({ created_at });

        // for setting the background color for the status tag
        if (res.status == "To Do") {
          res.status = this.state.status[1];
          res["status_color"] = "ticket_status_inqueue";
        }
        if (res.status == "In Progress") {
          res.status = this.state.status[2];
          res["status_color"] = "ticket_status_inwork";
        }
        if (res.status == "Done") {
          res.status = this.state.status[0];
          res["status_color"] = "ticket_status_resolved";
        }

        console.log(res.data);
        return res.attachment;
      })
      .then((attachments) => {
        let attachment = [];
        attachments.forEach(async (file) => {
          this.getThumbnail(file.id).then((image) => {
            file["image"] = image;
            attachment.push(file);
            this.setState({ attachment: attachment, isSpin: false });
          });
        });
      });
  };

  getThumbnail(attachment_id) {
    this.setState({ isSpin: true });
    const _headers = new Headers();
    _headers.append("Content-Type", "multipart/form-data");
    /*Check if data is null then logout*/
    var arr = [attachment_id];
    var chkdata = chkapiinput(arr);
    if (chkdata === false) {
      this.setState({ isSpin: false });
      setTimeout(function() {
        logout();
      }, 2000);
    }
    const params = {
      attachment_id: attachment_id,
    };
    const queryParams = new URLSearchParams(params).toString();
    let generate_Id = generateId(10);
    return customFetch(
      process.env.REACT_APP_URL +
        "/issue/get/attachment/thumbnail?" +
        queryParams,
      {
        method: "GET",
        headers: {
          "api-token": this.state.api_token,
          component_id: generate_Id1,
          apiId: generate_Id,
          api_group_id: generate_Id1,
          component: this.constructor.name,
          user_id: localStorage.getItem("in_userid"),
        },
      }
    )
      .then((res) => {
        return res.blob();
      })
      .then((blob) => {
        this.setState({ isSpin: false });
        const href = window.URL.createObjectURL(blob);
        let img = {
          url: href,
          content: blob,
        };
        return img;
      })
      .catch((err) => {
        return Promise.reject({ Error: "Something Went Wrong", err });
      });
  }

  handleDownload = (filename, attachment_id) => {
    this.setState({ isSpin: true });
    const _headers = new Headers();
    _headers.append("Content-Type", "multipart/form-data");
    /*Check if data is null then logout*/
    var arr = [attachment_id];
    var chkdata = chkapiinput(arr);
    if (chkdata === false) {
      this.setState({ isSpin: false });
      setTimeout(function() {
        logout();
      }, 2000);
    }
    const params = {
      attachment_id: attachment_id,
    };
    const queryParams = new URLSearchParams(params).toString();
    let generate_Id = generateId(10);
    customFetch(
      process.env.REACT_APP_URL + "/issue/get/attachment?" + queryParams,
      {
        method: "GET",
        headers: {
          "api-token": this.state.api_token,
          component_id: generate_Id1,
          apiId: generate_Id,
          api_group_id: generate_Id1,
          component: this.constructor.name,
          user_id: localStorage.getItem("in_userid"),
        },
      }
    )
      .then((res) => {
        return res.blob();
      })
      .then((blob) => {
        const href = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        this.setState({ isSpin: false });
      })
      .catch((err) => {
        return Promise.reject({ Error: "Something Went Wrong", err });
      });
  };

  viewImg = (attachment_id) => {
    this.setState({ isSpin: true });
    const _headers = new Headers();
    _headers.append("Content-Type", "multipart/form-data");
    /*Check if data is null then logout*/
    var arr = [attachment_id];
    var chkdata = chkapiinput(arr);
    if (chkdata === false) {
      this.setState({ isSpin: false });
      setTimeout(function() {
        logout();
      }, 2000);
    }
    const params = {
      attachment_id: attachment_id,
    };
    const queryParams = new URLSearchParams(params).toString();
    let generate_Id = generateId(10);
    customFetch(
      process.env.REACT_APP_URL + "/issue/get/attachment?" + queryParams,
      {
        method: "GET",
        headers: {
          "api-token": this.state.api_token,
          component_id: generate_Id1,
          apiId: generate_Id,
          api_group_id: generate_Id1,
          component: this.constructor.name,
          user_id: localStorage.getItem("in_userid"),
        },
      }
    )
      .then((res) => {
        return res.blob();
      })
      .then((blob) => {
        const href = window.URL.createObjectURL(blob);
        this.setState({ imageSrc: href, isOpen: true, isSpin: false });
      })
      .catch((err) => {
        return Promise.reject({ Error: "Something Went Wrong", err });
      });
  };

  componentDidMount() {
    if (
      window.Android &&
      typeof window.Android.getDataFromLocalStorage === "function"
    ) {
      const data = window.Android.getDataFromLocalStorage("your_key");
      if (data) {
        this.setState({ isSpin: false, flag: 1 }); // Set flag to 1 if accessed from Android
        const tokenParts = data.split(",");
        const tokenData = {};

        tokenParts.forEach((part) => {
          const [key, value] = part.split(":");
          tokenData[key] = value;
        });

        console.log("AndroidInterface->", data);
      }
    } else {
      console.log(
        "Error in AndroidInterface: Android method not found or not available."
      );
    }


    setTimeout(() => {
      if (document.readyState === "complete") {
        this.context.onPageLoad({
          component: this.constructor.name,
          component_id: generate_Id1,
        });
      } else {
        window.addEventListener("load", this.context.onPageLoad());
        this.cleanup = () =>
          window.removeEventListener("load", this.context.onPageLoad());
      }
    }, 1000);
    const uid = localStorage.getItem("in_userid");
    this.setState({ employee_name: localStorage.getItem("employee_name") });
    if (!uid) {
      localStorage.clear();
      this.props.history.push("/");
    }
    this.fetchDetails();
  }

  // logout = () => {
  //   localStorage.clear();
  //   this.props.history.push("/");
  // };

  handleClick = () => {
    console.log(this.state);
    this.forceUpdate();
  };

  created_at = (date) => {
    const formatter = new Intl.RelativeTimeFormat("en");
    let diff = (new Date() - date) / 1000;

    const datediff = { diff: diff, type: "second" };

    if (datediff.diff >= 60) {
      datediff.diff = datediff.diff / 60;
      datediff.type = "minutes";
      if (datediff.diff >= 60) {
        datediff.diff = datediff.diff / 60;
        datediff.type = "hours";
        if (datediff.diff >= 24) {
          datediff.diff = datediff.diff / 24;
          datediff.type = "days";
          if (datediff.diff >= 7) {
            datediff.diff = datediff.diff / 7;
            datediff.type = "weeks";
          }
        }
      }
    }

    let x = formatter.format(-parseInt(datediff.diff, 10), datediff.type);

    return x;
  };

  handleTicketClose = async (rating, review) => {
    try {
      //to move the issue status to Done()
      await this.updateTicketStatus();
      if (review != "") await this.addCommentJira(review);
      await this.addReview(rating, review);
      this.fetchDetails();
    } catch (e) {
      console.error(e);
    }
  };
  addReview = async (rating, review) => {
    const api_token = this.state.api_token;
    /*Check if data is null then logout*/
    var arr = [this.state.issue_key, rating];
    var chkdata = chkapiinput(arr);
    if (chkdata === false) {
      this.setState({ isSpin: false });
      setTimeout(function() {
        logout();
      }, 2000);
    }
    const formdata = new FormData();
    formdata.append("ticket_no", this.state.issue_key);
    formdata.append("rating", rating);
    if (review != "") formdata.append("review", review);
    let generate_Id = generateId(10);
    const requestOptions = {
      method: "POST",
      headers: {
        "api-token": api_token,
        component_id: generate_Id1,
        apiId: generate_Id,
        api_group_id: generate_Id1,
        component: this.constructor.name,
        user_id: localStorage.getItem("in_userid"),
      },
      body: formdata,
    };

    const response = await customFetch(
      process.env.REACT_APP_URL + "/issue/add/rating",
      requestOptions
    );
    if (response.status != 200) throw "Internal Server Error";
    const resp = await response.json();
    return resp;
  };

  addCommentJira = async (review) => {
    const username = localStorage.getItem("in_username");
    const comment =
      review +
      " (by: " +
      this.state.employee_name +
      " - username " +
      username +
      ")";
    const api_token = this.state.api_token;
    /*Check if data is null then logout*/
    var arr = [this.state.issue_key, comment];
    var chkdata = chkapiinput(arr);
    if (chkdata === false) {
      this.setState({ isSpin: false });
      setTimeout(function() {
        logout();
      }, 2000);
    }
    const formdata = new FormData();
    formdata.append("ticket_no", this.state.issue_key);
    formdata.append("comment", comment);
    let generate_Id = generateId(10);
    const requestOptions = {
      method: "POST",
      headers: {
        "api-token": api_token,
        component_id: generate_Id1,
        apiId: generate_Id,
        api_group_id: generate_Id1,
        component: this.constructor.name,
        user_id: localStorage.getItem("in_userid"),
      },
      body: formdata,
    };

    const response = await customFetch(
      process.env.REACT_APP_URL + "/issue/comment/add",
      requestOptions
    );
    if (response.status != 200) throw "Internal Server Error";
    const resp = await response.json();
    return resp;
  };

  updateTicketStatus = async () => {
    const api_token = this.state.api_token;
    /*Check if data is null then logout*/
    var arr = [this.state.issue_key];
    var chkdata = chkapiinput(arr);
    if (chkdata === false) {
      this.setState({ isSpin: false });
      setTimeout(function() {
        logout();
      }, 2000);
    }
    const formdata = new FormData();
    formdata.append("ticket_no", this.state.issue_key);
    formdata.append("status", "Done");
    let generate_Id = generateId(10);
    const requestOptions = {
      method: "POST",
      headers: {
        "api-token": api_token,
        component_id: generate_Id1,
        apiId: generate_Id,
        api_group_id: generate_Id1,
        component: this.constructor.name,
        user_id: localStorage.getItem("in_userid"),
      },
      body: formdata,
    };

    const response = await customFetch(
      process.env.REACT_APP_URL + "/issue/update/status",
      requestOptions
    );
    if (response.status != 200) throw "Internal Server Error";
    const resp = await response.json();

    return resp;
  };

  handleCommentChange = (e) => {
    const value = e.target.value;
    this.setState({ comment: value });
  };

  handleCommentSave = async () => {
    const comment = this.state.comment;
    try {
      await this.addCommentJira(this.state.comment);
      this.fetchDetails();
      this.setState({ comment: "" });
    } catch (e) {
      console.log(e);
    }
  };

  commentAuthor = (comment) => {
    const regex = /by:\s(.*?)\s-/;
    const matches = comment.match(regex);
    if (matches && matches.length > 1) {
      return matches[1];
    }
    return null;
  };

  render() {
    return (
      <>
      {this.state.flag === 0 && (
        <>
        {this.state.isSpin ? (
          <div class="preloader">
            <div class="loader" ng-hide="data.length > 0"></div>
          </div>
        ) : (
          ""
        )}
        {/* Image popup Start */}
        <div
          className={
            this.state.isOpen
              ? "modal fade bd-example-modal-sm show mymodal"
              : "modal fade bd-example-modal-sm"
          }
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="confirm-box"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content cdb-confirm-box col-sm-12">
              <button
                type="button"
                className="close text-right"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => this.setState({ isOpen: false })}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <img src={this.state.imageSrc} />
            </div>
          </div>
        </div>
        {/* Image popup End */}

        <div className="container support_ticket">
          <div className="support_list_navbar">
            <button
              className="button back_button"
              onClick={() => this.props.history.goBack()}
            >
              <img src={require("../assets/images/back.png")} />
            </button>
            <span>Support Ticket Detail</span>
            <button className="button" onClick={() => logout()}>
              <img src={require("../assets/images/power-off.png")} alt="" />
            </button>
          </div>
          {this.state.ticket ? (
            <div className="support_ticket_details">
              <PESpan element_id="TicketNO" className="support_list_ticket_id d-flex align-items-center justify-content-between">
                Ticket : {this.state.issue_key}
                {this.state.ticket.status != "Resolved" ? (
                  <CloseTicket handleTicketClose={this.handleTicketClose} />
                ) : (
                  ""
                )}
              </PESpan>
              <div className="d-flex align-items-center justify-content-between">
                <PESpan element_id="TicketDate" className="support_ticket_created_at">
                  created at,{this.state.created_at}
                </PESpan>
                <PEDiv element_id="TicketStatus"
                  className={`support_ticket_status ${this.state.ticket.status_color}`}
                >
                  {this.state.ticket.status}
                </PEDiv>
              </div>
              <PESpan element_id="TicketSummary" className="support_ticket_summary">
                {this.state.ticket.summary}
              </PESpan>
              <div className="d-flex flex-row align-items-center justify-content-between">
                <PEDiv element_id="TicketLabel" className="support_ticket_ctpr" onClick={this.handleClick}>
                  {this.state.ticket.label}
                </PEDiv>
                <PEDiv element_id="TicketPriority" className="support_ticket_ctpr">
                  {this.state.ticket.priority}
                </PEDiv>
              </div>
              <div className="support_ticket_heading">Description :</div>
              <PEDiv element_id="TicketDescription" className="support_ticket_description">
                {this.state.ticket.description}
              </PEDiv>
              <div className="support_ticket_heading">Attachments :</div>
              {this.state.attachment.length ? (
                ""
              ) : (
                <div className="support_ticket_description">No attachments</div>
              )}
              <PEDiv element_id="TicketAttachment" className="support_ticket_attachment_cnt">
                {this.state.attachment.map((file) => {
                  return (
                    <div
                      key={file.id}
                      className="support_ticket_attachment"
                      // onClick={()=>this.handleDownload(file.filename,file.id)}>
                      // onClick={()=>this.viewImg(file.id)}
                      onClick={() => {
                        const ext = file.filename.substring(
                          file.filename.lastIndexOf(".") + 1,
                          file.filename.length
                        );
                        const filetypes = ["JPG", "JPEG", "PNG"];
                        const index = filetypes.findIndex((element) => {
                          return element.toLowerCase() === ext.toLowerCase();
                        });
                        console.log(index);
                        if (index == -1)
                          this.handleDownload(file.filename, file.id);
                        else this.viewImg(file.id);
                      }}
                    >
                      <div className="attachment_image">
                        <img src={file.image.url} alt="" />
                      </div>
                      <div className="attachment_label">
                        <div>{file.filename}</div>
                      </div>
                    </div>
                  );
                })}
              </PEDiv>

              <div className="support_ticket_heading">Comments :</div>
              <div className="support_ticket_add_comment">
                <PEDiv element_id="TicketEmpShortName" className="support_ticket_add_comment_legend">
                  {getAcronym(this.state.employee_name)}
                </PEDiv>
                <PEDiv element_id="TicketComment" className="support_ticket_add_comment_form">
                  <TextArea
                    onChange={this.handleCommentChange}
                    value={this.state.comment}
                    placeholder="Add comment"
                  />
                  {this.state.comment && (
                    <div className="support_ticket_comment_action">
                      <Button
                        type="primary"
                        onClick={this.handleCommentSave}
                        block
                      >
                        Save
                      </Button>
                      <Button
                        type="default"
                        block
                        onClick={() => {
                          this.setState({ comment: "" });
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  )}
                </PEDiv>
              </div>
              <div className="support_ticket_comment_list">
                {this.state.ticket.data.length ? (
                  this.state.ticket.data.map((comment) => {
                    const d = new Date(comment.timestamp);
                    const months = [
                      "January",
                      "February",
                      "March",
                      "April",
                      "May",
                      "June",
                      "July",
                      "August",
                      "September",
                      "October",
                      "November",
                      "December",
                    ];
                    let hours = d.getHours();
                    let minutes = d.getMinutes();
                    const ampm = hours >= 12 ? "pm" : "am";

                    hours %= 12;
                    hours = hours || 12;
                    minutes = minutes < 10 ? `0${minutes}` : minutes;

                    const strTime = `${hours}:${minutes} ${ampm}`;
                    const author_name = this.commentAuthor(comment.content);
                    return (
                      <div className="support_ticket_comment" key={d}>
                        <div className="support_ticket_comment_heading">
                          <PESpan element_id="TicketAuthorName" className="comment_author">
                            {author_name ? author_name : comment.author_name}
                          </PESpan>
                          <PESpan element_id="TicketDateTime" className="comment_time">
                            {months[d.getMonth()] +
                              " " +
                              d.getDate() +
                              ", " +
                              d.getFullYear() +
                              " at " +
                              strTime}
                          </PESpan>
                        </div>
                        <div className="comment_body">
                          {author_name
                            ? comment.content.replace(
                                /\(by:\s.*?\s-\s\w+\s\w+\)/,
                                ""
                              )
                            : comment.content}
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="support_ticket_description">
                    No comments yet
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className=""></div>
          )}
        </div>
        </>
      )}
      </>
    );
  }
}

function CloseTicket({ handleTicketClose }) {
  const [modalOpen, setModalOpen] = useState(false);
  const [rating, setRating] = useState(5);
  const [review, setReview] = useState("");
  const [warning, setWarning] = useState("");

  const formValidate = () => {
    if (rating <= 3 && review.length <= 0) {
      if (review.length == 0) setWarning("Please enter review");
      else setWarning("review must be more than 10 chars");
      return;
    }
    handleTicketClose(rating, review);
    setModalOpen(false);
  };
  return (
    <>
      <button
        onClick={() => setModalOpen(true)}
        className="btn btn-danger btn-sm"
      >
        Close Ticket
      </button>
      <Modal
        title="Please enter ratings"
        style={{
          top: 30,
        }}
        open={modalOpen}
        onOk={() => formValidate()}
        onCancel={() => setModalOpen(false)}
        okButtonProps={{
          disabled: false,
        }}
        okText="Submit"
        cancelText="Cancel"
      >
        <Rate
          onChange={(value) => {
            setRating(value);
          }}
          value={rating}
          style={{
            display: "flex",
            marginBottom: 20,
            justifyContent: "space-around",
            transform: "scale(1.2)",
          }}
        ></Rate>
        {rating <= 3 ? (
          <>
            <p>
              please enter your remarks,
              <br />
            </p>
            <TextArea
              showCount
              maxLength={200}
              style={{
                height: 70,
                resize: "none",
              }}
              onChange={(e) => {
                setReview(e.target.value);
              }}
              value={review}
              placeholder="max 200 letters"
            />
          </>
        ) : (
          ""
        )}
        {warning != "" ? <span className="text-danger">*{warning}</span> : ""}
      </Modal>
    </>
  );
}

function getAcronym(str) {
  return str
    .split(/\s/)
    .reduce((response, word) => (response += word.slice(0, 1)), "")
    .toUpperCase();
}
