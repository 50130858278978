import React, { Component } from "react";
import customFetch from "./apicall/api";
import { logout, generateId } from "./Utility";
import { ThemeContext } from "../context/IsLoader";
import { PEButton, PEDiv, PEImg, PEInput } from "./permissionComponents";
const generate_Id1 = generateId(10);
class App_download extends Component {
  static contextType = ThemeContext;
  constructor(props) {
    super(props);
    this.webcamRef = React.createRef(null);
    this.state = {
      app_env: "",
      app_data: [],
      flag: 0,
    };
  }
  componentDidMount() {
    if (
      window.Android &&
      typeof window.Android.getDataFromLocalStorage === "function"
    ) {
      const data = window.Android.getDataFromLocalStorage("your_key");
      if (data) {
        this.setState({ isSpin: false, flag: 1 }); // Set flag to 1 if accessed from Android
        const tokenParts = data.split(",");
        const tokenData = {};

        tokenParts.forEach((part) => {
          const [key, value] = part.split(":");
          tokenData[key] = value;
        });

        console.log("AndroidInterface->", data);
      }
    } else {
      console.log(
        "Error in AndroidInterface: Android method not found or not available."
      );
    }

    // check if not found api token then logout user
    setTimeout(() => {
      if (document.readyState === "complete") {
        this.context.onPageLoad({
          component: this.constructor.name,
          component_id: generate_Id1,
        });
      } else {
        window.addEventListener("load", this.context.onPageLoad());
        this.cleanup = () =>
          window.removeEventListener("load", this.context.onPageLoad());
      }
    }, 1000);
    const { api_token } =
      (this.props.location && this.props.location.state) || {};
    if (!api_token) {
      localStorage.clear();
      this.props.history.push("/");
    }
    this.get_appdata();
  }
  get_appdata() {
    this.setState({ isSpin: true });
    const { api_token } =
      (this.props.location && this.props.location.state) || {};
    let generate_Id = generateId(10);
    customFetch(process.env.REACT_APP_URL + "/get/latest/version", {
      method: "GET",
      headers: {
        "api-token": api_token,
        component_id: generate_Id1,
        apiId: generate_Id,
        api_group_id: "",
        component: this.constructor.name,
        user_id: localStorage.getItem("in_userid"),
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        console.log("utr_data - ", json);
        if (json.api_code === 4 || json.api_code === 3 || json.api_code === 5) {
          localStorage.clear();
          this.props.history.push("/");
        }
        this.setState({
          isSpin: false,
          app_data: json,
        });
      })
      .catch((error) => console.log("error", error));
  }
  download_app(path) {
    this.setState({
      isSpin: true,
    });
    const { api_token } =
      (this.props.location && this.props.location.state) || {};
    const formdata = new FormData();
    formdata.append("blob_url", path);
    let generate_Id = generateId(10);
    const requestOptions = {
      method: "POST",
      headers: {
        "api-token": api_token,
        component_id: generate_Id1,
        apiId: generate_Id,
        api_group_id: "",
        component: this.constructor.name,
        user_id: localStorage.getItem("in_userid"),
      },
      body: formdata,
    };
    //console.log("Body",formdata);
    customFetch(process.env.REACT_APP_URL + "/get/public/url", requestOptions)
      .then((res) => {
        if (res.headers.get("api_code") > 0) {
          this.setState({
            isErrOpen: true,
            api_error_message: res.headers.get("api_error_message"),
            isSpin: false,
          });
        }

        if (res.api_code === 4 || res.api_code === 3 || res.api_code === 5) {
          localStorage.clear();
          this.props.history.push("/");
        }
        return res.json();
      })
      .then((img) => {
        this.setState({
          isSpin: false,
        });
        const url = img.public_url;
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", path.split("/")[3]);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      })
      .catch((error) => console.log("error", error));
  }
  render() {
    return (
      <>
        {this.state.isSpin ? (
          <div className="preloader">
            <div className="loader" ng-hide="data.length > 0"></div>
          </div>
        ) : (
          ""
        )}

        <div className="container">
          <div className="payload_view_navbar">
            <button
              className="button back_button"
              onClick={() => this.props.history.goBack()}
            >
              <img src={require("../assets/images/back.png")} alt="" />
            </button>
            <h4>App Download</h4>
            {this.state.flag === 0 && (
            <button className="button" onClick={() => logout()}>
              <img src={require("../assets/images/power-off.png")} alt="" />
            </button>
            )}
          </div>

          {this.state.app_data &&
            this.state.app_data.map((appdata) => (
              <PEDiv
                element_id={appdata.mobile_app_name}
                className="dashboard-card-holder mt-4"
              >
                <div
                  className="card card-border-small col-sm-6 py-2"
                  onClick={() => this.download_app(appdata.blob_url)}
                >
                  <div className="card-body">
                    <div className="image-holder-app">
                      <img
                        src={require("../assets/images/Acen-icon.png")}
                        alt=""
                      />
                    </div>
                    <div className="card-heading">
                      <h2>
                        {appdata.mobile_app_name} V.{appdata.version_no}
                      </h2>
                    </div>
                    <div className="text-center"></div>
                  </div>
                </div>
              </PEDiv>
            ))}
        </div>
      </>
    );
  }
}
export default App_download;
