import React, { Component } from "react";
import customFetch from "./apicall/api";
import { useHistory } from "react-router-dom";
import { logout, chkapiinput, generateId } from "./Utility";
import { ThemeContext } from "../context/IsLoader";
import { PEButton, PEDiv, PEImg, PEInput, PEA, PESpan, PELI, PETextArea } from "./permissionComponents";
const generate_Id1 = generateId(10);
class ReversalDetail extends Component {
  static contextType = ThemeContext;
  constructor(props) {
    super(props);

    this.state = {
      api_token: localStorage.getItem("api_token"),
      txn_ref_no: props.location.state.txn_ref_no,
      txn_details: null,
      acronym: "",
      remark: "",
      isSubmitted: false,
      form_error: "",
      disabled: false,
      isSpin: false,
      approveAccess: false,
    };
  }

  fetchDetails = async () => {
    try {
      const api_token = this.state.api_token;
      /*Check if data is null then logout*/
      var arr = [this.state.txn_ref_no];
      var chkdata = chkapiinput(arr);
      if (chkdata === false) {
        this.setState({ isSpin: false });
        setTimeout(function() {
          logout();
        }, 2000);
      }
      /*Check if data is null then logout End*/
      const params = {
        txn_ref_no: this.state.txn_ref_no,
      };
      const queryParams = new URLSearchParams(params).toString();
      let generate_Id = generateId(10);
      const requestOptions = {
        method: "GET",
        headers: {
          "api-token": api_token,
          component_id: generate_Id1,
          apiId: generate_Id,
          api_group_id: "",
          component: this.constructor.name,
          user_id: localStorage.getItem("in_userid"),
        },
      };

      const response = await customFetch(
        process.env.REACT_APP_URL + "/reversal/detail?" + queryParams,
        requestOptions
      );

      const data = await response.json();
      console.log(data);
      data.collection_detail.sort((a, b) => {
        return a.partner_id - b.partner_id;
      });
      return data;
    } catch (e) {
      console.error(e);
    }
  };

  componentDidMount() {
    setTimeout(() => {
      if (document.readyState === "complete") {
        this.context.onPageLoad({
          component: this.constructor.name,
          component_id: generate_Id1,
        });
      } else {
        window.addEventListener("load", this.context.onPageLoad());
        this.cleanup = () =>
          window.removeEventListener("load", this.context.onPageLoad());
      }
    }, 1000);
    const uid = localStorage.getItem("in_userid");
    if (!uid) {
      localStorage.clear();
      this.props.history.push("/");
    }
    this.setState({ isSpin: true });
    this.fetchDetails().then((txn_details) => {
      const checkUser = (obj) => obj.userid === parseInt(uid);
      let approveAccess = txn_details["approvers"].some(checkUser);
      let acronym = txn_details.household_name
        .split(/\s/)
        .reduce((response, word) => (response += word.slice(0, 1)), "")
        .slice(0, 2)
        .toUpperCase();
      this.setState({ txn_details, acronym, isSpin: false, approveAccess });
    });
  }

  // logout = () => {
  //   localStorage.clear();
  //   this.props.history.push("/");
  // };

  handleChange = (e) => {
    this.setState({ remark: e.target.value });
  };

  handleReject = async () => {
    console.log("Reject");
    try {
      this.setState({ form_error: "", disabled: true, isSpin: true });
      if (this.state.remark == "" || this.state.remark == null) {
        this.setState({
          form_error: "please enter the remarks",
          disabled: false,
          isSpin: false,
        });
        return;
      } else if (this.state.remark.length <= 10) {
        this.setState({
          form_error: "remark must be more than 10 charecters",
          disabled: false,
          isSpin: false,
        });
        return;
      }
      const api_token = this.state.api_token;
      /*Check if data is null then logout*/
      var arr = [
        this.state.txn_details.txn_ref_no,
        localStorage.getItem("in_userid"),
        this.state.remark,
      ];
      var chkdata = chkapiinput(arr);
      if (chkdata === false) {
        this.setState({ isSpin: false });
        setTimeout(function() {
          logout();
        }, 2000);
      }
      /*Check if data is null then logout End*/
      const formdata = new FormData();
      formdata.append("txn_ref_no", this.state.txn_details.txn_ref_no);
      formdata.append("userid", localStorage.getItem("in_userid"));
      formdata.append("remark", this.state.remark);
      let generate_Id = generateId(10);
      const requestOptions = {
        method: "POST",
        headers: {
          "api-token": api_token,
          component_id: generate_Id1,
          apiId: generate_Id,
          api_group_id: "",
          component: this.constructor.name,
          user_id: localStorage.getItem("in_userid"),
        },
        body: formdata,
      };

      const response = await customFetch(
        process.env.REACT_APP_URL + "/reversal/reject",
        requestOptions
      );

      const data = await response.json();
      if (data.status_code != 200) throw "Internal Server Error";
      this.setState({
        isSubmitted: true,
        message: "Reversal rejected successfully",
        disabled: false,
        isSpin: false,
      });
    } catch (e) {
      console.error(e);
    }
  };

  handleAccept = async () => {
    console.log("Accept");
    try {
      this.setState({ form_error: "", disabled: true, isSpin: true });
      if (this.state.remark == "" || this.state.remark == null) {
        this.setState({
          form_error: "please enter the remarks",
          disabled: false,
          isSpin: false,
        });
        return;
      } else if (this.state.remark.length <= 10) {
        this.setState({
          form_error: "remark must be more than 10 charecters",
          disabled: false,
          isSpin: false,
        });
        return;
      }
      const api_token = this.state.api_token;
      /*Check if data is null then logout*/
      var arr = [
        this.state.txn_details.txn_ref_no,
        localStorage.getItem("in_userid"),
        this.state.remark,
      ];
      var chkdata = chkapiinput(arr);
      if (chkdata === false) {
        this.setState({ isSpin: false });
        setTimeout(function() {
          logout();
        }, 2000);
      }
      /*Check if data is null then logout End*/
      const formdata = new FormData();
      formdata.append("txn_ref_no", this.state.txn_details.txn_ref_no);
      formdata.append("userid", localStorage.getItem("in_userid"));
      formdata.append("remark", this.state.remark);
      let generate_Id = generateId(10);
      const requestOptions = {
        method: "POST",
        headers: {
          "api-token": api_token,
          component_id: generate_Id1,
          apiId: generate_Id,
          api_group_id: "",
          component: this.constructor.name,
          user_id: localStorage.getItem("in_userid"),
        },
        body: formdata,
      };

      const response = await customFetch(
        process.env.REACT_APP_URL + "/reversal/accept",
        requestOptions
      );

      const data = await response.json();
      if (data.status_code != 200) throw "Internal Server Error";
      this.setState({
        isSubmitted: true,
        message: "Reversal approved successfully",
        disabled: false,
        isSpin: false,
      });
    } catch (e) {
      console.error(e);
    }
  };

  render() {
    let s_no = 1;
    return (
      <>
        {this.state.isSpin ? (
          <div class="preloader">
            <div class="loader" ng-hide="data.length > 0"></div>
          </div>
        ) : (
          ""
        )}
        <div className="container">
          <div className="reversal_navbar">
            <button
              className="button back_button"
              onClick={() => this.props.history.goBack()}
            >
              <img src={require("../assets/images/back.png")} />
            </button>
            <span>Reversal Detail</span>
            <button className="button" onClick={() => logout()}>
              <img src={require("../assets/images/power-off.png")} alt="" />
            </button>
          </div>

          {this.state.txn_details && !this.state.isSubmitted && (
            <div className="reversal_details_card">
              <div className="reversal_details_card_header">
                <div>AS</div>
                <ul className="reversal_hh_info">
                  <PELI element_id="ReversalTXNRefNo">Ref no: {this.state.txn_details.txn_ref_no}</PELI>
                  <PELI element_id="ReversalHHID">HH ID : {this.state.txn_details.household_number}</PELI>
                  <PELI element_id="ReversalHHName">{this.state.txn_details.household_name}</PELI>
                </ul>
              </div>
              <PEDiv element_id="ReversalAmount" className="reversal_trans_card_amount">
                <span>Amount :</span>
                <span>
                  Rs. {this.state.txn_details.amount.toLocaleString()}
                </span>
              </PEDiv>
              {this.state.txn_details.collection_detail.map((item, index) => {
                return (
                  <div
                    key={item.partner_id}
                    className="reversal_details_bal_block"
                  >
                    <h4>
                      {item.partner_id != "1" ? "Partner " + s_no++ + ":" : ""}{" "}
                      {item.partner}
                    </h4>
                    <div className="reversal_bal_block_main">
                      {item.P && (
                        <PEDiv element_id="ReversalAmountDetail" className="reversal_bal_charges">
                          <span>Amount</span>
                          <span>Rs. {item.P}</span>
                        </PEDiv>
                      )}
                      {item.C && (
                        <PEDiv element_id="ReversalCharges" className="reversal_bal_emi">
                          <span>Charges</span>
                          <span>Rs. {item.C}</span>
                        </PEDiv>
                      )}
                      {/* <div className='reversal_bal_overdue'>
                           <span>Overdue</span>
                           <span>Rs. 10,000</span>
                       </div> */}
                    </div>
                  </div>
                );
              })}

              {this.state.txn_details.userid ==
                localStorage.getItem("in_userid") &&
                this.state.txn_details.status == 2 && (
                  <div className="approver_block">
                    <span class="head-title">Approval Pending From</span>
                    <div id="approver_list">
                      <ol type="1">
                        {this.state.txn_details.approvers.map((item) => {
                          return (
                            <PELI element_id="ReversalApprovalPending" key={item["employee_name"]}>
                              {item["designation_code"] +
                                " - " +
                                item["employee_name"]}
                            </PELI>
                          );
                        })}
                      </ol>
                    </div>
                  </div>
                )}

              {this.state.approveAccess && this.state.txn_details.status == 2 && (
                <>
                  <PETextArea element_id="ReversalComments"
                    value={this.state.remark}
                    onChange={this.handleChange}
                    type="text"
                    name="comments"
                    placeholder="comments"
                    maxLength="150"
                  ></PETextArea>

                  {this.state.form_error != "" ? (
                    <span className="text-danger mt-2 mb-2">
                      *{this.state.form_error}
                    </span>
                  ) : (
                    ""
                  )}
                  <div className="reversal_act_rjt">
                    <PEButton element_id="ReversalApproveBtn"
                      disabled={this.state.disabled}
                      className="reversal_button"
                      onClick={this.handleAccept}
                    >
                      Approve
                    </PEButton>
                    <PEButton element_id="ReversalRejectBtn"
                      className="reversal_button"
                      disabled={this.state.disabled}
                      onClick={this.handleReject}
                    >
                      Reject
                    </PEButton>
                  </div>
                </>
              )}
            </div>
          )}

          {this.state.isSubmitted && (
            <ReversalSubmitted message={this.state.message} />
          )}
        </div>
      </>
    );
  }
}

export default ReversalDetail;

function ReversalSubmitted(props) {
  const history = useHistory();
  return (
    <div className="reversal_details_card">
      <div className="reversal_details_card_header">{props.message}</div>
      <button
        className="reversal_button"
        onClick={() => {
          history.goBack();
        }}
      >
        OK
      </button>
    </div>
  );
}
