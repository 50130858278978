import React, { Component, useState } from "react";
import customFetch from "./apicall/api";
import { withRouter } from "react-router";
import { useHistory } from "react-router-dom";
import moment from "moment";
import "antd/dist/antd.css";
import { Switch } from "antd";
import { logout, chkapiinput, generateId } from "./Utility";
import { ThemeContext } from "../context/IsLoader";
// import "../assets/css/BankRegisterNACH.css"
import "../assets/css/cheque.css";
const generate_Id1 = generateId(10);
export default class ChequeUpload extends Component {
  static contextType = ThemeContext;
  constructor(props) {
    super(props);

    this.state = {
      isVisible: "",
      // incorrect_mobile_number: 0,
      // unable_to_reach: 0,
      //preferred_date: new Date(),

      status: 1,
      isDisable: true,
      isErrOpen: false,
      message: "",
      api_error_message: "",
      isOpen: false,
      data: [],
      isSpin: false,
      IPv4: null,
      latitude: null,
      longitude: null,
      search_text: "",
      og_data: [],
    };
    // this.onSubmitHandler = this.onSubmitHandler.bind(this);
  }

  componentDidMount() {
    // const { Preferred_pay_date } =
    // (this.props.location && this.props.location.state) || {};
    setTimeout(() => {
      if (document.readyState === "complete") {
        this.context.onPageLoad({
          component: this.constructor.name,
          component_id: generate_Id1,
        });
      } else {
        window.addEventListener("load", this.context.onPageLoad());
        this.cleanup = () =>
          window.removeEventListener("load", this.context.onPageLoad());
      }
    }, 1000);
    const uid = localStorage.getItem("in_userid");

    customFetch("https://geolocation-db.com/json/")
      .then((response) => {
        return response.json();
      }, "jsonp")
      .then((res) => {
        console.log(res);
        this.setState({
          IPv4: res.IPv4,
          latitude: res.latitude,
          longitude: res.longitude,
        });
      })
      .catch((err) => console.log(err));

    const { api_token } =
      (this.props.location && this.props.location.state) || {};

    // console.log(this.props.location.state);
    // console.log(("hhno" in this.props.location.state));

    if ("hhno" in this.props.location.state) {
      this.state.search_text = this.props.location.state.hhno;
      this.onSearch();
    }

    // console.log(api_token);

    // console.log("Superman ID: " + uid);
    // if (!uid) {
    //   localStorage.clear();
    //   this.props.history.push("/");
    // }
    // if (Preferred_pay_date !== "" || Preferred_pay_date !== null) {
    //   this.setState({
    //     preferred_date: moment(Preferred_pay_date).format("YYYY-MM-DD"),
    //   });
    // }
    // this.setState({
    //   'currentDate': this.getDate(new Date(Date.now()))
    // });
  }

  handleSearchInput = (e) => {
    this.setState({ search_text: e.target.value.toUpperCase() });
    console.log(this.state.search_text);
    if (this.state.search_text == "") {
      this.state.utrdata = this.state.og_data;
    }
    this.forceUpdate();
  };

  onSearch = () => {
    console.log(this.state.search_text);

    if (this.state.search_text == "") {
      this.setState({
        isSpin: false,
        message: "Please Enter Household ID",
        isErrOpen: true,
      });
    } else {
      this.state.og_data = [];
      this.setState({ isSpin: true });
      const { api_token } =
        (this.props.location && this.props.location.state) || {};
      // AT-2143 | AT-2179 | Ankur Makavana | 13-07-2023 | conver post method to get method
      const params = {
        household_number: this.state.search_text,
        flag: "C",
      };
      const queryParams = new URLSearchParams(params).toString();
      let generate_Id = generateId(10);
      customFetch(
        process.env.REACT_APP_URL +
          "/get/household/bank/details?" +
          queryParams,
        {
          method: "GET",
          headers: {
            "api-token": api_token,
            component_id: generate_Id1,
            apiId: generate_Id,
            api_group_id: "",
            component: this.constructor.name,
            user_id: localStorage.getItem("in_userid"),
          },
        }
      )
        .then((res) => {
          return res.json();
        })
        .then((json) => {
          console.log("data - ", json);
          if (
            json.api_code === 4 ||
            json.api_code === 3 ||
            json.api_code === 5
          ) {
            localStorage.clear();
            this.props.history.push("/");
          } else if (json.length == 0) {
            this.setState({
              isSpin: false,
              message: "No Data Found!",
              isErrOpen: true,
            });
          } else {
            this.setState({
              isSpin: false,
              og_data: json,
            });
          }
        })
        .catch((error) => console.log("error", error));
    }
  };

  render() {
    const { og_data, isErrOpen, message } = this.state;
    // console.log(this.state.data);
    return (
      <>
        {this.state.isSpin ? (
          <div class="preloader">
            <div class="loader" ng-hide="data.length > 0"></div>
          </div>
        ) : (
          ""
        )}

        <div
          className={
            isErrOpen
              ? "modal fade bd-example-modal-sm show mymodal"
              : "modal fade bd-example-modal-sm"
          }
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="confirm-box"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content cdb-confirm-box col-sm-12">
              <button
                type="button"
                className="close text-right"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => this.setState({ isErrOpen: false })}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <p>{message}</p>
            </div>
          </div>
        </div>

        <section className="main-sec">
          <div className="container fix-width">
            <div className="">
              <div className="card-body">
                <div className="transaction-head-area">
                  <div
                    className="trans_back_icon_bg"
                    onClick={() => this.props.history.goBack()}
                  >
                    <img
                      src={require("../assets/images/back.png")}
                      className="back_icon_img"
                      alt=""
                    />
                  </div>
                  Search Household - Cheque
                  <div className="log-out-history">
                    <img
                      src={require("../assets/images/power-off.png")}
                      onClick={() => logout()}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="call_detail container d-flex flex-column align-items-center">
          {/* <div className="ca-header-box-cheque-upload">
            <div className="collection_block_header">
              <div className="title-holder_bg-cheque-upload">
                <div
                  className="back_icon_bg"
                  onClick={() => this.props.history.goBack()}
                >
                  <img
                    src={require("../assets/images/back.png")}
                    className="back_icon_img"
                  />
                </div>
                <h5></h5>
                <div className="log-out">
                  <img
                    src={require("../assets/images/power-off.png")}
                    onClick={() => logout()}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div> */}

          <br />
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              value={this.state.search_text}
              onChange={this.handleSearchInput}
              placeholder="Search Household"
            />
            <button
              class="btn btn-primary"
              type="button"
              onClick={this.onSearch}
            >
              Search
            </button>
          </div>
          <br />
          {og_data.map((item) => {
            return (
              <>
                <br />
                <div
                  className="support_list_ticket_preview"
                  onClick={() =>
                    this.props.history.push({
                      pathname: "/cheque",
                      state: {
                        acc_data: item,
                        hhno: this.state.search_text,
                        api_token: this.props.location.state.api_token,
                      },
                    })
                  }
                  key={item.account_number}
                >
                  <span className="support_list_ticket_id d-flex align-items-center justify-content-between">
                    {this.state.search_text} - {item.name_as_per_account}
                  </span>
                  <div className="d-flex">
                    <span className="support_list_ticket_summary">
                      {item.bank_name} <br /> {item.account_number}
                    </span>
                    <div>
                      <img src={require("../assets/images/next.png")} />
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </>
    );
  }
}
