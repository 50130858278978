import React, { Component, useState } from "react";
import customFetch from "./apicall/api";
import { withRouter } from "react-router";
import { useHistory } from "react-router-dom";
import moment from "moment";
import "antd/dist/antd.css";
import { Switch } from "antd";
import { logout, chkapiinput } from "./Utility";
import "../assets/css/BankRegisterNACH.css";
export default class NACHRegisterBank extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isVisible: "",
      // incorrect_mobile_number: 0,
      // unable_to_reach: 0,
      //preferred_date: new Date(),
      call_duration: 0,
      preferred_mode: "",
      status: 1,
      nach_flag: props.location.state.nach_flag,
      isDisable: true,
      isErrOpen: false,
      api_error_message: "",
      isOpen: false,
      nach_mapid: "",
      hh_bank_details: [],
      data: [],
      isSpin: false,
      flag: 0,
      NACH_form_upload: "",
      ISNACH_form_upload: false,
      pbutton: false,
      nbutton: false,
      dbutton: false,
      isOpenNach: false,
      vendor_id: 2,
      IPv4: null,
      latitude: null,
      longitude: null,
      search_text: "",
      og_data: [],
      isUpi: true,
    };
    // this.onSubmitHandler = this.onSubmitHandler.bind(this);
  }

  componentDidMount() {
    if (
      window.Android &&
      typeof window.Android.getDataFromLocalStorage === "function"
    ) {
      const data = window.Android.getDataFromLocalStorage("your_key");
      if (data) {
        this.setState({ isSpin: false, flag: 1}); // Set flag to 1 if accessed from Android
        const tokenParts = data.split(",");
        const tokenData = {};

        tokenParts.forEach((part) => {
          const [key, value] = part.split(":");
          tokenData[key] = value;
        });

        console.log("AndroidInterface->", data);
      }
    } else {
      console.log(
        "Error in AndroidInterface: Android method not found or not available."
      );
    }

    // const { Preferred_pay_date } =
    // (this.props.location && this.props.location.state) || {};
    const uid = localStorage.getItem("in_userid");

    customFetch("https://geolocation-db.com/json/")
      .then((response) => {
        return response.json();
      }, "jsonp")
      .then((res) => {
        console.log(res);
        this.setState({
          IPv4: res.IPv4,
          latitude: res.latitude,
          longitude: res.longitude,
        });
      })
      .catch((err) => console.log(err));

    // console.log("Superman ID: " + uid);
    // if (!uid) {
    //   localStorage.clear();
    //   this.props.history.push("/");
    // }
    // const branch_id = localStorage.getItem("mapped_branch_id");
    // const numbersArray = branch_id.split(",").map(Number);
    // console.log(
    //   "process.env.REACT_APP_UPI_AUTOPAY",
    //   numbersArray.includes(parseInt(process.env.REACT_APP_UPI_AUTOPAY))
    // );
    // const hasCommonValue = numbersArray.some((value) =>
    //   process.env.REACT_APP_UPI_AUTOPAY.includes(value)
    // );
    // //if (numbersArray.includes(parseInt(process.env.REACT_APP_UPI_AUTOPAY))) {
    // if (hasCommonValue) {
    //   console.log(
    //     `Number ${process.env.REACT_APP_UPI_AUTOPAY} is in the string`
    //   );
    //   this.setState({ isUpi: true });
    // } else {
    //   this.setState({ isUpi: false });
    // }
    // if (Preferred_pay_date !== "" || Preferred_pay_date !== null) {
    //   this.setState({
    //     preferred_date: moment(Preferred_pay_date).format("YYYY-MM-DD"),
    //   });
    // }
    // this.setState({
    //   'currentDate': this.getDate(new Date(Date.now()))
    // });
    // this.getBankHouseholdDetailsNACH(uid);

    // console.log("TESTING");
  }

  getBankHouseholdDetailsNACH() {
    this.state.hh_bank_details = [];

    this.setState({ isSpin: true });
    const uid = localStorage.getItem("in_userid");
    const { api_token } =
      (this.props.location && this.props.location.state) || {};

    const params = {
      user_id: uid,
      household_number: this.state.search_text
    };
    const queryParams = new URLSearchParams(params).toString();

    fetch(
      process.env.REACT_APP_URL + "/household/bank/details/nach?" + queryParams,
      {
        method: "GET",
        headers: { "api-token": api_token },
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        console.log("loan_data - ", json);
        if (json.api_code === 4 || json.api_code === 3 || json.api_code === 5) {
          localStorage.clear();
          this.props.history.push("/");
        }
        if (json.length == 0) {
          this.setState({
            isSpin: false,
            isErrOpen: true,
            message: "Data Not Found!",
          });
        } else {
          this.setState({
            isSpin: false,
            hh_bank_details: json,
            og_data: json,
          });
        }
      })
      .catch((error) => console.log("error", error));
  }

  setData(data) {
    var sflag = 0;
    this.state.isSpin = true;
    this.forceUpdate();
    if (data.length == 0) {
      this.state.message = "Bank Details are not Present!";
      this.state.isErrOpen = true;
    } else if (data.length == 1) {
      if (data[0].bank_name == null || data[0].bank_name == "") {
        this.state.message = "Bank Details Cannot be Blank!";
        this.state.isErrOpen = true;
      } else if (data[0].nach_status == "Registered") {
        this.state.message = "NACH already Registered, Deactive First";
        this.state.isErrOpen = true;
      } else {
        sflag = 1;
      }
    } else {
      var cdata = [];

      data.map((item) => {
        if (item.nach_status != "Registered") {
          if (item.bank_name != null) {
            cdata.push(item);
          }
        }
      });

      data = cdata;

      if (data.length > 0) {
        sflag = 1;
      } else {
        sflag = 0;
      }
    }

    // this.state.isSpin = false/;

    this.state.data = data;

    if (sflag == 1) {
      var request = new XMLHttpRequest();
      request.onreadystatechange = (e) => {
        if (request.readyState !== 4) {
          return;
        }

        if (request.status === 200) {
          var res = JSON.parse(request.responseText);
          console.log("success", request.responseText);

          if (res["status"] == 1) {
            if (res["physical"] == "Y") {
              this.state.pbutton = true;
            } else {
              this.state.pbutton = false;
            }
            if (res["netbanking"] == "Y") {
              this.state.nbutton = true;
            } else {
              this.state.nbutton = false;
            }
            if (res["debitcard"] == "Y") {
              this.state.dbutton = true;
            } else {
              this.state.dbutton = false;
            }

            this.state.isSpin = false;
            this.state.isOpenNach = true;
            this.forceUpdate();
          } else {
            this.state.isErrOpen = true;
          }
        } else {
          console.warn("error");
        }
      };

      const { api_token } =
        (this.props.location && this.props.location.state) || {};

      const params = {
        ifsc_code: data[0].ifsc,
        partner_vendor_id: this.state.vendor_id,
      };
      const queryParams = new URLSearchParams(params).toString();

      request.open(
        "GET",
        process.env.REACT_APP_URL + "/check/bankdetails/nachType?" + queryParams
      );
      request.setRequestHeader("api-token", api_token);
      request.send();
    }
  }

  openNachList(hh_no, regtype) {
    const { effective_designation_id, api_token } =
      (this.props.location && this.props.location.state) || {};

    // const ftoken = localStorage.getItem("currentToken");
    // const formdata2 = new FormData();
    // formdata2.append("in_username", process.env.REACT_APP_NACH_USERNAME);
    // formdata2.append("in_user_password", process.env.REACT_APP_NACH_PASSWORD);
    // formdata2.append("in_client_ip", this.state.IPv4);
    // formdata2.append("in_latitude", this.state.latitude);
    // formdata2.append("in_longitude", this.state.longitude);
    // formdata2.append(
    //   "in_gps_address",
    //   this.state.latitude + "," + this.state.longitude
    // );
    // formdata2.append("in_userid", 1);
    // formdata2.append("firebase_token", ftoken);
    // console.log(process.env.REACT_APP_NACH_HOUSEHOLD_LIST);
    // console.log(process.env.REACT_APP_NACH_USERNAME);

    // console.log(process.env.REACT_APP_URL + "/userexist")
    // formdata.append("in_username", process.env.REACT_APP_NACH_USERNAME);
    // fetch(process.env.REACT_APP_URL + "/userexist", {
    //   method: "POST",
    //   body: formdata2,
    // })
    //   .then((res2) => {
    //     return res2.json();
    //   })
    //   .then((data2) => {
    //     console.log(data2);
    //     if (data2.logged_in === true) {
    //       console.log(
    //         process.env.REACT_APP_NACH_HOUSEHOLD_LIST +
    //           "/NACH_Household_list?api_token=" +
    //           data2.api_token +
    //           "&lead_id=" +
    //           hh_no +
    //           "&user_id=" +
    //           data2.in_userid
    //       );
    //       this.props.history.push({
    //         // pathname: process.env.REACT_APP_NACH_HOUSEHOLD_LIST,
    //         state: { api_token: data2.api_token },
    //       });
    //       if (regtype == "NACH") {
    //         window.location.replace(
    //           process.env.REACT_APP_NACH_HOUSEHOLD_LIST +
    //             "/NACH_Household_list?api_token=" +
    //             data2.api_token +
    //             "&lead_id=" +
    //             hh_no +
    //             "&user_id=" +
    //             data2.in_userid
    //         );
    //       }
    //       if(regtype=="upi")
    //       {
    //         window.location.replace(
    //           process.env.REACT_APP_NACH_HOUSEHOLD_LIST +
    //             "/upi_autopay?api_token=" +
    //             data2.api_token +
    //             "&lead_id=" +
    //             hh_no +
    //             "&e_id=" +
    //             effective_designation_id
    //         );
    //       }
    //     }
    //   });
    if (regtype == "NACH") {
      const uid = localStorage.getItem("in_userid");
      window.location.replace(
        process.env.REACT_APP_NACH_HOUSEHOLD_LIST +
        "/NACH_Household_list?api_token=" +
        api_token +
        "&lead_id=" +
        hh_no +
        "&user_id=" +
        uid +
        "&e_id=" +
        effective_designation_id+
        "&b_id=" +
        localStorage.getItem("branch_id")
      );
    }
    if (regtype == "upi") {
      window.location.replace(
        process.env.REACT_APP_NACH_HOUSEHOLD_LIST +
        "/upi_autopay?api_token=" +
        api_token +
        "&lead_id=" +
        hh_no +
        "&e_id=" +
        effective_designation_id+
        "&b_id=" +
        localStorage.getItem("branch_id")
      );
    }
  }

  handleSearchInput = (e) => {

    this.state.search_text = e.target.value.toUpperCase();
    // console.log(this.state.search_text);
    if (this.state.search_text == "") {
      this.state.hh_bank_details = this.state.og_data;
    }
    this.forceUpdate();
  };

  onSearch = () => {
    let temp = this.state.og_data;

    console.log(this.state.search_text);
    if (this.state.search_text != "") {
      if (this.state.search_text.length < 5) {
        this.state.isErrOpen = true;
        this.state.message = "Search String Should be Atleast 5 Characters!";

      } else {

        this.getBankHouseholdDetailsNACH();
        let sort_data = [];
        // if (this.state.hh_bank_details.length == 0) {
        //   this.state.isErrOpen = true;
        //   this.state.message = "Data Not Found!";
        // sort_data = this.state.hh_bank_details.filter((i) => {
        //   return i.household_number.includes(this.state.search_text) ||
        //     i.household_name.includes(this.state.search_text)
        //     ? i
        //     : "";
        // });
        // }
        // console.log(sort_data);
        // this.state.hh_bank_details = sort_data;
        // this.forceUpdate();
      }
    } else {
      this.state.hh_bank_details = this.state.og_data;


    }
    this.forceUpdate();

  };

  render() {
    const {
      hh_bank_details,
      data,
      isErrOpen,
      message,
      isOpenNach,
    } = this.state;
    // console.log(this.state.data);
    return (
      <>
        {this.state.isSpin ? (
          <div class="preloader">
            <div class="loader" ng-hide="data.length > 0"></div>
          </div>
        ) : (
          ""
        )}

        <div
          className={
            isErrOpen
              ? "modal fade bd-example-modal-sm show mymodal"
              : "modal fade bd-example-modal-sm"
          }
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="confirm-box"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content cdb-confirm-box col-sm-12">
              <button
                type="button"
                className="close text-right"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => this.setState({ isErrOpen: false })}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <p>{message}</p>
            </div>
          </div>
        </div>

        <div
          className={
            isOpenNach
              ? "modal fade bd-example-modal-sm show mymodal"
              : "modal fade bd-example-modal-sm"
          }
          id="nachModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  Register NACH
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => this.setState({ isOpenNach: false })}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <table>
                  <tr>
                    <td>Bank</td>
                    <td>
                      <select>
                        {this.state.data.map((item) => {
                          return (
                            <option
                              value={
                                item.ifsc +
                                "|" +
                                item.bank_name +
                                "|" +
                                item.account_number
                              }
                            >
                              {item.ifsc +
                                " | " +
                                item.bank_name +
                                " | " +
                                item.account_number}
                            </option>
                          );
                        })}
                      </select>
                    </td>
                  </tr>

                  <tr>
                    <td>Vendor</td>
                    <td>
                      <select>
                        <option value="2">Razorpay</option>
                      </select>
                    </td>
                  </tr>

                  <tr>
                    <td>NACH Type</td>
                    <td>
                      {this.state.pbutton && (
                        <button className="btn btn-primary">Physical</button>
                      )}
                      {this.state.nbutton && (
                        <button className="btn btn-info">Netbanking</button>
                      )}
                      {this.state.dbutton && (
                        <button className="btn btn-warning">Debit Card</button>
                      )}
                    </td>
                  </tr>
                </table>
              </div>
              {/* <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary">Save changes</button>
              </div> */}
            </div>
          </div>
        </div>

        <div className="call_detail container d-flex flex-column align-items-center">
          <div className="ca-header-box">
            <div className="collection_block_header">
              <div className="title-holder_bg">
                <div
                  className="back_icon_bg"
                  onClick={() => this.props.history.goBack()}
                >
                  <img
                    src={require("../assets/images/back.png")}
                    className="back_icon_img"
                  />
                </div>
                <h2>Household Bank Details</h2>
                
                {this.state.flag === 0 && (
                <div className="log-out">
                  <img
                    src={require("../assets/images/power-off.png")}
                    onClick={() => logout()}
                    alt=""
                  />
                </div>
                )}
                {/* {console.log("flag......" +this.state.flag)} */}
              </div>
            </div>
          </div>
          {/* <div className="call_detail_nav">
            <button
              className="button back_button"
              onClick={() => this.props.history.goBack()}
            >
              <img src={require("../assets/images/back.png")} />
            </button>
            <span className="nav_heading">Household Bank Details</span>
           
            <button className="button" onClick={() => logout()}>
              <img src={require("../assets/images/power-off.png")} alt="" />
            </button>
          </div> */}
          <br />
          <div className="support_list_search_box">
            <span>Enter Household Number or Lead ID</span>
            <input
              className="form-control"
              type="text"
              value={this.state.search_text}
              onChange={this.handleSearchInput}
            />
            <button
              className="btn btn-primary rbtn btn-lg col-8 col-md-6 col-lg-4"
              onClick={this.onSearch}
            >
              Search
            </button>
          </div>

          {/* <div>
            <input type="text" className="form-control" placeholder="Enter Household Name or Lead ID" id="searchHH" name="searchHH" />
            <button className="btn btn-primary">Search</button>
          </div> */}

          {hh_bank_details.map((item, key) => {
            return (
              <div className="hh_white_block_nach" key={key} onClick={() => { }}>
                <div className="name_hh_block_nach">
                  <div className="d-flex flex-column align-items-center justify-content-center">
                    <span className="name">
                      HH Name: {item.household_name}
                      <br />
                      Lead ID: {item.household_number}
                    </span>
                  </div>
                  {/* {this.state.isUpi && ( */}
                  <button
                    className={`upload_icon_bg_upi mx-1 pull-right`}
                    data-toggle="modal"
                    data-target="#nachModal"
                    onClick={(e) => {
                      e.stopPropagation();

                      this.openNachList(item.household_number, "upi");

                      // this.setData(item.household_data)
                    }}
                  >
                    <img
                      src={require("../assets/images/nach_old.png")}
                      className="nach_icon_img"
                    />
                  </button>
                  {/* )} */}

                  <button
                    className={`upload_icon_bg_nach mx-1 pull-right`}
                    data-toggle="modal"
                    data-target="#nachModal"
                    onClick={(e) => {
                      e.stopPropagation();

                      this.openNachList(item.household_number, "NACH");

                      // this.setData(item.household_data)
                    }}
                  >
                    <img
                      src={require("../assets/images/add.png")}
                      className="nach_icon_img"
                    />
                  </button>
                </div>
                <div className="amt_details_block2">
                  {item.household_data.map((element) => {
                    return (
                      <div className="bank_details_nach">
                        <div className="bank_detail_nach">
                          <div className="bank_data">
                            <h6>
                              <b>{element.name_as_per_account}</b>
                            </h6>
                          </div>
                          <div className="bank_data">
                            {element.nach_status == "Not_Registered" ? (
                              <h5>
                                <span class="badge badge-warning">
                                  {element.nach_status}
                                </span>
                              </h5>
                            ) : element.nach_status === "Uploaded" ||
                              element.nach_status === "Issued" ||
                              element.nach_status === "Initiated" ? (
                              <h5>
                                <span class="badge badge-primary">
                                  {element.nach_status}
                                </span>
                              </h5>
                            ) : element.nach_status === "Registered" ? (
                              <h5>
                                <span class="badge badge-success">
                                  {element.nach_status}
                                </span>
                              </h5>
                            ) : (
                              <h5>
                                <span class="badge badge-danger">
                                  {element.nach_status}
                                </span>
                              </h5>
                            )}
                          </div>
                        </div>
                        <hr className="dotted_line_nach"></hr>
                        <div className="bank_detail_nach">
                          <div className="bank_data">
                            <h6>{element.bank_name}</h6>
                          </div>
                          <div className="bank_data">
                            <h6>{element.account_number}</h6>
                          </div>
                        </div>
                        <hr className="dotted_line_nach"></hr>
                        <div className="bank_detail_nach">
                          <div className="bank_data">
                            <h6>{element.ifsc}</h6>
                          </div>
                          <div className="bank_data">
                            <h6>{element.nach_type}</h6>
                          </div>
                        </div>

                        <div className="bank_detail_bottom_block">
                          <h6 className="nach_status_dt">
                            {element.nach_status_dt != null ||
                              element.nach_status_dt != ""
                              ? element.nach_status_dt
                              : ""}
                          </h6>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </>
    );
  }
}
